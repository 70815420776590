import React, { lazy, Suspense } from 'react';
import { Helmet } from 'react-helmet';
import { useLocation } from 'react-router-dom';

// Lazy load the components
const TopNavbar = lazy(() => import('../../Main-Components/TopNavbar/TopNavbar'));
const Menubar = lazy(() => import('../../Main-Components/Menubar/Menubar'));
const Footer = lazy(() => import('../../Main-Components/Footer/Footer'));
const ContactUsBanner = lazy(() => import('../../Small-Components/ContactUsBanner/ContactUsBanner'));
const ContactUsForm = lazy(() => import('../../Small-Components/ContactUsForm/ContactUsForm'));

export default function ContactUs() {

  const location = useLocation();

  const canonical = () => {
    const base_url = "https://onepagehoroscope.com";
    const current_path = location.pathname;

    const canonicalPaths = {
      "/contact-us": "/contact-us",
    };

    return (
      <link
        rel="canonical"
        href={base_url + (canonicalPaths[current_path] || current_path)}
      />
    );
  };

  return (
    <>
      <Helmet>
        <title>Contact Us Now | For Personalized Horoscope Services</title>
        <meta
          name="description"
          content="Get personalized horoscope services like marriage matching and baby names at One Page Horoscope. Contact us for support through email or phone now!"
        />
        <meta
          name="keywords"
          content="one page Horoscope,contact us,support,Horoscope"
        />
        <meta name="robots" content="index,follow" />
        {canonical()}
        <script type="application/ld+json">
          {`
            {
              "@context": "https://schema.org/",
              "@type": "Product",
              "name": "OnepageHoroscope About us",
              "image": "",
              "description": "Get personalized horoscope services like marriage matching and baby names at One Page Horoscope. Contact us for support through email or phone now!",
              "brand": {
                "@type": "Brand",
                "name": "One Page Horoscope"
              }
            }
          `}
        </script>
      </Helmet>

      {/* Suspense component to show fallback while components are being loaded */}
      <Suspense fallback={<div>Loading...</div>}>
        <TopNavbar />
        <Menubar NavBgstyle="outer-header rjr" NavTextColor="text-dark" SubmenuTop="true" />
        <ContactUsBanner />
        <ContactUsForm />
        <Footer />
      </Suspense>
    </>
  );
}
