import React from 'react'

export default function BlogListBanner() {
    return (
        <>
            <section className="page-outer center rjr blp">
                <div className="astro-container">
                    <div className="row">
                        <div className="col">
                            <div className="content">
                                <h1 className="sh lh-1">
                                    Explore Our <span className="grt">Astrology Insights</span>
                                </h1>
                                <p className="p-outer">
                                    Unlock the mysteries of the stars and embrace the cosmic guidance
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}
