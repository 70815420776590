import React, { createContext, useState } from 'react';

export const MarriageMatchingContext = createContext();

function MarriageMatching_ContextProvider({ children }) {
    const [dayOptions] = useState([...Array(31).keys()].map((day) => String(day + 1).padStart(2, '0')));
    const [monthOptions] = useState([...Array(12).keys()].map((month) => String(month + 1).padStart(2, '0')));
    const [yearOptions] = useState(() => {
        const currentYear = new Date().getFullYear();
        const startYear = 1950;
        return [...Array(currentYear - startYear + 1).keys()].map(
            (year) => String(startYear + year)
        );
    });

    const [hourOptions] = useState([...Array(12).keys()].map((hour) => String(hour + 1).padStart(2, '0')));
    const [minuteOptions] = useState([...Array(60).keys()].map((minute) => String(minute).padStart(2, '0')));

    const [maleName, setMalename] = useState("");
    const [maleHour, setMaleHour] = useState("");
    const [male_minute, setMaleMinute] = useState("");
    const [maleAmpm, setMaleAmpm] = useState("");
    const [maleBirthday, setMaleBithDay] = useState("");
    const [maleBirthMonth, setMaleBirthMonth] = useState("");
    const [maleBirthYear, setMaleBirthYear] = useState("");
    const [maleBirthplace, setMaleBirthPlace] = useState("");
    const [femaleName, setFemaleName] = useState("");
    const [femaleHour, setFemaleHour] = useState("");
    const [femaleMinute, setfemaleMinute] = useState("");
    const [femaleAmpm, setFemaleAmpm] = useState("");
    const [femaleBirthDay, setFemaleBirthDay] = useState("");
    const [femaleBirthMonth, setFemaleBirthMonth] = useState("");
    const [femaleBirthYear, setFemaleBirthYear] = useState("");
    const [femaleBirthplace, setFemaleBirthPlace] = useState("");
    const [malesuggestedPlaces, setMaleSuggestedPlaces] = useState([]);
    const [femalesuggestedPlaces, setfeMaleSuggestedPlaces] = useState([]);
    const [maleselectedPlaces, setmaleSelectedPlaces] = useState([]);
    const [femaleselectedPlaces, setfemaleSelectedPlaces] = useState([]);
    // error validation states
    const [maleNameError, setMaleNameError] = useState("");
    const [femaleNameError, setFemaleNameError] = useState("");
    const [maleDayError, setMaleDayError] = useState("");
    const [feMaleDayError, setFemaleDayError] = useState("");
    const [maleMonthError, setMaleMonthError] = useState("");
    const [femaleMonthError, setFemaleMonthError] = useState("");
    const [maleYearError, setMaleYearError] = useState("");
    const [femaleYearError, setFemaleYearError] = useState("");
    const [maleHourError, setMaleHourError] = useState("");
    const [femaleHourError, setFemaleHourError] = useState("");
    const [maleMinuteError, setMaleMinuteError] = useState("");
    const [feMaleMinuteError, setFemaleMinuteError] = useState("");
    const [maleAmPmError, setAmpmMinuteError] = useState("");
    const [femaleAmPmError, setfeAmpmMinuteError] = useState("");
    const [malebirthplaceError, setMaleBirthplaceError] = useState("");
    const [femalebirthplaceError, setfeMaleBirthplaceError] = useState("");
    const [MaleLocationSwitch, setMaleLocationSwitch] = useState(false);
    const [FemaleLocationSwitch, setFemaleLocationSwitch] = useState(false);
    const [MaleTimeZone, setMaleTimeZone] = useState("");
    const [FemaleTimeZone, setFemaleTimeZone] = useState("");
    const [marriageMatchingFromData, setmarriageMatchingFromData] = useState({
        maleName: "",
        MaleDateofBirth: "",
        MaleBirthtime: "",
        MaleBirthplace: "",
        Malelatitude: "",
        Malelongitude: "",
        FemaleName: "",
        femaleBirthplace: "",
        feMaleDateofBirth: "",
        FemaleBirthTine: "",
        femalelatitude: "",
        femalelongitude: "",
    });

    const contextValue = {
        dayOptions,
        monthOptions,
        yearOptions,
        hourOptions,
        minuteOptions,
        maleName, setMalename,
        maleHour, setMaleHour,
        male_minute, setMaleMinute,
        maleAmpm, setMaleAmpm,
        maleBirthday, setMaleBithDay,
        maleBirthMonth, setMaleBirthMonth,
        maleBirthYear, setMaleBirthYear,
        maleBirthplace, setMaleBirthPlace,
        femaleName, setFemaleName,
        femaleHour, setFemaleHour,
        femaleMinute, setfemaleMinute,
        femaleAmpm, setFemaleAmpm,
        femaleBirthDay, setFemaleBirthDay,
        femaleBirthMonth, setFemaleBirthMonth,
        femaleBirthYear, setFemaleBirthYear,
        femaleBirthplace, setFemaleBirthPlace,
        malesuggestedPlaces, setMaleSuggestedPlaces,
        femalesuggestedPlaces, setfeMaleSuggestedPlaces,
        maleselectedPlaces, setmaleSelectedPlaces,
        femaleselectedPlaces, setfemaleSelectedPlaces,
        maleNameError, setMaleNameError,
        femaleNameError, setFemaleNameError,
        maleDayError, setMaleDayError,
        feMaleDayError, setFemaleDayError,
        maleMonthError, setMaleMonthError,
        femaleMonthError, setFemaleMonthError,
        maleYearError, setMaleYearError,
        femaleYearError, setFemaleYearError,
        maleHourError, setMaleHourError,
        femaleHourError, setFemaleHourError,
        maleMinuteError, setMaleMinuteError,
        feMaleMinuteError, setFemaleMinuteError,
        maleAmPmError, setAmpmMinuteError,
        femaleAmPmError, setfeAmpmMinuteError,
        malebirthplaceError, setMaleBirthplaceError,
        femalebirthplaceError, setfeMaleBirthplaceError,
        marriageMatchingFromData, setmarriageMatchingFromData,
        MaleTimeZone,setMaleTimeZone,
        FemaleTimeZone,setFemaleTimeZone,
        FemaleLocationSwitch,setFemaleLocationSwitch,
        MaleLocationSwitch,setMaleLocationSwitch
    };
    return (
        <MarriageMatchingContext.Provider value={contextValue}>
            {children}
        </MarriageMatchingContext.Provider>
    )
}

export default MarriageMatching_ContextProvider;