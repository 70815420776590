import React from 'react'
import TopNavbar from '../../Main-Components/TopNavbar/TopNavbar'
import Menubar from '../../Main-Components/Menubar/Menubar'
import Footer from '../../Main-Components/Footer/Footer'
import OnePagePaymentSuccessBanner from '../../Small-Components/OnePagePaymentSuccessBanner/OnePagePaymentSuccessBanner';
import HorsocopeBookPaymentSuccessContent from '../../Small-Components/HorsocopeBookPaymentSuccessContent/HorsocopeBookPaymentSuccessContent';

export default function TamilJathagamBookPaymentSuccess() {
    return (
        <>
            <TopNavbar />
            <Menubar NavBgstyle="outer-header bc-outer" NavTextColor="text-dark" SubmenuTop="true" />
            <OnePagePaymentSuccessBanner />
            <HorsocopeBookPaymentSuccessContent />
            <Footer />
        </>
    )
}
