import React, { useState } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import { Loading } from "notiflix/build/notiflix-loading-aio";
import { Notify } from "notiflix/build/notiflix-notify-aio";
import ReactDOMServer from "react-dom/server";
import html2pdf from "html2pdf.js/dist/html2pdf.min";
import { useContext } from 'react';
import Chart from '../../Main-Components/Chart/Chart'

import { MarriageMatchingViewContext } from "../../context/viewpage/MarriageMatchingViewContextprovider";
// import getepayPortal from '../../../../components/tamil/Getepay_pg_react';
import MgPaidTemplate from "../../../../components/tamil/Mg-Paid-Template/MgPaidTemplate";
import { Report } from "notiflix/build/notiflix-report-aio";
import { Confirm } from 'notiflix/build/notiflix-confirm-aio';
import { RazerpayPaymentGateway } from '../../payment-gateway/RazerpayGateway';

function MarriageMathingViewChart() {
  const { value } = useContext(MarriageMatchingViewContext);
  const [showPopup, setShowPopup] = useState(false);
  const [buyerMobilenumber, setMobilenumber] = useState("");
  const [email, setEmail] = useState("");
  const [buyerName, setBuyername] = useState("");
  const [mobilenumberError, setMobieNumberError] = useState("");
  const [emailError, setEmailError] = useState("");
  const [buyerNameError, setBuyerNameError] = useState("");

  const female_star = value.female_rasi_table;
  const male_star = value.male_rasi_table;
  const FemaleRasiChat = {
    'r1': female_star.female_mesham,
    'r2': female_star.female_rishbam,
    'r3': female_star.female_mithunam,
    'r4': female_star.female_kadagam,
    'r5': female_star.female_simmam,
    'r6': female_star.female_kanni,
    'r7': female_star.female_thulam,
    'r8': female_star.female_viruchagam,
    'r9': female_star.female_dhanush,
    'r10': female_star.female_magaram,
    'r11': female_star.female_kumbam,
    'r12': female_star.female_meenam,
  }
  const maleRasiChart = {
    'r1': male_star.male_mesham,
    'r2': male_star.male_rishbam,
    'r3': male_star.male_mithunam,
    'r4': male_star.male_kadagam,
    'r5': male_star.male_simmam,
    'r6': male_star.male_kanni,
    'r7': male_star.male_thulam,
    'r8': male_star.male_viruchagam,
    'r9': male_star.male_dhanush,
    'r10': male_star.male_magaram,
    'r11': male_star.male_kanni,
    'r12': male_star.male_meenam,
  }


  // const [user_id, setUserId] = useState(initialUserId);


  const validateInput = () => {
    let hasError = false;

    if (!email) {
      setEmailError("Field is required");
      hasError = true;
    } else {
      setEmailError("");
    }

    if (!buyerName) {
      setBuyerNameError("Field is required");
      hasError = true;
    } else {
      setBuyerNameError("");
    }

    if (!buyerMobilenumber || buyerMobilenumber.length !== 10) {
      setMobieNumberError("Field is required and must be 10 digits");
      hasError = true;
    } else {
      setMobieNumberError("");
    }

    return !hasError;
  };


  const navigate = useNavigate();

  const customRedirect = (route, data) => {
    navigate(route, { state: data });
  };

  const editForm = () => {
    const outputvalue = JSON.parse(localStorage.getItem("mgformdata"));
    customRedirect("/Marriage-matching", outputvalue);
    // console.log(outputvalue);
  };


  const HandlePAY = () => {
    if (validateInput()) {
      const storedUserInfo = localStorage.getItem("MgUserInfo");
      const userInfo = JSON.parse(storedUserInfo);

      const user_data_id = userInfo.user_id;
      const customer_data_id = userInfo.customerDataId;

      if ((user_data_id !== "" && user_data_id !== null) && (customer_data_id !== "" && customer_data_id !== null)) {
        proceedToPayment();
      } else {
        if ((user_data_id === null || user_data_id === "") && (customer_data_id === null || customer_data_id === "")) { // Fix the condition here
          const mGGeomenery = localStorage.getItem("mgformdata");
          const GeoLocation = JSON.parse(mGGeomenery);
          const Male_latitude = GeoLocation.Malelatitude;
          const Male_longitude = GeoLocation.Malelongitude;
          const feMale_latitude = GeoLocation.femalelatitude;
          const feMale_longitude = GeoLocation.femalelongitude;
          const userData = {
            buyerName: buyerName,
            buyerEmail: email,
            buyerMobileNo: buyerMobilenumber,
            maleName: value.personal_info.male_name,
            FemaleName: value.personal_info.female_name,
            MaleDateofBirth: value.personal_info.maleDateofBirth,
            FemaleDateofBirth: value.personal_info.female_dob,
            MaleBirthTime: value.personal_info.male_birth_time,
            FemaleBirthTime: value.personal_info.female_birth_time,
            MalebirthPlace: value.personal_info.male_birthplace,
            FemaleBirthPlace: value.personal_info.female_birthplace,
            Malelatitude: Male_latitude,
            Malelongitude: Male_longitude,
            femalelatitude: feMale_latitude,
            femalelongitude: feMale_longitude
          };
          // console.log(userData);
          axios
            .post("https://ctsvsolutions.com/onepage-Api/public/api/Mg-Statement-customerData-insert", {
              userData,
            })
            .then((response) => {
              const userId = response.data.data.userId;
              const customerDataId = response.data.data.customerDataId;
              const userInfo = {
                user_id: userId,
                customerDataId: customerDataId,
              };
              const UserInfoValues = JSON.stringify(userInfo);
              localStorage.setItem("MgUserInfo", UserInfoValues);
              // setcustomerDataId(customerDataId);
              // setUserId(userId);
              proceedToPayment();
            });
        } else {
          proceedToPayment();
        }
      }
    }
  };

  const proceedToPayment = async() => {
    const storedUserInfo = localStorage.getItem("MgUserInfo");
    const userInfo = JSON.parse(storedUserInfo);
    Loading.dots();
    const user_id = userInfo.user_id;
    const customerDataId = userInfo.customerDataId;
  
    const payment_Data = {
      amount: '149', 
      email: email,
      description:'Marriage matching',
      name: buyerName,
      mobileNumber: buyerMobilenumber,
      user_id: user_id,
      theme: '#FDF2F8',
      customerDataId: customerDataId,
      paymentCallBackurl: 'https://ctsvsolutions.com/onepage-Api/public/api/update-razerpay-payment-paid-status'
    };
    setShowPopup(false);
    await RazerpayPaymentGateway(payment_Data,DownloadMarriagePaidPdf,true);
    Loading.remove();
    // const user_id = userInfo.user_id;
    // const customerDataId = userInfo.customerDataId;
    // const data = {
    //   mid: "993377",
    //   amount: "149.00",
    //   merchantTransactionId: "sd12121",
    //   transactionDate: "Mon Oct 03 13:54:33 IST 2022",
    //   terminalId: "getepay.merchant131068@icici",
    //   udf1: buyerMobilenumber,
    //   udf2: email,
    //   udf3: buyerName,
    //   udf4: user_id,
    //   udf5: customerDataId,
    //   udf6: "",
    //   udf7: "",
    //   udf8: "",
    //   udf9: "",
    //   udf10: "",
    //   ru: "https://ctsvsolutions.com/onepage-Api/public/mG-Payment",
    //   callbackUrl: "",
    //   currency: "INR",
    //   paymentMode: "ALL",
    //   bankId: "",
    //   txnType: "single",
    //   productType: "IPG",
    //   txnNote: "Onepage-marriage matchings",
    //   vpa: "getepay.merchant131068@icici",
    // };

    // const Config = {
    //   "mid": "993377",
    //   "Getepay Terminal Id": "getepay.merchant131068@icici",
    //   "Getepay Key": "rjW99m2Vo75SBbpyc+K5nfB/eebzWUmkAtx90U5Ku3I=",
    //   "Getepay IV": "Y7fOrFLKVPosfDFSIFSISQ==",
    //   "Getepay Url":
    //     "https://portal.getepay.in:8443/getepayPortal/pg/generateInvoice"
    // };
    // // console.log(data);
    // getepayPortal(data, Config);
  }
  const paidpdfConvertion = () => {
    return <MgPaidTemplate value={value} />;
  };

  //after the payment
  const currentLocation = window.location.href;
  const [baseUrl, queryParamString] = currentLocation.split("?");
  const queryParamsObject = {};

  if (queryParamString) {
    const queryParams = queryParamString.split("&");

    for (const param of queryParams) {
      const [key, value] = param.split("=");
      queryParamsObject[key] = value;
    }
    const payment_status = queryParamsObject["payment_status"];

    if (payment_status === "CrEdiT") {
      Report.success(
        "Payment Success",
        "உங்கள் கட்டணம் வெற்றிகரமாக பெறப்பட்டது ! நீங்கள்  கீழே உள்ள Download ஐ  கிளிக் செய்து உங்கள் PDF ஐ பதிவிறக்கம் செய்து கொள்ளலாம் ...",
        "Download Pdf",
        () => {
          Loading.hourglass("Marriage Matching is processing...", {
            backgroundColor: "rgba(0,0,0,0.8)",
          });

          const storedUserInfo = localStorage.getItem("MgUserInfo");
          const userInfo = JSON.parse(storedUserInfo);

          const printElement = ReactDOMServer.renderToString(
            paidpdfConvertion()
          );
          html2pdf()
            .from(printElement)
            .set({
              image: { type: 'jpeg', quality: 1 },
              dpi: 300,
              renderer: 'canvas',
              html2canvas: { scale: 2 },
            })
            .save();

          html2pdf().from(printElement)
            .set({
              image: { type: 'jpeg', quality: 1 },
              dpi: 300,
              renderer: 'canvas',
              html2canvas: { scale: 2 },
            }).outputPdf()
            .then((pdfData) => {
              const base64Data = btoa(pdfData);
              savePaidPdf(base64Data, userInfo.user_id, userInfo.customerDataId);
            });
        }
      );
    }
  }
  const savePaidPdf = (pdfData, user_id, customerDataId) => {
    const pdfDataVal = {
      pdfData: pdfData,
      userId: user_id,
      customerId: customerDataId,
      FileName: 'marriage_matching'
    };
    const DataInfo = {
      userId: user_id,
      customerId: customerDataId,
      columnName: "pdf_file"
    };
    axios
      .post("https://ctsvsolutions.com/onepage-Api/public/api/Save-Paid-Pdf", pdfDataVal)
      .then((response) => {
        axios
          .post(
            "https://ctsvsolutions.com/onepage-Api/public/api/send-paid-PdfIn-whastapp",
            DataInfo
          )
          .then((response) => {
            axios
              .post(
                "https://ctsvsolutions.com/onepage-Api/public/api/Send-pdfIn-Email",
                DataInfo
              )
              .then((response) => {

                Loading.remove();
                Confirm.prompt(
                  "Rating ⭐⭐⭐⭐⭐",
                  "How was your experince?",
                  "",
                  "Answer",
                  "Cancel",
                  (clientAnswer) => {
                    const RatingInfo = {
                      'user_id': user_id,
                      'customer_data_id': customerDataId,
                      'feedback': clientAnswer
                    }
                    axios
                      .post(
                        "https://ctsvsolutions.com/onepage-Api/public/api/Update-Feed-Back",
                        RatingInfo
                      )
                      .then((response) => {
                        // window.location='Marriage-Matching';
                        window.location.replace("/Marriage-Matching-ThankYou");
                      });

                  },
                  (clientAnswer) => {
                    window.location.replace("/Marriage-Matching-ThankYou");
                  },

                );
                Notify.success("Sample pdf file sended succussfully");

              })
              .catch((error) => {
                console.error("Error sending WhatsApp message:", error);
              });
          })
          .catch((error) => {
            console.error("Error sending WhatsApp file:", error);
          });
      })
      .catch((error) => {
        console.error("Error saving PDF:", error);
      });
  };
  const DownloadMarriagePaidPdf=()=>{
    const storedUserInfo = localStorage.getItem("MgUserInfo");
    const userInfo = JSON.parse(storedUserInfo);

    const printElement = ReactDOMServer.renderToString(
      paidpdfConvertion()
    );
    html2pdf()
      .from(printElement)
      .set({
        image: { type: 'jpeg', quality: 1 },
        dpi: 300,
        renderer: 'canvas',
        html2canvas: { scale: 2 },
      })
      .save();
    html2pdf().from(printElement)
      .set({
        image: { type: 'jpeg', quality: 1 },
        dpi: 300,
        renderer: 'canvas',
        html2canvas: { scale: 2 },
      }).outputPdf()
      .then((pdfData) => {
        const base64Data = btoa(pdfData);
        savePaidPdf(base64Data, userInfo.user_id, userInfo.customerDataId);
      });
  }
  return (
    <>
      <section className="ac-charts ac-charts-2">
        <div className="astro-container">
          <div className="heading">
            <h2 className="sh">
            ஜாதக<span className="grt"> கட்டம்</span>
            </h2>
          </div>
          <div className="row">
            <div className="col">
              <div className="chart">
                <div className="curve top-curve">
                  <img src='https://ctsvsolutions.com/onepage-images/new-ui/chart-top-curve.webp' alt='male marraige matching curve top'/>
                </div>
                <div className="curve top-right">
                  <img src='https://ctsvsolutions.com/onepage-images/new-ui/chart-right-curve.webp' alt='male marraige matching curve right'/>
                </div>
                <div className="curve top-bottom">
                  <img src='https://ctsvsolutions.com/onepage-images/new-ui/chart-bottom-curve.webp' alt='male marraige matching curve bottom'/>
                </div>
                <div className="curve top-left">
                  <img src='https://ctsvsolutions.com/onepage-images/new-ui/chart-left-curve.webp' alt='male marraige matching curve left'/>
                </div>
                <Chart rasiChat={maleRasiChart} type="rasi-chart" chartName="ராசி"></Chart>
              </div>
              <div className="chart-heading">
                <h3 className="sh">
                  <span className="grt">மணமகன் </span>ராசி கட்டம்
                </h3>
              </div>
            </div>
            <div className="col">
              <div className="chart">
                <div className="curve top-curve">
                  <img src='https://ctsvsolutions.com/onepage-images/new-ui/chart-top-curve.webp' alt='female marraige matching curve top'/>
                </div>
                <div className="curve top-right">
                  <img src='https://ctsvsolutions.com/onepage-images/new-ui/chart-right-curve.webp' alt='female marraige matching curve right'/>
                </div>
                <div className="curve top-bottom">
                  <img src='https://ctsvsolutions.com/onepage-images/new-ui/chart-bottom-curve.webp' alt='female marraige matching curve bottom'/>
                </div>
                <div className="curve top-left">
                  <img src='https://ctsvsolutions.com/onepage-images/new-ui/chart-left-curve.webp' alt='female marraige matching curve left'/>
                </div>
                <Chart rasiChat={FemaleRasiChat} type="rasi-chart" chartName="ராசி"></Chart>
              </div>
              <div className="chart-heading">
                <h3 className="sh">
                  <span className="grt">மணமகள் </span>ராசி கட்டம்
                </h3>
              </div>
            </div>
          </div>
          <div className="center-btn">
            <button typr="button" className="btn-lg btn-pnk border-0" onClick={editForm}>
              Edit
              <img src='https://ctsvsolutions.com/onepage-images/new-ui/edit-icon.webp' alt='horoscope matching edit'/>
            </button>
            <button type='button' className="btn-lg btn-pnk border-0" onClick={() => setShowPopup(true)}>
              Download PDF
              <img src='https://ctsvsolutions.com/onepage-images/new-ui/download-icon.webp' alt='horoscope matching download pdf'/>
            </button>
          </div>
        </div>
      </section>
      {/* popup */}

      {showPopup && (
        <div className="global-popup dp-popup">
          <div className="content">
            <div className="popup-close" onClick={() => setShowPopup(false)}>
              <img src='https://ctsvsolutions.com/onepage-images/new-ui/popup-cross-icon.webp' alt="Close" />
            </div>
            <img className="illus" src='https://ctsvsolutions.com/onepage-images/new-ui/download-popup-icon.webp' alt="horoscope matching pdf" />
            <h3 className="sh">
              Download<span className="grt"> PDF</span>
            </h3>
            <p>
              Your report will be delivered to your email and WhatsApp number. Before
              payment, confirm your details.
            </p>
            <form className="cmn-form">
              <div className="form-group">
                <label htmlFor="email">Email</label>
                <input className='input' placeholder='Enter Your E-Mail' type="email" name="email" id="email" value={email}
                  onChange={(e) => {
                    setEmail(e.target.value);
                  }} />
                <span id="name_error1" style={{ color: "red" }}>
                  {emailError}
                </span>
              </div>
              <div className="form-group">
                <label htmlFor="name">Name</label>
                <input className='input' placeholder='Enter Your Name' type="text" name="name" id="name" onChange={(e) => setBuyername(e.target.value)}
                  value={buyerName} />
                <span id="name_error1" style={{ color: "red" }}>
                  {buyerNameError}
                </span>
              </div>
              <div className="form-group">
                <label htmlFor="whatsapp">WhatsApp Number</label>
                <input className='input' placeholder='Enter WhatsApp Number' type="tel" name="whatsapp" id="whatsapp" value={buyerMobilenumber}
                  onChange={(e) => setMobilenumber(e.target.value)} />
                <span id="name_error1" style={{ color: "red" }}>
                  {mobilenumberError}
                </span>
              </div>
            </form>
            <div className="buttons">
              <button type='button' className="btn-lg btn-pnk border-0" onClick={HandlePAY}>
                Buy Now
                <img src="https://ctsvsolutions.com/onepage-images/new-ui/btn-arrow-white.webp" alt="marriage matching Arrow" />
              </button>
            </div>
          </div>
          <div className="overlay" onClick={() => setShowPopup(false)} />
        </div>
      )}
    </>
  )
}

export default MarriageMathingViewChart