import React from 'react'

import { useNavigate } from "react-router-dom";

export default function TamilJathagaViewPerDet({value}) {
  const Navigate = useNavigate();

  const Edit = () => {
    Navigate("/Tamil-Jathagam-Book");
  };

  return (
    <>
      <section className="result-info ri-mobile">
        <div className="astro-container">
          <div className="result">
            <div className="bhres-row-1">
              <div className="name">
                <h2 className="sh">{value.personal_info.name}</h2>
              </div>
              <div className="edit">
                <button  className="btn-lg btn-prpl border-0" type='button' onClick={Edit}>
                  Edit
                  <img src='https://ctsvsolutions.com/onepage-images/new-ui/btn-edit-icon.webp' alt="edit" />
                </button>
              </div>
            </div>
            <div className="bhres-row-2">
              <div className="rd">
                <div className="icon">
                  <img src='https://ctsvsolutions.com/onepage-images/new-ui/date-icon.webp' alt="date" />
                </div>
                <div className="detail">
                  <span>date of birth</span>
                  <h4>{value.personal_info.birth_date}</h4>
                </div>
              </div>
              <div className="rd">
                <div className="icon">
                  <img src='https://ctsvsolutions.com/onepage-images/new-ui/gender-icon.webp' alt="gender"/>
                </div>
                <div className="detail">
                  <span>Gender</span>
                  <h4>{value.personal_info.gender}</h4>
                </div>
              </div>
              
              <div className="rd">
                <div className="icon">
                  <img src='https://ctsvsolutions.com/onepage-images/new-ui/birth-time-icon.webp' alt="gender" />
                </div>
                <div className="detail">
                  <span>birth time</span>
                  <h4>{value.personal_info.birth_time}</h4>
                </div>
              </div>
              <div className="rd">
                <div className="icon">
                  <img src='https://ctsvsolutions.com/onepage-images/new-ui/location-icon.webp'  alt="gender"/>
                </div>
                <div className="detail">
                  <span>birth place</span>
                  <h4>{value.personal_info.birth_place.split(',')[0]}</h4>
                </div>
              </div>
          
            </div>
            <button className="btn-lg btn-prpl edit-btn-mobile border-0" type="button"
                onClick={Edit}>
              Edit
              <img src='https://ctsvsolutions.com/onepage-images/new-ui/btn-edit-icon.webp' alt='edit' />
            </button>
          </div>
        </div>
      </section>
    </>
  )
}
