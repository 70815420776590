import React from 'react'
import TopNavbar from '../../Main-Components/TopNavbar/TopNavbar'
import Menubar from '../../Main-Components/Menubar/Menubar'
import Footer from '../../Main-Components/Footer/Footer'
import BlogListBanner from '../../Small-Components/BlogListBanner/BlogListBanner'
import BlogRecent from "../../Small-Components/BlogRecent/BlogRecent";
import BlogCategories from "../../Small-Components/BlogCategories/BlogCategories ";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Link, useLocation } from 'react-router-dom'
import { faFacebook, faInstagram, faLinkedin, faTwitter } from '@fortawesome/free-brands-svg-icons'
import { Helmet } from 'react-helmet'
import MarriageMatchingForm from "../../Forms/MarriageMatchingForm/MarriageMatchingForm";
import MarriageMatchingContextProvider from "../../context/forms/MarriageMatching_contexProvider"
export default function NakshatraMatchingBlog() {

    const location = useLocation();
    const canonical = () => {
        const base_url = "https://onepagehoroscope.com";
        const current_path = location.pathname;

        const canonicalPaths = {
            "/Blog/nakshatra-matching": "/Blog/nakshatra-matching",

        };

        return (
            <link
                rel="canonical"
                href={base_url + (canonicalPaths[current_path] || current_path)}
            />
        );
    };

    return (
        <>
            <Helmet>
                <title>Nakshatra matching for marriage</title>
                <meta
                    name="description"
                    content="
          Unlock the secrets of Nakshatra matching and find cosmic compatibility. Discover your destined partner with expert horoscope insights."
                />
                <meta
                    name="keywords"
                    content="Nakshatra matching"
                />
                <meta name="robots" content="index,follow" />
                <meta property="og:title" content="Nakshatra matching for marriage" />
                <meta property="og:description" content="Unlock the secrets of Nakshatra matching and find cosmic compatibility. Discover your destined partner with expert horoscope insights." />
                <meta property="og:image" content="https://ctsvsolutions.com/onepage_admin/onepage-images/Nakshatra-matching.webp" />
                <meta property="og:url" content="https://onepagehoroscope.com/Blog/nakshatra-matching" />
                <meta name="twitter:card" content="summary_large_image" />
                <meta name="twitter:title" content="Nakshatra matching for marriage" />
                <meta name="twitter:description" content="Unlock the secrets of Nakshatra matching and find cosmic compatibility. Discover your destined partner with expert horoscope insights." />
                <meta name="twitter:url" content="https://onepagehoroscope.com/Blog/nakshatra-matching" />
                {canonical()}
                <script type="application/ld+json">
                    {JSON.stringify({

                        "@context": "https://schema.org",
                        "@type": "Article",
                        "mainEntityOfPage": {
                            "@type": ".webpage",
                            "@id": "https://onepagehoroscope.com/Blog/Jathagam-Online-PDF"
                        },
                        "headline": "Jathagam Online - Easy PDF Jathagam Online",
                        "description": "Unlock your destiny with Jathagam Online - Your trusted source for accurate astrological predictions. Get personalized insights now!",
                        "image": "https://ctsvsolutions.com/onepage-images/Jathagam-Online.webp",
                        "author": {
                            "@type": "",
                            "name": ""
                        },
                        "publisher": {
                            "@type": "Organization",
                            "name": "",
                            "logo": {
                                "@type": "ImageObject",
                                "url": ""
                            }
                        },
                        "datePublished": "2024-03-19",
                        "dateModified": "2024-03-21"
                    })}
                </script>
            </Helmet>
            <TopNavbar />
            <Menubar NavBgstyle="outer-header rjr" NavTextColor="text-dark" SubmenuTop="true" />
            <BlogListBanner />
            <NakshatraMatchingBlogContent />
            <Footer />
        </>
    )
}

function NakshatraMatchingBlogContent() {
    return (
        <>
            <section className="article-wrapper">
                <div className="astro-container pt-3">
                    <div className="row">
                        <div className="col-lg-8 my-2">
                            <div className="card bg-transparent border-0 blog-view">
                                <div className="card-header bg-transparent border-0 px-0">
                                    <h1 className="fs-4 blog-view-heading">
                                        Nakshatra matching for marriage:
                                    </h1>
                                </div>
                                <div className="card-body pt-0 px-0">
                                    <div className="my-2">
                                        <img
                                            src="https://ctsvsolutions.com/onepage_admin/onepage-images/Nakshatra-matching.webp"
                                            alt="Nakshatra matching"
                                            className="img-fluid rounded"
                                        />
                                    </div>
                                    <div className="my-4 d-flex">
                                        <a href="https://www.facebook.com/onepagehoroscope/">
                                            <div className="view-blog-icons facebook">
                                                <FontAwesomeIcon
                                                    icon={faFacebook}
                                                    className="text-white px-2"
                                                />
                                            </div>
                                        </a>
                                        <a href="https://www.instagram.com/one_page_horoscope/">
                                            <div className="view-blog-icons instagram">
                                                <FontAwesomeIcon
                                                    icon={faInstagram}
                                                    className="text-white px-2"
                                                />
                                            </div>
                                        </a>
                                        <a href="https://twitter.com/onepage321">
                                            <div className="view-blog-icons twitter">
                                                <FontAwesomeIcon icon={faTwitter} className="text-white px-2" />
                                            </div>
                                        </a>
                                        <a href="https://linkedin.com/showcase/97871433/admin/feed/posts/">
                                            <div className="view-blog-icons linkedin">
                                                <FontAwesomeIcon
                                                    icon={faLinkedin}
                                                    className="text-white px-2"
                                                />
                                            </div>
                                        </a>
                                    </div>
                                    <div className="description mt-3">
                                        <p className="mb-3">
                                            <Link to="/Marriage-matching" className="text-decoration-none">
                                                <span className="text-danger">Nakshatra matching</span></Link> for marriage in south India, its one of the
                                            quick and basic prediction for making marriage
                                        </p>
                                        <div>
                                        <MarriageMatchingContextProvider>
                                               <MarriageMatchingForm BlogStatus={true} />
                                        </MarriageMatchingContextProvider >
                                        </div>
                                        <div>
                                            <h2 className="mb-3 fs-5 blog-view-heading mt-3">
                                                Nakshatra matching for marriage - Quick and easy prediction
                                            </h2>
                                            <p className="description-content mb-3">
                                                Nakshatra matching for marriage in south India, it's one of the
                                                quick and basic prediction for making marriage, it will help for
                                                proceeding further about the marriage
                                            </p>
                                            <p className="description-content mb-3">Example</p>
                                            <ul className="blog-view-list">
                                                <li>
                                                    <strong>female star - Anusham</strong>
                                                </li>
                                                <li>
                                                    <strong>male star - Rohini</strong>
                                                </li>
                                                <li>
                                                    <strong>
                                                        Total Number : Rohini finish 15-6 which is good.
                                                    </strong>
                                                </li>
                                                <li>
                                                    <strong>Female Nakshatra: Thiruvonam</strong>
                                                </li>
                                                <li>
                                                    <strong>Male star: Karthikai.</strong>
                                                </li>
                                                <li>
                                                    <strong>Total Number : 9 This is good</strong>
                                                </li>
                                            </ul>
                                            <p className="description-content mb-3">
                                                10th and 19th Nakshatra (if Male Nakshatra) should not be added
                                                while counting from Janma Nakshatra. Because they belong to the
                                                same Rajju. (See Rajjub match given below).
                                            </p>
                                            <p className="description-content mb-3">
                                                The Nakshatra in which one is born is called Janma Nakshatra,
                                                the 10th Nakshatra from Janma Nakshatra is Karma Nakshatra and
                                                the 19th Nakshatra is Adhana Nakshatra (Prachna Markam Chapter
                                                21, Chloka 25) These are called Janma, Anu Janma and Tri Janma
                                                Nakshatra respectively.
                                            </p>
                                        </div>
                                        <div>
                                            <h2 className="mb-3 fs-5 blog-view-heading">Gana Porutham</h2>
                                            <p className="description-content mb-3">
                                                The Nakshatras are divided into three categories as seen below.
                                                Deva Kanam, Manushya Kanam, Rakshaya Kanam This matching shows
                                                whether the nature of both male and female is compatible with
                                                the other. Mukki shows whether they will get along well with
                                                each other in their daily life.
                                            </p>
                                            <p className="description-content mb-3">
                                                This match is not very important but if the 2nd house (family)
                                                12th house (sayana sugam -bed) or both houses are malefic in
                                                male or female horoscope in one horoscope and free of miru dosha
                                                in the other then the horoscope is not suitable. However, if
                                                Lagna 7, 8 planets in these houses are strong (in both male and
                                                female horoscopes) then this moment matching is not necessary.
                                            </p>
                                        </div>
                                        <div>
                                            <h2 className="mb-3 fs-5 blog-view-heading">
                                                Mahendram Porutham
                                            </h2>
                                            <p className="description-content mb-3">
                                                This match is not that important. And this is contrary to the
                                                Rajju fit. No need to look at this matchup (Mahendra matchup) as
                                                Rajju matchup is important. (This is applicable if male star is
                                                1, 4, 7, 10, 13, 16, 19, 22 or 25 from female star.)
                                            </p>
                                        </div>
                                        <div>
                                            <h2 className="mb-3 fs-5 blog-view-heading">Isthiri Porutham</h2>
                                            <p className="description-content mb-3">
                                                If male nakshatra is more than 13 when counted from female
                                                nakshatra, then it is suitable, if male nakshatra is more than 7
                                                when counted from female nakshatra, then this match is normal
                                                (middle). This match is not important, so it can be omitted.
                                            </p>
                                        </div>
                                        <div>
                                            <h2 className="mb-3 fs-5 blog-view-heading">Yoni Porutham</h2>
                                            <p className="description-content mb-3">
                                                Under this match, specific animals are assigned to different
                                                Nakshatras. If there is enmity between the animals assigned to
                                                female star and male star This is not relevant.
                                            </p>
                                            <p className="description-content mb-3">
                                                The purpose of this match is to see and see if both men and
                                                women have proper sexual attraction. This match is normal (not
                                                very important) and the above points can be seen by analysing
                                                the horoscopes of both male and female according to the method
                                                of Pal Phoori, Makkadperu (in important aspects).
                                            </p>
                                        </div>
                                        <div>
                                            <h2 className="mb-3 fs-5 blog-view-heading">Rasi Porutham</h2>
                                            <p className="description-content mb-3">
                                                If the male sign is more than 6 from the female sign (Rasi -
                                                Moon position) then the sign is suitable. If male rock is 6th
                                                from female sign then it should be avoided. If so, it will be
                                                Shashtashtaka Dosha. But the following Rasis are female and do
                                                not have this dosha. Aries, Gemini, Chima, Libra, Sagittarius,
                                                Aquarius, Varusha and Cancer do not have this dosha - Libra for
                                                Varushapa and Sagittarius for Cancer, due to vasiyamakai,
                                                Shashtashka dosha should be seen if the male sign is 6th from
                                                the following female sign. Virgo, Scorpio, Capricorn or Pisces.
                                            </p>
                                        </div>
                                        <div>
                                            <h2 className="mb-3 fs-5 blog-view-heading">
                                                Rasi Athibathi Porutham
                                            </h2>
                                            <p className="description-content mb-3">
                                                The lord of the female sign is the Mitra of the lord of the male
                                                sign This is appropriate. The following table is female rasi It
                                                shows the president and his allies.
                                            </p>
                                        </div>
                                        <div>
                                            <h2 className="mb-3 fs-5 blog-view-heading">Vasiya Porutham</h2>
                                            <p className="description-content mb-3">
                                                Vachyam or Rasi Vachyam is very important. It does not remove
                                                many other doshas if it is suitable. That is a huge profit. If
                                                the male sign is favorable to the female sign, the favorable
                                                match is favorable..
                                            </p>
                                        </div>
                                        <div>
                                            <h2 className="mb-3 fs-5 blog-view-heading">Rajju Porutham</h2>
                                            <p className="description-content mb-3">
                                                Rajjub matching is the most important next to Dina matching. We
                                                have already seen this in detail under daily compatibility.
                                                Female Rajju and Male Rajju should be completely different..
                                            </p>
                                        </div>
                                        <div>
                                            <h2 className="mb-3 fs-5 blog-view-heading">Vedai Porutham</h2>
                                            <p className="description-content mb-3">
                                                Vedha means hindrance The following star pairs are hindrances to
                                                each other. Aswini - Kettai: Barani - Anusham : Karttikai -
                                                Visagam; Rohini - Swati; Mrigaseersham - Chitrai, Avitam,
                                                Thiruvadhirai - Thiruvonam; Punarbhusam - Uthradam : Bhusam -
                                                Puradam : Aiyalam - by ; Magam - Revathi ; Pooram - Uttaratathi,
                                                Uttaram - Puratathi: Hastam - Sadayam Vedic match Dinapoorutham
                                                - Rajju match.
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-4 my-2">
                            <div className="row">
                                <BlogRecent />
                                <BlogCategories />
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}