import React from 'react'

export default function BabyNameListCard() {
    return (
        <>
            <section className="pyramid-am">
                <div className="astro-container">
                    <div className="content">
                        <h2 className="sh lh-1">
                            pyramid<span className="grt"> arithmetic method</span>
                        </h2>
                        <ul className='ps-0'>
                            <li>
                                The Pyramid Arithmetic method is an ancient method that can provide
                                unparalleled results.
                            </li>
                            <li>
                                It acts like a foundation for a house, like a root for a tree and like
                                an anchor for a ship. It is an occult number hidden within the name,
                                invisible to the viewer.
                            </li>
                            <li>
                                When the name is placed in the lucky number, it gives good results. At
                                the same time, the pyramid number is a lucky number and will give you
                                the best results in Vitad.
                            </li>
                            <li>
                                It is in this rare way that this name list has been prepared. Although
                                all the names are lucky numbers, only some of them are lucky numbers
                                in pyramid counting.
                            </li>
                            <li>
                                A * symbol is inserted to identify such best names. *Choose coded
                                names and pray to almighty to bless your child's future.
                            </li>
                        </ul>
                    </div>
                </div>
            </section>
        </>
    )
}
