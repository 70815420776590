import React, { useContext } from 'react';
import { BirthViewContext } from '../../context/viewpage/BirthViewContextprovider';
import { useNavigate } from "react-router-dom";

export default function BirthHoroscopeViewPerDetail() {
    const { personalInfo } = useContext(BirthViewContext);
    const navigate = useNavigate();

    const customRedirect = (route, data) => {
        navigate(route, { state: data });
    };

    const editForm = () => {
        const outputValue = JSON.parse(localStorage.getItem("formdata"));
        customRedirect("/Birth-horoscope", outputValue);
    };

    return (
        <section className="result-info bc-outer bcr Bh-result">
            <div className="astro-container">
                <div className="result">
                    <div className="bhres-row-1">
                        <div className="name">
                            <h2 className="sh">{personalInfo.name}</h2>
                        </div>
                        <div className="edit">
                            <a href={() => false} type='submit' className="btn-lg btn-prpl" onClick={editForm}>
                                Edit
                                <img src='https://ctsvsolutions.com/onepage-images/new-ui/btn-edit-icon.webp' alt="birth chart edit" />
                            </a>
                        </div>
                    </div>
                    <div className="bhres-row-2 bhres-col-4">
                        <div className="rd">
                            <div className="icon">
                                <img src='https://ctsvsolutions.com/onepage-images/new-ui/date-icon.webp' alt="Date of Birth" />
                            </div>
                            <div className="detail">
                                <span>பிறந்த தேதி</span>
                                <h4>{personalInfo.birth_date}</h4>
                            </div>
                        </div>
                        <div className="rd">
                            <div className="icon">
                                <img src='https://ctsvsolutions.com/onepage-images/new-ui/gender-icon.webp' alt="Gender" />
                            </div>
                            <div className="detail">
                                <span>பாலினம்</span>
                                <h4>{personalInfo.gender}</h4>
                            </div>
                        </div>
                        <div className="rd">
                            <div className="icon">
                                <img src='https://ctsvsolutions.com/onepage-images/new-ui/birth-time-icon.webp' alt="Birth Time" />
                            </div>
                            <div className="detail">
                                <span>பிறந்த நேரம்</span>
                                <h4>{personalInfo.birth_time}</h4>
                            </div>
                        </div>
                        <div className="rd">
                            <div className="icon">
                                <img src='https://ctsvsolutions.com/onepage-images/new-ui/location-icon.webp' alt="Birth Place" />
                            </div>
                            <div className="detail">
                                <span>பிறந்த இடம்</span>
                                <h4>{personalInfo.birth_place.split(",")[0]}</h4>
                            </div>
                        </div>
                    </div>
                    <a href={() => false} type='submit' className="btn-lg btn-prpl edit-btn-mobile" onClick={editForm}>
                        Edit
                        <img src='https://ctsvsolutions.com/onepage-images/new-ui/btn-edit-icon.webp' alt="horoscope edit" />
                    </a>
                </div>
            </div>
        </section>
    );
}
