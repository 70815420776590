import { createContext } from 'react';

const MarriageMatchingViewContextProvider = ({ children }) => {
    const value = JSON.parse(localStorage.getItem('mgValues'));
    
    const personalInfo = value.personal_info;
    const maleJathagaInfo = value.male_jathagam_info;
    const femalejathagaInfo = value.female_jathagam_info;
    
    return (
        <MarriageMatchingViewContext.Provider value={{ personalInfo, maleJathagaInfo, femalejathagaInfo,value }}>
            {children}
        </MarriageMatchingViewContext.Provider>
    );
};

export const MarriageMatchingViewContext = createContext();
export default MarriageMatchingViewContextProvider;
