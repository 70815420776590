import React from 'react'
import TopNavbar from '../../Main-Components/TopNavbar/TopNavbar'
import Menubar from '../../Main-Components/Menubar/Menubar'
import Footer from '../../Main-Components/Footer/Footer'
import BlogListBanner from '../../Small-Components/BlogListBanner/BlogListBanner'
import BlogRecent from "../../Small-Components/BlogRecent/BlogRecent";
import BlogCategories from "../../Small-Components/BlogCategories/BlogCategories ";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Link, useLocation } from 'react-router-dom'
import { faFacebook, faInstagram, faLinkedin, faTwitter } from '@fortawesome/free-brands-svg-icons'
import { Helmet } from 'react-helmet'
import MarriageMatchingForm from "../../Forms/MarriageMatchingForm/MarriageMatchingForm";
import MarriageMatchingContextProvider from "../../context/forms/MarriageMatching_contexProvider"
export default function MarriageMatchingBlog() {

    const location = useLocation();
    const canonical = () => {
        const base_url = "https://onepagehoroscope.com";
        const current_path = location.pathname;

        const canonicalPaths = {
            "/Blog/marriage-matching-tips": "/Blog/marriage-matching-tips",

        };

        return (
            <link
                rel="canonical"
                href={base_url + (canonicalPaths[current_path] || current_path)}
            />
        );
    };

    return (
        <>
            <Helmet>
                <title> Marriage-matching-tips |Marriage matching பற்றிய குறிப்புகள்</title>
                <meta
                    name="description"
                    content="Marriage matching is a tool that helps couples determine compatibility and potential challenges in their relationship based on their astrological signs.."
                />
                <meta
                    name="keywords"
                    content="Marriage matching"
                />
                <meta name="robots" content="index,follow" />
                {canonical()}
                <script type="application/ld+json">
                    {JSON.stringify({

                        "@context": "https://schema.org",
                        "@type": "BlogPosting",
                        "mainEntityOfPage": {
                            "@type": ".webpage",
                            "@id": "https://onepagehoroscope.com/Blog/marriage-matching-tips"
                        },
                        "headline": "Marriage matching பற்றிய குறிப்புகள்:",
                        "description": "Marriage matching is a tool that helps couples determine compatibility and potential challenges in their relationship based on their astrological signs..",
                        "image": "https://ctsvsolutions.com/onepage-images/marriage-matching.webp",
                        "author": {
                            "@type": "",
                            "name": ""
                        },
                        "publisher": {
                            "@type": "Organization",
                            "name": "",
                            "logo": {
                                "@type": "ImageObject",
                                "url": ""
                            }
                        },
                        "datePublished": "2024-03-06",
                        "dateModified": "2024-03-21"
                    })}
                </script>
            </Helmet>
            <TopNavbar />
            <Menubar NavBgstyle="outer-header rjr" NavTextColor="text-dark" SubmenuTop="true" />
            <BlogListBanner />
            <MarriageMatchingBlogContent />
            <Footer />
        </>
    )
}

function MarriageMatchingBlogContent() {
    return (
        <>
            <section className="article-wrapper">
                <div className="astro-container pt-3">
                    <div className="row">
                        <div className="col-lg-8 my-2">
                            <div className="card bg-transparent border-0 blog-view">
                                <div className="card-header bg-transparent border-0 px-0">
                                    <h1 className="fs-4 blog-view-heading">
                                        Marriage matching பற்றிய குறிப்புகள்:
                                    </h1>
                                </div>
                                <div className="card-body pt-0 px-0">
                                    <div className="my-2">
                                        <img
                                            src="https://ctsvsolutions.com/onepage-images/marriage-matching.webp"
                                            alt="Marriage matching"
                                            className="img-fluid rounded"
                                        />
                                    </div>
                                    <div className="my-4 d-flex">
                                        <a href="https://www.facebook.com/onepagehoroscope/">
                                            <div className="view-blog-icons facebook">
                                                <FontAwesomeIcon icon={faFacebook} className="text-white px-2" />
                                            </div>
                                        </a>
                                        <a href="https://www.instagram.com/one_page_horoscope/">
                                            <div className="view-blog-icons instagram">
                                                <FontAwesomeIcon icon={faInstagram} className="text-white px-2" />
                                            </div>
                                        </a>
                                        <a href="https://twitter.com/onepage321">
                                            <div className="view-blog-icons twitter">
                                                <FontAwesomeIcon icon={faTwitter} className="text-white px-2" />
                                            </div>
                                        </a>
                                        <a href="https://linkedin.com/showcase/97871433/admin/feed/posts/">
                                            <div className="view-blog-icons linkedin">
                                                <FontAwesomeIcon icon={faLinkedin} className="text-white px-2" />
                                            </div>
                                        </a>
                                    </div>
                                    <div className="description mt-3">
                                        <p className="mb-3">
                                            நமது நாட்டின் பண்பாடுகளில் <Link to="/Marriage-matching"> <span className="text-danger">(Marriage matching)</span></Link>  மிகவும் அற்புதமானது
                                            ஆண் - பெண் ஜாதகப் பொருத்தம் பார்ப்பது. இந்த ஜாதக பொருத்தம்
                                            பார்ப்பது காலம் காலமாக தொண்று தொண்டு இருந்து வரும் நடைமுறையாகும்.
                                        </p>
                                        <h2 className="mb-3 fs-5 blog-view-heading">ஜாதகப் பொருத்தம்:</h2>
                                        <p className="description-content mb-3">
                                            ஜாதகப் பொருத்தம் என்பது 12 பொருத்தங்களாக பிரிக்கப்பட்டுள்ளது
                                            கீழ்வருமாறு,
                                        </p>
                                        <div>
                                        <MarriageMatchingContextProvider>
                                               <MarriageMatchingForm BlogStatus={true} />
                                        </MarriageMatchingContextProvider >
                                        </div>
                      
                                        <ul className="blog-view-list">
                                            <li>
                                                <strong>
                                                    தினம் அல்லது நட்சத்திரப் பொருத்தம் (ஆயுள் ஆரோக்கியம்).
                                                </strong>
                                            </li>
                                            <li>
                                                <strong>கணப் பொருத்தம் (மங்களம் சுப சோபனங்கள்).</strong>
                                            </li>
                                            <li>
                                                <strong>மஹேந்திரப் பொருத்தம்.</strong>
                                            </li>
                                            <li>
                                                <strong>ஸ்திரீதீர்க்கப் பொருத்தம்.</strong>
                                            </li>
                                            <li>
                                                <strong>யோனிப் பொருத்தம்.</strong>
                                            </li>
                                            <li>
                                                <strong>ராசிப் பொருத்தம்.</strong>
                                            </li>
                                            <li>
                                                <strong>ராசி அதிபதி பொருத்தம்.</strong>
                                            </li>
                                            <li>
                                                <strong>வசியப் பொருத்தம்.</strong>
                                            </li>
                                            <li>
                                                <strong>ரஜ்ஜூப் பொருத்தம்.</strong>
                                            </li>
                                            <li>
                                                <strong>வேதைப் பொருத்தம்.</strong>
                                            </li>
                                            <li>
                                                <strong>நாடிப் பொருத்தம்.</strong>
                                            </li>
                                            <li>
                                                <strong>மரப் பொருத்தம்.</strong>
                                            </li>
                                        </ul>
                                        <h2 className="mb-3 fs-5 blog-view-heading">
                                            செவ்வாய் தோஷம் (அங்காரக தோஷம்)
                                        </h2>
                                        <p className="description-content mb-3">
                                            செவ்வாய் தோஷம் என்பது பற்றி பலரும் தெரிந்த விஷயம் என்றாலும்
                                            ஜாதகத்தில் சரியான முறையில் பரிசீலனை செய்து பின்பு இந்த செவ்வாய்
                                            (அங்காரக) தோஷம் உள்ளது. என்று நன்கு ஆராய்ந்து பார்க்க வேண்டும்.
                                        </p>
                                        <p className="description-content mb-3">
                                            செவ்வாய் தோஷம் ஆண் அல்லது பெண்ணின் ஜாதகத்தில் காணப்பட்டால்
                                            திருமணம் தாமதாவது உண்டு.
                                        </p>
                                        <p className="description-content mb-3">
                                            இரு ஜாதகத்தையும் மேல்வாரியாகப் பார்த்து செவ்வாய் தோஷம் என்று
                                            தள்ளுவது பெரிய தவறு. இதில் பல விலக்குகள் உண்டு, இந்த
                                            விலக்குகளையும் சரிவர ஆராய்ந்து பார்த்து தோஷம் உள்ளதா என்பதை
                                            கவனிக்க வேண்டும்.
                                        </p>
                                        <h2 className="mb-3 fs-5 blog-view-heading">பொருத்த நிர்ணயம்</h2>
                                        <p className="description-content mb-3">
                                            நாம் மேலே பார்த்த 12 வகை பொருத்தத்தில் மிக முக்கியமான
                                            பொருத்தங்கள்,
                                        </p>
                                        <ul className="blog-view-list">
                                            <li>
                                                <strong>நட்சத்திரப் பொருத்தம்</strong>
                                            </li>
                                            <li>
                                                <strong>கணப் பொருத்தம்.</strong>
                                            </li>
                                            <li>
                                                <strong>யோனிப் பொருத்தம்.</strong>
                                            </li>
                                            <li>
                                                <strong>ராசிப் பொருத்தம்.</strong>
                                            </li>
                                            <li>
                                                <strong>ராசி அதிபதி பொருத்தம்.</strong>
                                            </li>
                                            <li>
                                                <strong>ரஜ்ஜூப் பொருத்தம்.</strong>
                                            </li>
                                            <li>
                                                <strong>வேதைப் பொருத்தம்.</strong>
                                            </li>
                                        </ul>
                                        <p className="description-content mb-3">
                                            இதில் மிக முக்கியமானது ரஜ்ஜூப் பொருத்தம் மற்றும் யோனி
                                            பொருத்தம். ஒவ்வொரு பொருத்தத்தின் விளக்கங்களை அடுத்து பார்க்கலாம்.
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-4 my-2">
                            <div className="row">
                                <BlogRecent />
                                <BlogCategories />
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}