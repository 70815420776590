import React from 'react'
import TopNavbar from '../../Main-Components/TopNavbar/TopNavbar'
import Menubar from '../../Main-Components/Menubar/Menubar'
import Footer from '../../Main-Components/Footer/Footer'
import BlogListBanner from '../../Small-Components/BlogListBanner/BlogListBanner'
import BlogRecent from "../../Small-Components/BlogRecent/BlogRecent";
import BlogCategories from "../../Small-Components/BlogCategories/BlogCategories ";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Link, useLocation } from 'react-router-dom'
import { faFacebook, faInstagram, faLinkedin, faTwitter } from '@fortawesome/free-brands-svg-icons'
import { Helmet } from 'react-helmet'
import BioDataForm from '../../Forms/BioDataForm/BioDataForm';
import BiodataFormContextProvider from "../../context/forms/BiodataForm_context_provider"
export default function MarriageBioDataBlog() {

    const location = useLocation();
    const canonical = () => {
        const base_url = "https://onepagehoroscope.com";
        const current_path = location.pathname;

        const canonicalPaths = {
            "/Blog/bio-data-for-marriage-in-tamil": "/Blog/bio-data-for-marriage-in-tamil",

        };

        return (
            <link
                rel="canonical"
                href={base_url + (canonicalPaths[current_path] || current_path)}
            />
        );
    };

    return (
        <>
            <Helmet>
                <title> Bio data for marriage in tamil | Marriage Bio-Data</title>
                <meta
                    name="description"
                    content="Bio data for marriage in Tamil language include personal details, horoscope details, professional and salary details and family details."
                />
                <meta
                    name="keywords"
                    content="Bio data for marriage in tamil"
                />
                <meta name="robots" content="index,follow" />
                {canonical()}

            </Helmet>
            <TopNavbar />
            <Menubar NavBgstyle="outer-header rjr" NavTextColor="text-dark" SubmenuTop="true" />
            <BlogListBanner />
            <MarriageBioDataBlogContent />
            <Footer />
        </>
    )
}

function MarriageBioDataBlogContent() {
    return (
        <>
            <section className="article-wrapper">
                <div className="astro-container pt-3">
                    <div className="row">
                        <div className="col-lg-8 my-2">
                            <div className="card bg-transparent border-0 blog-view">
                                <div className="card-header bg-transparent border-0 px-0">
                                    <h1 className="fs-4 blog-view-heading">
                                        Bio data for marriage in tamil - Marriage Bio-Data
                                    </h1>
                                </div>
                                <div className="card-body pt-0 px-0">
                                    <div className="my-2">
                                        <img
                                            src="https://ctsvsolutions.com/onepage_admin/onepage-images/bio-data-for-marriage-intamil.webp"
                                            alt="bio-data-for-marriage-intamil"
                                            className="img-fluid rounded"
                                        />
                                    </div>
                                    <div className="my-4 d-flex">
                                        <a href="https://www.facebook.com/onepagehoroscope/">
                                            <div className="view-blog-icons facebook">
                                                <FontAwesomeIcon icon={faFacebook} className="text-white px-2" />
                                            </div>
                                        </a>
                                        <a href="https://www.instagram.com/one_page_horoscope/">
                                            <div className="view-blog-icons instagram">
                                                <FontAwesomeIcon icon={faInstagram} className="text-white px-2" />
                                            </div>
                                        </a>
                                        <a href="https://twitter.com/onepage321">
                                            <div className="view-blog-icons twitter">
                                                <FontAwesomeIcon icon={faTwitter} className="text-white px-2" />
                                            </div>
                                        </a>
                                        <a href="https://linkedin.com/showcase/97871433/admin/feed/posts/">
                                            <div className="view-blog-icons linkedin">
                                                <FontAwesomeIcon icon={faLinkedin} className="text-white px-2" />
                                            </div>
                                        </a>
                                    </div>
                                    <div className="description mt-3">
                                        <p className="mb-3">
                                            <Link to="/Marriage-Bio-Data" className="text-decoration-none">
                                                <span className="text-danger">Bio data for marriage in tamil</span>
                                            </Link> language include personal details of the bride or groom, professional information with CTC, horoscope od birde or groom with panjangam details.
                                        </p>
                                        <div className="mb-3">
                                        <BiodataFormContextProvider >
                                              <BioDataForm BlogStatus={true} />
                                        </BiodataFormContextProvider>
                                        </div>

                                        <h2 className="mb-3 fs-5 blog-view-heading">Bio data for marriage - Why it is Important ?</h2>
                                        <p className="description-content mb-3">
                                            Bio data for marriage is a basic procedure in arrange mariage, this will helpful for parents and their familes to know about bride or grrom.
                                        </p>
                                        <p className="description-content mb-3">
                                            First impression is the best impression so planning for perfect bio data for marriage will be helpful to be impress your life partner and his/her family along with best thing about you.
                                        </p>

                                        <h2 className="mb-3 fs-5 blog-view-heading">
                                            Essential Information  In Marriage Bio Data
                                        </h2>
                                        <p className="description-content mb-3">
                                            To Stand out from the croud you should consider the essential informtaion an bio data should have.
                                        </p>
                                        <ul className="view-list">
                                            <li>
                                                <strong>Basic Information</strong>
                                            </li>
                                            <li>
                                                <strong>Educational Information</strong>
                                            </li>
                                            <li>
                                                <strong>Professional Information</strong>
                                            </li>
                                            <li>
                                                <strong>Family Details</strong>
                                            </li>
                                            <li>
                                                <strong>Horoscope Details</strong>
                                            </li>
                                            <li>
                                                <strong>Partner Preference</strong>
                                            </li>
                                            <li>
                                                <strong>Your Recent Image</strong>
                                            </li>
                                        </ul>
                                        <p className="description-content mb-3">
                                            Above are the details you should consider while creating a new marriage bio data for your bride or groom search.
                                        </p>
                                        <h2 className="mb-3 fs-5 blog-view-heading">Basic Information Bride/Groom</h2>
                                        <p className="description-content mb-3">
                                            About yourself shows your personality that howyou are going to be the potential partner, It shows your intrest and passion and give value about your self.
                                        </p>

                                        <p className="description-content mb-3">
                                            Generic bio-data with showcase you how you going to be a best lifepartner, it value the intrest of your lifepartner.
                                        </p>
                                        <h2 className="mb-3 fs-5 blog-view-heading">Educational Information</h2>
                                        <p className="description-content mb-3">
                                            Education information in marrige bio data gives compatibility indication, your lifestyle, expectaion and ambition.
                                        </p>

                                        <p className="description-content mb-3">
                                            Your qualification tells your life partner what are the goal and achievenement he/she can expect in their carier or professional life.
                                        </p>
                                        <p className="description-content mb-3">
                                            If your partner have diffecrent education level will leads to plan finacial and further.
                                        </p>
                                        <h2 className="mb-3 fs-5 blog-view-heading">Professional Information</h2>
                                        <p className="description-content mb-3">
                                            Your professional information and your salary scale show case your financial strength in this society, its a foundation for your future growth. Mostly in bride circle they have selected expectaion in groom education and professional this you showcase your profile.
                                        </p>

                                        <p className="description-content mb-3">
                                            Annual CTC tell your financial stabilty and how comfortable you are in your lifestly.
                                        </p>
                                        <h2 className="mb-3 fs-5 blog-view-heading">Famil Details</h2>
                                        <p className="description-content mb-3">
                                            Important of family details is to show your family caulture, value of your family and how comfort you going to be with your life partner along with your g=family. You should mention your family level and your siblings in details to understand your life partner about your family.
                                        </p>
                                        <h2 className="mb-3 fs-5 blog-view-heading">Horoscope Details</h2>
                                        <p className="description-content mb-3">
                                            Astrology of individual play a significant role in indian marriage culture, to check the compatible of both horoscope matching, this will helpful for the family to check the suitable of both,
                                        </p>
                                        <p className="description-content mb-3">
                                            There is lot of technical details reuired to match your horoscope with your partner, kindly check here. Insted you can mention your birth time, birth day and birth place, basic panjag details and your birch chart, it will be helpful for the astrologer to analysis your compatiblity with you partner.
                                        </p>
                                        <h2 className="mb-3 fs-5 blog-view-heading">Partner Preference</h2>
                                        <p className="description-content mb-3">
                                            Above all details tell about you, but partner prference show whom to choose your profile. It will give your partner a perfect fit, emotionaly connected and its a long term sucess from the bottom of the heart.
                                        </p>
                                        <p className="description-content mb-3">
                                            Partner preference is very important in the marriage bio date to make the relation enrish sucessfully.
                                        </p>
                                        <h2 className="mb-3 fs-5 blog-view-heading">Recent Photograph</h2>
                                        <p className="description-content mb-3">
                                            Every one should connect emotionally with horoscope, education, profession, salary or family, apart from all these your good recent photography gives first impression, authenticate and cofident booster to your life partner to choose you.
                                        </p>
                                        <h2 className="mb-3 fs-5 blog-view-heading">Conclusion</h2>
                                        <p className="description-content mb-3">
                                            A marriage bio data should be formal and treditional, and more personal approch for your life parner, conside these thing to choose a marriage bio data to be success in marriage.
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-4 my-2">
                            <div className="row">
                                <BlogRecent />
                                <BlogCategories />
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}