import React from 'react'
// import SuccessImage from '../../images/tick1.webp'
import { useNavigate } from 'react-router-dom';

export default function RuthuJathagaPaymentSuccessContent() {

    

    const Navigate=useNavigate();
    const Redirect = () => {
        Navigate('/Ruthu-Jathagam')
    }

  return (
    <>
      <section className="result-info bc-outer bcr Bh-result">
                <div className="astro-container privacy-policy-content">
                    <div className='row'>
                        <div className="col-lg-6 col-md-8 m-auto my-5">
                            <div className="card px-2 shadow success-payment-card">
                                <div className='text-center'>
                                    <img src='https://ctsvsolutions.com/onepage-images/new-ui/payment-success.webp' alt="ruthu jathagam payment success" className='img-fluid' width={125} height={125} />
                                </div>
                                <div>
                                    <p className='paragraph' style={{ textIndent: '20px' }}>One Page Horoscope சேவையை உபயோகப்படுத்தியதற்கு நன்றி 🙏. ஜாதகர் நல்லுடல், நீண்ட ஆயுள், நிறைந்த செல்வம், உயர்ந்த புகழ், மெய்ஞ்ஞானம் ஓங்கி வாழ்க வளமுடன் 🙏.</p>
                                    <p className='paragraph' style={{ textIndent: '20px' }}>உங்களது ஜாதகம் நீங்கள் கொடுக்கப்பட்ட WhatsApp மற்றும் Email க்கு வெற்றிகரமாக அனுப்பப்பட்டது.</p>
                                </div>
                                <div className='mb-4 text-center'>
                                    <button className='btn btn-success' onClick={Redirect}>Okay, Thank You</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
    </>
  )
}
