import React,{useContext} from 'react'
// import MessageImg from '../../images/cd-left-icon-1.webp'
// import PhoneNumber from '../../images/bio-data-phone.png'
// import WhatsappNumber from '../../images/cd-icon-2.webp'
// import Email from '../../images/cd-icon-3.webp'
import {BioViewContext} from "../../context/viewpage/BioViewContextProvider";
export default function MarriageBioDataViewContact() {
    const  { personalInfo }= useContext(BioViewContext);
    return (
        <>
            <section className="contact-details">
                <div className="astro-container">
                    <div className="heading">
                        <h2 className="sh lh-1">
                            contact<span className="grt"> details</span>
                        </h2>
                    </div>
                    <div className="bio-cd-row">
                        <div className="cd-illus-left">
                            <img src='https://ctsvsolutions.com/onepage-images/new-ui/contact-details-left-icon.webp' alt='contact details'/>
                        </div>
                        <div className="col">
                            <span>phone number</span>
                            <h4>
                                <img src='https://ctsvsolutions.com/onepage-images/new-ui/bio-data-phone.png' style={{width:'15%'}} alt='phone number'/>
                                {personalInfo.mobile_number}
                            </h4>
                        </div>
                        <div className="col">
                            <span>whatsapp</span>
                            <h4>
                                <img src='https://ctsvsolutions.com/onepage-images/new-ui/whatsapp-icon.webp' alt='whatsapp number'/>
                                {personalInfo.whatsapp_number}
                            </h4>
                        </div>
                        <div className="col">
                            <span>email</span>
                            <h4 className='d-flex overflow-auto'>
                                <img src='https://ctsvsolutions.com/onepage-images/new-ui/mail-icon.webp' alt='email'/>
                                {personalInfo.email}
                            </h4>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}
