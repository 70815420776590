import React from 'react'
// import MarriagNatchingIcon1 from '../../images/mm-icon-1.webp'
// import MarriagNatchingIcon2 from '../../images/mm-icon-2.webp'
// import MarriagNatchingIcon3 from '../../images/mm-icon-3.webp'
// import MarriagNatchingIcon4 from '../../images/mm-icon-4.webp'

export default function MarriageMatchingViewContent() {
    return (
        <>
            <section className="bc-features mm-result">
                <div className="astro-container">
                    <div className="row">
                        <div className="col">
                            <div className="item">
                                <div className="ch-row">
                                    <img src='https://ctsvsolutions.com/onepage-images/new-ui/birth-chart-icon-1.webp' alt='natchathira porutham'/>
                                    <h3 className="sh">
                                        நட்சத்திர
                                        <br />
                                        <span className="grt">பொருத்தம்</span>
                                    </h3>
                                </div>
                                <p className="p-16">
                                    நட்சத்திர பொருத்தங்களில் தினம், கணம், யோனி, ராசி, ரஜ்ஜு இவை ஐந்தும் முக்கியமானவை. ஆண், பெண் இருவரது ராசி அதிபதி ஒருவரானாலும், அல்லது அவர்கள் நட்பானாலும், சமசப்தம ரசிகளானாலும் ரஜ்ஜு வேதை கணம் ராசிப் பொருத்தங்கள் இல்லாவிட்டாலும் விவாகம் செய்யலாம்.பெண்ணுக்கும் பிள்ளைக்கும் ஜாதகம்மிராவிட்டால் நாம நட்சத்திரத்திற்கு பொருத்தம் பார்க்கவும்.இருவர் ஜாதக ரீதியாகவும் பொருத்தம் பார்ப்பது மிக அவசியம்.
                                </p>
                                <div className="bottom-line">
                                    ஜாதக ராசிப்படி 12 க்கு <span className='txt-blr'>பொருத்தங்கள் உண்டு.
                                    </span>
                                </div>
                            </div>
                        </div>
                        <div className="col">
                            <div className="item">
                                <div className="ch-row">
                                    <img src='https://ctsvsolutions.com/onepage-images/new-ui/birth-chart-icon-2.webp' alt='sevvai dosa porutham'/>
                                    <h3 className="sh">
                                        செவ்வாய் தோஷப்
                                        <br />
                                        <span className="grt">பொருத்தம்</span>
                                    </h3>
                                </div>
                                <p className="p-16">
                                    ஒவ்வொரு ஜாதகத்திலும் செவ்வாய் தோஷத்திற்கு மிகவும் முக்கியத்துவம் கொடுக்கப்பட வேண்டும். திருமண பொருத்தம் பார்க்கும் பொழுது செவ்வாய் கிரகத்தினுடைய நிலை முக்கியத்துவம் பெறுகிறது. செவ்வாய் ஏழாம் ஸ்தானத்திலோ அல்லது எட்டாம் ஸ்தானத்திலோ உள்ளதினால் செவ்வாய் தோஷம் உண்டு என்று பலரும் சொல்லுவார்கள். ஏழாம் அல்லது எட்டாம் ஸ்தானத்திலுள்ள செவ்வாய் தோஷத்திற்கு பல விலக்குகள் உள்ளதாக ஆதார பூர்வமான நூல்கள் கூறுகின்றன.இந்த முறையில் உள்ள செவ்வாய் தோஷ நிர்ணயம் கீழே கொடுக்கப்பட்டுள்ளது.
                                </p>
                                <div className="ri-bar rb-3">
                                    <div className="col">
                                        <p>தோஷம்</p>
                                        <strong className='txt-blr'>tuesday</strong>
                                    </div>
                                    <div className="col">
                                        <p>பெண்</p>
                                        <strong className='txt-blr'>14-08-2000</strong>
                                    </div>
                                    <div className="col">
                                        <p>ஆண்</p>
                                        <strong className='txt-blr'>11-08-1996</strong>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col">
                            <div className="item">
                                <div className="ch-row">
                                    <img src='https://ctsvsolutions.com/onepage-images/new-ui/birth-chart-icon3.webp' alt='dasa santhi'/>
                                    <h3 className="sh">
                                        தசா சந்தி
                                        <br />
                                        <span className="grt">ஆய்வு</span>
                                    </h3>
                                </div>
                                <p className="p-16">
                                    ஆண் அல்லது பெண் இருவருக்கும் 18 மாத காலத்தில் தசா மாறுதல் ஏற்ப்பட்டால் அதனை தசா சந்தி என்று ஐதீகம்.<br/>இருவருக்கும் ஒரே தசா இருந்தால் கூட்டு தசா ஏற்படும்.
                                </p>
                                <p className="p-16">இருவருக்கும் வெவ்வேறு தசா இருந்தால் உத்தமம்.</p>
                                <div className="bottom-line">
                                தசா சந்திப்பு பெண்ணுக்கும் ஆணுக்கும்{" "}
                                    <span className="txt-blr">உத்தமம்.</span>
                                </div>
                            </div>
                        </div>
                        <div className="col">
                            <div className="item">
                                <div className="ch-row">
                                    <img src='https://ctsvsolutions.com/onepage-images/new-ui/birth-chart-icon-4.webp' alt='poruthathin vilakkam'/>
                                    <h3 className="sh">
                                        பொருத்தத்தின் விளக்கமும் 
                                        <br />
                                        <span className="grt">சிபாரிசும் </span>
                                    </h3>
                                </div>
                                <div className="ri-bar">
                                    <div className="col">
                                        <p>நட்சத்திர பொருத்தம்</p>
                                        <strong className='txt-blr'>19-01-1990</strong>
                                    </div>
                                    <div className="col">
                                        <p>செவ்வாய் தோஷப் பொருத்தம்</p>
                                        <strong className='txt-blr'>08:18 PM</strong>
                                    </div>
                                </div>
                                <div className="bottom-line">
                                பொருத்தம்{" "}
                                    <span className="txt-blr">
                                    இருவருக்கும் வெவ்வேறு தசா இருந்தால் உத்தமம்.
                                    </span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}
