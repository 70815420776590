import React, { useContext, useState } from 'react';

import { BirthViewContext } from '../../context/viewpage/BirthViewContextprovider';
import html2pdf from "html2pdf.js/dist/html2pdf.min";
import axios from "axios";
import { Loading } from "notiflix/build/notiflix-loading-aio";
import { Notify } from "notiflix/build/notiflix-notify-aio";
import { Report } from "notiflix/build/notiflix-report-aio";
import OnepagePaidTemplate from "../../../../components/tamil/onepagePaidTemplate/onepagePaidtemplate";
import { Confirm } from 'notiflix/build/notiflix-confirm-aio';
// import getepayPortal from "../../../../components/tamil/Getepay_pg_react";
import ReactDOMServer from "react-dom/server";
import {RazerpayPaymentGateway} from "../../payment-gateway/RazerpayGateway";

export default function BirthHoroscopeViewHoroDetail() {
    const { personalInfo,value } = useContext(BirthViewContext);
    const [showPopup, setShowPopup] = useState(false);
    const [buyerMobilenumber, setMobilenumber] = useState("");
    const [email, setEmail] = useState("");
    const [buyerName, setBuyername] = useState("");
    const [mobilenumberError, setMobieNumberError] = useState("");
    const [emailError, setEmailError] = useState("");
    const [buyerNameError, setBuyerNameError] = useState("");


    const handleDownloadClick = (e) => {
        e.preventDefault();
        setShowPopup(true);
    };



    const validateInput = () => {
        let hasError = false;

        if (!email) {
            setEmailError("Field is required");
            hasError = true;
        } else {
            setEmailError("");
        }

        if (!buyerName) {
            setBuyerNameError("Field is required");
            hasError = true;
        } else {
            setBuyerNameError("");
        }

        if (!buyerMobilenumber || buyerMobilenumber.length !== 10) {
            setMobieNumberError("Field is required and must be 10 digits");
            hasError = true;
        } else {
            setMobieNumberError("");
        }

        return !hasError;
    };


    const handlepay = () => {
        if (validateInput()) {
            const storedUserInfo = localStorage.getItem("userInfo");
            const userInfo = JSON.parse(storedUserInfo);

            const user_data_id = userInfo.user_id;
            const customer_data_id = userInfo.customerDataId;
            if ((user_data_id !== "" && user_data_id !== null) && (customer_data_id !== "" && customer_data_id !== null)) {
                proceedToPayment();
            } else {
                if (user_data_id === null && customer_data_id === null) {
                    const OnepageGeomenery = localStorage.getItem("onepageformdata");
                    const GeoLocation = JSON.parse(OnepageGeomenery);
                    const latitude = GeoLocation.latitude;
                    const longitude = GeoLocation.longitude;
                    const userData = {
                        buyerName: buyerName,
                        buyerEmail: email,
                        buyerMobileNo: buyerMobilenumber,
                        name: personalInfo.name,
                        DateofBirth: personalInfo.birth_date,
                        birthTime: personalInfo.birth_time,
                        gender: personalInfo.gender,
                        birthPlace: personalInfo.birth_place,
                        Amount: 99,
                        Package: 'one_page',
                        latitude: latitude,
                        longitude: longitude
                    };
                    axios
                        .post("https://ctsvsolutions.com/onepage-Api/public/api/Statement-customerData-insert", {
                            userData,
                        })
                        .then((response) => {
                            const userId = response.data.data.userId;
                            const customerDataId = response.data.data.customerDataId;
                            const userInfo = {
                                user_id: userId,
                                customerDataId: customerDataId,
                            };
                            const UserInfoValues = JSON.stringify(userInfo);
                            localStorage.setItem("userInfo", UserInfoValues);

                            proceedToPayment();
                        });
                } else {
                    // console.log('its from 3rd');
                    proceedToPayment();
                }
            }
        }
    };


    const proceedToPayment = async() => {
        Loading.dots();

        const storedUserInfo = localStorage.getItem("userInfo");
        const userInfo = JSON.parse(storedUserInfo);
        const user_id = userInfo.user_id;
        const customerDataId = userInfo.customerDataId;
      
        const payment_Data = {
          amount: '99', 
          email: email,
          name: buyerName,
          description:'Onepage Horosocpe',
          mobileNumber: buyerMobilenumber,
          user_id: user_id,
          theme: '#e6ffe6',
          customerDataId: customerDataId,
          paymentCallBackurl: 'https://ctsvsolutions.com/onepage-Api/public/api/update-razerpay-payment-paid-status'
        };
        setShowPopup(false);
       
          await RazerpayPaymentGateway(payment_Data,DownloadOnepagePaidPdf,true);
        Loading.remove();
        // const storedUserInfo = localStorage.getItem("userInfo");
        // const userInfo = JSON.parse(storedUserInfo);
        // const user_id = userInfo.user_id;
        // const customerDataId = userInfo.customerDataId;
        // const data = {
        //     mid: "993377",
        //     amount: "99.00",
        //     merchantTransactionId: "sd12121",
        //     transactionDate: "Mon Oct 03 13:54:33 IST 2022",
        //     terminalId: "getepay.merchant131068@icici",
        //     udf1: buyerMobilenumber,
        //     udf2: email,
        //     udf3: buyerName,
        //     udf4: user_id,
        //     udf5: customerDataId,
        //     udf6: "",
        //     udf7: "",
        //     udf8: "",
        //     udf9: "",
        //     udf10: "",
        //     ru: "https://ctsvsolutions.com/onepage-Api/public/onepage-payment",
        //     callbackUrl: "",
        //     currency: "INR",
        //     paymentMode: "ALL",
        //     bankId: "",
        //     txnType: "single",
        //     productType: "IPG",
        //     txnNote: "Onepage Horoscope",
        //     vpa: "getepay.merchant131068@icici",
        // };

        // const Config = {
        //     "mid": "993377",
        //     "Getepay Terminal Id": "getepay.merchant131068@icici",
        //     "Getepay Key": "rjW99m2Vo75SBbpyc+K5nfB/eebzWUmkAtx90U5Ku3I=",
        //     "Getepay IV": "Y7fOrFLKVPosfDFSIFSISQ==",
        //     "Getepay Url":
        //         "https://portal.getepay.in:8443/getepayPortal/pg/generateInvoice"
        // };
        // // console.log(data);
        // getepayPortal(data, Config);
    };

    const DownloadOnepagePaidPdf=()=>{

        const storedUserInfo = localStorage.getItem("userInfo");
        const userInfo = JSON.parse(storedUserInfo);

        const printElement = ReactDOMServer.renderToString(
            paidpdfConvertion()
        );
        html2pdf()
            .from(printElement)
            .set({
                image: { type: 'jpeg', quality: 1 },
                dpi: 300,
                renderer: 'canvas',
                html2canvas: { scale: 2 },
            })
            .save();

        html2pdf().from(printElement)
            .set({
                image: { type: 'jpeg', quality: 1 },
                dpi: 300,
                renderer: 'canvas',
                html2canvas: { scale: 2 },
            }).outputPdf()
            .then((pdfData) => {
                const base64Data = btoa(pdfData);
                savePaidPdf(base64Data, userInfo.user_id, userInfo.customerDataId);
            });
    }

    const currentLocation = window.location.href;
    const [baseUrl, queryParamString] = currentLocation.split("?");
    const queryParamsObject = {};

    if (queryParamString) {
        const queryParams = queryParamString.split("&");

        for (const param of queryParams) {
            const [key, value] = param.split("=");
            queryParamsObject[key] = value;
        }
        const payment_status = queryParamsObject["payment_status"];

        if (payment_status === "CrEdiT") {
            Report.success(
                "Payment Succuss",
                "உங்கள் கட்டணம் வெற்றிகரமாக பெறப்பட்டது ! நீங்கள்  கீழே உள்ள Download ஐ  கிளிக் செய்து உங்கள் PDF ஐ பதிவிறக்கம் செய்து கொள்ளலாம் ...",
                // "
                // "Thank you for your payment! Your transaction has been completed. You can now download your PDF document by clicking the button below:",
                "Download Pdf",
                () => {
                    Loading.hourglass("Generating Birth Horoscope....", {
                        backgroundColor: "rgba(0,0,0,0.8)",
                    });


                    const storedUserInfo = localStorage.getItem("userInfo");
                    const userInfo = JSON.parse(storedUserInfo);

                    const printElement = ReactDOMServer.renderToString(
                        paidpdfConvertion()
                    );
                    html2pdf()
                        .from(printElement)
                        .set({
                            image: { type: 'jpeg', quality: 1 },
                            dpi: 300,
                            renderer: 'canvas',
                            html2canvas: { scale: 2 },
                        })
                        .save();

                    html2pdf().from(printElement)
                        .set({
                            image: { type: 'jpeg', quality: 1 },
                            dpi: 300,
                            renderer: 'canvas',
                            html2canvas: { scale: 2 },
                        }).outputPdf()
                        .then((pdfData) => {
                            const base64Data = btoa(pdfData);
                            savePaidPdf(base64Data, userInfo.user_id, userInfo.customerDataId);
                        });
                }
            );
        }
    }
    const paidpdfConvertion = () => {
        return <OnepagePaidTemplate value={value} />;
    };
    const savePaidPdf = (pdfData, user_id, customerDataId) => {
        const pdfDataVal = {
            pdfData: pdfData,
            userId: user_id,
            customerId: customerDataId,
            FileName: 'one_page'
        };
        const DataInfo = {
            userId: user_id,
            customerId: customerDataId,
            columnName: "pdf_file"
        };
        axios
            .post("https://ctsvsolutions.com/onepage-Api/public/api/Save-Paid-Pdf", pdfDataVal)
            .then((response) => {
                axios
                    .post(
                        "https://ctsvsolutions.com/onepage-Api/public/api/send-paid-PdfIn-whastapp",
                        DataInfo
                    )
                    .then((response) => {
                        axios
                            .post(
                                "https://ctsvsolutions.com/onepage-Api/public/api/Send-pdfIn-Email",
                                DataInfo
                            )
                            .then((response) => {

                                Loading.remove();
                                Confirm.prompt(
                                    "Rating ⭐⭐⭐⭐⭐",
                                    "How was your experince?",
                                    "",
                                    "Answer",
                                    "Cancel",
                                    (clientAnswer) => {
                                        const RatingInfo = {
                                            'user_id': user_id,
                                            'customer_data_id': customerDataId,
                                            'feedback': clientAnswer
                                        }
                                        axios
                                            .post(
                                                "https://ctsvsolutions.com/onepage-Api/public/api/Update-Feed-Back",
                                                RatingInfo
                                            )
                                            .then((response) => {
                                                window.location.replace("/OnePage-ThankYou");
                                            });

                                    },
                                    (clientAnswer) => {
                                        window.location.replace("/OnePage-ThankYou");
                                    },

                                );
                                Notify.success("Sample pdf file sended succussfully");

                            })
                            .catch((error) => {
                                console.error("Error sending WhatsApp message:", error);
                            });
                    })
                    .catch((error) => {
                        console.error("Error sending WhatsApp file:", error);
                    });
            })
            .catch((error) => {
                console.error("Error saving PDF:", error);
            });
    };
    return (
        <>
        {/* <OnepagePaidTemplate value={value}></OnepagePaidTemplate> */}
            <section className="ad-details ad-p70">
                <div className="astro-container">
                    <div className="ad-details-row">
                        <div className="img">
                            <img className="img-res" src='https://ctsvsolutions.com/onepage-images/new-ui/birth-chart-result-img.webp' alt="Horoscope Chart Result" />
                        </div>
                        <div className="content">
                            <h3 className="sh">
                               <span className="grt">பஞ்சாங்க </span>விவரம்
                            </h3>
                            <div className="row">
                                <div className="item">
                                    <span>தமிழ் தேதி
                                    </span>
                                    <h4>{personalInfo.birth_day}</h4>
                                </div>
                                <div className="item">
                                    <span>திதி</span>
                                    <h4>{personalInfo.thithi}</h4>
                                </div>
                                <div className="item">
                                    <span>யோக நட்சத்திரம்</span>
                                    <h4>{personalInfo.yoga_natchathiram}</h4>
                                </div>
                                <div className="item">
                                    <span>யோகம்</span>
                                    <h4>{personalInfo.yogam}</h4>
                                </div>
                                <div className="item">
                                    <span>லக்னம்</span>
                                    <h4>{personalInfo.laknam}</h4>
                                </div>
                                <div className="item">
                                    <span>யோகி</span>
                                    <h4>{personalInfo.yogi}</h4>
                                </div>
                                <div className="item">
                                    <span>ராசி </span>
                                    <h4>{personalInfo.rasi}</h4>
                                </div>
                                <div className="item">
                                    <span>கரணம்</span>
                                    <h4>{personalInfo.karanam}</h4>
                                </div>
                                <div className="item">
                                    <span>அவயோக நட்சத்திரம்</span>
                                    <h4>{personalInfo.avayogi_natchathiram}</h4>
                                </div>
                                <div className="item">
                                    <span>நட்சத்திரம்</span>
                                    <h4>{personalInfo.natchathiram}</h4>
                                </div>
                                <div className="item">
                                    <span>அயனாம்சம் </span>
                                    <h4>{personalInfo.ayanamsam}</h4>
                                </div>
                                <div className="item">
                                    <span>அவயோகி</span>
                                    <h4>{personalInfo.avayogi}</h4>
                                </div>
                            </div>
                            <a href={() => false} type='submit' className="btn-lg btn-pnk pdf-btn" onClick={handleDownloadClick}>
                            Download PDF
                                <img src='https://ctsvsolutions.com/onepage-images/new-ui/btn-pdf-icon.webp' alt="Horoscope PDF" />
                            </a>
                        </div>
                    </div>
                </div>
            </section>

            {showPopup && (
                <div className="global-popup dp-popup">
                    <div className="content">
                        <div className="popup-close" onClick={() => setShowPopup(false)}>
                            <img src='https://ctsvsolutions.com/onepage-images/new-ui/popup-cross-icon.webp' alt="Close" />
                        </div>
                        <img className="illus" src='https://ctsvsolutions.com/onepage-images/new-ui/download-popup-icon.webp' alt="astrology PDF" />
                        <h3 className="sh">
                        Download<span className="grt"> PDF</span>
                        </h3>
                        <p>
                        உங்கள் அறிக்கை உங்கள் மின்னஞ்சல் மற்றும் வாட்ஸ்அப் எண்ணுக்கு அனுப்பப்படும். பணம் செலுத்துவதற்கு முன், உங்கள் விவரங்களை உறுதிப்படுத்தவும்.
                        </p>
                        <form className="cmn-form">
                            <div className="form-group">
                                <label htmlFor="email">Email</label>
                                <input className='input' type="email" name="email" placeholder='Enter Your Email' id="email" value={email}
                                    onChange={(e) => {
                                        setEmail(e.target.value);
                                    }} />
                                <span id="name_error1" style={{ color: "red" }}>
                                    {emailError}
                                </span>
                            </div>
                            <div className="form-group">
                                <label htmlFor="name">Name</label>
                                <input className='input' placeholder='Enter Your Name' type="text" name="name" id="name" onChange={(e) => setBuyername(e.target.value)}
                                    value={buyerName} />
                                <span id="name_error1" style={{ color: "red" }}>
                                    {buyerNameError}
                                </span>
                            </div>
                            <div className="form-group">
                                <label htmlFor="whatsapp">WhatsApp Number</label>
                                <input className='input' type="tel" name="whatsapp" placeholder='Enter Your WhatsApp Number' id="whatsapp" value={buyerMobilenumber}
                                    onChange={(e) => setMobilenumber(e.target.value)} />
                                <span id="name_error1" style={{ color: "red" }}>
                                    {mobilenumberError}
                                </span>
                            </div>
                        </form>
                        <div className="buttons">
                            <a  href={() => false} type='submit' className="btn-lg btn-pnk" onClick={handlepay}>
                                Buy Now
                                <img src="https://ctsvsolutions.com/onepage-images/new-ui/btn-arrow-white.webp" alt="Horoscope Arrow" />
                            </a>
                        </div>
                    </div>
                    <div className="overlay" onClick={() => setShowPopup(false)} />
                </div>
            )}
        </>
    );
}
