import React from 'react'
import { Link } from 'react-router-dom';


export default function Footer() {
    return (
        <>
            <footer className="footer">
                <div className="astro-container">
                    <div className="footer-row">
                        <div className="footer-col">
                            <div className="content">
                                <Link to='/'><img src='https://ctsvsolutions.com/onepage-images/new-ui/logo.webp ' alt='logo' width={203} height={60} loading="lazy"/></Link>
                                <p>
                                    One Page Horoscope is an online platform that provides personalized
                                    horoscope readings.
                                </p>
                                <ul className='ps-0'>
                                    <li>
                                        <Link aria-label="facebook" to='https://www.facebook.com/onepagehoroscope/'>
                                            <img src='https://ctsvsolutions.com/onepage-images/new-ui/fb.webp' alt='facebook' width={37} height={37} loading="lazy"/>
                                        </Link>
                                    </li>
                                    <li>
                                        <Link aria-label="Twitter" to='https://twitter.com/onepage321'>
                                            <img src='https://ctsvsolutions.com/onepage-images/new-ui/x.webp' alt='twitter' width={37} height={37} loading="lazy"/>
                                        </Link>
                                    </li>
                                    <li>
                                        <Link aria-label="Instargram" to='https://www.instagram.com/one_page_horoscope/'>
                                            <img src='https://ctsvsolutions.com/onepage-images/new-ui/insta.webp' alt='instagram' width={37} height={37} loading="lazy"/>
                                        </Link>
                                    </li>
                                    <li>
                                        <Link aria-label="Youtube" to='https://www.youtube.com/channel/UCbDTBLIU3Mp6983K8k3ZKNg/'>
                                            <img src='https://ctsvsolutions.com/onepage-images/new-ui/yt.webp' alt='youtube' width={37} height={37} loading="lazy"/>
                                        </Link>
                                    </li>
                                </ul>
                            </div>
                        </div>
                        <div className="footer-col">
                            <div className="footer-menu">
                                <h3>Products</h3>
                                <ul className='ps-0'>
                                    <li>
                                        <Link to='/Birth-horoscope'>Birth Horoscope</Link>
                                    </li>
                                    <li>
                                        <Link to='/Marriage-matching'>Marriage Matching</Link>
                                    </li>
                                    <li>
                                        <Link to='/Marriage-Bio-Data'>Marriage Bio-Data</Link>
                                    </li>
                                    <li>
                                        <Link to='/Baby-Name-List'>Baby Name</Link>
                                    </li>
                                    <li>
                                        <Link to='/Ruthu-Jathagam'>Ruthu Jathagam</Link>
                                    </li>
                                </ul>
                            </div>
                        </div>
                        <div className="footer-col">
                            <div className="footer-menu">
                                <h3>Services</h3>
                                <ul className='ps-0'>
                                    <li>
                                        <Link to='/Jathaga-Palan'>Audio Prediction</Link>
                                    </li>
                                    <li>
                                        <Link to='/Tamil-jathagam-book'>hand written Horoscope</Link>
                                    </li>
                                    <li>
                                        <Link to='/Talk-To-Astrologer'>Astro talk</Link>
                                    </li>
                                </ul>
                            </div>
                        </div>
                        <div className="footer-col">
                            <div className="footer-menu">
                                <h3>important links</h3>
                                <ul className='ps-0'>
                                    <li>
                                        <Link to='/Blog'>blogs</Link>
                                    </li>
                                    <li>
                                        <Link to='/FAQ'>fAQS</Link>
                                    </li>
                                    <li>
                                        <Link to='/contact-us'>contact us</Link>
                                    </li>
                                </ul>
                            </div>
                        </div>
                        <div className="footer-col">
                            <div className="footer-menu">
                                <h3>Legal and Policies:</h3>
                                <ul className='ps-0'>
                                    <li>
                                        <Link to='/Terms-condition'>Terms and Conditions</Link>
                                    </li>
                                    <li>
                                        <Link to='/privacy-policy'>privacy policy </Link>
                                    </li>
                                    <li>
                                        <Link to='/Cancellation-policy'>Refund Policy</Link>
                                    </li>
                                </ul>
                            </div>
                        </div>
                        <div className="footer-col">
                            <div className="footer-menu">
                                <h3>additional</h3>
                                <ul className='ps-0'>
                                    <li>
                                        <Link to='/rasi-palan/Weekly-rasi-palan'>Weekly rasi palan</Link>
                                    </li>
                                    <li>
                                        <Link to='/Horaigal'>Hora</Link>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="footer-bottom">
                    <div className="astro-container">
                        <div className="footer-row">
                            <div className="copyright">© 2024 onepagehoroscope, all right reserved</div>
                            <div className="payment-icons">
                                <ul className='ps-0'>
                                    <li>
                                        <img src='https://ctsvsolutions.com/onepage-images/new-ui/visa.webp' alt='visa payment' width={'100%'} height={'100%'} loading="lazy"/>
                                    </li>
                                    <li>
                                        <img src='https://ctsvsolutions.com/onepage-images/new-ui/dinersclub.webp' alt='dinersclub payment' width={'100%'} height={'100%'} loading="lazy"/>
                                    </li>
                                    <li>
                                        <img src='https://ctsvsolutions.com/onepage-images/new-ui/amex.webp' alt='amex payment' width={'100%'} height={'100%'} loading="lazy"/>
                                    </li>
                                    <li>
                                        <img src='https://ctsvsolutions.com/onepage-images/new-ui/discover.webp' alt='discover payment' width={'100%'} height={'100%'} loading="lazy"/>
                                    </li>
                                    <li>
                                        <img src='https://ctsvsolutions.com/onepage-images/new-ui/mastercard.webp' alt='mastercard payment' width={'100%'} height={'100%'} loading="lazy"/>
                                    </li>
                                    <li>
                                        <img src='https://ctsvsolutions.com/onepage-images/new-ui/googlepay.webp' alt='google payment' width={'100%'} height={'100%'} loading="lazy"/>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </footer>
        </>
    )
}
