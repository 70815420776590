import React from "react";
import Chart from "../../Main-Components/Chart/Chart";
// import ChartTopCurve from '../../images/ac-top-curve.webp'
// import ChartRightCurve from '../../images/ac-right-curve.webp'
// import ChartBottomCurve from '../../images/ac-bottom-curve.webp'
// import ChartLeftCurve from '../../images/ac-left-curve.webp'
import { BirthViewContext } from "../../context/viewpage/BirthViewContextprovider";
import { useContext } from "react";

export default function BirthHoroscopeViewChart() {
  const { rasiChat, amsamChart } = useContext(BirthViewContext);
  return (
    <>
      <section className="ac-charts mt-5">
        <div className="astro-container">
          <div className="heading">
            <h2 className="sh">
              <span className="grt"> ஜாதக</span> கட்டம்
            </h2>
          </div>
          <div className="row">
            <div className="col">
              <div className="chart">
                <div className="curve top-curve">
                  <img
                    src="https://ctsvsolutions.com/onepage-images/new-ui/chart-top-curve.webp"
                    alt="rasi chart curve top"
                  />
                </div>
                <div className="curve top-right">
                  <img
                    src="https://ctsvsolutions.com/onepage-images/new-ui/chart-right-curve.webp"
                    alt="rasi chart curve right"
                  />
                </div>
                <div className="curve top-bottom">
                  <img
                    src="https://ctsvsolutions.com/onepage-images/new-ui/chart-bottom-curve.webp"
                    alt="rasi chart curve bottom"
                  />
                </div>
                <div className="curve top-left">
                  <img
                    src="https://ctsvsolutions.com/onepage-images/new-ui/chart-left-curve.webp"
                    alt="rasi chart curve left"
                  />
                </div>
                <Chart
                  rasiChat={rasiChat}
                  type="rasi-chart"
                  chartName="ராசி"
                ></Chart>
              </div>
              <div className="chart-heading">
                <h3 className="sh">
                  <span className="grt">ராசி</span> கட்டம்
                </h3>
              </div>
            </div>
            <div className="col">
              <div className="chart">
                <div className="curve top-curve">
                  <img
                    src="https://ctsvsolutions.com/onepage-images/new-ui/chart-top-curve.webp"
                    alt="amsam chart curve top"
                  />
                </div>
                <div className="curve top-right">
                  <img
                    src="https://ctsvsolutions.com/onepage-images/new-ui/chart-right-curve.webp"
                    alt="amsam chart curve right"
                  />
                </div>
                <div className="curve top-bottom">
                  <img
                    src="https://ctsvsolutions.com/onepage-images/new-ui/chart-bottom-curve.webp"
                    alt="amsam chart curve bottom"
                  />
                </div>
                <div className="curve top-left">
                  <img
                    src="https://ctsvsolutions.com/onepage-images/new-ui/chart-left-curve.webp"
                    alt="amsam chart curve left"
                  />
                </div>
                <Chart
                  amsamChart={amsamChart}
                  type="amsam-chart"
                  chartName="அம்சம்"
                >
                  {" "}
                </Chart>
              </div>
              <div className="chart-heading">
                <h3 className="sh">
                  <span className="grt">அம்சம்</span> கட்டம்
                </h3>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}
