import React from 'react'
// import MarriageMatchingContentImg from '../../images/matching.webp'
// import MarriageMatchingContentIcon1 from '../../images/mm-icon-1.webp'
// import MarriageMatchingContentIcon2 from '../../images/mm-icon-2.webp'
// import MarriageMatchingContentIcon3 from '../../images/mm-icon-3.webp'
// import MarriageMatchingContentIcon4 from '../../images/mm-icon-4.webp'

export default function MarriageMatchingContent() {
    return (
        <>
            <section className="marriage-matching">
                <div className="astro-container">
                    <div className="marriage-matching-row">
                        <div className="img bb">
                            <img className="img-res" src='https://ctsvsolutions.com/onepage-images/new-ui/matching.webp' alt='horoscope matching'/>
                        </div>
                        <div className="content bb">
                            <h2 className="sh lh-1">
                                <span className="grt"> Marriage Matching</span>
                            </h2>
                            <p className="p-16">
                                Marriage matching is a decision taken by the bride and groom before
                                they get married, jathaga porutham. This matching includes their
                                marriage life, children, understanding, health, wealth, etc. In South
                                India, they call it horoscope matching.
                            </p>
                            <h4>What onepage gives in marriage matching</h4>
                            <div className="row">
                                <div className="item">
                                    <div className="inner-row">
                                        <img src='https://ctsvsolutions.com/onepage-images/new-ui/birth-chart-icon-6.webp' alt='thirumana porutham'/>
                                        <div className="col-h">
                                            <h4>Star Matching</h4>
                                            <span>Thirumana Porutham</span>
                                        </div>
                                    </div>
                                    <p className="p-16">
                                        We compare the bride and groom stars and give them a detailed
                                        report on star matching. This star matching includes Dina
                                        porutham, Gana porutham, Mahendra Porutham, Yoni porutham, Rasi
                                        porutham, Rajju porutham, Vethai Porutham, Nadi porutham, Mara
                                        porutham
                                    </p>
                                </div>
                                <div className="item">
                                    <div className="inner-row">
                                        <img src='https://ctsvsolutions.com/onepage-images/new-ui/birth-chart-icon-1.webp' alt='best thirumana porutham online'/>
                                        <div className="col-h">
                                            <h4>Dhosam Matching</h4>
                                            <span>Thirumana Porutham</span>
                                        </div>
                                    </div>
                                    <p className="p-16">
                                        By birth, bride and groom dhosam have been fixed like chevvai
                                        dhosam, Ragu kethu dhosam, and all. We analyze both reports and
                                        give results based on their birth.
                                    </p>
                                </div>
                                <div className="item">
                                    <div className="inner-row">
                                        <img src='https://ctsvsolutions.com/onepage-images/new-ui/birth-chart-icon-5.webp' alt='jathagam porutham'/>
                                        <div className="col-h">
                                            <h4>Dasa Matching</h4>
                                            <span>Thirumana Porutham</span>
                                        </div>
                                    </div>
                                    <p className="p-16">
                                        Birth chart itself fixes the date of birth of the bride and groom
                                        in marriage matching. We have marriage matching check and analyze
                                        using technology whether they have kootu dasa.
                                    </p>
                                </div>
                                <div className="item">
                                    <div className="inner-row">
                                        <img src='https://ctsvsolutions.com/onepage-images/new-ui/birth-chart-icon-4.webp' alt='rasi porutham for marriage'/>
                                        <div className="col-h">
                                            <h4>Basic Matching</h4>
                                            <span>Jathaga poruthasm</span>
                                        </div>
                                    </div>
                                    <p className="p-16">
                                        Dive into the Depths of Your Birth Chart Unlock the Secrets of
                                        Your Cosmic Blueprint Discover the Hidden Influences Shaping Your
                                        Destiny
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}
