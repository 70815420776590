import React, { useState } from 'react'
import TopNavbar from '../../Main-Components/TopNavbar/TopNavbar'
import Menubar from '../../Main-Components/Menubar/Menubar'
import Footer from '../../Main-Components/Footer/Footer'
import BlogListBanner from '../../Small-Components/BlogListBanner/BlogListBanner'
import BlogRecent from "../../Small-Components/BlogRecent/BlogRecent";
import BlogCategories from "../../Small-Components/BlogCategories/BlogCategories ";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Link, useLocation } from 'react-router-dom'
import { faFacebook, faInstagram, faLinkedin, faTwitter } from '@fortawesome/free-brands-svg-icons'
import { Helmet } from 'react-helmet'
import axios from 'axios'

export default function NumorologyBlog() {

    const location = useLocation();
    const canonical = () => {
        const base_url = "https://onepagehoroscope.com";
        const current_path = location.pathname;

        const canonicalPaths = {
            "/Blog/Numerology-Calculator":
                "/Blog/Numerology-Calculator",
        };

        return (
            <link
                rel="canonical"
                href={base_url + (canonicalPaths[current_path] || current_path)}
            />
        );
    };

    return (
        <>
            <Helmet>
                <title> Numerology Calculator | Name Number Calculator</title>
                <meta
                    name="description"
                    content="Numerology number calculator, based on date of birth, this calculation depends uyir yen and udal yen."
                />
                <meta name="keywords" content="Numerology number calculator" />
                <meta name="robots" content="index,follow" />
                {canonical()}
            </Helmet>
            <TopNavbar />
            <Menubar NavBgstyle="outer-header rjr" NavTextColor="text-dark" SubmenuTop="true" />
            <BlogListBanner />
            <NumorologyBlogContent />
            <Footer />
        </>
    )
}

function NumorologyBlogContent() {

    const [name, setName] = useState("");
    const [showOutput, setShowOutput] = useState(false);
    const [nameError, setNameError] = useState("");
    const [ApiResponse, setApiResponse] = useState("");
    const checkNumerology = () => {
        if (!name) {
            setNameError("Name is Required");
        } else {
            const data = {
                Name: name,
            };
            axios
                .post(
                    "https://ctsvsolutions.com/onepage-Api/public/api/numerology",
                    data
                )
                .then((response) => {
                    // console.log(response);
                    if (response.data.status === true) {
                        // console.log('yes true');
                        setApiResponse(response.data.data);
                        setShowOutput(true);
                    }
                })
                .catch((error) => {
                    console.error("Error:", error);
                });
        }
    };

    return (
        <>
            <section className="article-wrapper">
                <div className="astro-container pt-3">
                    <div className="row">
                        <div className="col-lg-8 my-2">
                            <div className="card bg-transparent border-0 blog-view">
                                <div className="card-header bg-transparent border-0 px-0">
                                    <h1 className="fs-4 blog-view-heading">
                                        Numerology Calculator - Name Number Calculator
                                    </h1>
                                </div>
                                <div className="card-body pt-0 px-0">
                                    <div className="my-2">
                                        <img
                                            src="https://ctsvsolutions.com/onepage_admin/onepage-images/Numerology-Calculator.webp"
                                            alt="New Born Baby Jathagam in Tamil"
                                            className="img-fluid rounded"
                                        />
                                    </div>
                                    <div className="my-4 d-flex">
                                        <a href="https://www.facebook.com/onepagehoroscope/">
                                            <div className="view-blog-icons facebook">
                                                <FontAwesomeIcon
                                                    icon={faFacebook}
                                                    className="text-white px-2"
                                                />
                                            </div>
                                        </a>
                                        <a href="https://www.instagram.com/one_page_horoscope/">
                                            <div className="view-blog-icons instagram">
                                                <FontAwesomeIcon
                                                    icon={faInstagram}
                                                    className="text-white px-2"
                                                />
                                            </div>
                                        </a>
                                        <a href="https://twitter.com/onepage321">
                                            <div className="view-blog-icons twitter">
                                                <FontAwesomeIcon icon={faTwitter} className="text-white px-2" />
                                            </div>
                                        </a>
                                        <a href="https://linkedin.com/showcase/97871433/admin/feed/posts/">
                                            <div className="view-blog-icons linkedin">
                                                <FontAwesomeIcon
                                                    icon={faLinkedin}
                                                    className="text-white px-2"
                                                />
                                            </div>
                                        </a>
                                    </div>
                                    <div className="description mt-3">
                                        <p className="mb-3">
                                            <Link to="/Birth-horoscope" className="text-decoration-none">
                                                <span className="text-danger">Numerology calculator</span>
                                            </Link>
                                            , is a kind of ancient alphabet numerology number system believed
                                            by most peoples in inda, specially choosing a name for new born
                                            baies, company name, vehicle registration number, based on date of
                                            birth, this calculation depends uyir yen and udam yen.
                                        </p>
                                        <NumerologyForm
                                            name={name}
                                            setName={setName}
                                            checkNumerology={checkNumerology}
                                            nameError={nameError}
                                        />
                                        {showOutput && <NumerologyOutput ApiResponse={ApiResponse} />}

                                        <h2 className="mb-3 fs-5 blog-view-heading">
                                            Importance of Numerology
                                        </h2>
                                        <p className="description-content mb-3">
                                            Numerology based on astrology, some time peoples searching for
                                            better hope for their future, astrology provide that hope to make
                                            your soul more confident with the help of astrologer.
                                        </p>
                                        <p className="description-content mb-3">
                                            Also we dont have choice to skip this, even an non astrology
                                            beliver is changing his mind to get better guidence for his/her
                                            future at his bad situation, there is lot of books explaining
                                            about astrology but a person who wrote books for astrology topic
                                            is B.V.Ramanan,.
                                        </p>
                                        <p className="description-content mb-3">
                                            From his book he is willing to prove the effects of every planets
                                            and how the planets leading us, based on the ruling dasa planet
                                            and the particular planet in our birth chart.
                                        </p>
                                        <p className="description-content mb-3">
                                            Numerlogy tell us the same from our Date of Birth and the ruling
                                            planet for bright future, It give us self understanding, life path
                                            guidance, improved relationship and decision making.
                                        </p>
                                        <h2 className="mb-3 fs-5 blog-view-heading">
                                            Numerology Calculator - Get your ruling number and planets
                                        </h2>
                                        <p className="description-content mb-3">
                                            We can calculate numerology number by sum of our date of birth,
                                            your numerology number is the light house for your live path it
                                            helps you determine the character of a person .you number is your
                                            identity you can choose your carrier, love, lifet parner.
                                        </p>
                                        <p className="description-content mb-3">
                                            You numerology number assign with a planet and the planet rule you
                                            for your life path, some times astrologer predicts your past and
                                            future by just giving your Date of Birth. Also we can find the
                                            name numerology for our name every alphabets consist of a number
                                            by creat the sum of numbers into single number give the number
                                            name suits your name.
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-4 my-2">
                            <div className="row">
                                <BlogRecent />
                                <BlogCategories />
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}

const NumerologyForm = ({ name, setName, checkNumerology, nameError }) => {
    const handleKeyDown = (e) => {
        if (e.key === '.') {
            e.preventDefault();
        }
        if (e.key === ',') {
            e.preventDefault();
        }

    };

    return (
        <div className="container mt-2 mb-3">
            <div className="row justify-content-center">
                <div className="col-12 col-md-8 ">
                    <div
                        className="card shadow-lg p-4"
                        style={{ borderRadius: "20px", backgroundColor: "#f8f9fa" }}
                    >
                        <h5 className="card-title text-center text-danger fw-bold">
                            Name Number Numerology
                        </h5>
                        <div className="mb-3">
                            <label htmlFor="name" className="form-label">
                                Enter Your Name
                            </label>
                            <input
                                type="text"
                                className="form-control"
                                id="name"
                                placeholder="Enter Your Name"
                                onChange={(e) => setName(e.target.value)}
                                value={name}
                                onKeyDown={handleKeyDown}
                            />
                        </div>
                        {nameError && <span className="text-danger">{nameError}</span>}
                        <div className="d-grid gap-2">
                            <button
                                className="btn btn-danger"
                                type="button"
                                onClick={checkNumerology}
                            >
                                Calculate
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

const NumerologyOutput = ({ ApiResponse }) => {
    return (
        <div className=" mt-2 mb-3">
            <div className="card shadow p-4 rounded">
                <h3 className="card-title text-center text-danger fw-bold">Name Numerology Reading of {ApiResponse.Letter}</h3>
                <div className="text-center my-4">
                    <h3>{ApiResponse.Letter}</h3>
                    <h3>{ApiResponse.Letters_Value}</h3>
                </div>
                <div className="text-center">
                    <p className="card-text">Compound Name Number (Numerology Total of Your Name): <span className="fw-bold">{ApiResponse.Compound_Name}</span></p>
                    <p className="card-text">Name Destiny: <span className="fw-bold">{ApiResponse.Name_Denstiny}</span></p>
                </div>
            </div>
        </div>
    );
};