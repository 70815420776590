import React, { useEffect } from 'react'
// import RithuJathagamImage from '../../images/center-form-with-img.webp'
// import RithuJathagamMobileImage from '../../images/center-form-with-img-mobile.webp';
import { useReducer } from 'react';
import axios from 'axios';
import RuthuFormReducer, { RuthuInitialValues } from '../../Reducer/RuthuFormReducer';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faLocationDot } from "@fortawesome/free-solid-svg-icons";
import { useNavigate } from "react-router-dom";


export default function RuthuJathagamForm() {
    const [RuthuFormData, dispatch] = useReducer(RuthuFormReducer, RuthuInitialValues);
    const AddTostate = (e, field) => {
        dispatch({
            type: 'ADD_TO_STATE',
            payload: e.target.value,
            field: field
        });
    }
    const handleLocationChange = (e) => {
        dispatch({ type: 'ADD_TO_STATE', payload: true, field: 'switch_location' });
        const currentValue = e.target.value;
        dispatch({
            type: 'ADD_TO_STATE',
            payload: currentValue,
            field: 'birthPlace'
        });
        fetchSuggestions(currentValue, 'geoname_cities');
    };
    // for form handaling.
    useEffect(() => {
        const storedValue = localStorage.getItem("Ruthuformdata");
        if (storedValue) {
            const parsedValue = JSON.parse(storedValue);
            const dateofBirth = parsedValue.userDateofBirth;
            const [selectedYear, selectedMonth, selectedDay] = dateofBirth.split("-");
            const Birthtime = parsedValue.userBirthtime;
            const timeComponents = Birthtime.split(" ");
            const time = timeComponents[0];
            const selectedAmpm = timeComponents[1];
            const [selectedHour, selectedMinute] = time.split(":");
            const latitude = parsedValue.latitude;
            const longitude = parsedValue.longitude;
            const userName = parsedValue.username;
            const FatherName = parsedValue.FatherName;
            const MotherName = parsedValue.MotherName;
            const birthPlace = parsedValue.userBirthplace;
            const TimeZone = parsedValue.TimeZone;

            dispatch({ type: 'ADD_TO_STATE', payload: selectedDay, field: 'selectedDay' });
            dispatch({ type: 'ADD_TO_STATE', payload: selectedMonth, field: 'selectedMonth' });
            dispatch({ type: 'ADD_TO_STATE', payload: selectedYear, field: 'selectedYear' });
            dispatch({ type: 'ADD_TO_STATE', payload: latitude, field: 'latitude' });
            dispatch({ type: 'ADD_TO_STATE', payload: longitude, field: 'longitude' });
            dispatch({ type: 'ADD_TO_STATE', payload: userName, field: 'userName' });
            dispatch({ type: 'ADD_TO_STATE', payload: birthPlace, field: 'birthPlace' });
            dispatch({ type: 'ADD_TO_STATE', payload: selectedHour, field: 'selectedHour' });
            dispatch({ type: 'ADD_TO_STATE', payload: selectedMinute, field: 'selectedMinute' });
            dispatch({ type: 'ADD_TO_STATE', payload: selectedAmpm, field: 'selectedAmpm' });
            dispatch({ type: 'ADD_TO_STATE', payload: FatherName, field: 'FatherName' });
            dispatch({ type: 'ADD_TO_STATE', payload: MotherName, field: 'MotherName' });
            dispatch({ type: 'ADD_TO_STATE', payload: TimeZone, field: 'TimeZone' });
        }



    }, []);


    const handleSuggestionSelect = (selectedPlace) => {
        dispatch({
            type: 'ADD_TO_STATE',
            payload: false,
            field: 'switch_location'
        })
        const latitude = selectedPlace.latitude;
        const longitude = selectedPlace.longitude;
        const TimeZone = selectedPlace.TimeZone;
        const parts = selectedPlace.districts.split(",");
        const displayName = parts.slice(0, 3).join(",");
        const location = displayName;
        const updatedSelectedPlaces = [...RuthuFormData.selectedPlaces, location];

        dispatch({
            type: 'ADD_TO_STATE',
            payload: updatedSelectedPlaces,
            field: 'selectedPlaces'
        })
        dispatch({
            type: 'ADD_TO_STATE',
            payload: location,
            field: 'birthPlace'
        });
        dispatch({
            type: 'ADD_TO_STATE',
            payload: '',
            field: 'birthplaceError'
        });

        dispatch({
            type: 'ADD_TO_STATE',
            payload: TimeZone,
            field: 'TimeZone'
        });
        dispatch({
            type: 'ADD_TO_STATE',
            payload: [],
            field: 'suggestedPlaces'
        })
        dispatch({
            type: 'ADD_TO_STATE',
            payload: latitude,
            field: 'latitude'
        })
        dispatch({
            type: 'ADD_TO_STATE',
            payload: longitude,
            field: 'longitude'
        });
    };

    const fetchSuggestions = (currentValue, LocationType) => {
        if (currentValue.length >= 1) {
            const Data = {
                location: currentValue,
                Type: LocationType,
            };
            axios
                .post(
                    "https://ctsvsolutions.com/onepage-Api/public/api/Location-Api",
                    Data
                )
                .then((response) => {
                    if (response.data.status == true) {
                        const suggestions = response.data.location;
                        dispatch({
                            type: 'ADD_TO_STATE',
                            payload: suggestions,
                            field: 'suggestedPlaces'
                        });
                    } else {
                        dispatch({
                            type: 'ADD_TO_STATE',
                            payload: '',
                            field: 'suggestedPlaces'
                        });
                    }
                });
        } else {
            dispatch({
                type: 'ADD_TO_STATE',
                payload: '',
                field: 'suggestedPlaces'
            });
        }
    };
    const SearchNewLocation = () => {
        const currentLocation = RuthuFormData.birthPlace.includes(",")
            ? RuthuFormData.birthPlace.split(",")[0]
            : RuthuFormData.birthPlace;
        fetchSuggestions(currentLocation, 'Map_Box');
    };
    const navigate = useNavigate();
    const customRedirect = (route, data) => {
        navigate(route, { state: data });
    };

    const HandleRuthuFormSubmit = () => {
        const Validate = Validation();
        if (Validate) {
            const formdata = {
                username: RuthuFormData.userName,
                gender: 'Female',
                userDateofBirth: `${RuthuFormData.selectedYear}-${RuthuFormData.selectedMonth}-${RuthuFormData.selectedDay}`,
                userBirthtime: `${RuthuFormData.selectedHour}:${RuthuFormData.selectedMinute} ${RuthuFormData.selectedAmpm}`,
                userBirthplace: RuthuFormData.birthPlace,
                latitude: RuthuFormData.latitude,
                longitude: RuthuFormData.longitude,
                FatherName: RuthuFormData.FatherName,
                MotherName: RuthuFormData.MotherName,
                TimeZone: RuthuFormData.TimeZone
            };
            console.log('form is submitted', formdata);
            //   Loading.dots("Your Horoscope is processing...");
            axios
                .post(
                    "https://ctsvsolutions.com/onepage-Api/public/api/Random-Ruthu-Jathagam",
                    formdata
                )
                .then((response) => {
                    if (response.status === 200) {
                        axios
                            .post(
                                "https://ctsvsolutions.com/onepage-Api/public/api/Onepage-Horoscope",
                                formdata
                            )
                            .then((response) => {
                                if (response.status === 200) {
                                    //   Loading.remove();
                                    customRedirect("/Ruthu-Jathagam-Confirm-Details", response.data);
                                    const value = JSON.stringify(response.data.data);
                                    localStorage.setItem("RuthuVal", value);
                                    const formdatavalue = JSON.stringify(formdata);
                                    localStorage.setItem("Ruthuformdata", formdatavalue);
                                    const userInfo = {
                                        user_id: null,
                                        customerDataId: null,
                                    };
                                    const UserInfoValues = JSON.stringify(userInfo);
                                    localStorage.setItem("RuthuUserInfo", UserInfoValues);
                                }
                            });
                    }
                })
        }
    }
    const Validation = () => {
        let IsValid = true;
        if (!RuthuFormData.userName) {
            dispatch({
                type: 'ADD_TO_STATE',
                payload: 'Field is required',
                field: 'nameError'
            })
            IsValid = false;
        } else {
            dispatch({
                type: 'ADD_TO_STATE',
                payload: '',
                field: 'nameError'
            })
        }
        if (!RuthuFormData.selectedDay) {
            dispatch({
                type: 'ADD_TO_STATE',
                payload: 'Field is required',
                field: 'birthdateError'
            });
            IsValid = false;
        } else {
            dispatch({
                type: 'ADD_TO_STATE',
                payload: '',
                field: 'birthdateError'
            });
        }
        if (!RuthuFormData.selectedMonth) {
            dispatch({
                type: 'ADD_TO_STATE',
                payload: 'Field is required',
                field: 'birthMonthError'
            });
            IsValid = false;
        } else {
            dispatch({
                type: 'ADD_TO_STATE',
                payload: '',
                field: 'birthMonthError'
            });
        }
        if (!RuthuFormData.selectedYear) {
            dispatch({
                type: 'ADD_TO_STATE',
                payload: 'Field is required',
                field: 'birthYearError'
            });
            IsValid = false;
        } else {
            dispatch({
                type: 'ADD_TO_STATE',
                payload: '',
                field: 'birthYearError'
            });
        }
        if (!RuthuFormData.selectedHour) {
            dispatch({
                type: 'ADD_TO_STATE',
                payload: 'Field is required',
                field: 'birthHourError'
            });
            IsValid = false;
        } else {
            dispatch({
                type: 'ADD_TO_STATE',
                payload: '',
                field: 'birthHourError'
            });
        }

        if (!RuthuFormData.selectedMinute) {
            dispatch({
                type: 'ADD_TO_STATE',
                payload: 'Field is required',
                field: 'birthMinuteError'
            });
            IsValid = false;
        } else {
            dispatch({
                type: 'ADD_TO_STATE',
                payload: '',
                field: 'birthMinuteError'
            });
        }
        if (!RuthuFormData.selectedAmpm) {
            dispatch({
                type: 'ADD_TO_STATE',
                payload: 'Field is required',
                field: 'AmpmError'
            });
            IsValid = false;
        } else {
            dispatch({
                type: 'ADD_TO_STATE',
                payload: '',
                field: 'AmpmError'
            });
        }

        if (!RuthuFormData.latitude) {
            dispatch({
                type: 'ADD_TO_STATE',
                payload: 'Again select the Birth place',
                field: 'birthplaceError'
            });
            IsValid = false;
        } else {
            dispatch({
                type: 'ADD_TO_STATE',
                payload: '',
                field: 'birthplaceError'
            });
        }
        if (!RuthuFormData.birthPlace) {
            dispatch({
                type: 'ADD_TO_STATE',
                payload: 'Field is required',
                field: 'birthplaceError'
            });
            IsValid = false;
        } else {
            dispatch({
                type: 'ADD_TO_STATE',
                payload: '',
                field: 'birthplaceError'
            });
        }
        if (!RuthuFormData.FatherName) {
            dispatch({
                type: 'ADD_TO_STATE',
                payload: 'Father Name is Required',
                field: 'FatherNameError'
            });
            IsValid = false;
        } else {
            dispatch({
                type: 'ADD_TO_STATE',
                payload: '',
                field: 'FatherNameError'
            });
        }
        if (!RuthuFormData.MotherName) {
            dispatch({
                type: 'ADD_TO_STATE',
                payload: 'Mother Name is required',
                field: 'MotherNameError'
            });
            IsValid = false;
        } else {
            dispatch({
                type: 'ADD_TO_STATE',
                payload: '',
                field: 'MotherNameError'
            });
        }
        return IsValid;
    };
    return (
        <>
            <section className="form-with-img">
                <div className="l-form">
                    <img className="cfwi-desktop" src='https://ctsvsolutions.com/onepage-images/new-ui/ruthu-jathagam-form-desktop.webp' alt='ruthu jathagam pdf'/>
                    <img className="cfwi-mobile" src='https://ctsvsolutions.com/onepage-images/new-ui/ruthu-jathagam-form-mobile.webp' alt='tamil ruthu jathagam palan'/>
                    <form className="form-wrapper cmn-form">
                        <h4 className="f-heading">Enter your details</h4>
                        <p className="f-sub">
                            Provide accurate details for the most precise results.
                        </p>
                        <div className="form-group">
                            <label>Full Name</label>
                            <input className='input' placeholder='Enter Your Name' type="text" value={RuthuFormData.userName} onChange={(e) => AddTostate(e, 'userName')} />
                            {RuthuFormData.nameError && (
                                <p className="text-danger">{RuthuFormData.nameError}</p>
                            )}
                        </div>
                        <div className="form-group">
                            <label>cool place</label>
                            <input className='input' placeholder='Enter Cool Place' type="text" value={RuthuFormData.birthPlace} onChange={(e) => handleLocationChange(e)} />
                            <ul>
                                {RuthuFormData.suggestedPlaces && (
                                    <ul className="p-0 mt-1 mb-0">
                                        {RuthuFormData.suggestedPlaces.reverse().map((place, index) => {
                                            if (place.districts) {
                                                const listItemStyle = {
                                                    padding: "5px 12px",
                                                    cursor: "pointer",
                                                    marginBottom: "1px",
                                                    backgroundColor: "#f2f2f2",
                                                    fontSize: "14px",
                                                    fontWeight: "540",
                                                    borderBottom: "1px dashed #ddd",
                                                };

                                                return (
                                                    <React.Fragment key={index}>
                                                        <p
                                                            style={listItemStyle}
                                                            className="px-2"
                                                            onClick={() => handleSuggestionSelect(place)}
                                                        >
                                                            <FontAwesomeIcon icon={faLocationDot} />
                                                            &nbsp;
                                                            {place.districts}
                                                        </p>
                                                    </React.Fragment>
                                                );
                                            }
                                            return null;
                                        })}

                                    </ul>
                                )}
                                {RuthuFormData.switch_location && (
                                    <p
                                        className="w-100 text-start btn py-0 rounded-0 text-white fw-bold mb-0 mt-0"
                                        style={{ fontSize: "14px", backgroundColor: "#fe1b57" }}
                                        onClick={SearchNewLocation}
                                    >
                                        {" "}
                                        <FontAwesomeIcon icon={faLocationDot} /> my place is not
                                        in List
                                    </p>
                                )}
                                {RuthuFormData.birthplaceError && (
                                    <p className="text-danger  ">{RuthuFormData.birthplaceError}</p>
                                )}
                            </ul>
                        </div>
                        <div className="form-group">
                            <label>date of birth</label>
                            <div className="row">
                                <div className="form-group">
                                    <label>date</label>
                                    <select value={RuthuFormData.selectedDay} onChange={(e) => AddTostate(e, 'selectedDay')}>
                                        <option value="">Day</option>
                                        {RuthuFormData.dayOptions.map((day) => (
                                            <option key={day} value={day}>
                                                {day}
                                            </option>
                                        ))}
                                    </select>
                                    {RuthuFormData.birthdateError && (
                                        <p className="text-danger">{RuthuFormData.birthdateError}</p>
                                    )}
                                </div>
                                <div className="form-group">
                                    <label>month</label>
                                    <select value={RuthuFormData.selectedMonth} onChange={(e) => AddTostate(e, 'selectedMonth')}>
                                        <option value="">Month</option>
                                        {RuthuFormData.monthOptions.map((month) => (
                                            <option key={month} value={month}>
                                                {month}
                                            </option>
                                        ))}
                                    </select>
                                    {RuthuFormData.birthMinuteError && (
                                        <p className="text-danger">{RuthuFormData.birthMinuteError}</p>
                                    )}
                                </div>
                                <div className="form-group">
                                    <span>year</span>
                                    <select value={RuthuFormData.selectedYear} onChange={(e) => AddTostate(e, 'selectedYear')}>
                                        <option value="">year</option>
                                        {RuthuFormData.yearOptions.map((year) => (
                                            <option key={year} value={year}>
                                                {year}
                                            </option>
                                        ))}
                                    </select>
                                    {RuthuFormData.birthYearError && (
                                        <p className="text-danger">{RuthuFormData.birthYearError}</p>
                                    )}
                                </div>
                            </div>
                        </div>
                        <div className="form-group">
                            <label>birth time</label>
                            <div className="row">
                                <div className="form-group">
                                    <label>hour</label>
                                    <select value={RuthuFormData.selectedHour} onChange={(e) => AddTostate(e, 'selectedHour')}>
                                        <option value="">Hour</option>
                                        {RuthuFormData.hourOptions.map((hour) => (
                                            <option key={hour} value={hour}>
                                                {hour}
                                            </option>
                                        ))}
                                    </select>
                                    {RuthuFormData.birthHourError && (
                                        <p className="text-danger">{RuthuFormData.birthHourError}</p>
                                    )}
                                </div>
                                <div className="form-group">
                                    <label>minute</label>

                                    <select value={RuthuFormData.selectedMinute} onChange={(e) => AddTostate(e, 'selectedMinute')}>
                                        <option value="">Minutes</option>
                                        {RuthuFormData.minuteOptions.map((minute) => (
                                            <option key={minute} value={minute}>
                                                {minute}
                                            </option>
                                        ))}
                                    </select>
                                    {RuthuFormData.birthMinuteError && (
                                        <p className="text-danger">{RuthuFormData.birthMinuteError}</p>
                                    )}
                                </div>
                                <div className="form-group">
                                    <label>am/pm</label>
                                    <select value={RuthuFormData.selectedAmpm} onChange={(e) => AddTostate(e, 'selectedAmpm')}>
                                        <option value="">period</option>
                                        <option value="AM">AM</option>
                                        <option value="PM">PM</option>
                                    </select>
                                    {RuthuFormData.AmpmError && (
                                        <p className="text-danger">{RuthuFormData.AmpmError}</p>
                                    )}
                                </div>
                            </div>
                        </div>
                        <div className="form-group">
                            <label>father’s name</label>
                            <input className='input' placeholder='Enter Father Name' type="text" name="" value={RuthuFormData.FatherName} onChange={(e) => AddTostate(e, 'FatherName')} />
                            {RuthuFormData.FatherNameError && (
                                <p className="text-danger">{RuthuFormData.FatherNameError}</p>
                            )}
                        </div>
                        <div className="form-group">
                            <label>mother’s name</label>
                            <input className='input' placeholder='Enter Mother Name' type="text" name="" value={RuthuFormData.MotherName} onChange={(e) => AddTostate(e, 'MotherName')} />
                            {RuthuFormData.MotherNameError && (
                                <p className="text-danger">{RuthuFormData.MotherNameError}</p>
                            )}
                        </div>
                        <div className="form-group-btn">
                            <button type="button" className="form-btn" onClick={HandleRuthuFormSubmit}>
                                submit
                                <img src='https://ctsvsolutions.com/onepage-images/new-ui/btn-arrow-white.webp' alt='ruthu jathagam arrow'/>
                            </button>
                        </div>
                        <div className="mt-3">
                            <p className="f-sub text-danger text-center mb-0">
                                * Your ruthu jathagam will be generated in Tamil.
                            </p>
                        </div>
                    </form>
                </div>
            </section>
        </>
    )
}
