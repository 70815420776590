import React, { useState,useEffect } from "react";
import ReportSearch from "./ReportSearch";
import Table from "../components/Table";
import { useNavigate } from "react-router-dom";
export default function AdminReport() {
    const [Package,SetPackage]=useState('');
    const [UserData, setUserData] = useState([]);
    const [showCustomDate, setShowCustomDate] = useState(false);
    const [TableHeader, setTableHeader] = useState([]);
    const [TableBody, setTableBody] = useState([]);
  
const navigate = useNavigate();
    useEffect(() => {
      console.log('yes its a user data',UserData);
      if (UserData.package === 'one_page') {
        setTableHeader(['s.no', 'user_id', 'Data id', 'Name','Gender','Date of Birth', 'Birth Time', 'Birth Place', 'package']);
        setTableBody([
          {
            id: 1,
            user_id_fk: UserData.user_id_fk,
            data_id: UserData.id,
            name: UserData.name,
            gender:UserData.gender,
            birth_date: UserData.birth_date,
            birth_time: UserData.birth_time,
            birth_place: UserData.birth_place,
            package: UserData.package,
            latitude:UserData.latitude,
            longitude:UserData.longitude
          }
        ]);
      } else if (UserData.package === 'marriage_matching') {
        setTableHeader(['s.no', 'user_id', 'Data id', 'Name', 'Date of Birth', 'Birth Time', 'Birth Place', 'package']);
        setTableBody([
          {
            id: 1,
            user_id_fk: UserData.user_id_fk,
            data_id: UserData.id,
            name: UserData.name,
            birth_date: UserData.birth_date,
            birth_time: UserData.birth_time,
            birth_place: UserData.birth_place,
            package: UserData.package,
            latitude:UserData.latitude,
            longitude:UserData.longitude
          }
        ]);
      } else if (UserData.package === 'baby_name') {
        setTableHeader(['s.no', 'user_id', 'Data id','Gender','Date of Birth', 'Birth Time', 'Birth Place', 'father Name', 'mother Name', 'package']);
        setTableBody([
          {
            id: 1,
            user_id_fk: UserData.user_id_fk,
            data_id: UserData.id,
            gender:UserData.gender,
            birth_date: UserData.birth_date,
            birth_time: UserData.birth_time,
            birth_place: UserData.birth_place,
            father_name: UserData.father_name,
            mother_name: UserData.mother_name,
            package: UserData.package,
            latitude:UserData.latitude,
            longitude:UserData.longitude
          }
        ]);
      }
      else if (UserData.package === 'Ruthu_Jathagam') {
        setTableHeader(['s.no', 'user_id','Data id','Name','Gender','Date of Birth', 'Birth Time', 'Birth Place', 'father Name', 'mother Name', 'package']);
        setTableBody([
          {
            id: 1,
            user_id_fk: UserData.user_id_fk,
            data_id: UserData.id,
            Name:UserData.name,
            gender:UserData.gender,
            birth_date: UserData.birth_date,
            birth_time: UserData.birth_time,
            birth_place: UserData.birth_place,
            father_name: UserData.father_name,
            mother_name: UserData.mother_name,
            package: UserData.package,
            latitude:UserData.latitude,
            longitude:UserData.longitude
          }
        ]);
      }
      else if (UserData.package === 'marriage_bio_data') {
        setTableHeader(['s.no', 'user_id','Data id','Name','Gender','Date of Birth', 'Birth Time', 'Birth Place', 'father Name', 'mother Name', 'package','Template']);
        setTableBody([
          {
            id: 1,
            user_id_fk: UserData.user_id_fk,
            data_id: UserData.customer_id_fk,
            Name:UserData.name,
            gender:UserData.gender,
            birth_date: UserData.birth_date,
            birth_time: UserData.birth_time,
            birth_place: UserData.birth_place,
            father_name: UserData.father_name,
            mother_name: UserData.mother_name,
            package: UserData.package,
            Template:UserData.template,
            latitude:UserData.latitude,
            longitude:UserData.longitude
          }
        ]);
      }
    }, [UserData.package]);

    useEffect(() => {
        let IsAuth = localStorage.getItem('IsLogin');
        if (!IsAuth) {
            navigate('/Admin-Login');
            // navigate('/Admin-Report');
        }
        console.log('Data is',TableBody);
    }, []);
  
    
    return (
        <>
          <div
            style={{
              border: "1px solid #ccc",
              borderRadius: "8px",
              padding: "15px",
              marginBottom: "20px",
            }}
            className="container p-2 mt-4"
          >
            <div className="row">
              <div style={{ flex: "0 0 100%", maxWidth: "100%" }} className="col-lg-12">
                <ReportSearch
                  SetPackage={SetPackage}
                  setUserData={setUserData}
                  showCustomDate={showCustomDate}
                />
              </div>
            </div>
          </div>
          <div
            style={{
              border: "1px solid #ccc",
              borderRadius: "8px",
              padding: "15px",
            }}
            className="card mt-4 container"
          >
            <section style={{ marginTop: "10px" }}>
                <div className="text-center">
                <span className="text-primary fw-bold ">Report</span>
                </div>
               
              <Table TableHeader={TableHeader} TableData={TableBody} className="" />
            </section>
          </div>
        </>
      );
    }