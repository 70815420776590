import React, { useContext } from 'react'
// import EditImage from '../..//images/btn-edit-icon.webp'
// import DOB from '../../images/rd-icon-1.webp'
// import Gender from '../../images/rd-icon-2.webp'
// import BOT from '../../images/rd-icon-4.webp'
// import Location from '../../images/rd-icon-5.webp';
import { RuthuViewContext } from '../../context/viewpage/RuthuViewContextProvider'
import { useNavigate } from 'react-router-dom'

export default function RuthuJathagaViewPerDet() {
    const { personal_info } = useContext(RuthuViewContext)
   
    const Navigate = useNavigate();
    const Edit = () => {
        Navigate('/Ruthu-Jathagam')
    }
    return (
        <>
            <section className="result-info rjr prjr">
                <div className="astro-container">
                    <div className="result">
                        <div className="bhres-row-1">
                            <div className="name">
                                <h2 className="sh">{personal_info.name}</h2>
                            </div>
                            <div className="edit">
                                <button type='button' onClick={Edit} className="btn-lg btn-prpl border-0">
                                    Edit
                                    <img src='https://ctsvsolutions.com/onepage-images/new-ui/btn-edit-icon.webp' alt='ruthu jathagam edit'/>
                                </button>
                            </div>
                        </div>
                        <div className="bhres-row-2 bhres-col-4">
                            <div className="rd">
                                <div className="icon">
                                    <img src='https://ctsvsolutions.com/onepage-images/new-ui/date-icon.webp' alt='expiration date' />
                                </div>
                                <div className="detail">
                                    <span>expiration date</span>
                                    <h4>{personal_info.birth_date}</h4>
                                </div>
                            </div>
                            <div className="rd">
                                <div className="icon">
                                    <img src='https://ctsvsolutions.com/onepage-images/new-ui/gender-icon.webp' alt='female'/>
                                </div>
                                <div className="detail">
                                    <span>Gender</span>
                                    <h4>{personal_info.gender}</h4>
                                </div>
                            </div>
                            <div className="rd">
                                <div className="icon">
                                    <img src='https://ctsvsolutions.com/onepage-images/new-ui/birth-time-icon.webp' alt='cool time'/>
                                </div>
                                <div className="detail">
                                    <span>cool time</span>
                                    <h4>{personal_info.birth_time}</h4>
                                </div>
                            </div>
                            <div className="rd">
                                <div className="icon">
                                    <img src='https://ctsvsolutions.com/onepage-images/new-ui/location-icon.webp' alt='location'/>
                                </div>
                                <div className="detail">
                                    <span>old town</span>
                                    <h4>{personal_info.birth_place.split(',')[0]}</h4>
                                </div>
                            </div>
                        </div>
                        <button type='button' onClick={Edit} className="btn-lg btn-prpl edit-btn-mobile border-0">
                            Edit
                            <img src='https://ctsvsolutions.com/onepage-images/new-ui/btn-edit-icon.webp' alt='tamil ruthu jathagam edit'/>
                        </button>
                    </div>
                </div>
            </section>
        </>
    )
}
