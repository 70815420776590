import React from 'react'
import TopNavbar from '../../Main-Components/TopNavbar/TopNavbar'
import Menubar from '../../Main-Components/Menubar/Menubar'
import Footer from '../../Main-Components/Footer/Footer'
import BlogListBanner from '../../Small-Components/BlogListBanner/BlogListBanner'
import BlogRecent from "../../Small-Components/BlogRecent/BlogRecent";
import BlogCategories from "../../Small-Components/BlogCategories/BlogCategories ";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Link, useLocation } from 'react-router-dom'
import { faFacebook, faInstagram, faLinkedin, faTwitter } from '@fortawesome/free-brands-svg-icons'
import { Helmet } from 'react-helmet'
import BirthHoroscopeForm from '../../Forms/BirthHoroscopeForm/BirthHoroscopeForm';
import BirthHoroscopeHomeContextProvider from "../../context/forms/BirthHoroscope_Home_ContextProvider"

export default function CreateHoroscopeBlog() {

    const location = useLocation();
    const canonical = () => {
        const base_url = "https://onepagehoroscope.com";
        const current_path = location.pathname;

        const canonicalPaths = {
            "/Blog/tamil-astrology-12-houses-explained": "/Blog/tamil-astrology-12-houses-explained",

        };

        return (
            <link
                rel="canonical"
                href={base_url + (canonicalPaths[current_path] || current_path)}
            />
        );
    };

    return (
        <>
            <Helmet>
                <title> Jathagam Online - Easy PDF Jathagam Online</title>
                <meta
                    name="description"
                    content="Unlock your destiny with Jathagam Online - Your trusted source for accurate astrological predictions. Get personalized insights now!"
                />
                <meta
                    name="keywords"
                    content="jathagam online"
                />
                <meta name="robots" content="index,follow" />
                <meta property="og:title" content="Jathagam Online - Easy PDF Jathagam Online" />
                <meta property="og:description" content="Unlock your destiny with Jathagam Online - Your trusted source for accurate astrological predictions. Get personalized insights now!" />
                <meta property="og:image" content="https://ctsvsolutions.com/onepage-images/Jathagam-Online.webp" />
                <meta property="og:url" content="https://onepagehoroscope.com/Blog/Jathagam-Online-PDF" />
                <meta name="twitter:card" content="summary_large_image" />
                <meta name="twitter:title" content="Jathagam Online - Easy PDF Jathagam Online" />
                <meta name="twitter:description" content="Unlock your destiny with Jathagam Online - Your trusted source for accurate astrological predictions. Get personalized insights now!" />
                <meta name="twitter:url" content="https://onepagehoroscope.com/Blog/Jathagam-Online-PDF" />
                {canonical()}
                <script type="application/ld+json">
                    {JSON.stringify({

                        "@context": "https://schema.org",
                        "@type": "Article",
                        "mainEntityOfPage": {
                            "@type": ".webpage",
                            "@id": "https://onepagehoroscope.com/Blog/Jathagam-Online-PDF"
                        },
                        "headline": "Jathagam Online - Easy PDF Jathagam Online",
                        "description": "Unlock your destiny with Jathagam Online - Your trusted source for accurate astrological predictions. Get personalized insights now!",
                        "image": "https://ctsvsolutions.com/onepage-images/Jathagam-Online.webp",
                        "author": {
                            "@type": "",
                            "name": ""
                        },
                        "publisher": {
                            "@type": "Organization",
                            "name": "",
                            "logo": {
                                "@type": "ImageObject",
                                "url": ""
                            }
                        },
                        "datePublished": "2024-03-19",
                        "dateModified": "2024-03-21"
                    })}
                </script>
            </Helmet>
            <TopNavbar />
            <Menubar NavBgstyle="outer-header rjr" NavTextColor="text-dark" SubmenuTop="true" />
            <BlogListBanner />
            <CreateHoroscopeBlogContent />
            <Footer />
        </>
    )
}

function CreateHoroscopeBlogContent() {
    return (
        <>
            <section className="article-wrapper">
                <div className="astro-container pt-3">
                    <div className="row">
                        <div className="col-lg-8 my-2">
                            <div className="card bg-transparent border-0 blog-view">
                                <div className="card-header bg-transparent border-0 px-0">
                                    <h1 className="fs-4 blog-view-heading">
                                        Jathagam Online - Easy PDF Jathagam Online
                                    </h1>
                                </div>
                                <div className="card-body pt-0 px-0">
                                    <div className="my-2">
                                        <img
                                            src="https://ctsvsolutions.com/onepage-images/Jathagam-Online.webp"
                                            alt="Jathagam Online"
                                            className="img-fluid rounded"
                                        />
                                    </div>


                                    {/* <div ><a target="_blank" href="https://www.facebook.com/sharer/sharer.php?u=https%3A%2F%2Fdevelopers.facebook.com%2Fdocs%2Fplugins%2F&amp;src=sdkpreparse" class="fb-xfbml-parse-ignore">Share</a></div> */}
                                    <div className="my-4 d-flex">
                                        <div className="fb-share-button" data-href="https://developers.facebook.com/docs/plugins/" data-layout="" data-size="">
                                            <a  href="https://www.facebook.com/sharer/sharer.php?u=https://onepagehoroscope.com/Blog/marriage-matching-tips">
                                                <div className="view-blog-icons facebook">
                                                    <FontAwesomeIcon
                                                        icon={faFacebook}
                                                        className="text-white px-2"
                                                    />
                                                </div>
                                            </a>
                                        </div>
                                        {/* <FacebookShareButton url={shareUrl}>
          <FontAwesomeIcon
                  icon={faFacebook}
                  className="text-white px-2"
                />
  {(shareCount) => <span className="myShareCountWrapper">{shareCount}</span>}
</FacebookShareButton> */}


                                        <a href="https://www.instagram.com/one_page_horoscope/">
                                            <div className="view-blog-icons instagram">
                                                <FontAwesomeIcon
                                                    icon={faInstagram}
                                                    className="text-white px-2"
                                                />
                                            </div>
                                        </a>
                                        <a href="https://twitter.com/onepage321">
                                            <div className="view-blog-icons twitter">
                                                <FontAwesomeIcon icon={faTwitter} className="text-white px-2" />
                                            </div>
                                        </a>
                                        <a href="https://linkedin.com/showcase/97871433/admin/feed/posts/">
                                            <div className="view-blog-icons linkedin">
                                                <FontAwesomeIcon
                                                    icon={faLinkedin}
                                                    className="text-white px-2"
                                                />
                                            </div>
                                        </a>
                                    </div>
                                    <div className="description mt-3">
                                        <p className="mb-3">
                                            <Link to="/Birth-horoscope" className="text-decoration-none">
                                                <span className="text-danger">Jathagam online</span>
                                            </Link> is a Tamil word, also called birth chart. We know
                                            jathagam is used to analyse the future with the help of <Link to="/Blog/tamil-astrology-12-houses-explained" className="text-decoration-none">
                                                <span className="text-danger">planetary
                                                    position from the 12 houses.</span>
                                            </Link>
                                        </p>
                                        <div className="mb-3">
                                        <BirthHoroscopeHomeContextProvider>
                                        <BirthHoroscopeForm />
                                        </BirthHoroscopeHomeContextProvider>
                                        </div>
                                        {/* <div className="my-2">
                                            <BirthHorosopeBlogForm />
                                        </div> */}
                                        <div>
                                            <h2 className="mb-3 fs-5 blog-view-heading">
                                                Step by Step to create Jathagam
                                            </h2>
                                            <p className="description-content mb-3">
                                                Before creating jathagam we should have basic details date of
                                                birth, birth time and birth location be ready with these details
                                                if you know before thats grate.
                                            </p>
                                        </div>
                                        <div>
                                            <h2 className="mb-3 fs-5 blog-view-heading">Setp: 1</h2>
                                            <p className="description-content mb-3">
                                                Login to one page{" "}
                                                <Link to="/Birth-horoscope" className="text-decoration-none">
                                                    <span className="text-danger">www.onepagehoroscope.com</span>
                                                </Link>
                                            </p>
                                            <div className="my-2">
                                                <img
                                                    src="https://ctsvsolutions.com/onepage-images/tamilhoroscope.webp"
                                                    alt="onepage horoscope 12 houses"
                                                    className="img-fluid rounded"
                                                />
                                            </div>
                                        </div>
                                        <div>
                                            <h2 className="mb-3 fs-5 blog-view-heading">Step : 2</h2>
                                            <p className="description-content mb-3">
                                                Click on Birth horoscope form, it will open. use this input form to
                                                create your jathagam,
                                            </p>
                                            <div className="my-2">
                                                <img
                                                    src="https://ctsvsolutions.com/onepage-images/horoscope-in-tamil.webp"
                                                    alt="onepage horoscope 12 houses"
                                                    className="img-fluid rounded"
                                                />
                                            </div>
                                        </div>
                                        <div>
                                            <h2 className="mb-3 fs-5 blog-view-heading">Step : 3</h2>
                                            <p className="description-content mb-3">
                                                Fill the form with your basic details, Example{" "}
                                            </p>
                                            <p className="description-content mb-3">Name : Ajith Kumar</p>
                                            <p className="description-content mb-3">Gender : Male</p>
                                            <p className="description-content mb-3">Birth Place : Chennai</p>
                                            <p className="description-content mb-3">
                                                Date of birth : 01/05/2000
                                            </p>
                                            <p className="description-content mb-3">Birth Time : 10:10 PM</p>
                                            <p className="description-content mb-3">
                                                after filling details click genereate horoscope.
                                            </p>
                                        </div>
                                        <div>
                                            <h2 className="mb-3 fs-5 blog-view-heading">Step : 4</h2>
                                            <p className="description-content mb-3">
                                                After creating the horosope, it shows your horoscope details.check
                                                details like your birth chart and birth horoscope sign to confirm
                                                your enterted the correct details.
                                            </p>
                                        </div>
                                        <div className="my-2">
                                            <img
                                                src="https://ctsvsolutions.com/onepage-images/online-astrology.webp"
                                                alt="onepage horoscope 12 houses"
                                                className="img-fluid rounded"
                                            />
                                        </div>
                                        <div>
                                            <h2 className="mb-3 fs-5 blog-view-heading">Setp : 5</h2>
                                            <p className="description-content mb-3">
                                                Click the download button. It will redirect you to the payment gateway. After the payment, you will receive the jathagam in PDF format on WhatsApp within 2 minutes
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-4 my-2">
                            <div className="row">
                                <BlogRecent />
                                <BlogCategories />
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}