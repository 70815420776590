import React from 'react'
import TopNavbar from '../../Main-Components/TopNavbar/TopNavbar';
import Menubar from '../../Main-Components/Menubar/Menubar';
import Footer from '../../Main-Components/Footer/Footer';
import MarriageBioDataViewBanner from '../../Small-Components/MarriageBioDataViewBanner/MarriageBioDataViewBanner'
import MarriageBioDataViewProfile from '../../Small-Components/MarriageBioDataViewProfile/MarriageBioDataViewProfile'
import MarriageBioDataViewContact from '../../Small-Components/MarriageBioDataViewContact/MarriageBioDataViewContact'
import MarriageBioDataViewFullDetail from '../../Small-Components/MarriageBioDataViewFullDetail/MarriageBioDataViewFullDetail'
import BioViewSlider from "../../Small-Components/BiodataViewSlider/BiodataViewSlider";
import BioViewContextProvider from "../../context/viewpage/BioViewContextProvider";
export default function BioDataViewNew() {
  return (
    <>
      <BioViewContextProvider>
      <TopNavbar />
      <Menubar NavBgstyle="outer-header mbr" NavTextColor="text-dark" SubmenuTop="true" />
      <MarriageBioDataViewBanner />
        <MarriageBioDataViewProfile />
        <MarriageBioDataViewContact />
        <MarriageBioDataViewFullDetail />
        <BioViewSlider />
      <Footer />
      </BioViewContextProvider>
    </>
  )
}
