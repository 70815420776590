import React from 'react'
// import RuthuJathagamIcon1 from '../../images/rjt-icon-1.webp'
// import RuthuJathagamIcon2 from '../../images/rjt-icon-2.webp'
// import RuthuJathagamMobileIcon1 from '../../images/rjt-icon-mobile-1.webp'

export default function RuthuJathagamBanner() {
    return (
        <>
            <section className="page-outer center form-with-img">
                <div className="astro-container">
                    <div className="row">
                        <div className="col">
                            <div className="content">
                                <div className="rjt-icon-left">
                                    <img src='https://ctsvsolutions.com/onepage-images/new-ui/ruthu-jathaga-icon-2.webp' alt='ruthu jathagam tamil'/>
                                </div>
                                <h1 className="sh lh-1">
                                    <span className="grt">ருது ஜாதகம் - </span> Ruthu jathagam in tamil
                                </h1>
                                <p className="p-outer">Rutu Horoscope</p>
                                <div className="rjt-icon-right">
                                    <img src='https://ctsvsolutions.com/onepage-images/new-ui/ruthu-jathaga-icon-1.webp' alt='ruthu jathaga panchangam'/>
                                </div>
                                <div className="rjt-icon-mobile">
                                    <img src='https://ctsvsolutions.com/onepage-images/new-ui/ruthu-jathaga-icon-mobile-1.webp' alt='ruthu jathagam horoscope'/>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}
