import React, { useRef, useEffect, useState } from 'react';
import Slider from 'react-slick';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import axios from 'axios';

function Review() {
    const sliderRef = useRef(null);

    const handleNext = () => {
        if (sliderRef.current) {
            sliderRef.current.slickNext();
        }
    };

    useEffect(() => {
        const intervalId = setInterval(() => {
            handleNext();
        }, 10000);

        return () => clearInterval(intervalId);
    }, []);

    const carouselSettings = {
        infinite: true,
        slidesToShow: 3,
        speed: 400,
        slidesToScroll: 1,
        centerMode: true,
        arrows: true,
        responsive: [
            {
                breakpoint: 1279,
                settings: {
                    slidesToShow: 3,
                    slidesToScroll: 1,
                }
            },
            {
                breakpoint: 1024,
                settings: {
                    slidesToShow: 3,
                    slidesToScroll: 1,
                }
            },
            {
                breakpoint: 768,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                }
            },
            {
                breakpoint: 479,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                    centerMode: false,
                }
            },
        ]
    };

    const [customerReview, setCustomerReview] = useState([]);

    useEffect(() => {
       axios.get('https://ctsvsolutions.com/onepage-Api/public/api/User-Review').then(response=>{
        setCustomerReview(response.data.data)
       })
    }, [])

    return (
        <div>
            <section className="clients-says">
                <div className="astro-container">
                    <div className="s-heading">
                        <h2 className="sh">What <span className="grt">Clients</span> Are Saying</h2>
                        <h3>Client Success Stories: Hear What Our Users Have to Say!</h3>
                    </div>
                    <div className="cs-slider">
                        <Slider className="mt-1" {...carouselSettings} ref={sliderRef}>
                            {
                                customerReview.map((data, index) => (
                                    <div key={index}>
                                        <div className=''>
                                            <div className="slide">
                                                <div className="item nth-1">
                                                    <p>{data.review}</p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                ))
                            }
                        </Slider>
                    </div>
                </div>
            </section>
        </div>
    );
}

export default Review;
