import React from "react";
import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import { Loading } from "notiflix/build/notiflix-loading-aio";

export default function TamilJathagaBookForm() {
  // const [showModal, setShowModal] = useState(false);
  // useEffect(() => {
  //   // console.log(location.pathname);
  //   if (location.pathname === '/Tamil-jathagam-book') {

  //     setShowModal(true);
  //   } else {
  //     setShowModal(false);
  //   }
  // }, [location]);
  // const closeModal = () => {
  //   setShowModal(false);
  // };
  const [dayOptions] = useState(
    [...Array(31).keys()].map((day) => String(day + 1).padStart(2, "0"))
  );
  const [monthOptions] = useState(
    [...Array(12).keys()].map((month) => String(month + 1).padStart(2, "0"))
  );
  const [yearOptions] = useState(() => {
    const currentYear = new Date().getFullYear();
    const startYear = 1950;
    return [...Array(currentYear - startYear + 1).keys()].map(
      (year) => startYear + year
    );
  });
  const [hourOptions] = useState(
    [...Array(12).keys()].map((hour) => String(hour + 1).padStart(2, "0"))
  );
  const [minuteOptions] = useState(
    [...Array(60).keys()].map((minute) => String(minute).padStart(2, "0"))
  );
  const [birthPlace, setBirthPlace] = useState("");
  const [userName, setUserName] = useState("");
  const [userGender, setUserGender] = useState("");
  const [suggestedPlaces, setSuggestedPlaces] = useState([]);
  const [selectedPlaces, setSelectedPlaces] = useState([]);
  const [selectedDay, setSelectedDay] = useState("");
  const [selectedMonth, setSelectedMonth] = useState("");
  const [selectedYear, setSelectedYear] = useState("");
  const [selectedHour, setSelectedHour] = useState("");
  const [selectedMinute, setSelectedMinute] = useState("");
  const [selectedAmpm, setSelectedAmpm] = useState("");
  const [nameError, setNameError] = useState("");
  const [genderError, setGenderError] = useState("");
  const [birthplaceError, setBirthplaceError] = useState("");
  const [birthdateError, setBirthdateError] = useState("");
  const [birthHourError, setBirthHourError] = useState("");
  const [birthMinuteError, setBirthMinuteError] = useState("");
  const [birthAmpmError, setBirthAmpmError] = useState("");
  const [FatherName, setFatherName] = useState('');
  const [MotherName, setMotherName] = useState('');
  const [FatherNameError, setFatherNameError] = useState('');
  const [MotherNameError, setMotherNameError] = useState('');


  const handleLocationChange = (event) => {
    const currentValue = event.target.value;
    setBirthPlace(currentValue);
    fetchSuggestions(currentValue);
  };

  const handleSuggestionSelect = (selectedPlace) => {
    // const { geometry, properties } = selectedPlace;
    const latitude = selectedPlace.center[1];
    const longitude = selectedPlace.center[0];

    const parts = selectedPlace.place_name.split(",");
    const displayName = parts.slice(0, 3).join(",");
    const location = displayName;

    const updatedSelectedPlaces = [...selectedPlaces, location];

    setSelectedPlaces(updatedSelectedPlaces);
    setBirthPlace(location);
    setBirthplaceError("");

    setSuggestedPlaces([]);

    document.getElementById("atflg").value = longitude;
    document.getElementById("atflt").value = latitude;
  };

  const fetchSuggestions = async (currentValue) => {
    if (currentValue.length >= 3) {
      const apiKey =
        "pk.eyJ1Ijoic2hpbmVhc3Ryb2xvZ3kiLCJhIjoiY2xxZjVveXR3MGloajJrczVod25zYmYxMiJ9.z7JbT8ljzoLOerZLUlgVQg";
      const url = `https://api.mapbox.com/geocoding/v5/mapbox.places/${currentValue}.json?access_token=${apiKey}&limit=10`;

      try {
        const response = await fetch(url);
        console.log(response);
        if (response.ok) {
          const data = await response.json();
          setSuggestedPlaces(data.features);
        } else {
          console.error("Error fetching suggestions from the API");
          setSuggestedPlaces([]);
        }
      } catch (error) {
        console.error("Error fetching suggestions from the API: ", error);
        setSuggestedPlaces([]);
      }
    }
  };

  const navigate = useNavigate();

  const customRedirect = (route, data) => {
    navigate(route, { state: data });
  };

  const validation = () => {
    let IsValid = true;
    if (!userName) {
      setNameError("Field is required");
      IsValid = false;
    } else {
      setNameError("");
    }

    if (!userGender) {
      setGenderError("Field is required");
      IsValid = false;
    } else {
      setGenderError("");
    }

    if (!selectedDay || !selectedMonth || !selectedYear) {
      setBirthdateError("Field is required");
      IsValid = false;
    } else {
      setBirthdateError("");
    }

    if (!selectedHour) {
      setBirthHourError("Field is required");
      IsValid = false;
    } else {
      setBirthHourError("");
    }

    if (!selectedMinute) {
      setBirthMinuteError("Field is required");
      IsValid = false;
    } else {
      setBirthMinuteError("");
    }

    if (!selectedAmpm) {
      setBirthAmpmError("Field is required");
      IsValid = false;
    } else {
      setBirthAmpmError("");
    }

    if (!birthPlace) {
      setBirthplaceError("Field is required");
      IsValid = false;
    } else {
      setBirthplaceError("");
    }
    let LatitudeError = document.getElementById("atflt").value;

    if (!LatitudeError) {
      setBirthplaceError("Again Select the BirthPlace");
      IsValid = false;
    } else {
      setBirthplaceError("");
    }

    if (!FatherName) {
      setFatherNameError("Father Name is Required");
      IsValid = false;
    } else {
      setFatherNameError("");
    }
    if (!MotherName) {
      setMotherNameError("Mother Name is Required");
      IsValid = false;
    } else {
      setMotherNameError("");
    }
    return IsValid;
  };
  const HoroscopeFormSubmit = (e) => {
    e.preventDefault();
    if (validation() === true) {
      const formdata = {
        username: userName,
        gender: userGender,
        userDateofBirth: `${selectedYear}-${selectedMonth}-${selectedDay}`,
        userBirthtime: `${selectedHour}:${selectedMinute} ${selectedAmpm}`,
        userBirthplace: birthPlace,
        FatherName: FatherName,
        MotherName: MotherName,
        latitude: document.getElementById("atflt").value,
        longitude: document.getElementById("atflg").value,
      };
      // console.log('1',formdata);
      Loading.dots("Your Horoscope is processing...");

      axios
        .post(
          "https://ctsvsolutions.com/onepage-Api/public/api/Horoscope-Book-Random",
          formdata
        )
        .then((response) => {
          if (response.status === 200) {
            axios
              .post(
                "https://ctsvsolutions.com/onepage-Api/public/api/Onepage-Horoscope",
                formdata
              )
              .then((response) => {
                if (response.status === 200) {
                  Loading.remove();
                  customRedirect("/Tamil-Jathagam-Confirm-Details", response.data);
                  const value = JSON.stringify(response.data.data);
                  localStorage.setItem("HoroscopeBookVal", value);
                  const formdatavalue = JSON.stringify(formdata);
                  localStorage.setItem("Bookformdata", formdatavalue);
                  const userInfo = {
                    user_id: null,
                    customerDataId: null,
                  };
                  const UserInfoValues = JSON.stringify(userInfo);
                  localStorage.setItem("BookuserInfo", UserInfoValues);
                }
              });
          }
        })
        .catch((err) => {
          console.log(err);
        });
    }
  }
  useEffect(() => {
    const storedValue = localStorage.getItem("Bookformdata");
    if (storedValue) {
      const parsedValue = JSON.parse(storedValue);
      const dateofBirth = parsedValue.userDateofBirth;
      const [year, month, day] = dateofBirth.split("-");

      const Birthtime = parsedValue.userBirthtime;
      const timeComponents = Birthtime.split(" ");
      const time = timeComponents[0];
      const ampm = timeComponents[1];
      const [hour, minute] = time.split(":");

      setUserName(parsedValue.username);
      setUserGender(parsedValue.gender);
      setSelectedPlaces(parsedValue.userBirthplace);
      setSelectedDay(day);
      setSelectedMonth(month);
      setSelectedYear(year);
      setSelectedHour(hour);
      setSelectedMinute(minute);
      setSelectedAmpm(ampm);
      setFatherName(parsedValue.FatherName)
      setMotherName(parsedValue.MotherName)
      setBirthPlace(parsedValue.userBirthplace);
      document.getElementById("atflg").value = parsedValue.longitude;
      document.getElementById("atflt").value = parsedValue.latitude;
    }
  }, []);

  return (
    <>

      <div className="form">
        <form className="cmn-form">
          <h4 className="f-heading mb-0">Enter your birth details</h4>
          <p className="f-sub">
            Provide accurate details for the most precise report.
          </p>
          <div className="form-group">
            <label className={`${nameError && "is-invalid"
              }`}>Full Name</label>
            <input className='input' type="text"
              autoComplete="off"
              placeholder="Enter your name"
              id="Name"
              value={userName}
              onChange={(e) => setUserName(e.target.value)}
            />
            {nameError && (
              <div className="invalid-feedback fw-bold">
                {nameError}
              </div>
            )}
          </div>
          <div className="form-group">
            <label>Gender</label>
            <select id="gender" className={`${genderError && "is-invalid"
              }`}
              value={userGender}
              onChange={(e) => setUserGender(e.target.value)}>
              <option value="">Gender</option>
              <option value="Male">Male</option>
              <option value="Female">Female</option>
            </select>
            {genderError && (
              <div className="invalid-feedback">{genderError}</div>
            )}
          </div>
          <div className="form-group">
            <label>date of birth</label>
            <div className="row">
              <div className="form-group date-icon">
                <label>date</label>
                <select id="day" className={`${birthdateError && "is-invalid"
                  }`} value={selectedDay}
                  onChange={(e) => setSelectedDay(e.target.value)}>
                  <option value="">Day</option>
                  {dayOptions.map((day) => (
                    <option key={day} value={day}>
                      {day}
                    </option>
                  ))}
                </select>
                {birthdateError && (
                  <div className="invalid-feedback ">{birthdateError}</div>
                )}
              </div>
              <div className="form-group">
                <label>month</label>
                <select id="month" className={`${birthdateError && "is-invalid"
                  }`} value={selectedMonth}
                  onChange={(e) => setSelectedMonth(e.target.value)}>
                  <option value="">Month</option>
                  {monthOptions.map((month) => (
                    <option key={month} value={month}>
                      {month}
                    </option>
                  ))}
                </select>
                {birthdateError && (
                  <div className="invalid-feedback ">{birthdateError}</div>
                )}
              </div>
              <div className="form-group">
                <label>year</label>
                <select id="year" className={`${birthdateError && "is-invalid"
                  }`} value={selectedYear}
                  onChange={(e) => setSelectedYear(e.target.value)}>
                  <option value="">Year</option>
                  {yearOptions.map((year) => (
                    <option key={year} value={year}>
                      {year}
                    </option>
                  ))}
                </select>
                {birthdateError && (
                  <div className="invalid-feedback ">{birthdateError}</div>
                )}
              </div>
            </div>
          </div>
          <div className="form-group">
            <label>birth time</label>
            <div className="row">
              <div className="form-group time-icon">
                <label>hour</label>
                <select id="hour" className={`${birthHourError && "is-invalid"
                  }`} style={{ color: '#102156' }}
                  value={selectedHour}
                  onChange={(e) => setSelectedHour(e.target.value)}>
                  <option value="">Hour</option>
                  {hourOptions.map((hour) => (

                    <option key={hour} value={hour}>
                      {hour}
                    </option>
                  ))}
                </select>
                {birthHourError && (
                  <div className="invalid-feedback  fw-bold">
                    {birthHourError}
                  </div>
                )}
              </div>
              <div className="form-group">
                <label>minute</label>
                <select id="minute" className={`${birthMinuteError && "is-invalid"
                  }`} value={selectedMinute}
                  onChange={(e) => setSelectedMinute(e.target.value)}>
                  <option value="">Minute</option>
                  {minuteOptions.map((minute) => (
                    <option key={minute} value={minute}>
                      {minute}
                    </option>
                  ))}
                </select>
                {birthMinuteError && (
                  <div className="invalid-feedback  fw-bold">
                    {birthMinuteError}
                  </div>
                )}
              </div>
              <div className="form-group">
                <label>am/pm</label>
                <select id="Ampm" className={`${birthAmpmError && "is-invalid"
                  }`} value={selectedAmpm}
                  onChange={(e) => setSelectedAmpm(e.target.value)}>
                  <option value="">Select</option>
                  <option value="AM">AM</option>
                  <option value="PM">PM</option>
                </select>
                {birthAmpmError && (
                  <div className="invalid-feedback  ">{birthAmpmError}</div>
                )}
              </div>
            </div>
          </div>
          <div className="form-group">
            <label className={`${FatherNameError && "is-invalid"
              }`}>father’s name</label>
            <input className='input' type="text"
              placeholder="Enter your father name"
              id="Father_Name"
              value={FatherName}
              onChange={(e) => setFatherName(e.target.value)}
            />
            {FatherNameError && (
              <div className="invalid-feedback fw-bold">
                {FatherNameError}
              </div>
            )}

          </div>
          <div className="form-group">
            <label className={`${MotherNameError && "is-invalid"
              }`}>mother’s name</label>
            <input className='input' type="text" placeholder="Enter your mother name"
              id="Mother_Name"
              value={MotherName}
              onChange={(e) => setMotherName(e.target.value)}
            />
            {MotherNameError && (
              <div className="invalid-feedback fw-bold">
                {MotherNameError}
              </div>
            )}
          </div>
          <div className="form-group">
            <label>birth location</label>
            <input type="text" className={`input ${birthplaceError && "is-invalid"
              }`}
              id="Birth_place"
              placeholder="Select your birth place"
              onChange={handleLocationChange}
              value={birthPlace}
            />
            {birthplaceError && (
              <div className="invalid-feedback  ">{birthplaceError}</div>
            )}
            <input
              id="atflt"
              className="pfipbx"
              name="the_lat"
              type="hidden"
              placeholder="Latitude"
            />
            <input
              id="atflg"
              className="pfipbx"
              name="the_lng"
              type="hidden"
              placeholder="Longitude"
            />
            <ul>
              {suggestedPlaces.map((place, index) => {
                if (place.place_name) {
                  const listItemStyle = {
                    padding: "5px 10px",
                    cursor: "pointer",
                    fontSize: "16px",
                    borderBottom: "1px dashed #ddd",
                  };

                  // Split the place_name and get the first three parts
                  const parts = place.place_name.split(",");
                  const displayName = parts.slice(0, 3).join(",");

                  return (
                    <p
                      style={listItemStyle}
                      key={index}
                      onClick={() => handleSuggestionSelect(place)}
                    >
                      {displayName}
                    </p>
                  );
                }
                return null; // Handle the case where place.place_name is falsy
              })}
            </ul>
          </div>
          <div className="form-group-btn">
            <button type="button" className="form-btn" onClick={HoroscopeFormSubmit}>
              get hand written horoscope
              <img src='https://ctsvsolutions.com/onepage-images/new-ui/btn-arrow-white.webp' alt="horoscope Book" />
            </button>
          </div>
          <div className="mt-3">
            <p className="f-sub text-danger text-center mb-0">
              * Your horoscopr book will be generated in Tamil.
            </p>
          </div>
        </form>
      </div>
    </>
  )
}
