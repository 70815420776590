import React from 'react';
import TopNavbar from '../../Main-Components/TopNavbar/TopNavbar';
import Menubar from '../../Main-Components/Menubar/Menubar';
import Footer from '../../Main-Components/Footer/Footer';
import BirthHoroscopeViewBanner from '../../Small-Components/BirthHoroscopeViewBanner/BirthHoroscopeViewBanner';
import BirthHoroscopeViewPerDetail from '../../Small-Components/BirthHoroscopeViewPerDetail/BirthHoroscopeViewPerDetail';
import BirthHoroscopeViewHoroDetail from '../../Small-Components/BirthHoroscopeViewHoroDetail/BirthHoroscopeViewHoroDetail';
import BirthHoroscopeViewChart from '../../Small-Components/BirthHoroscopeViewChart/BirthHoroscopeViewChart';
import Review from '../../Main-Components/Review/Review';
import BirthViewContextProvider from '../../context/viewpage/BirthViewContextprovider';

export default function BirthHoroscopeview() {
    return (
        <>
            <BirthViewContextProvider>
                <TopNavbar />
                <Menubar NavBgstyle="outer-header bc-outer" NavTextColor="text-dark" SubmenuTop="true" />
                <BirthHoroscopeViewBanner />
                <BirthHoroscopeViewPerDetail />
                <BirthHoroscopeViewChart />
                <BirthHoroscopeViewHoroDetail />
                <Review />
                <Footer />
            </BirthViewContextProvider>
        </>
    );
}
