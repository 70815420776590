import axios from "axios";
import html2pdf from "html2pdf.js/dist/html2pdf.min";
import ReactDOMServer from "react-dom/server";
import ReactDOM from "react-dom";
import { Notify } from "notiflix/build/notiflix-notify-aio";
import { Loading } from "notiflix/build/notiflix-loading-aio";
import OnepagePaidTemplate from "../../tamil/onepagePaidTemplate/onepagePaidtemplate";
import MgPaidTemplate from "../../tamil/Mg-Paid-Template/MgPaidTemplate";
import BabyNameTemplate from "../../tamil/BabyNameTemplate/BabyNameTemplate";
import RuthuPaidTemplate from "../../tamil/RuthuPaidTemplate/RuthuPaidTemplate";
import BioTemplate1 from "../../tamil/BioData-Templates/BioTemplate1";
import BioTemplate2 from "../../tamil/BioData-Templates/BioTemplate2";
import BioTemplate3 from "../../tamil/BioData-Templates/BioTemplate3";
import BioTemplate4 from "../../tamil/BioData-Templates/BioTemplate4";
import BioTemplate5 from "../../tamil/BioData-Templates/BioTemplate5";
import BioTemplate6 from "../../tamil/BioData-Templates/BioTemplate6";
import html2canvas from "html2canvas";

// Function to convert data to PDF
export const OnePagePaidPdfConvertion = (data) => {
  // console.log('data is',data);
  Loading.hourglass("Pdf is generating.....");

  // Extracting date components
  const dateComponents = data.birth_date.split("-");
  const day = dateComponents[0];
  const month = dateComponents[1];
  const year = dateComponents[2];
  const formattedDate = `${year}-${month}-${day}`;

  // Creating form data
  const formdata = {
    username: data.name,
    gender: data.gender,
    userDateofBirth: formattedDate,
    userBirthtime: data.birth_time,
    userBirthplace: data.birth_place,
    latitude: data.latitude,
    longitude: data.longitude,
  };

  // Making API call to get data
  axios.post("https://ctsvsolutions.com/onepage-Api/public/api/Onepage-Horoscope", formdata)
    .then((response) => {
      // Function to generate PDF using the received data
      const paidPdf = () => {
        return <OnepagePaidTemplate value={response.data.data} />;
      };

      const printElement = ReactDOMServer.renderToString(paidPdf());

      // Configuring PDF options
      html2pdf()
        .from(printElement)
        .set({
          image: { type: "jpeg", quality: 1 },
          dpi: 300,
          renderer: "canvas",
          html2canvas: { scale: 2 },
        })
        .outputPdf()
        .then((pdfData) => {
          // Converting PDF data to base64 and saving the PDF
          const base64Data = btoa(pdfData);
          saveOnepagePaidPdf(base64Data, data.user_id_fk, data.data_id);
        });
    });
};

// Function to save the generated PDF
const saveOnepagePaidPdf = (pdfData, user_id, customerDataId) => {
  const pdfDataVal = {
    pdfData: pdfData,
    userId: user_id,
    customerId: customerDataId,
    FileName: "one_page",
  };

  const DataInfo = {
    userId: user_id,
    customerId: customerDataId,
    columnName: "pdf_file",
  };

  // Making API call to save the PDF
  axios.post("https://www.ctsvsolutions.com/onepage-Api/public/api/Save-Paid-Pdf", pdfDataVal)
    .then((response) => {
      Notify.success("Pdf saved Successfully....!!!");

      // Sending the saved PDF to WhatsApp
      axios.post("https://www.ctsvsolutions.com/onepage-Api/public/api/send-paid-PdfIn-whastapp", DataInfo)
        .then((response) => {
          Notify.success("Pdf sent to WhatsApp....!!!");

          // Sending the saved PDF via Email
          axios.post("https://www.ctsvsolutions.com/onepage-Api/public/api/Send-pdfIn-Email", DataInfo)
            .then((response) => {
              Loading.remove();
              Notify.success("PDF file sent successfully");
            })
            .catch((error) => {
              console.error("Error sending PDF via Email:", error);
            });
        })
        .catch((error) => {
          console.error("Error sending PDF to WhatsApp:", error);
        });
    })
    .catch((error) => {
      console.error("Error saving PDF:", error);
    });
};


// marriage matching
export const MarriageMatchingPdfConverTion = (data) => {

    Loading.hourglass("Pdf is generating.....");
    const BirthDate=data.birth_date.split(',');
    const maleBirthDate =BirthDate[0].split("-");
    const FemaleBirthDate = BirthDate[1].split("-");
    const State = data.birth_place.split(",");
    const MaleState = State[0];
    const FemaleState = State[1];
    const Latitude = data.latitude.split(",");
    const Longitude = data.longitude.split(",");
    const BirthTime = data.birth_time.split(",");
    const MaleBirthTime = BirthTime[0];
    const FemaleBirthTime = BirthTime[1];
    const MaleLatitude = Latitude[0];
    const FemaleLatitude = Latitude[1];
    const femaleLongitude = Longitude[0];
    const MaleLogitude = Longitude[1];
    const Name = data.name.split(",");
    const MaleName = Name[0];
    const FemaleName = Name[1];
    const BirthPlace = data.birth_place.split(",");
    const MaleBirthPlace = BirthPlace[0] +','+MaleState;
    const femaleBirthplace = BirthPlace[1] +','+FemaleState;
    const Maleday = maleBirthDate[2];
    const Malemonth = maleBirthDate[1];
    const Maleyear = maleBirthDate[0];
    const Femaleday = FemaleBirthDate[2];
    const FemaleMonth = FemaleBirthDate[1];
    const FemaleYear = FemaleBirthDate[0];
    const femaleDatateofBirth = `${FemaleYear}-${FemaleMonth}-${Femaleday}`;
    const MaleDateofBirth = `${Maleyear}-${Malemonth}-${Maleday}`;

    const marriageMatchingFormData = {
      maleName: MaleName,
      MaleDateofBirth: MaleDateofBirth,
      MaleBirthtime: MaleBirthTime,
      MaleBirthplace: MaleBirthPlace,
      Malelatitude: MaleLatitude,
      Malelongitude: MaleLogitude,
      FemaleName: FemaleName,
      femaleBirthplace: femaleBirthplace,
      feMaleDateofBirth: femaleDatateofBirth,
      FemaleBirthTime: FemaleBirthTime,
      femalelatitude: FemaleLatitude,
      femalelongitude: femaleLongitude,
    };
    // console.log("valur",marriageMatchingFormData);
    axios
      .post(
        "https://ctsvsolutions.com/onepage-Api/public/api/Marriage-Matching",
        marriageMatchingFormData
      )
      .then((response) => {
        const paidPdf = () => {
          return <MgPaidTemplate value={response.data.data} />;
        };

        const printElement = ReactDOMServer.renderToString(paidPdf());

        html2pdf().from(printElement)
            .set({
              image: { type: 'jpeg', quality: 1 },
              dpi: 300,
              renderer: 'canvas',
              html2canvas: { scale: 2 },
            }).outputPdf()
            .then((pdfData) => {
              const base64Data = btoa(pdfData);
            SaveMgpdf(base64Data, data.user_id_fk, data.data_id);
            });
        
      });
  };
  const SaveMgpdf = (pdfData, user_id, customerDataId) => {
    const pdfDataVal = {
      pdfData: pdfData, 
      userId: user_id,
      customerId: customerDataId,
      FileName:'marriage_matching'
    };
    const DataInfo = {
      userId: user_id,
      customerId: customerDataId,
      columnName:"pdf_file"
    };
    axios
      .post(" https://www.ctsvsolutions.com/onepage-Api/public/api/Save-Paid-Pdf", pdfDataVal)
      .then((response) => {
        axios
          .post(
            " https://www.ctsvsolutions.com/onepage-Api/public/api/send-paid-PdfIn-whastapp",
            DataInfo
          )
          .then((response) => {
            axios
              .post(
                " https://www.ctsvsolutions.com/onepage-Api/public/api/Send-pdfIn-Email",
                DataInfo
              )
              .then((response) => {
               
                Loading.remove();
                Notify.success("Sample pdf file sended succussfully");
                
              })
              .catch((error) => {
                console.error("Error sending WhatsApp message:", error);
              });
          })
          .catch((error) => {
            console.error("Error sending WhatsApp file:", error);
          });
      })
      .catch((error) => {
        console.error("Error saving PDF:", error);
      });
  };

  export const BabyNamePdf = async (data) => {
    Loading.hourglass("Pdf is generating.....");
    // console.log(data);
  
    const dateComponents = data.birth_date.split("-");
    const selectedDay = dateComponents[0];
    const selectedMonth = dateComponents[1];
    const selectedYear = dateComponents[2];
    const formattedDate = `${selectedYear}-${selectedMonth}-${selectedDay}`;
  
    const BabyNameList = {
      BirthMonth: selectedMonth,
      BirthYear: selectedYear,
      BirthDay: selectedDay,
      BirthDate: formattedDate,
      BirthTime: data.birth_time,
      BirthPlace: data.birth_place + "," + data.state,
      initial: data.initial,
      father_name: data.father_name,
      mother_name: data.mother_name,
      gender: data.gender,
      latitude: data.latitude,
      longitude: data.longitude,
    };
  
    try {
      const response = await axios.post(
        "https://ctsvsolutions.com/onepage-Api/public/api/Baby-Name-List",
        BabyNameList
      );
  
      console.log("baby name list", response.data.data);
  
      const paidPdf = () => {
        return <BabyNameTemplate value={response.data.data} />;
      };
  
      const printElement = ReactDOMServer.renderToString(paidPdf());
  
      const pdfData = await html2pdf()
        .from(printElement)
        .set({
          image: { type: "jpeg", quality: 1 },
          dpi: 300,
          renderer: "canvas",
          html2canvas: { scale: 2 },
        })
        .outputPdf();
  
      const base64Data = btoa(pdfData);
  
      await SaveBabyNamePdf(base64Data, data.user_id_fk, data.data_id);
    } catch (error) {
      console.error("Error generating PDF:", error);
      Loading.remove();
      Notify.error("Error generating PDF. Please try again.");
    }
  };
  
  const SaveBabyNamePdf = async (pdfData, user_id, customerDataId) => {
    const pdfDataVal = {
      pdfData: pdfData,
      userId: user_id,
      customerId: customerDataId,
      FileName: "baby_name", // Update the FileName if needed
    };
  
    const DataInfo = {
      userId: user_id,
      customerId: customerDataId,
      columnName: "pdf_file",
    };
  
    try {
       await axios.post(
        "https://www.ctsvsolutions.com/onepage-Api/public/api/Save-Paid-Pdf",
        pdfDataVal
      );
  
      await axios.post(
        "https://ctsvsolutions.com/onepage-Api/public/api/Baby-Name-pdf-Whastapp",
        DataInfo
      );
  
      await axios.post(
        "https://ctsvsolutions.com/onepage-Api/public/api/Baby-Name-Email",
        DataInfo
      );
  
      Loading.remove();
      Notify.success("Sample PDF file sent successfully");
    } catch (error) {
      console.error("Error saving or sending PDF:", error);
    }
  };

 export const RuthuJathagamPdf= async (data)=>{
  Loading.hourglass("Pdf is generating.....");
  const dateComponents = data.birth_date.split("-");
  const day = dateComponents[0];
  const month = dateComponents[1];
  const year = dateComponents[2];
  const formattedDate = `${year}-${month}-${day}`;
  const father_name=data.father_name;
  const mother_name=data.mother_name;

  const formdata = {
    username: data.Name,
    gender: data.gender,
    userDateofBirth: formattedDate,
    userBirthtime: data.birth_time,
    userBirthplace: data.birth_place,
    latitude: data.latitude,
    longitude: data.longitude,
    FatherName:father_name,
    MotherName:mother_name
  };
  console.log('form data is',formdata);
  axios.post("https://ctsvsolutions.com/onepage-Api/public/api/Onepage-Horoscope", formdata)
    .then((response) => {
      const paidPdf = () => {
        return <RuthuPaidTemplate value={response.data.data} />;
      };

      const printElement = ReactDOMServer.renderToString(paidPdf());

      html2pdf()
        .from(printElement)
        .set({
          image: { type: "jpeg", quality: 1 },
          dpi: 300,
          renderer: "canvas",
          html2canvas: { scale: 2 },
        })
        .save();

      html2pdf()
        .from(printElement)
        .set({
          image: { type: "jpeg", quality: 1 },
          dpi: 300,
          renderer: "canvas",
          html2canvas: { scale: 2 },
        })
        .outputPdf()
        .then((pdfData) => {
          const base64Data = btoa(pdfData);
          saveRuthuPaidPdf(
            base64Data,
            data.user_id_fk,
            data.data_id
          );
        });
    });
  }
  const saveRuthuPaidPdf=(pdfData,user_id,customerDataId)=>{
    const pdfDataVal = {
      pdfData: pdfData,
      userId: user_id,
      customerId: customerDataId,
      FileName: "Ruthu_Jathagam",
    };
    const DataInfo = {
      userId: user_id,
      customerId: customerDataId,
      columnName: "pdf_file",
    };
    axios
      .post(
        "https://ctsvsolutions.com/onepage-Api/public/api/Save-Paid-Pdf",
        pdfDataVal
      )
      .then((response) => {
        axios
          .post(
            "https://ctsvsolutions.com/onepage-Api/public/api/send-paid-PdfIn-whastapp",
            DataInfo
          )
          .then((response) => {
            axios
              .post(
                "https://ctsvsolutions.com/onepage-Api/public/api/Send-pdfIn-Email",
                DataInfo
              )
              .then((response) => {
                Loading.remove();

                Notify.success("pdf file sended succussfully");
              })
              .catch((error) => {
                console.error("Error sending WhatsApp message:", error);
              });
          })
          .catch((error) => {
            console.error("Error sending WhatsApp file:", error);
          });
      })
      .catch((error) => {
        console.error("Error saving PDF:", error);
      });
  }
  
  const GetBioInfoFromReport = async (data) => {
    let user_id = data.user_id_fk;
    let customer_data_id = data.data_id;
    let TemplateId=data.Template;
    const Data = {
      user_id: user_id,
      customer_data_id: customer_data_id,
      template_id: TemplateId
    };

    try {
      const response = await axios.post(
        "https://www.ctsvsolutions.com/onepage-Api/public/api/Bio-data-Details",
        Data,
      
      );

      const BiodataVal = response.data.data;
      const Siblings = BiodataVal.sibling;

      const MyVal = Siblings.split(",");
      const SiblingsArray = MyVal.map((Val) => {
        return { value: Val, label: Val };
      });

      const birthDateComponents = BiodataVal.birth_date.split("-");
      const birthTimeComponents = BiodataVal.birth_time.split(/:|\s/);

      return {
        user_id: data.user_id_fk,
        customer_id: data.data_id,
        userName: BiodataVal.name,
        userGender: BiodataVal.gender,
        BirthTime: BiodataVal.birth_time,
        BirthDate: BiodataVal.birth_date,
        BirthDay: birthDateComponents[0],
        BirthMonth: birthDateComponents[1],
        BirthYear: birthDateComponents[2],
        BirthHour: birthTimeComponents[0],
        BirthMinute: birthTimeComponents[1],
        BirthAmPm: birthTimeComponents[2],
        BirthPlace: BiodataVal.birth_place,
        Education: BiodataVal.education,
        WorkDetail: BiodataVal.work_detail,
        MonthlySalary: BiodataVal.income,
        Height: BiodataVal.height,
        weight: BiodataVal.weight,
        Color: BiodataVal.color,
        Caste: BiodataVal.caste,
        kulam_KulaDeivam: BiodataVal.kulam,
        fatherName: BiodataVal.father_name,
        MotherName: BiodataVal.mother_name,
        FatherWorkDetails: BiodataVal.father_occupation,
        MotherWorkDetails: BiodataVal.mother_occupation,
        Siblings: SiblingsArray,
        AssetsDetail: BiodataVal.asset_details,
        MobileNumber: BiodataVal.mobile_number,
        WhatsappNumber: BiodataVal.whatsapp_number,
        Email: BiodataVal.email,
        CurrentLocation: BiodataVal.current_location,
        Address: BiodataVal.address,
        latitudeValue: BiodataVal.latitude,
        LongitudeValue: BiodataVal.longitude,
        Imageurl: BiodataVal.image,
      };
    } catch (error) {
      console.error("Error in API call:", error.message);
      throw error; // Re-throw the error to be caught in the calling function
    }
  };


  export const BioDataResend = async (data) => {
    Loading.hourglass('Processing....');
    try {
      const BioDataFormData = await GetBioInfoFromReport(data);
  
      const response = await axios.post(
        "https://ctsvsolutions.com/onepage-Api/public/api/Bio-Data",
        BioDataFormData
      );
      console.log('Api response BioData', response);
      if (response.status == 200) {
        const value = response.data;
        const choosedTemplate = data.Template;
  
        // Create container and append it to the body
        const container = document.createElement("div");
        document.body.appendChild(container);
  
        // Render the chosen template inside the container
        const printElement = DownloadTemplate(choosedTemplate, value);
        const root = ReactDOM.createRoot(container);
        root.render(printElement);
  
        // Wait for the component to mount
        await new Promise((resolve) => setTimeout(resolve, 1000));
  
        // Capture the container using html2canvas and convert to PDF
        const canvas = await html2canvas(container, { useCORS: true });
        const imageData = canvas.toDataURL("image/jpeg", 1.0);
  
        await html2pdf()
          .from(container)
          .set({
            image: { type: "jpeg", quality: 1 },
            dpi: 300,
            renderer: "canvas",
            html2canvas: { scale: 2, useCORS: true },
          })
          .save();
  
        // Convert the rendered content to PDF and save it
        const pdfData = await html2pdf()
          .from(container)
          .set({
            image: { type: "jpeg", quality: 1 },
            dpi: 300,
            renderer: "canvas",
            html2canvas: { scale: 2, useCORS: true },
          })
          .outputPdf();
        
        const base64Data = btoa(pdfData);
        savePaidBioDataPdf(base64Data, data.user_id_fk, data.data_id, choosedTemplate);
      } else {
        Notify.failure('Error occurred...!');
      }
    } catch (error) {
      console.error("Error in GetPaid:", error);
    }
  };
  
  const DownloadTemplate = (currentTemplate, value) => {
    switch (currentTemplate) {
      case "BiosampleTemplate1":
        return <BioTemplate1 value={value} />;
      case "BiosampleTemplate2":
        return <BioTemplate2 value={value} />;
      case "BiosampleTemplate3":
        return <BioTemplate3 value={value} />;
      case "BiosampleTemplate4":
        return <BioTemplate4 value={value} />;
      case "BiosampleTemplate5":
        return <BioTemplate5 value={value} />;
      default:
        return <BioTemplate6 value={value} />;
    }
  };
  
  const savePaidBioDataPdf = (pdfData, user_id, customerDataId, choosedTemplate) => {
    const pdfDataVal = {
      pdfData: pdfData,
      userId: user_id,
      customerId: customerDataId,
      FileName: 'BioDataTemplate'.trim().replace(/^"|"$/g, ""),
    };
    const DataInfo = {
      userId: user_id,
      customerId: customerDataId,
      columnName: "pdf_file",
    };
    axios
      .post("https://ctsvsolutions.com/onepage-Api/public/api/Save-Paid-Pdf", pdfDataVal)
      .then(() => {
        axios
          .post("https://ctsvsolutions.com/onepage-Api/public/api/send-paid-PdfIn-whastapp", DataInfo)
          .then(() => {
            axios
              .post("https://ctsvsolutions.com/onepage-Api/public/api/Send-pdfIn-Email", DataInfo)
              .then(() => {
                Loading.remove();
                Notify.success("Sample pdf file sent successfully");
              })
              .catch((error) => {
                console.error("Error sending email:", error);
              });
          })
          .catch((error) => {
            console.error("Error sending WhatsApp file:", error);
          });
      })
      .catch((error) => {
        console.error("Error saving PDF:", error);
      });
  };
  