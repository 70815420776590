import React,{useContext} from 'react'
// import BioDataFullDetail from '../../images/cb-left-img.webp'
import {BioViewContext} from "../../context/viewpage/BioViewContextProvider";
export default function MarriageBioDataViewFullDetail() {
    const  { personalInfo }= useContext(BioViewContext);
    console.log(personalInfo);
    return (
        <>
            <section className="ad-details ad2 ad-p70">
                <div className="astro-container">
                    <div className="ad-details-row">
                        <div className="img">
                            <img className="img-res" src='https://ctsvsolutions.com/onepage-images/new-ui/details-left-img.webp' alt='bio data information'/>
                        </div>
                        <div className="content">
                            <h3 className="sh">
                                complete <span className="grt">Biodata</span>
                            </h3>
                            <div className="row">
                                <div className="item px-lg-0">
                                    <span>study</span>
                                    <h4>{personalInfo.education}</h4>
                                </div>
                                <div className="item px-lg-0">
                                    <span>zodiac sign</span>
                                    <h4>{personalInfo.rasi}</h4>
                                </div>
                                <div className="item px-lg-0">
                                    <span>height</span>
                                    <h4>{personalInfo.height}</h4>
                                </div>
                                <div className="item px-lg-0">
                                    <span>job description</span>
                                    <h4>{personalInfo.work_details}</h4>
                                </div>
                                <div className="item px-lg-0">
                                    <span>Star</span>
                                    <h4>{personalInfo.natchathiram}</h4>
                                </div>
                                <div className="item px-lg-0">
                                    <span>color</span>
                                    <h4>{personalInfo.color}</h4>
                                </div>
                                <div className="item px-lg-0">
                                    <span>monthly income</span>
                                    <h4>₹ {personalInfo.income}</h4>
                                </div>
                                <div className="item px-lg-0">
                                    <span>Ascendant</span>
                                    <h4>{personalInfo.laknam}</h4>
                                </div>
                                <div className="item px-lg-0">
                                    <span>weight</span>
                                    <h4>{personalInfo.weight}</h4>
                                </div>
                                <div className="item px-lg-0">
                                    <span>father’s occupation</span>
                                    <h4>{personalInfo.father_occupation}</h4>
                                </div>
                                <div className="item px-lg-0">
                                    <span>caste</span>
                                    <h4>{personalInfo.caste}</h4>
                                </div>
                                <div className="item px-lg-0">
                                    <span>Tamil Day</span>
                                    <h4>{personalInfo.tamil_day}</h4>
                                </div>
                                <div className="item px-lg-0">
                                    <span>mother’s occupation</span>
                                    <h4>{personalInfo.mother_occupation}</h4>
                                </div>
                                <div className="item px-lg-0">
                                    <span>Kulam</span>
                                    <h4>{personalInfo.kulam}</h4>
                                </div>
                                <div className="item px-lg-0">
                                    <span>Star Letter</span>
                                    <h4>{personalInfo.natchathira_letter}</h4>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}
