import React, { createContext, useState } from 'react'

function BiodataForm_context_provider({ children }) {
    const [step, setStep] = useState(1);
    const [Active, setActive] = useState({
        step1: true,
        step2: false,
        step3: false,
        step4: false,
        step5: false,
    });
    const [dayOptions] = useState([...Array(31).keys()].map((day) => String(day + 1).padStart(2, '0')));
    const [monthOptions] = useState([...Array(12).keys()].map((month) => String(month + 1).padStart(2, '0')));

    const [yearOptions] = useState(() => {
        const currentYear = new Date().getFullYear();
        const startYear = 1950;
        return [...Array(currentYear - startYear + 1).keys()].map(
            (year) => startYear + year
        );
    });
    const [hourOptions] = useState([...Array(12).keys()].map((hour) => String(hour + 1).padStart(2, '0')));
    const [minuteOptions] = useState([...Array(60).keys()].map((minute) => String(minute).padStart(2, '0')));

    const weightOptions = [];
    for (let i = 40; i <= 180; i++) {
        weightOptions.push(i + ' kg');
    }

    const heightOptions = [];
    for (let i = 100; i <= 220; i++) {
        heightOptions.push(i + ' cm');
    }

    const [userName, setUserName] = useState('');
    const [userGender, setUserGender] = useState('');
    const [BirthDay, setBirthDay] = useState('');
    const [BirthMonth, setBirthMonth] = useState('');
    const [BirthYear, setBirthYear] = useState('');
    const [BirthHour, setBirthHour] = useState('');
    const [BirthMinute, setBirthMinute] = useState('');
    const [BirthAmPm, setAmPm] = useState('');
    const [BirthPlace, setBirthPlace] = useState('');
    const [Education, SetEducation] = useState('');
    const [WorkDetail, setWorkDetail] = useState('');
    const [OtherWorkDetail, setOtherWorkDetail] = useState('');
    const [MonthlySalary, setMonthlySalary] = useState('');
    const [Height, setHeight] = useState('');
    const [weight, setWeight] = useState('');
    const [Color, setColor] = useState('');
    const [Caste, setCaste] = useState('');
    const [kulam_KulaDeivam, setKulam_KulaDeivam] = useState('');
    const [fatherName, setFatherName] = useState('');
    const [MotherName, setMotherName] = useState('');
    const [FatherWorkDetails, setFatherWorkDetails] = useState('');
    const [MotherWorkDetails, setMotherWorkDetails] = useState('');
    const [AssetsDetail, setAssetsDetails] = useState('');
    const [MobileNumber, setMobileNumber] = useState('');
    const [WhatsappNumber, setWhatsappNumber] = useState('');
    const [Email, setEmail] = useState('');
    const [CurrentLocation, setCurrentLocation] = useState('');
    const [Address, setAddress] = useState('');
    const [suggestedPlaces, setSuggestedPlaces] = useState([]);
    const [selectedPlaces, setSelectedPlaces] = useState([]);
    const [latitudeValue, setLatitude] = useState('');
    const [LongitudeValue, setLongitude] = useState('');
    const [SwitchLocationButton, SetSwitchLocationButton] = useState(false);
    const [Timezone, setTimeZone] = useState('');

    // Validation states
    const [userNameError, setUserNameError] = useState('');
    const [GenderError, setGenderError] = useState('');
    const [DayError, setDayError] = useState('');
    const [MonthError, setMonthError] = useState('');
    const [BirthYearError, setBirthYearError] = useState('');
    const [BirthHourError, setBirthHourError] = useState('');
    const [BirthMinuteError, setBirthMinuteError] = useState('');
    const [AmPmError, setAmPmError] = useState('');
    const [BirthPlaceError, setBirthPlaceError] = useState('');
    const [EducationError, setEducationError] = useState('');
    const [WorkDetailError, setWorkDetailError] = useState('');
    const [MonthlySalaryError, setMonthlySalaryError] = useState('');
    const [HeightError, setHeightError] = useState('');
    const [WeightError, setWeightError] = useState('');
    const [ColorError, setColorError] = useState('');
    const [CasteError, setCasteError] = useState('');
    const [Kulam_KulaDeivamError, setKulam_KulaDeivamError] = useState('');
    const [FatherNameError, setFatherNameError] = useState('');
    const [MotherNameError, setMotherNameError] = useState('');
    const [FatherWorkDetailsError, setFatherWorkDetailsError] = useState('');
    const [MotherWorkDetailsError, setMotherWorkDetailsError] = useState('');
    const [SiblingError, setSiblingError] = useState('');
    const [AssetsError, setAssetsError] = useState('');
    const [MobileNumberError, setMobileNumberError] = useState('');
    const [WhatsappNumberError, setWhatsappNumberError] = useState('');
    const [EmailError, setEmailError] = useState('');
    const [CurrentLocationError, setCurrentLocationError] = useState('');
    const [AddressError, setAddressError] = useState('');
    const [ImageError, setImageError] = useState('');

    const siblingOptions = [
        { value: "தம்பி-1", label: "தம்பி-1" },
        { value: "தம்பி-2", label: "தம்பி-2" },
        { value: "அக்கா-1", label: "அக்கா-1" },
        { value: "அக்கா-2", label: "அக்கா-2" },
        { value: "அண்ணன்-1", label: "அண்ணன்-1" },
        { value: "அண்ணன்-2", label: "அண்ணன்-2" },
        { value: "தங்கை-1", label: "தங்கை-1" },
        { value: "தங்கை-2", label: "தங்கை-2" },
        { value: "அக்கா சுபம்-1", label: "அக்கா சுபம்-1" },
        { value: "அக்கா சுபம்-2", label: "அக்கா சுபம்-2" },
        { value: "அண்ணன் சுபம்-1", label: "அண்ணன் சுபம்-1" },
        { value: "அண்ணன் சுபம்-2", label: "அண்ணன் சுபம்-2" },
        { value: "தங்கை சுபம்- 1", label: "தங்கை சுபம்- 1" },
        { value: "தங்கை சுபம்- 2", label: "தங்கை சுபம்- 2" },
        { value: "யாருமில்லை", label: "யாருமில்லை" },
    ];


    const BioDataData = {
        step,
        setStep,
        Active,
        setActive,
        dayOptions,
        monthOptions,
        yearOptions,
        hourOptions,
        minuteOptions,
        weightOptions,
        heightOptions,
        userName,
        setUserName,
        userGender,
        setUserGender,
        BirthDay,
        setBirthDay,
        BirthMonth,
        setBirthMonth,
        BirthYear,
        setBirthYear,
        BirthHour,
        setBirthHour,
        BirthMinute,
        setBirthMinute,
        BirthAmPm,
        setAmPm,
        BirthPlace,
        setBirthPlace,
        Education,
        SetEducation,
        WorkDetail,
        setWorkDetail,
        OtherWorkDetail,
        setOtherWorkDetail,
        MonthlySalary,
        setMonthlySalary,
        Height,
        setHeight,
        weight,
        setWeight,
        Color,
        setColor,
        Caste,
        setCaste,
        kulam_KulaDeivam,
        setKulam_KulaDeivam,
        fatherName,
        setFatherName,
        MotherName,
        setMotherName,
        FatherWorkDetails,
        setFatherWorkDetails,
        MotherWorkDetails,
        setMotherWorkDetails,
        AssetsDetail,
        setAssetsDetails,
        MobileNumber,
        setMobileNumber,
        WhatsappNumber,
        setWhatsappNumber,
        Email,
        setEmail,
        CurrentLocation,
        setCurrentLocation,
        Address,
        setAddress,
        suggestedPlaces,
        setSuggestedPlaces,
        selectedPlaces,
        setSelectedPlaces,
        latitudeValue,
        setLatitude,
        LongitudeValue,
        setLongitude,
        userNameError,
        setUserNameError,
        GenderError,
        setGenderError,
        DayError,
        setDayError,
        MonthError,
        setMonthError,
        BirthYearError,
        setBirthYearError,
        BirthHourError,
        setBirthHourError,
        BirthMinuteError,
        setBirthMinuteError,
        AmPmError,
        setAmPmError,
        BirthPlaceError,
        setBirthPlaceError,
        EducationError,
        setEducationError,
        WorkDetailError,
        setWorkDetailError,
        MonthlySalaryError,
        setMonthlySalaryError,
        HeightError,
        setHeightError,
        WeightError,
        setWeightError,
        ColorError,
        setColorError,
        CasteError,
        setCasteError,
        Kulam_KulaDeivamError,
        setKulam_KulaDeivamError,
        FatherNameError,
        setFatherNameError,
        MotherNameError,
        setMotherNameError,
        FatherWorkDetailsError,
        setFatherWorkDetailsError,
        MotherWorkDetailsError,
        setMotherWorkDetailsError,
        SiblingError,
        setSiblingError,
        AssetsError,
        setAssetsError,
        MobileNumberError,
        setMobileNumberError,
        WhatsappNumberError,
        setWhatsappNumberError,
        EmailError,
        setEmailError,
        CurrentLocationError,
        setCurrentLocationError,
        AddressError,
        setAddressError,
        ImageError,
        setImageError,
        siblingOptions,
        SwitchLocationButton,
        SetSwitchLocationButton,
        Timezone,
        setTimeZone
    };
    return (
        <BioDataContext.Provider value={BioDataData}>
            {children}
        </BioDataContext.Provider>
    )
}

export const BioDataContext = createContext();
export default BiodataForm_context_provider