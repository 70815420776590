import React from 'react';
import MobileMenu from "../../Small-Components/MobileMenu/MobileMenu";
import NavMenu from "../../Small-Components/NavMenu/NavMenu";
import MobileMenuContextProvider from '../../context/menu/MobileMenuContext';

function Menubar({NavBgstyle,NavTextColor,SubmenuTop}) {
  return (
    <MobileMenuContextProvider>
      <NavMenu NavBgstyle={NavBgstyle}  NavTextColor={NavTextColor} SubmenuTop={SubmenuTop}/>
      <MobileMenu />
    </MobileMenuContextProvider>
  );
}

export default Menubar;
