import React,{useContext} from 'react'
// import ChartTopCurve from '../../images/ac-top-curve.webp'
// import ChartRightCurve from '../../images/ac-right-curve.webp'
// import ChartBottomCurve from '../../images/ac-bottom-curve.webp'
// import ChartLeftCurve from '../../images/ac-left-curve.webp'
import { RuthuViewContext } from '../../context/viewpage/RuthuViewContextProvider'
import Chart from '../../Main-Components/Chart/Chart'

export default function RuthuJathagaViewChart() {
  const {amsamChart, rasiChat}=useContext(RuthuViewContext)
  return (
    <>
      <section className="ac-charts">
        <div className="astro-container">
          <div className="heading">
            <h2 className="sh">
              astrological<span className="grt"> charts</span>
            </h2>
          </div>
          <div className="row">
            <div className="col">
              <div className="chart">
                <div className="curve top-curve">
                  <img src='https://ctsvsolutions.com/onepage-images/new-ui/chart-top-curve.webp' alt='ruthu jathagam rasi curve top'/>
                </div>
                <div className="curve top-right">
                  <img src='https://ctsvsolutions.com/onepage-images/new-ui/chart-right-curve.webp' alt='ruthu jathagam rasi curve right'/>
                </div>
                <div className="curve top-bottom">
                  <img src='https://ctsvsolutions.com/onepage-images/new-ui/chart-bottom-curve.webp' alt='ruthu jathagam rasi curve bottom'/>
                </div>
                <div className="curve top-left">
                  <img src='https://ctsvsolutions.com/onepage-images/new-ui/chart-left-curve.webp' alt='ruthu jathagam rasi curve left'/>
                </div>
                <Chart rasiChat={rasiChat} type="rasi-chart" chartName="Rasi"></Chart>
              </div>
              <div className="chart-heading">
                <h3 className="sh">
                  <span className="grt">Rasi </span>zodiac phase
                </h3>
              </div>
            </div>
            <div className="col">
              <div className="chart">
                <div className="curve top-curve">
                  <img src='https://ctsvsolutions.com/onepage-images/new-ui/chart-top-curve.webp' alt='ruthu jathagam amsam curve top'/>
                </div>
                <div className="curve top-right">
                  <img src='https://ctsvsolutions.com/onepage-images/new-ui/chart-right-curve.webp' alt='ruthu jathagam amsam curve right'/>
                </div>
                <div className="curve top-bottom">
                  <img src='https://ctsvsolutions.com/onepage-images/new-ui/chart-bottom-curve.webp' alt='ruthu jathagam amsam curve bottom'/>
                </div>
                <div className="curve top-left">
                  <img src='https://ctsvsolutions.com/onepage-images/new-ui/chart-left-curve.webp' alt='ruthu jathagam amsam curve left'/>
                </div>
                <Chart amsamChart={amsamChart} type="amsam-chart" chartName="Amsam">  </Chart>
              </div>
              <div className="chart-heading">
                <h3 className="sh">
                  <span className="grt">Amsam </span>zodiac phase
                </h3>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  )
}
