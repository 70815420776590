import React, { lazy, Suspense } from 'react';
import { Helmet } from 'react-helmet';
import { useLocation } from 'react-router-dom';

// Lazy load the components
const TopNavbar = lazy(() => import('../../Main-Components/TopNavbar/TopNavbar'));
const Menubar = lazy(() => import('../../Main-Components/Menubar/Menubar'));
const Footer = lazy(() => import('../../Main-Components/Footer/Footer'));
const WeeklyRasiPalanBanner = lazy(() => import('../../Small-Components/WeeklyRasiPalanBanner/WeeklyRasiPalanBanner'));
const WeeklyRasiPalanView = lazy(() => import('../../Small-Components/WeeklyRasiPalanView/WeeklyRasiPalanView'));

export default function WeeklyRasiPalan() {
  const location = useLocation();

  const canonical = () => {
    const base_url = "https://onepagehoroscope.com";
    const current_path = location.pathname;

    const canonicalPaths = {
      "/rasi-palan/weekly-rasi-palan": "/rasi-palan/weekly-rasi-palan",
    };

    return (
      <link
        rel="canonical"
        href={base_url + (canonicalPaths[current_path] || current_path)}
      />
    );
  };

  return (
    <>
      <Helmet>
        <title>Rasi Palan | Weekly Rasi Palan</title>
        <meta
          name="description"
          content="Rasi Palan gives weekly horoscope insights for your zodiac sign, guiding you in career, finance, and health to navigate life's challenges."
        />
        <meta
          name="keywords"
          content="
          rasi palan,weekly rasi palan,rasi palan in tamil,today rasi palan,daily rasi palan in tamil,daily rasi palan today"
        />
        <meta name="robots" content="index,follow" />
        <meta property="og:title" content="Rasi Palan | Weekly Rasi Palan" />
        <meta property="og:description" content="Rasi Palan gives weekly horoscope insights for your zodiac sign, guiding you in career, finance, and health to navigate life's challenges." />

        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:title" content="Rasi Palan | Weekly Rasi Palan" />
        <meta name="twitter:description" content="Rasi Palan gives weekly horoscope insights for your zodiac sign, guiding you in career, finance, and health to navigate life's challenges." />

        {canonical()}
      </Helmet>

      {/* Suspense to provide a fallback while the components load */}
      <Suspense fallback={<div>Loading...</div>}>
        <TopNavbar />
        <Menubar NavBgstyle="outer-header lp" NavTextColor="text-dark" SubmenuTop="true" />
        <WeeklyRasiPalanBanner />
        <WeeklyRasiPalanView />
        <Footer />
      </Suspense>
    </>
  );
}
