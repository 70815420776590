import React, { lazy, Suspense } from 'react';
import { Helmet } from 'react-helmet';
import { useLocation } from 'react-router-dom';

// Lazy load components
const TopNavbar = lazy(() => import('../../Main-Components/TopNavbar/TopNavbar'));
const Menubar = lazy(() => import('../../Main-Components/Menubar/Menubar'));
const Footer = lazy(() => import('../../Main-Components/Footer/Footer'));
const AstroTalkBanner = lazy(() => import('../../Small-Components/AstroTalkBanner/AstroTalkBanner'));
const AstroTalkContentCard = lazy(() => import('../../Small-Components/AstroTalkContentCard/AstroTalkContentCard'));
const AstroTalkApp = lazy(() => import('../../Small-Components/AstroTalkApp/AstroTalkApp'));
const Review = lazy(() => import('../../Main-Components/Review/Review'));

export default function AstroTalk() {
  const location = useLocation();

  const canonical = () => {
    const base_url = "https://onepagehoroscope.com";
    const current_path = location.pathname;

    const canonicalPaths = {
      "/Talk-To-Astrologer": "/Talk-To-Astrologer",
    };

    return (
      <link
        rel="canonical"
        href={base_url + (canonicalPaths[current_path] || current_path)}
      />
    );
  };

  return (
    <>
      <Helmet>
        <title>Talk to astrologer | Free online astrologer prediction</title>
        <meta charSet="utf-8" />
        <meta
          name="viewport"
          content="width=device-width,initial-scale=1,maximum-scale=5"
        />
        <meta
          name="description"
          content="Talk to astrologer app for accurate marriage predictions, life, education, and future insights. Experience personalized guidance from expert astrologers."
        />
        <meta
          name="keywords"
          content="talk to astrologer,talk to astrologer for free,free talk to astrologer,talk to astrologer online,speak to an astrologer, astrologer prediction, online astrologer,best astrologer online"
        />
        <meta name="robots" content="index,follow" />
        {canonical()}
      </Helmet>

      {/* Use Suspense to handle lazy-loaded components */}
      <Suspense fallback={<div>Loading...</div>}>
        <TopNavbar />
        <Menubar NavBgstyle="outer-header" NavTextColor="text-dark" SubmenuTop="true" />
        <AstroTalkBanner />
        <AstroTalkApp />
        <AstroTalkContentCard />
        <Review />
        <Footer />
      </Suspense>
    </>
  );
}
