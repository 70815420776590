import React from 'react'
import TopNavbar from '../../Main-Components/TopNavbar/TopNavbar'
import Menubar from '../../Main-Components/Menubar/Menubar'
import Footer from '../../Main-Components/Footer/Footer'
import BlogListBanner from '../../Small-Components/BlogListBanner/BlogListBanner'
import BlogRecent from "../../Small-Components/BlogRecent/BlogRecent";
import BlogCategories from "../../Small-Components/BlogCategories/BlogCategories ";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Link, useLocation } from 'react-router-dom'
import { faFacebook, faInstagram, faLinkedin, faTwitter } from '@fortawesome/free-brands-svg-icons'
import { Helmet } from 'react-helmet'
import BirthHoroscopeForm from '../../Forms/BirthHoroscopeForm/BirthHoroscopeForm';
import BirthHoroscopeHomeContextProvider from "../../context/forms/BirthHoroscope_Home_ContextProvider"
export default function LifeHoroscopeBlog() {

    const location = useLocation();
    const canonical = () => {
        const base_url = "https://onepagehoroscope.com";
        const current_path = location.pathname;

        const canonicalPaths = {
            "/Blog/Life-Horoscope-In-tamil": "/Blog/Life-Horoscope-In-tamil",

        };

        return (
            <link
                rel="canonical"
                href={base_url + (canonicalPaths[current_path] || current_path)}
            />
        );
    };

    return (
        <>
            <Helmet>
                <title>Get Life Horoscope in Tamil for Marriage, Business & Health.</title>
                <meta
                    name="description"
                    content="Discover personalized life horoscope predictions in Tamil for marriage, business, education, and health. Learn about the twelve houses in astrology for insights."
                />
                <meta
                    name="keywords"
                    content="Marriage matching"
                />
                <meta name="robots" content="index,follow" />
                <meta property="og:title" content="Life Horoscope In tamil | உங்களது ஜாதகத்திற்கான பலன்" />
                <meta property="og:description" content="Unlock the secrets of your future with accurate Life Horoscope in Tamil predictions. Get personalized insights now for a brighter tomorrow!" />
                <meta property="og:image" content="https://ctsvsolutions.com/onepage-images/Life-Horoscope-in-tamil.webp" />
                <meta property="og:url" content="https://onepagehoroscope.com/Blog/Life-Horoscope-In-tamil" />
                <meta name="twitter:card" content="summary_large_image" />
                <meta name="twitter:title" content="Life Horoscope In tamil | உங்களது ஜாதகத்திற்கான பலன்" />
                <meta name="twitter:description" content="Unlock the secrets of your future with accurate Life Horoscope in Tamil predictions. Get personalized insights now for a brighter tomorrow!" />
                <meta name="twitter:image" content="https://ctsvsolutions.com/onepage-images/Life-Horoscope-in-tamil.webp" />
                <meta name="twitter:url" content="https://onepagehoroscope.com/Blog/Life-Horoscope-In-tamil" />
                {canonical()}
                <script type="application/ld+json">
                    {JSON.stringify({

                        "@context": "https://schema.org",
                        "@type": "BlogPosting",
                        "mainEntityOfPage": {
                            "@type": ".webpage",
                            "@id": "https://onepagehoroscope.com/Blog/Life-Horoscope-In-tamil"
                        },
                        "headline": "Life Horoscope In tamil - உங்களது ஜாதகத்திற்கான பலன்",
                        "description": "Unlock the secrets of your future with accurate Life Horoscope in Tamil predictions. Get personalized insights now for a brighter tomorrow!",
                        "image": "https://ctsvsolutions.com/onepage-images/Life-Horoscope-in-tamil.webp",
                        "author": {
                            "@type": "",
                            "name": ""
                        },
                        "publisher": {
                            "@type": "Organization",
                            "name": "",
                            "logo": {
                                "@type": "ImageObject",
                                "url": ""
                            }
                        },
                        "datePublished": "2024-03-19",
                        "dateModified": "2024-03-21"
                    })}
                </script>

            </Helmet>
            <TopNavbar />
            <Menubar NavBgstyle="outer-header rjr" NavTextColor="text-dark" SubmenuTop="true" />
            <BlogListBanner />
            <LifeHoroscopeBlogContent />
            <Footer />
        </>
    )
}

function LifeHoroscopeBlogContent() {
    return (
        <>
            <section className="article-wrapper">
                <div className="astro-container pt-3">
                    <div className="row">
                        <div className="col-lg-8 my-2">
                            <div className="card bg-transparent border-0 blog-view">
                                <div className="card-header bg-transparent border-0 px-0">
                                    <h1 className="fs-4 blog-view-heading">
                                        Life Horoscope In tamil - உங்களது ஜாதகத்திற்கான பலன்
                                    </h1>
                                </div>
                                <div className="card-body pt-0 px-0">
                                    <div className="my-2">
                                        <img
                                            src="https://ctsvsolutions.com/onepage-images/Life-Horoscope-in-tamil.webp"
                                            alt="Life Horoscope In tamil"
                                            className="img-fluid rounded"
                                        />
                                    </div>
                                    <div className="my-4 d-flex">
                                        <a href="https://www.facebook.com/onepagehoroscope/">
                                            <div className="view-blog-icons facebook">
                                                <FontAwesomeIcon
                                                    icon={faFacebook}
                                                    className="text-white px-2"
                                                />
                                            </div>
                                        </a>
                                        <a href="https://www.instagram.com/one_page_horoscope/">
                                            <div className="view-blog-icons instagram">
                                                <FontAwesomeIcon
                                                    icon={faInstagram}
                                                    className="text-white px-2"
                                                />
                                            </div>
                                        </a>
                                        <a href="https://twitter.com/onepage321">
                                            <div className="view-blog-icons twitter">
                                                <FontAwesomeIcon icon={faTwitter} className="text-white px-2" />
                                            </div>
                                        </a>
                                        <a href="https://linkedin.com/showcase/97871433/admin/feed/posts/">
                                            <div className="view-blog-icons linkedin">
                                                <FontAwesomeIcon
                                                    icon={faLinkedin}
                                                    className="text-white px-2"
                                                />
                                            </div>
                                        </a>
                                    </div>
                                    <div className="description mt-3">
                                        <p className="mb-3">
                                            <Link to="/Birth-Horoscope" className="text-decoration-none">
                                                <span className="text-danger">Life horoscope in tamil</span>
                                            </Link>{" "}
                                            get your personalized horoscope prediction from our well
                                            experienced astrologer, with the help of one page horoscope
                                        </p>
                                        <div className="mb-3">
                                        <BirthHoroscopeHomeContextProvider>
                                        <BirthHoroscopeForm />
                                        </BirthHoroscopeHomeContextProvider>
                                        </div>
                                        
                                       
                                        {/* <div className="mb-5">
                                            <BirthHorosopeBlogForm />
                                        </div> */}
                                        <div>
                                            <h2 className="mb-3 fs-5 blog-view-heading">
                                                Life Horoscpe in tamil - Your horoscope prediction
                                            </h2>
                                            <p className="description-content mb-3">
                                                Life horoscope in tamil prediction is help us to be aware in all times about our desicion in life on everytime like marriage, business, buying new vehicle about our studies.
                                            </p>
                                        </div>
                                        <div>
                                            <h2 className="mb-3 fs-5 blog-view-heading">
                                                Basic of horoscope prediction:
                                            </h2>
                                            <p className="description-content mb-3">
                                                Each and every bhava of our birth chart tell about us many
                                                answers, check your about the <Link to="/Blog/tamil-astrology-12-houses-explained" className="text-decoration-none">
                                                    <span className="text-danger">12 bhavas in astrology.</span>
                                                </Link>
                                            </p>
                                            <p className="description-content mb-3">
                                                Nine placement in twelve bhavas help us to predict answer for
                                                our questions, we can take this prediciton as a guide to confirm
                                                your desicion.
                                            </p>
                                        </div>
                                        <div>
                                            <h2 className="mb-3 fs-5 blog-view-heading">
                                                Yourself
                                            </h2>
                                            <p className="description-content mb-3">
                                                In every birth chart there Is a ascendant called lagnam, Is the first house describe your body health, your skin tone, your attitude, character, your life and your inner happiness and sorrows.
                                            </p>
                                            <p className="description-content mb-3">
                                                It’s a main house of every individual horoscope, so many people may have same lagnam but their life and lifestyle are will be different depends on place of birth, family, parents and everything.
                                            </p>
                                        </div>
                                        <div>
                                            <h2 className="mb-3 fs-5 blog-view-heading">
                                                Wealth
                                            </h2>
                                            <p className="description-content mb-3">
                                                Second bava or second house is helpful for predicting your family wealth, your earnings, profit, speech and everything.
                                            </p>
                                            <p className="description-content mb-3">
                                                Second house basically shows how saving your earnings, how you are going to manage the earning with your family.
                                            </p>
                                        </div>
                                        <div>
                                            <h2 className="mb-3 fs-5 blog-view-heading">
                                                Siblings
                                            </h2>
                                            <p className="description-content mb-3">
                                                Third bhava basic of siblings and your braveness, occupying good planets and get a view from good planets are things to confirm the house is strong and It will going to give you best positive things.
                                            </p>
                                            <p className="description-content mb-3">
                                                How you are going to be with your sibling,some time astrologer predicting your siblings may be boy are girl and it happen in some situation.
                                            </p>
                                        </div>
                                        <div>
                                            <h2 className="mb-3 fs-5 blog-view-heading">
                                                Education
                                            </h2>
                                            <p className="description-content mb-3">
                                                Fourth Bhava if you plan to predict you educational qualification or what kind of profession suit your carrier you have to see the fourth house.
                                            </p>
                                            <p className="description-content mb-3">
                                                Planet in the fourth house tell your education like you will be graduate, or doctorate, travel long for education, depends on the house is movable or immovable house and also about the house ruling planet.
                                            </p>
                                            <p>Another useful prediction of fourth house is about your mother, and his health, wealth, character and attitude.</p>
                                        </div>
                                        <div>
                                            <h2 className="mb-3 fs-5 blog-view-heading">
                                                Childrens
                                            </h2>
                                            <p className="description-content mb-3">
                                                Fifth bhava or puthira sthanam, astrologer predict your children’s after marriage based on your fifth house and ruling planet dasa.
                                            </p>
                                        </div>
                                        <div>
                                            <h2 className="mb-3 fs-5 blog-view-heading">
                                                Health Prediction:
                                            </h2>
                                            <p className="description-content mb-3">
                                                Sixth bava and the ruling planet placement in your birth chart
                                                will help to predict your health according to your dasa planet.
                                            </p>
                                        </div>
                                        <div>
                                            <h2 className="mb-3 fs-5 blog-view-heading">
                                                Marriage Predition:
                                            </h2>
                                            <p className="description-content mb-3">
                                                Seventh bava and seventh house athibathi will tell your marriage
                                                prediction, this prediction about your life partner, your life
                                                after marriage, how fortune your life partner for you, life
                                                partner physical and abour your children.
                                            </p>
                                        </div>
                                        <div>
                                            <h2 className="mb-3 fs-5 blog-view-heading">
                                                Job and Business:
                                            </h2>
                                            <p className="description-content mb-3">
                                                Your tenth house or tenth bava is the right bava to predict your
                                                job or business for your life style, this bava even helps to
                                                predict you are suit for business or regular job or independent
                                                worker or professional everything.
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-4 my-2">
                            <div className="row">
                                <BlogRecent />
                                <BlogCategories />
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}