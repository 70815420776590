import React from 'react'
// import BabyLeftImage from '../../images/form-left-baby-img.webp'
import BabyNameForm from '../../Forms/BabyNameForm/BabyNameForm'

export default function BabyNameFormSection() {
    return (
        <>
            <section className="form-img">
                <div className="astro-container">
                    <div className="row pbn-mobile-row">
                        <div className="col">
                            <div className="img">
                                <img className="img-res" src='https://ctsvsolutions.com/onepage-images/new-ui/form-left-baby-img.webp' alt='Stylish Tamil Baby Names'/>
                            </div>
                        </div>
                        <div className="col">
                            <div className="upper-form">
                               <BabyNameForm/>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}
