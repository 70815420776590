import React from 'react'
import TopNavbar from '../../Main-Components/TopNavbar/TopNavbar'
import Menubar from '../../Main-Components/Menubar/Menubar'
import Footer from '../../Main-Components/Footer/Footer'
import TamilJathagaBookBanner from '../../Small-Components/TamilJathagaBookBanner/TamilJathagaBookBanner'
import TamilJathagaBookContent from '../../Small-Components/TamilJathagaBookContent/TamilJathagaBookContent'
import Guiding from '../../Main-Components/Guiding/Guiding'

export default function TamilJathagaBook() {
    return (
        <>
            <TopNavbar />
            <Menubar NavBgstyle="outer-header hb" NavTextColor="text-dark" SubmenuTop="true" />
            <TamilJathagaBookBanner />
            <TamilJathagaBookContent />
            <Guiding/>
            <Footer />
        </>
    )
}
