import React, { useState, useEffect } from "react";
import axios from "axios";
import { Confirm } from 'notiflix/build/notiflix-confirm-aio';
import getepayPortal from "../../../../components/tamil/Getepay_pg_react";
import { RazerpayPaymentGateway } from "../../payment-gateway/RazerpayGateway";
import { useNavigate } from "react-router-dom";
import { Loading } from "notiflix";
// import JathagaPalanLeftIllus from '../../images/left-form-illus.webp'
// import JathagaPalanRightllus from '../../images/right-form-illus.webp'
// import JathagaPalanRightllusMobile from '../../images/sapf-mobile-illus.webp'
// import JathagaPalanRighthdp from '../../images/ap-hdph.webp'

export default function JathagaPalanForm() {
  const Navigate=useNavigate();
  const currentYear = new Date().getFullYear();
  const years = Array.from(new Array(currentYear - 1950), (_, index) => currentYear - index);
  const hours = Array.from(new Array(12), (val, index) => index + 1);
  const minutes = Array.from(new Array(60), (val, index) =>
    index < 10 ? `0${index}` : index
  );
  const [dobDay, setDobDay] = useState("");
  const [dobMonth, setDobMonth] = useState("");
  const [gender, setGender] = useState("");
  const [dobYear, setDobYear] = useState("");
  const [birthHour, setBirthHour] = useState("");
  const [birthMinute, setBirthMinute] = useState("");
  const [amPm, setAmPm] = useState("AM");
  const [Name, setName] = useState("");
  const [askToAstrologer, setAskToAstrologer] = useState([
    "திருமணம்",
    "கல்வி",
    "தொழில்",
  ]);
  const [birthPlace, setBirthPlace] = useState("");
  const [suggestedPlaces, setSuggestedPlaces] = useState([]);
  const [selectedPlaces, setSelectedPlaces] = useState([]);
  const [Email, setEmail] = useState("");
  const [Mobile_number, setMobileNumber] = useState("");
  const [Latitude, setLatitude] = useState("");
  const [Longitude, setLongitude] = useState("");
  const [NameError, setNameError] = useState("");
  const [genderError, setGenderError] = useState("");
  const [DobError, setDobError] = useState("");
  const [BirthTimeError, setBirthTimeError] = useState("");
  // const [QuestionError, setQuestionError] = useState("");
  const [BirthPlaceError, setBirthPlaceError] = useState("");
  const [MobileNumberError, setMobileNumberError] = useState("");
  const [EmailError, setEmailError] = useState("");
  // cost
  const handleCheckboxChange = (event) => {
    const { value, checked } = event.target;
    if (checked) {
      setAskToAstrologer([...askToAstrologer, value]);
    } else {
      setAskToAstrologer(askToAstrologer.filter((item) => item !== value));
    }
  };
  const handleDobDayChange = (e) => {
    setDobDay(e.target.value);
  };

  const handleDobMonthChange = (e) => {
    setDobMonth(e.target.value);
  };

  const handleDobYearChange = (e) => {
    setDobYear(e.target.value);
  };

  const handleBirthHourChange = (e) => {
    setBirthHour(e.target.value);
  };

  const handleBirthMinuteChange = (e) => {
    setBirthMinute(e.target.value);
  };

  const handleAmPmChange = (e) => {
    setAmPm(e.target.value);
  };
  const handleLocationChange = (event) => {
    const currentValue = event.target.value;
    setBirthPlace(currentValue);
    fetchSuggestions(currentValue);
  };

  const handleSuggestionSelect = (selectedPlace) => {
    const { geometry, properties } = selectedPlace;
    const latitude = geometry.coordinates[1];
    const longitude = geometry.coordinates[0];
    const district = properties.city;
    const state = properties.state;
    const country = properties.country;
    const location = `${district}, ${state}, ${country}`;

    const updatedSelectedPlaces = [...selectedPlaces, location];

    setSelectedPlaces(updatedSelectedPlaces);
    setBirthPlace(location);
    setSuggestedPlaces([]);
    setLatitude(latitude);
    setLongitude(longitude);
    document.getElementById("atflg").value = longitude;
    document.getElementById("atflt").value = latitude;
  };

  const fetchSuggestions = async (currentValue) => {
    if (currentValue.length >= 3) {
      const apiKey = "68ab21fa64da4974b4f47e04943328b8";
      const url = `https://api.geoapify.com/v1/geocode/autocomplete?text=${encodeURIComponent(
        currentValue
      )}&limit=5&apiKey=${apiKey}`;

      try {
        const response = await fetch(url);
        if (response.ok) {
          const data = await response.json();
          setSuggestedPlaces(data.features);
        } else {
          console.error("Error fetching suggestions from the API");
          setSuggestedPlaces([]);
        }
      } catch (error) {
        console.error("Error fetching suggestions from the API: ", error);
        setSuggestedPlaces([]);
      }
    }
  };

  const Validation = () => {
    let isValid = true;

    if (!Name) {
      setNameError("Name is required");
      isValid = false;
    } else {
      setNameError("");
    }

    if (!gender) {
      setGenderError("Gender is Required");
      isValid = false;
    } else {
      setGenderError("");
    }

    if (!dobYear || !dobMonth || !dobDay) {
      setDobError("Date of Birth is required");
      isValid = false;
    } else {
      setDobError("");
    }

    if (!birthHour || !birthMinute || !amPm) {
      setBirthTimeError("Birth Time is required");
      isValid = false;
    } else {
      setBirthTimeError("");
    }

    // if (!askToAstrologer) {
    //   setQuestionError("Questions are required");
    //   isValid = false;
    // } else {
    //   setQuestionError("");
    // }

    if (!birthPlace || !Longitude || !Latitude) {
      setBirthPlaceError("Birth place is required");
      isValid = false;
    } else {
      setBirthPlaceError("");
    }

    if (!Mobile_number || !/^\d{10}$/.test(Mobile_number)) {
      setMobileNumberError("Mobile Number is required");
      isValid = false;
    } else {
      setMobileNumberError("");
    }
    if (!Email) {
      setEmailError("Email is Required");
      isValid = false;
    } else if (!/^\S+@\S+\.\S+$/.test(Email)) {
      setEmailError("Invalid Email Format");
      isValid = false;
    } else {
      setEmailError("");
    }
    return isValid;
  };
  useEffect(() => {
    const storedValue = localStorage.getItem("AudioBookData");
    // console.log(storedValue);

    if (storedValue) {
      const parsedValue = JSON.parse(storedValue);
      setName(parsedValue.Name);
      setGender(parsedValue.gender);
      setBirthPlace(parsedValue.birthPlace);
      setDobDay(parsedValue.Day);
      setDobMonth(parsedValue.Month);
      setDobYear(parsedValue.Year);
      setBirthHour(parsedValue.Hour);
      setBirthMinute(parsedValue.Minute);
      setAmPm(parsedValue.amPm);
      setEmail(parsedValue.Email);
      setAskToAstrologer(parsedValue.Questions);
      setLatitude(parsedValue.Latitude);
      setLongitude(parsedValue.Longitude);
      setMobileNumber(parsedValue.MobileNumber);
      document.getElementById("atflg").value = parsedValue.Longitude;
      document.getElementById("atflt").value = parsedValue.Latitude;
    }
  }, []);

  const AudioFormSubmit = () => {
    if (Validation()) {
      Confirm.show(
        'Before payment, verify your data.',
        'Within 4 hours, you will receive your astrology prediction in audio format on WhatsApp.',
        'Yes',
        'No',
        () => {
          let userData = {
            Name: Name,
            Email: Email,
            Day: dobDay,
            Month: dobMonth,
            Year: dobYear,
            Hour: birthHour,
            Minute: birthMinute,
            amPm: amPm,
            Latitude: Latitude,
            Longitude: Longitude,
            Questions: askToAstrologer,
            gender: gender,
            birthPlace: birthPlace,
            MobileNumber: Mobile_number
          }
          const userInfoValues = JSON.stringify(userData);
          localStorage.setItem("AudioBookData", userInfoValues);
          let Call = ApiInsert();
        },
        () => {

        }
      );
    }
  };

  function ApiInsert() {
    const userData = {
      buyerName: Name,
      buyerEmail: Email,
      buyerMobileNo: Mobile_number,
      DateofBirth: dobDay + "-" + dobMonth + "-" + dobYear,
      birthTime: birthHour + ":" + birthMinute + " " + amPm,
      gender: gender,
      birthPlace: birthPlace,
      Amount:500,
      Package: "Audio_Book",
      latitude: Latitude,
      longitude: Longitude,
      name: Name,
      Questions: askToAstrologer.join(',')
    };


    axios
      .post("https://ctsvsolutions.com/onepage-Api/public/api/Statement-customerData-insert", {
        userData,
      })
      .then((response) => {
        console.log(response.data);
        const userId = response.data.data.userId;
        const customerDataId = response.data.data.customerDataId;
        const newUserData = {
          user_id: userId,
          customerDataId: customerDataId,
        };
        const userInfoValues = JSON.stringify(newUserData);
        localStorage.setItem("AudioBookUser", userInfoValues);
        let Pay = proceedToPayment()
      });
  };


  const  proceedToPayment= async()=> {
    Loading.dots();  
    const userInfoJSON = localStorage.getItem("AudioBookUser");
    const userInfo = JSON.parse(userInfoJSON);
    let user_id = userInfo.user_id;
    let customer_id = userInfo.customerDataId;
    const payment_Data = {
      amount: '500', 
      email: Email,
      description:'Audio prediction',
      name: Name,
      mobileNumber: Mobile_number,
      user_id: user_id,
      theme: '#E2F2FE',
      customerDataId: customer_id,
      paymentCallBackurl: 'https://ctsvsolutions.com/onepage-Api/public/api/update-razerpay-Audio-prediction-payment-paid-status'
    };
    
    const paymentStatus = await RazerpayPaymentGateway(payment_Data, SuccessMessageAudioPaid,false);
    Loading.remove();
    // const data = {
    //   mid: "993377",
    //   amount: "500.00",
    //   merchantTransactionId: "sd12121",
    //   transactionDate: "Mon Oct 03 13:54:33 IST 2022",
    //   terminalId: "getepay.merchant131068@icici",
    //   udf1: Mobile_number,
    //   udf2: user_id,
    //   udf3: Name,
    //   udf4: customer_id,
    //   udf5: "",
    //   udf6: "",
    //   udf7: "",
    //   udf8: "",
    //   udf9: "",
    //   udf10: "",
    //   ru: "https://ctsvsolutions.com/onepage-Api/public/Audio-Book",
    //   callbackUrl: "",
    //   currency: "INR",
    //   paymentMode: "ALL",
    //   bankId: "",
    //   txnType: "single",
    //   productType: "IPG",
    //   txnNote: "One-Page Audio-Book",
    //   vpa: "getepay.merchant131068@icici",
    // };
    // const Config = {
    //   "mid": "993377",
    //   "Getepay Terminal Id": "getepay.merchant131068@icici",
    //   "Getepay Key": "rjW99m2Vo75SBbpyc+K5nfB/eebzWUmkAtx90U5Ku3I=",
    //   "Getepay IV": "Y7fOrFLKVPosfDFSIFSISQ==",
    //   "Getepay Url":
    //     "https://portal.getepay.in:8443/getepayPortal/pg/generateInvoice"
    // };
    // getepayPortal(data, Config);
    // console.log(data);
  };
  const SuccessMessageAudioPaid=()=>{
    Navigate('/Horosocope-Book-ThankYou');
  }
  return (
    <>
      <div className="form-wrapper">
        <form className="global-form">
          <div className="left-illus">
            <img src='https://ctsvsolutions.com/onepage-images/new-ui/jathaga-left-form-illus.webp' alt="tamil audio jathagam"/>
          </div>
          <div className="right-hdp">
            <img src='https://ctsvsolutions.com/onepage-images/new-ui/audio-prediction.webp' alt="audio horoscope"/>
          </div>
          <h4 className="f-heading">Enter your personal details</h4>
          <p className="f-sub">
            Provide accurate details for the most precise report.
          </p>
          <div className="col">
            <label>Full Name</label>
            <input className='input' type="text"
              id="fullname"
              name="fullname"
              value={Name}
              placeholder="Enter your full name"
              onChange={(e) => setName(e.target.value)}
            />
            {NameError && (
              <span className="text-danger ">{NameError}</span>
            )}
          </div>
          <div className="col">
            <label>Gender</label>
            <select value={gender}
              onChange={(e) => setGender(e.target.value)}>
              <option value="">Gender</option>
              <option value="male">Male</option>
              <option value="female">Female</option>
            </select>
            {genderError && (
              <span className="text-danger ">{genderError}</span>
            )}
          </div>
          <label className="form-rh">date of birth</label>
          <div className="row">
            <div className="col-3">
              <label>date</label>
              <select value={dobDay}
                onChange={handleDobDayChange}>
                <option value="">Day</option>
                {Array.from(new Array(31), (val, index) => index + 1).map(
                  (day) => (
                    <option key={day} value={day}>
                      {day}
                    </option>
                  )
                )}
              </select>
            </div>
            <div className="col-3">
              <label>Month</label>
              <select
                value={dobMonth}
                onChange={handleDobMonthChange}
              >
                <option value="">Month</option>
                {Array.from(new Array(12), (val, index) => index + 1).map(
                  (month) => (
                    <option key={month} value={month}>
                      {month}
                    </option>
                  )
                )}
              </select>
            </div>
            <div className="col-3">
              <label>Year</label>
              <select
                value={dobYear}
                onChange={handleDobYearChange}
                className="form-select"
              >
                <option value="">Year</option>
                {years.map((year) => (
                  <option key={year} value={year}>
                    {year}
                  </option>
                ))}
              </select>
            </div>
            {DobError && (
              <span className="text-danger ">{DobError}</span>
            )}
          </div>
          <label className="form-rh">birth time</label>
          <div className="row">
            <div className="col-3">
              <label>hour</label>
              <select
                value={birthHour}
                onChange={handleBirthHourChange}
              >
                <option value="">Hour</option>
                {hours.map((hour) => (
                  <option key={hour} value={hour}>
                    {hour}
                  </option>
                ))}
              </select>
            </div>
            <div className="col-3">
              <label>Minute</label>
              <select
                value={birthMinute}
                onChange={handleBirthMinuteChange}
              >
                <option value="">Minute</option>
                {minutes.map((minute) => (
                  <option key={minute} value={minute}>
                    {minute}
                  </option>
                ))}
              </select>
            </div>
            <div className="col-3">
              <label>AM/PM</label>
              <select
                value={amPm}
                onChange={handleAmPmChange}
                className="form-select"
              >
                <option value="">AM/PM</option>
                <option value="AM">AM</option>
                <option value="PM">PM</option>
              </select>
            </div>
            {BirthTimeError && (
              <span className="text-danger ">
                {BirthTimeError}
              </span>
            )}
          </div>
          <div className="col">
            <label>birth location</label>
            <input className='input' type="text"
              id="birthplace"
              onChange={handleLocationChange}
              value={birthPlace}
              name="birthplace"
              placeholder="Enter your birth place"
            />
            {BirthPlaceError && (
              <span className="text-danger ">
                {BirthPlaceError}
              </span>
            )}
            <input
              id="atflt"
              className="pfipbx"
              name="the_lat"
              type="hidden"
              placeholder="Latitude"
            />
            <input
              id="atflg"
              className="pfipbx"
              name="the_lng"
              type="hidden"
              placeholder="Longitude"
            />
            <ul>
              {suggestedPlaces.map((place, index) => {
                if (place.properties.city) {
                  const listItemStyle = {
                    padding: "5px 10px",
                    cursor: "pointer",
                    fontSize: "16px",
                    borderBottom: "1px dashed #ddd",
                  };
                  return (
                    <p
                      style={listItemStyle}
                      key={index}
                      onClick={() => handleSuggestionSelect(place)}
                    >
                      {place.properties.city}, {place.properties.state},{" "}
                      {place.properties.country}
                    </p>
                  );
                }
              })}
            </ul>
          </div>
          <div className="col">
            <label>ask to astrologer</label>
            <div className="checkbox-wrapper mt-2">
              <div className="checkbox">
                <input
                  type="checkbox"
                  value="திருமணம்"
                  style={{ marginRight: "5px" }}
                  onChange={handleCheckboxChange}
                  checked={askToAstrologer.includes("திருமணம்")}
                />
                <label>wedding</label>
              </div>
              <div className="checkbox">
                <input
                  type="checkbox"
                  value="கல்வி"
                  style={{ marginRight: "5px" }}
                  onChange={handleCheckboxChange}
                  checked={askToAstrologer.includes("கல்வி")}
                />
                <label>education</label>
              </div>
              <div className="checkbox">
                <input
                  type="checkbox"
                  value="வண்டி வாகனம்"
                  style={{ marginRight: "5px" }}
                  onChange={handleCheckboxChange}
                  checked={askToAstrologer.includes("வண்டி வாகனம்")}
                />
                <label>carriage vehicle</label>
              </div>
              <div className="checkbox">
                <input
                  type="checkbox"
                  value="தொழில்"
                  style={{ marginRight: "5px" }}
                  onChange={handleCheckboxChange}
                  checked={askToAstrologer.includes("தொழில்")}
                />
                <label>professsion</label>
              </div>
              <div className="checkbox">
                <input
                  type="checkbox"
                  value="சொத்து"
                  onChange={handleCheckboxChange}
                  style={{ marginRight: "5px" }}
                  checked={askToAstrologer.includes("சொத்து")}
                />
                <label>property</label>
              </div>
            </div>
          </div>
          <div className="col">
            <label>email address</label>
            <input className='input' type="text"
              value={Email}
              onChange={(e) => setEmail(e.target.value)}
              id="email"
              name="email"
              placeholder="Enter your email address"
            />
            {EmailError && (
              <span className="text-danger ">{EmailError}</span>
            )}
          </div>
          <div className="col">
            <label>whatsapp number</label>
            <input className='input' type="text"
              value={Mobile_number}
              onChange={(e) => setMobileNumber(e.target.value)}
              id="mobile"
              name="mobile"
              placeholder="Enter your mobile number"
            />
            {MobileNumberError && (
              <span className="text-danger ">
                {MobileNumberError}
              </span>
            )}
          </div>
          <div className="text-center">
            <button className="jathagapalan-btn-lg btn-prpl border-0" type="button" onClick={AudioFormSubmit}>
              Get Audio Prediction
              <img src='https://ctsvsolutions.com/onepage-images/new-ui/btn-arrow-white.webp' alt="audio prediction arrow"/>
            </button>
          </div>
          <div className="mt-3">
            <p className="f-sub text-danger text-center mb-0">
              * Your audio prediction will be generated in Tamil.
            </p>
          </div>
          <div className="right-illus">
            <img src='https://ctsvsolutions.com/onepage-images/new-ui/jathaga-right-form-illus.webp' alt="audio horoscope prediction"/>
          </div>
          <div className="right-illus-mobile">
            <img src='https://ctsvsolutions.com/onepage-images/new-ui/jathaga-palan-mobile-illus.webp' alt="tamil audio prediction"/>
          </div>
        </form>
      </div>
    </>
  )
}
