import React, { useState, useContext, useEffect, useRef } from 'react'
// import BioDataFormLeftIcon from '../../images/rd-left-icon.webp'
// import BioDataStepImage1 from '../../images/step-form-icon-1.webp'
// import BioDataStepImage2 from '../../images/step-form-icon-3.webp'
// import BioDataStepImage3 from '../../images/step-form-icon-4.webp'
// import BioDataStepImage4 from '../../images/step-form-icon-5.webp'
// import BioDataStepImage5 from '../../images/step-form-icon-6.webp'
import '../../style/mystyle.css'
import { faLocationDot } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faDownload, faCheck } from "@fortawesome/free-solid-svg-icons";
import { BioDataContext } from '../../context/forms/BiodataForm_context_provider';
import axios from 'axios';
import "croppie/croppie.css";
import Croppie from "croppie";
import Select from "react-select";
import { useNavigate } from 'react-router-dom';
import { Loading } from 'notiflix/build/notiflix-loading-aio';
const croppieOptions = {
    showZoomer: true,
    enableOrientation: true,
    mouseWheelZoom: "ctrl",
    viewport: {
        width: 200,
        height: 200,
        type: "square",
    },
    boundary: {
        width: 250,
        height: 250,
    },
};
export default function BioDataForm({ BlogStatus = false }) {
    let croppieInstance = null;
    const [uploadedImage, setUploadedImage] = useState(null);
    const [croppedImage, setCroppedImage] = useState(null);
    const fileInputRef = useRef(null);
    const croppieRef = useRef(null);
    const [selectedSiblings, setSelectedSiblings] = useState([]);
    const {
        // Step and active states
        step, setStep, Active, setActive,

        // Options for day, month, year, hour, minute, weight, height
        dayOptions, monthOptions, yearOptions, hourOptions, minuteOptions, weightOptions, heightOptions,

        // User details
        userName, setUserName, userGender, setUserGender, BirthDay, setBirthDay, BirthMonth, setBirthMonth,
        BirthYear, setBirthYear, BirthHour, setBirthHour, BirthMinute, setBirthMinute, BirthAmPm, setAmPm,
        BirthPlace, setBirthPlace, Education, SetEducation, WorkDetail, setWorkDetail, OtherWorkDetail, setOtherWorkDetail,
        MonthlySalary, setMonthlySalary, Height, setHeight, weight, setWeight, Color, setColor, Caste, setCaste,
        kulam_KulaDeivam, setKulam_KulaDeivam, fatherName, setFatherName, MotherName, setMotherName,
        FatherWorkDetails, setFatherWorkDetails, MotherWorkDetails, setMotherWorkDetails, AssetsDetail, setAssetsDetails,
        MobileNumber, setMobileNumber, WhatsappNumber, setWhatsappNumber, Email, setEmail, CurrentLocation, setCurrentLocation,
        Address, setAddress, SwitchLocationButton, SetSwitchLocationButton, Timezone, setTimeZone,

        // Place and location details
        suggestedPlaces, setSuggestedPlaces, selectedPlaces, setSelectedPlaces, latitudeValue, setLatitude, LongitudeValue, setLongitude,

        // Validation errors
        userNameError, setUserNameError, GenderError, setGenderError, DayError, setDayError, MonthError, setMonthError,
        BirthYearError, setBirthYearError, BirthHourError, setBirthHourError, BirthMinuteError, setBirthMinuteError,
        AmPmError, setAmPmError, BirthPlaceError, setBirthPlaceError, EducationError, setEducationError,
        WorkDetailError, setWorkDetailError, MonthlySalaryError, setMonthlySalaryError, HeightError, setHeightError,
        WeightError, setWeightError, ColorError, setColorError, CasteError, setCasteError, Kulam_KulaDeivamError, setKulam_KulaDeivamError,
        FatherNameError, setFatherNameError, MotherNameError, setMotherNameError, FatherWorkDetailsError, setFatherWorkDetailsError,
        MotherWorkDetailsError, setMotherWorkDetailsError, SiblingError, setSiblingError, AssetsError, setAssetsError,
        MobileNumberError, setMobileNumberError, WhatsappNumberError, setWhatsappNumberError, EmailError, setEmailError,
        CurrentLocationError, setCurrentLocationError, AddressError, setAddressError, ImageError, setImageError,

        // Sibling options
        siblingOptions,
    } = useContext(BioDataContext);

    useEffect(() => {
        if (step === 5) {
            if (!croppieInstance) {

                croppieInstance = new Croppie(croppieRef.current, croppieOptions);
            }

            // Bind the uploaded image to the Croppie instance if it exists
            if (uploadedImage) {
                croppieInstance.bind({ url: uploadedImage });
            }
        }

        return () => {
            if (croppieInstance) {
                croppieInstance.destroy();
                croppieInstance = null;
            }
        };
    }, [step, uploadedImage]);

    const handleFileUpload = () => {
        const reader = new FileReader();

        const file = fileInputRef.current.files[0];
        setFileName(file ? file.name : '');
        reader.readAsDataURL(file);
        reader.onload = () => {
            setUploadedImage(reader.result);
            // console.log(reader.result);

        };
    };

    const handleCrop = () => {
        if (croppieInstance) {
            croppieInstance.result("base64").then((base64) => {
                setCroppedImage(base64);
                alert('image cropied succussfully');
            });
        }
        else {
            alert("kindly upload the image");
        }

    };

    const handleSiblingsChange = (selectedOptions) => {
        if (selectedOptions.length <= 5) {
            setSelectedSiblings(selectedOptions);
        }
    };

    function NexStep() {
        setStep((prev) => prev + 1);
        setActive((prev) => ({
            ...prev,
            [`step${step + 1}`]: true
        }));
    }

    function preStep() {
        setStep((prev) => (prev > 1 ? prev - 1 : prev));
        // setActive()
        setActive((prev) => ({
            ...prev,
            [`step${step}`]: false
        }));
    }

    const handleLocationChange = (event) => {
        SetSwitchLocationButton(true)
        const currentValue = event.target.value;
        setBirthPlace(currentValue);
        fetchSuggestions(currentValue, 'geoname_cities');
    };

    const handleSuggestionSelect = (selectedPlace) => {
        SetSwitchLocationButton(false);
        const latitude = selectedPlace.latitude;
        const longitude = selectedPlace.longitude;
        const Timezone = selectedPlace.TimeZone;
        const parts = selectedPlace.districts.split(",");
        const displayName = parts.slice(0, 3).join(",");
        const location = displayName;

        const updatedSelectedPlaces = [...selectedPlaces, location];

        setSelectedPlaces(updatedSelectedPlaces);
        setBirthPlace(location);
        setLongitude(longitude);
        setLatitude(latitude);
        setBirthPlaceError("");
        setTimeZone(Timezone);
        setSuggestedPlaces('');

        document.getElementById("atflg").value = longitude;
        document.getElementById("atflt").value = latitude;
    };

    const fetchSuggestions = (currentValue, LocationType) => {
        if (currentValue.length >= 1) {
            const Data = {
                location: currentValue,
                Type: LocationType,
            };
            axios
                .post(
                    "https://ctsvsolutions.com/onepage-Api/public/api/Location-Api",
                    Data
                )
                .then((response) => {
                    if (response.data.status == true) {
                        setSuggestedPlaces(response.data.location);
                    } else {
                        setSuggestedPlaces('');
                    }
                });
        } else {
            setSuggestedPlaces('');
        }


    };
    const SearchNewLocation = () => {

        const currentLocation = BirthPlace.includes(",")
            ? BirthPlace.split(",")[0]
            : BirthPlace;
        fetchSuggestions(currentLocation, 'Map_Box');
    };


    const Step1Validation = () => {
        let isValid = true;

        if (userName === "") {
            setUserNameError("Name is required");
            isValid = false;
        } else {
            setUserNameError("");
        }
        if (userGender === "") {
            setGenderError("Field is required");
            isValid = false;
        } else {
            setGenderError("");
        }
        if (BirthDay === "") {
            setDayError("field is required");
            isValid = false;
        } else {
            setDayError("");
        }
        if (BirthMonth === "") {
            setMonthError("Field is required");
            isValid = false;
        } else {
            setMonthError("");
        }
        if (BirthYear === "") {
            setBirthYearError("Field is required");
            isValid = false;
        } else {
            setBirthYearError("");
        }
        if (BirthHour === "") {
            setBirthHourError("Filed is required");
            isValid = false;
        } else {
            setBirthHourError("");
        }
        if (BirthMinute === "") {
            setBirthMinuteError("field is required");
            isValid = false;
        } else {
            setBirthMinuteError("");
        }
        if (BirthAmPm === "") {
            setAmPmError("field is required");
            isValid = false;
        } else {
            setAmPmError("");
        }
        if (BirthPlace === "" || latitudeValue === "" || LongitudeValue === "") {
            setBirthPlaceError("Field is required");
            isValid = false;
        } else {
            setBirthPlaceError("");
        }
        if (isValid === true) {

            setStep(step + 1);
            setActive((prev) => ({
                ...prev,
                step2: true
            }));
        }
    };

    const step2Validation = () => {
        let isValid = true;

        if (Education === "") {
            setEducationError("Field is required");
            isValid = false;
        } else {
            setEducationError("");
        }
        if (WorkDetail === "") {
            setWorkDetailError("Field is required");
            isValid = false;
        } else {
            setWorkDetailError("");
            if (WorkDetail === "Others" && OtherWorkDetail === "") {
                setWorkDetailError("Field is required");
                isValid = false;
            } else {
                setWorkDetailError("");
            }
        }
        if (MonthlySalary < 1) {
            setMonthlySalaryError("field is required");
            isValid = false;
        } else {
            setMonthlySalaryError("");
        }
        if (Height === "") {
            setHeightError("field is required");
            isValid = false;
        } else {
            setHeightError("");
        }
        if (!weight) {
            setWeightError("Field is required");
            isValid = false;
        } else {
            setWeightError("");
        }
        if (Color === "") {
            setColorError("field is required");
            isValid = false;
        } else {
            setColorError("");
        }
        if (Caste === "") {
            setCasteError("Filed is required");
            isValid = false;
        } else {
            setCasteError("");
        }
        if (kulam_KulaDeivam === "") {
            setKulam_KulaDeivamError("field is required");
            isValid = false;
        } else {
            setKulam_KulaDeivamError("");
        }
        if (isValid === true) {
            setStep(step + 1);
            setActive((prev) => ({
                ...prev,
                step3: true
            }));
        }
    };

    const step3Validation = () => {
        let isValid = true;
        if (fatherName === "") {
            setFatherNameError("field is required");
            isValid = false;
        } else {
            setFatherNameError("");
        }
        if (MotherName === "") {
            setMotherNameError("field is required");
            isValid = false;
        } else {
            setMotherNameError("");
        }
        if (FatherWorkDetails === "") {
            setFatherWorkDetailsError("field is required");
            isValid = false;
        } else {
            setFatherWorkDetailsError("");
        }
        if (MotherWorkDetails === "") {
            setMotherWorkDetailsError("Field is required");
            isValid = false;
        } else {
            setMotherWorkDetailsError("");
        }
        if (selectedSiblings.length === 0) {
            setSiblingError("Filed is required");
            isValid = false;
        } else {
            setSiblingError("");
        }
        if (AssetsDetail === "") {
            setAssetsError("Field is required");
            isValid = false;
        } else {
            setAssetsError("");
        }
        // return isValid;
        if (isValid === true) {
            setStep(step + 1);
            setActive((prev) => ({
                ...prev,
                step4: true
            }));
        }
    };
    const step4Validation = () => {

        let isValid = true;
        if (MobileNumber.length !== 10) {
            setMobileNumberError("Mobile Number must be 10 digits");
            isValid = false;
        } else {
            setMobileNumberError("");
        }
        if (WhatsappNumber.length !== 10) {
            setWhatsappNumberError("whatsapp number should be 10 digit");
            isValid = false;
        } else {
            setWhatsappNumberError("");
        }
        var EmailCondition = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;
        if (!Email.match(EmailCondition)) {
            setEmailError("Email is Invalid");
            isValid = false;
        } else {
            setEmailError("");
        }
        if (CurrentLocation === "") {
            setCurrentLocationError("Filed is required");
            isValid = false;
        } else {
            setCurrentLocationError("");
        }
        if (Address === "") {
            setAddressError("field is required");
            isValid = false;
        } else {
            setAddressError("");
        }

        if (isValid === true) {
            setStep(step + 1);
            setActive((prev) => ({
                ...prev,
                step5: true
            }));
        }
    };
    const step5Validation = () => {
        if (!uploadedImage) {
            setImageError('Field is required');
        }
        else {
            setImageError("");
        }
        return true;
    };

    const checkCrop = () => {
        if (!croppedImage) {
            return false;
        }
        else {
            return true;
        }
    }

    const BiodataFormSubmit = (e) => {
        e.preventDefault();
        if (step5Validation()) {
            if (checkCrop() === true) {
                formInsert();
            }
            else {
                alert('click the crop button to crop image');
            }
        }
    };
    const navigate = useNavigate();

    const customRedirect = (route, data) => {
        navigate(route, { state: data });
    };

    const formInsert = () => {
        // Loading.dots();
        Loading.dots('Bio Data is Loading...');
        const modifiedWorkDetail = WorkDetail === 'Others' ? OtherWorkDetail : WorkDetail;

        const BioDataFormData = {
            userName: userName,
            userGender: userGender,
            BirthTime: BirthHour + ':' + BirthMinute + " " + BirthAmPm,
            BirthDate: BirthDay + "-" + BirthMonth + "-" + BirthYear,
            BirthDay: BirthDay,
            BirthMonth: BirthMonth,
            BirthYear: BirthYear,
            BirthHour: BirthHour,
            BirthMinute: BirthMinute,
            BirthAmPm: BirthAmPm,
            BirthPlace: BirthPlace,
            Education: Education,
            WorkDetail: modifiedWorkDetail,
            MonthlySalary: MonthlySalary,
            Height: Height,
            weight: weight,
            Color: Color,
            Caste: Caste,
            kulam_KulaDeivam: kulam_KulaDeivam,
            fatherName: fatherName,
            MotherName: MotherName,
            FatherWorkDetails: FatherWorkDetails,
            MotherWorkDetails: MotherWorkDetails,
            Siblings: selectedSiblings,
            AssetsDetail: AssetsDetail,
            MobileNumber: MobileNumber,
            WhatsappNumber: WhatsappNumber,
            Email: Email,
            CurrentLocation: CurrentLocation,
            Address: Address,
            latitudeValue: latitudeValue,
            LongitudeValue: LongitudeValue,
            Imageurl: croppedImage,
            Timezone: Timezone
        }

        axios.post("https://ctsvsolutions.com/onepage-Api/public/api/Bio-Data-Random", BioDataFormData)
            .then((response1) => {
                //start
                axios.post("https://ctsvsolutions.com/onepage-Api/public/api/Bio-Data-Insert", BioDataFormData)
                    .then((response2) => {
                        if (response2.status === 200) {
                            // console.log(response2);
                            BioDataFormData.user_id = response2.data.user_id;
                            BioDataFormData.customer_id = response2.data.customer_id;

                            const BioUserInfo = {
                                'user_id': response2.data.user_id,
                                'customerDataId': response2.data.customer_id
                            }
                            const BioDetails = JSON.stringify(BioUserInfo);
                            localStorage.setItem('BioUserInfo', BioDetails);


                            axios.post("https://ctsvsolutions.com/onepage-Api/public/api/Bio-Data", BioDataFormData)
                                .then((response2) => {
                                    if (response2.status === 200) {
                                        Loading.remove();

                                        // console.log("Response 2:", response2.data);
                                        const value = JSON.stringify(response2.data);
                                        localStorage.setItem('BioValues', value);
                                        const formdatavalue = JSON.stringify(BioDataFormData);
                                        localStorage.setItem('Bioformdata', formdatavalue);
                                        customRedirect('/Bio-Data-View', response2.data);
                                    }
                                    else {
                                        console.error("Error in Response 2:", response2);
                                    }
                                })
                                .catch((error) => {
                                    console.error("Error in Axios POST for Marriage-Matching:", error);
                                });

                        }
                        else {
                            console.error("Error in Response 2:", response2);
                        }
                    })
                    .catch((error) => {
                        console.error("Error in Axios POST for Marriage-Matching:", error);
                    });
            })
            .catch((error) => {
                console.error("Error in Axios POST for Marriage-Matching-Random:", error);
            });
        // }
        // console.log(BioDataFormData);
    }

    useEffect(() => {
        const storedValue = localStorage.getItem('Bioformdata');

        if (storedValue) {
            const parsedValue = JSON.parse(storedValue);

            // Check if WorkDetail is in the predefined list
            const isWorkDetailValid = ['IT', 'NON-IT', 'Civil and Arch', 'Bank Sector', 'Government Job', 'Private Job', 'Sales', 'Others', 'Business', 'Foreign', 'Doctor', 'Education', 'Engineering', 'Research', 'Lawyer', 'Artist', 'Chef', 'Athlete', 'Actor/Actress', 'Journalist', 'Fashion Designer'].includes(parsedValue.WorkDetail);

            if (!isWorkDetailValid) {
                // If WorkDetail is not in the predefined list, select "Others" and set OtherWorkDetail
                setWorkDetail('Others');
                setOtherWorkDetail(parsedValue.WorkDetail);
            } else {
                // If WorkDetail is in the predefined list, set it directly
                setWorkDetail(parsedValue.WorkDetail);
                setOtherWorkDetail(''); // Clear OtherWorkDetail
            }

            // Set other values as before
            setUserName(parsedValue.userName);
            setUserGender(parsedValue.userGender);
            setBirthDay(parsedValue.BirthDay);
            setBirthMonth(parsedValue.BirthMonth);
            setBirthYear(parsedValue.BirthYear);
            setBirthHour(parsedValue.BirthHour);
            setBirthMinute(parsedValue.BirthMinute);
            setAmPm(parsedValue.BirthAmPm);
            setBirthPlace(parsedValue.BirthPlace);
            SetEducation(parsedValue.Education);
            setMonthlySalary(parsedValue.MonthlySalary);
            setHeight(parsedValue.Height);
            setWeight(parsedValue.weight);
            setColor(parsedValue.Color);
            setCaste(parsedValue.Caste);
            setKulam_KulaDeivam(parsedValue.kulam_KulaDeivam);
            setFatherName(parsedValue.fatherName);
            setMotherName(parsedValue.MotherName);
            setFatherWorkDetails(parsedValue.FatherWorkDetails);
            setMotherWorkDetails(parsedValue.MotherWorkDetails);
            setSelectedSiblings(parsedValue.Siblings);
            setAssetsDetails(parsedValue.AssetsDetail);
            setMobileNumber(parsedValue.MobileNumber);
            setWhatsappNumber(parsedValue.WhatsappNumber);
            setEmail(parsedValue.Email);
            setCurrentLocation(parsedValue.CurrentLocation);
            setAddress(parsedValue.Address);
            setLatitude(parsedValue.latitudeValue);
            setLongitude(parsedValue.LongitudeValue);
            setUploadedImage(parsedValue.Imageurl);
        }
    }, []);

    const [fileName, setFileName] = useState('');

    return (
        <>
            <section className="step-form" style={{ padding: BlogStatus ? '0px' : '' }}>
                {!BlogStatus && (
                    <div className="rd-left-icon">
                        <img src='https://ctsvsolutions.com/onepage-images/new-ui/bio-data-form-head-img.webp' alt='biodata for marriage in tamil' />
                    </div>
                )}
                <div className="heading">
                    <h2 className="sh">
                        required<span className="grt"> details</span>
                    </h2>
                </div>
                <div className="container-form">
                    <div className="bio-card">
                        <div className="form">
                            {!BlogStatus && (
                                <div className="left-side">
                                    <div className="steps-content">
                                        <h3>
                                            Step <span className="step-number">1</span>
                                        </h3>
                                    </div>
                                    <ul className="bio-progress-bar ps-0">
                                        <li className="active">
                                            <img src='https://ctsvsolutions.com/onepage-images/new-ui/bio-data-form-icon-1.webp' alt='birth details' />
                                            birth details
                                        </li>
                                        <li className={`${Active.step2 == true ? 'active' : ''}`}>
                                            <img src='https://ctsvsolutions.com/onepage-images/new-ui/bio-data-form-icon-2.webp' alt='personal details' />
                                            personal details
                                        </li>
                                        <li className={`${Active.step3 == true ? 'active' : ''}`}>
                                            <img src='https://ctsvsolutions.com/onepage-images/new-ui/bio-data-form-icon-3.webp' alt='family details' />
                                            family details
                                        </li>
                                        <li className={`${Active.step4 == true ? 'active' : ''}`}>
                                            <img src='https://ctsvsolutions.com/onepage-images/new-ui/bio-data-form-icon-4.webp' alt='contact details' />
                                            contact details
                                        </li>
                                        <li className={`${Active.step5 == true ? 'active' : ''}`}>
                                            <img src='https://ctsvsolutions.com/onepage-images/new-ui/bio-data-form-icon-5.webp' alt='photo' />
                                            photo
                                        </li>
                                    </ul>
                                </div>
                            )}

                    <div className={`right-side ${BlogStatus ? 'w-100 ps-0':'' }`} >
                                {step == 1 && (
                                    <div className="step-main active">
                                        <form className="cmn-form">
                                            <h4 className="f-heading">Enter your birth details</h4>
                                            <p className="f-sub">
                                                Provide accurate details for the most precise report.
                                            </p>
                                            <div className="form-group">
                                                <label>Full Name</label>
                                                <input className='input' placeholder='Enter Your Name' type="text" name="name" value={userName} onChange={(e) => setUserName(e.target.value)} />
                                                {userNameError && (
                                                    <span className='text-danger'>
                                                        {userNameError}
                                                    </span>
                                                )}
                                            </div>
                                            <div className="form-group">
                                                <label>Gender</label>
                                                <select

                                                    onChange={(e) => {
                                                        setUserGender(e.target.value);
                                                    }}
                                                    value={userGender}
                                                >
                                                    <option value="">Select</option>
                                                    <option value="Male">Male</option>
                                                    <option value="Female">Female</option>
                                                </select>
                                                {GenderError && (
                                                    <span className="text-danger">
                                                        {GenderError}
                                                    </span>
                                                )}

                                            </div>
                                            <div className="form-group">
                                                <label>date of birth</label>
                                                <div className="row">
                                                    <div className="form-group">
                                                        <label>date</label>
                                                        <select value={BirthDay}
                                                            onChange={(e) => {
                                                                setBirthDay(e.target.value);
                                                            }}>
                                                            <option value="">Date</option>
                                                            {dayOptions.map((day) => (
                                                                <option key={day} value={day}>
                                                                    {day}
                                                                </option>
                                                            ))}
                                                        </select>
                                                        {DayError && (
                                                            <span className="text-danger">
                                                                {DayError}
                                                            </span>
                                                        )}
                                                    </div>
                                                    <div className="form-group">
                                                        <label>month</label>
                                                        <select value={BirthMonth}
                                                            onChange={(e) => {
                                                                setBirthMonth(e.target.value);
                                                            }}>

                                                            <option value="">Month</option>
                                                            {monthOptions.map((month) => (
                                                                <option key={month} value={month}>
                                                                    {month}
                                                                </option>
                                                            ))}
                                                        </select>
                                                        {MonthError && (
                                                            <div className="text-danger">
                                                                {MonthError}
                                                            </div>
                                                        )}
                                                    </div>
                                                    <div className="form-group">
                                                        <label>year</label>
                                                        <select value={BirthYear}
                                                            onChange={(e) => {
                                                                setBirthYear(e.target.value);
                                                            }}>
                                                            <option value="">Year</option>
                                                            {yearOptions.map((year) => (
                                                                <option key={year} value={year}>
                                                                    {year}
                                                                </option>
                                                            ))}
                                                        </select>
                                                        {BirthYearError && (
                                                            <div className="text-danger">
                                                                {BirthYearError}
                                                            </div>
                                                        )}
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="form-group">
                                                <label>birth time</label>
                                                <div className="row">
                                                    <div className="form-group">
                                                        <label>hour</label>
                                                        <select value={BirthHour}
                                                            onChange={(e) => {
                                                                setBirthHour(e.target.value);
                                                            }}>
                                                            <option value=""> Hour</option>
                                                            {hourOptions.map((hour) => (
                                                                <option key={hour} value={hour}>
                                                                    {hour}
                                                                </option>
                                                            ))}
                                                        </select>
                                                        {BirthHourError && (
                                                            <div className="text-danger">
                                                                {BirthHourError}
                                                            </div>
                                                        )}
                                                    </div>
                                                    <div className="form-group">
                                                        <label>minute</label>
                                                        <select value={BirthMinute}
                                                            onChange={(e) => {
                                                                setBirthMinute(e.target.value);
                                                            }}>
                                                            <option value="">Minute</option>
                                                            {minuteOptions.map((minute) => (
                                                                <option key={minute} value={minute}>
                                                                    {minute}
                                                                </option>
                                                            ))}
                                                        </select>
                                                        {BirthMinuteError && (
                                                            <div className="text-danger">
                                                                {BirthMinuteError}
                                                            </div>
                                                        )}
                                                    </div>
                                                    <div className="form-group">
                                                        <label>am/pm</label>
                                                        <select value={BirthAmPm}
                                                            onChange={(e) => {
                                                                setAmPm(e.target.value);
                                                            }}>
                                                            <option value="">AM/PM</option>
                                                            <option value="AM">AM</option>
                                                            <option value="PM">PM</option>
                                                        </select>
                                                        {AmPmError && (
                                                            <span className="text-danger">
                                                                {AmPmError}
                                                            </span>
                                                        )}
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="form-group">
                                                <label>birth place</label>
                                                <input className='input' placeholder='enter the Birth place' type="text" name="" value={BirthPlace}
                                                    onChange={handleLocationChange} />
                                                <input
                                                    id="atflg"
                                                    className="pfipbx"
                                                    name="the_lng"
                                                    type="hidden"
                                                    value={LongitudeValue}
                                                    placeholder="Longitude"
                                                />
                                                <input
                                                    id="atflt"
                                                    className="pfipbx"
                                                    name="cf_1164"
                                                    type="hidden"
                                                    value={latitudeValue}
                                                    placeholder="latitude"
                                                />
                                                {BirthPlaceError && (
                                                    <div className="text-danger">
                                                        {BirthPlaceError}
                                                    </div>
                                                )}
                                                {suggestedPlaces && (
                                                    <ul className="p-0 mt-1 mb-0 overflow-hidden">
                                                        {suggestedPlaces.reverse().map((place, index) => {
                                                            if (place.districts) {
                                                                const listItemStyle = {
                                                                    padding: "5px 12px",
                                                                    cursor: "pointer",
                                                                    marginBottom: "1px",
                                                                    backgroundColor: "#f2f2f2",
                                                                    fontSize: "14px",
                                                                    fontWeight: "540",
                                                                    borderBottom: "1px dashed #ddd",
                                                                };

                                                                return (
                                                                    <React.Fragment key={index}>
                                                                        <p
                                                                            style={listItemStyle}
                                                                            className="px-2"
                                                                            onClick={() => handleSuggestionSelect(place)}
                                                                        >
                                                                            <FontAwesomeIcon icon={faLocationDot} />
                                                                            &nbsp;
                                                                            {place.districts}
                                                                        </p>
                                                                    </React.Fragment>
                                                                );
                                                            }
                                                            return null;
                                                        })}

                                                    </ul>
                                                )}
                                                {SwitchLocationButton && (
                                                    <p
                                                        className="w-100 text-start btn py-0 rounded-0 text-white fw-bold mb-0 mt-0"
                                                        style={{ fontSize: "14px", backgroundColor: "#fe1b57" }}
                                                        onClick={SearchNewLocation}
                                                    >
                                                        {" "}
                                                        <FontAwesomeIcon icon={faLocationDot} /> my place is not
                                                        in List
                                                    </p>
                                                )}
                                            </div>
                                        </form>
                                        <div className="buttons">
                                            <button className="next_button btn-lg" onClick={Step1Validation}>
                                                Next
                                                <img src='https://ctsvsolutions.com/onepage-images/new-ui/btn-arrow-white.webp' alt='bio data arrow' />
                                            </button>
                                        </div>
                                        <div className="mt-3">
                                            <p className="f-sub text-danger text-center mb-0">
                                                * This bio-data is generating in Tamil.
                                            </p>
                                        </div>
                                    </div>
                                )
                                }
                                {step == 2 && (
                                    <div className="step-main">
                                        <form className="cmn-form">
                                            <h4 className="f-heading">Enter your personal details</h4>
                                            <p className="f-sub">
                                                Provide accurate details for the most precise report.
                                            </p>
                                            <div className="form-group">
                                                <div className="row">
                                                    <div className="col-md-6 p-0">
                                                        <div className="form-group">
                                                            <label>Education</label>
                                                            <input className='input' placeholder='Enther Education' type="text" value={Education}
                                                                onChange={(e) => {
                                                                    SetEducation(e.target.value);
                                                                }} />
                                                            {EducationError && (
                                                                <span className="text-danger">
                                                                    {EducationError}
                                                                </span>
                                                            )}
                                                        </div>
                                                    </div>
                                                    <div className="col-md-6 p-0">
                                                        <div className="form-group">
                                                            <label>Job description</label>
                                                            <select value={WorkDetail}

                                                                onChange={(e) => {
                                                                    setWorkDetail(e.target.value);
                                                                }}>
                                                                <option value="">Select</option>
                                                                <option value="IT">IT</option>
                                                                <option value="NON-IT">NON-IT</option>
                                                                <option value="Civil and Arch">
                                                                    Civil and Arch
                                                                </option>
                                                                <option value="Bank Sector">Bank Sector</option>
                                                                <option value="Government Job">
                                                                    Government Job
                                                                </option>
                                                                <option value="Private Job">Private Job</option>
                                                                <option value="Sales">Sales</option>
                                                                <option value="Others">Others</option>
                                                                <option value="Business">Business</option>
                                                                <option value="Foreign">Foreign</option>
                                                                <option value="Doctor">Doctor</option>
                                                                <option value="Education">Education</option>
                                                                <option value="Engineering">Engineering</option>
                                                                <option value="Research">Research</option>
                                                                <option value="Lawyer">Lawyer</option>
                                                                <option value="Artist">Artist</option>
                                                                <option value="Chef">Chef</option>
                                                                <option value="Athlete">Athlete</option>
                                                                <option value="Actor/Actress">Actor/Actress</option>
                                                                <option value="Journalist">Journalist</option>
                                                                <option value="Fashion Designer">
                                                                    Fashion Designer
                                                                </option>
                                                            </select>
                                                            {WorkDetailError && (
                                                                <span className="text-danger">
                                                                    {WorkDetailError}
                                                                </span>
                                                            )}
                                                        </div>

                                                    </div>
                                                </div>
                                            </div>
                                            <div className='form-group'>
                                                {WorkDetail === "Others" && (
                                                    <>
                                                        <label
                                                            htmlFor="work_detail"
                                                            className="form-label"

                                                        >
                                                            Job description:
                                                        </label>
                                                        <input
                                                            type="text"
                                                            id="work_detail"
                                                            maxLength="30"
                                                            value={OtherWorkDetail} // Change this line
                                                            onChange={(e) => setOtherWorkDetail(e.target.value)}
                                                            className={'input'}

                                                        />
                                                    </>

                                                )}
                                            </div>
                                            <div className="form-group">
                                                <label>Monthly Income</label>
                                                <input className='input' type="text" name="" placeholder='Enther Your Monthly Income' value={MonthlySalary}
                                                    onChange={(e) => {
                                                        setMonthlySalary(e.target.value);
                                                    }} />
                                                {MonthlySalaryError && (
                                                    <span className="text-danger">
                                                        {MonthlySalaryError}
                                                    </span>
                                                )}
                                            </div>
                                            <div className="form-group">
                                                <div className="row">
                                                    <div className="form-group">
                                                        <label>Height</label>
                                                        <select value={Height}
                                                            onChange={(e) => {
                                                                setHeight(e.target.value);
                                                            }}>
                                                            <option value="">Select</option>
                                                            {heightOptions.map((option) => (
                                                                <option key={option} value={option}>
                                                                    {option}
                                                                </option>
                                                            ))}
                                                        </select>
                                                        {HeightError && (
                                                            <span className="text-danger">
                                                                {HeightError}
                                                            </span>
                                                        )}
                                                    </div>
                                                    <div className="form-group">
                                                        <label>Weight</label>
                                                        <select value={weight}
                                                            onChange={(e) => {
                                                                setWeight(e.target.value);
                                                            }}>
                                                            <option value="">Select</option>
                                                            {weightOptions.map((option) => (
                                                                <option key={option} value={option}>
                                                                    {option}
                                                                </option>
                                                            ))}
                                                        </select>
                                                        {WeightError && (
                                                            <span className="text-danger">
                                                                {WeightError}
                                                            </span>
                                                        )}
                                                    </div>
                                                    <div className="form-group">
                                                        <label>Colour</label>
                                                        <select value={Color}
                                                            onChange={(e) => {
                                                                setColor(e.target.value);
                                                            }}>
                                                            <option value="">Select</option>
                                                            <option value="சிவப்பு">சிவப்பு </option>
                                                            <option value="மாநிறம்">மாநிறம் </option>
                                                            <option value="மாநிறத்தை விட குறைவு">
                                                                மாநிறத்தை விட குறைவு
                                                            </option>
                                                        </select>
                                                        {ColorError && (
                                                            <span className="text-danger">
                                                                {ColorError}
                                                            </span>
                                                        )}
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="form-group">
                                                <div className="row">
                                                    <div className="col-md-6 p-0">
                                                        <div className="form-group">
                                                            <label>Caste</label>
                                                            <input className='input' type="text" placeholder='Enther Your Caste' name="" value={Caste}
                                                                onChange={(e) => {
                                                                    setCaste(e.target.value);
                                                                }} />
                                                            {CasteError && (
                                                                <span className="text-danger">
                                                                    {CasteError}
                                                                </span>
                                                            )}
                                                        </div>
                                                    </div>
                                                    <div className="col-md-6 p-0">
                                                        <div className="form-group">
                                                            <label>Clan/Gothram/House</label>
                                                            <input className='input' type="text" placeholder='Enter Clan/Gothram/House' name="" value={kulam_KulaDeivam}
                                                                onChange={(e) => {
                                                                    setKulam_KulaDeivam(e.target.value);
                                                                }} />
                                                            {Kulam_KulaDeivamError && (
                                                                <div className="text-danger">
                                                                    {Kulam_KulaDeivamError}
                                                                </div>
                                                            )}
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </form>
                                        <div className="buttons button_space">
                                            <button className="back_button" onClick={preStep}>Back</button>
                                            <button className="next_button" onClick={step2Validation}>Next Step</button>
                                        </div>
                                    </div>
                                )
                                }
                                {step == 3 && (
                                    <div className="step-main">
                                        <form className="cmn-form">
                                            <h4 className="f-heading">Enter your family details</h4>
                                            <p className="f-sub">
                                                Provide accurate details for the most precise report.
                                            </p>
                                            <div className="form-group">
                                                <div className="row">
                                                    <div className="col-md-6 p-0">
                                                        <div className="form-group">
                                                            <label>Father Name</label>
                                                            <input className='input' type="text" placeholder='Enter Father Name' name="" value={fatherName}
                                                                onChange={(e) => {
                                                                    setFatherName(e.target.value);
                                                                }}
                                                            />
                                                            {FatherNameError && (
                                                                <div className="text-danger">
                                                                    {FatherNameError}
                                                                </div>
                                                            )}
                                                        </div>
                                                    </div>
                                                    <div className="col-md-6 p-0">
                                                        <div className="form-group">
                                                            <label>Mother Name</label>
                                                            <input className='input' type="text" placeholder='Enter Mother Name' value={MotherName}
                                                                onChange={(e) => {
                                                                    setMotherName(e.target.value);
                                                                }} />
                                                            {MotherNameError && (
                                                                <div className="text-danger">
                                                                    {MotherNameError}
                                                                </div>
                                                            )}
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="form-group">
                                                <div className="row">
                                                    <div className="col-md-6 p-0">
                                                        <div className="form-group">
                                                            <label>Father Job Description</label>
                                                            <input className='input' type="text" placeholder='Enter Father Job Description' name="" value={FatherWorkDetails}
                                                                onChange={(e) => {
                                                                    setFatherWorkDetails(e.target.value);
                                                                }} />
                                                            {FatherWorkDetailsError && (
                                                                <div className="text-danger">
                                                                    {FatherWorkDetailsError}
                                                                </div>
                                                            )}
                                                        </div>
                                                    </div>
                                                    <div className="col-md-6 p-0">
                                                        <div className="form-group">
                                                            <label>Mother Job Description</label>
                                                            <input className='input' type="text" placeholder='Enter Mother Job Description' name="" value={MotherWorkDetails}
                                                                onChange={(e) => {
                                                                    setMotherWorkDetails(e.target.value);
                                                                }} />
                                                            {MotherWorkDetailsError && (
                                                                <div className="text-danger">
                                                                    {MotherWorkDetailsError}
                                                                </div>
                                                            )}
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="form-group">
                                                <label>Siblings</label>
                                                <Select
                                                    options={siblingOptions}
                                                    isMulti
                                                    isSearchable
                                                    noOptionsMessage={() => "Nodata found"}
                                                    value={selectedSiblings}
                                                    onChange={handleSiblingsChange}
                                                    maxMenuHeight={6 * 30}
                                                    id="siblings"

                                                />
                                                {SiblingError && (
                                                    <div className="text-danger">
                                                        {SiblingError}
                                                    </div>
                                                )}
                                            </div>
                                            <div className="form-group">
                                                <label>Property Details</label>
                                                <textarea className='textarea' name="" id="" placeholder='Enter Your Property Details' rows={3} value={AssetsDetail} onChange={(e) => {
                                                    setAssetsDetails(e.target.value);
                                                }}></textarea>
                                                {AssetsError && (
                                                    <span className="text-danger">
                                                        {AssetsError}
                                                    </span>
                                                )}
                                            </div>
                                        </form>
                                        <div className="buttons button_space">
                                            <button className="back_button" onClick={preStep}>Back</button>
                                            <button className="next_button" onClick={step3Validation}>Next Step</button>
                                        </div>
                                    </div>
                                )}
                                {step == 4 && (
                                    <div className="step-main">
                                        <form className="cmn-form">
                                            <h4 className="f-heading">Enter your contact details</h4>
                                            <p className="f-sub">
                                                Provide accurate details for the most precise report.
                                            </p>
                                            <div className="form-group">
                                                <div className="row">
                                                    <div className="col-md-6 p-0">
                                                        <div className="form-group">
                                                            <label>Phone Number</label>
                                                            <input className='input' type="text" placeholder='Enter Phone Number' name="" value={MobileNumber}
                                                                onChange={(e) => {
                                                                    setMobileNumber(e.target.value);
                                                                }} />
                                                            {MobileNumberError && (
                                                                <div className="text-danger">
                                                                    {MobileNumberError}
                                                                </div>
                                                            )}
                                                        </div>
                                                    </div>
                                                    <div className="col-md-6 p-0">
                                                        <div className="form-group">
                                                            <label>Whatsapp Number</label>
                                                            <input className='input' type="text" placeholder='Enter Whatsapp Number' name="" value={WhatsappNumber}
                                                                onChange={(e) => {
                                                                    setWhatsappNumber(e.target.value);
                                                                }} />
                                                            {WhatsappNumberError && (
                                                                <div className="text-danger">
                                                                    {WhatsappNumberError}
                                                                </div>
                                                            )}
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="form-group">
                                                <label>E-mail</label>
                                                <input className='input' type="text" name="" placeholder='Enter Your E-Mail' value={Email}
                                                    onChange={(e) => {
                                                        setEmail(e.target.value);
                                                    }} />
                                                {EmailError && (
                                                    <span className="text-danger">
                                                        {EmailError}
                                                    </span>
                                                )}
                                            </div>
                                            <div className="form-group">
                                                <label>Location</label>
                                                <input className='input' type="text" placeholder='Enter Your Location' name="" value={CurrentLocation}
                                                    onChange={(e) => {
                                                        setCurrentLocation(e.target.value);
                                                    }} />
                                                {CurrentLocationError && (
                                                    <span className="text-danger">
                                                        {CurrentLocationError}
                                                    </span>
                                                )}
                                            </div>
                                            <div className="form-group">
                                                <label>Address</label>
                                                <textarea className='textarea' name="" placeholder='Enter Your Address' id="" rows={3} value={Address}
                                                    onChange={(e) => {
                                                        setAddress(e.target.value);
                                                    }}></textarea>
                                                {AddressError && (
                                                    <span className="text-danger">
                                                        {AddressError}
                                                    </span>
                                                )}
                                            </div>
                                        </form>
                                        <div className="buttons button_space">
                                            <button className="back_button" onClick={preStep}>Back</button>
                                            <button className="next_button" onClick={step4Validation}>Next Step</button>
                                        </div>
                                    </div>
                                )}
                                {step == 5 && (
                                    <div className="step-main">
                                        <form className="cmn-form">
                                            <h4 className="f-heading">Enter your Photo</h4>
                                            <p className="f-sub">
                                                Provide accurate details for the most precise report.
                                            </p><div className="form-group">
                                                <div ref={croppieRef}></div>
                                                <input
                                                    type="file"
                                                    name="file"
                                                    id="file"
                                                    accept="image/*"
                                                    className="input-file"
                                                    ref={fileInputRef}
                                                    onChange={handleFileUpload}
                                                />
                                                <label
                                                    htmlFor="file"
                                                    className={`btn btn-tertiary js-labelFile ${fileName ? 'has-file' : ''}`}
                                                >
                                                    <FontAwesomeIcon
                                                        icon={fileName ? faCheck : faDownload}
                                                        className={`me-2 ${fileName ? 'text-success' : ''}`}
                                                    />
                                                    <span className="js-fileName">{fileName || 'Upload a file'}</span>
                                                </label>
                                                <div className='text-center mt-3'>
                                                    <button type="button" className="action-button-previous btn btn-success" onClick={(e) => { handleCrop() }}>Crop</button>
                                                </div>
                                            </div>
                                            {/* <div className="form-group">
                                                <div ref={croppieRef}></div>
                                                <input
                                                    type="file"
                                                    id="files"
                                                    accept="image/*"
                                                    className={`form-control ${ImageError && "is-invalid"
                                                        }`}
                                                    ref={fileInputRef}
                                                    onChange={handleFileUpload}
                                                />
                                                {ImageError && (
                                                    <div className="invalid-feedback fw-bold">
                                                        {ImageError}
                                                    </div>
                                                )}
                                               
                                                <button type="button" className="action-button-previous" onClick={(e) => { handleCrop() }}>Crop</button>
                                            </div> */}
                                        </form>
                                        <div className="buttons button_space">
                                            <button className="back_button" onClick={preStep}>Back</button>
                                            <button className="submit_button" onClick={BiodataFormSubmit}>Submit</button>
                                        </div>
                                        <div className="mt-3">
                                            <p className="f-sub text-danger text-center mb-0">
                                                * This bio-data report is generating in Tamil.
                                            </p>
                                        </div>
                                    </div>
                                )}

                                <div className="main">
                                    <svg
                                        className="checkmark"
                                        xmlns="http://www.w3.org/2000/svg"
                                        viewBox="0 0 52 52"
                                    >
                                        <circle
                                            className="checkmark__circle"
                                            cx={26}
                                            cy={26}
                                            r={25}
                                            fill="none"
                                        />
                                        <path
                                            className="checkmark__check"
                                            fill="none"
                                            d="M14.1 27.2l7.1 7.2 16.7-16.8"
                                        />
                                    </svg>
                                    <div className="text congrats">
                                        <h2>Congratulations!</h2>
                                        <p>
                                            Thanks Mr./Mrs. <span className="shown_name" /> your information
                                            have been submitted successfully for the future reference we
                                            will contact you soon.
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}
