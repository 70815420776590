import React from 'react'
import Menubar from '../../Main-Components/Menubar/Menubar'
import Footer from '../../Main-Components/Footer/Footer'
import ErrorContent from '../../Small-Components/ErrorContent/ErrorContent'
import TopNavbar from '../../Main-Components/TopNavbar/TopNavbar'
function Error404() {
 
  
  return (
    <>
     <TopNavbar />
     <Menubar NavBgstyle="outer-header l-pnk" NavTextColor="text-dark" SubmenuTop="true" />
     <ErrorContent/>
      <Footer />
    </>
  )

}

export default Error404;

