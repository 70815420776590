import React from 'react'
// import BabyBannerImage from '../../images/pbn-right-illus.webp'
// import BabyBannerMobileImage from '../../images/babyname-mobile-img.webp'

export default function BabyNameListBanner() {
    return (
        <>
            <section className="page-outer mb pbn">
                <div className="astro-container">
                    <div className="row">
                        <div className="col">
                            <div className="content">
                                <h1 className="sh lh-1">
                                Numerology <span className="grt"> <br/>baby names</span>
                                </h1>
                                <p className="p-outer">
                                    After the birth of the child, it is the wish of the parents to study
                                    that which name will make the future of the child better and more
                                    fortunate.
                                </p>
                                <p className="p-outer">
                                    Nakshatra: Shastra, Patara, Sadhu Nakshatras respectively
                                    Punarbhusa, Swathi, Thiruvonam, Avitam, Sadayam, Rohini, Uthiram,
                                    Uthiradam, Uthiratathi, Asuvini, Pusam, Astam, Mrigasiridam,
                                    Chitrai, Anusha or Revathi are best.
                                </p>
                                <div className="mb-right-illus">
                                    <img src='https://ctsvsolutions.com/onepage-images/new-ui/pbn-right-illus.webp' alt='tamil baby name'/>
                                </div>
                                <div className="pbn-mobile-illus">
                                    <img src='https://ctsvsolutions.com/onepage-images/new-ui/babyname-mobile-img.webp' alt='numerolgy name'/>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}
