import React from 'react'
import TopNavbar from '../../Main-Components/TopNavbar/TopNavbar'
import Menubar from '../../Main-Components/Menubar/Menubar'
import Footer from '../../Main-Components/Footer/Footer'
import BabyNamePaymentSuccessBanner from '../../Small-Components/BabyNamePaymentSuccessBanner/BabyNamePaymentSuccessBanner'
import BabyNamePaymentSuccessContent from '../../Small-Components/BabyNamePaymentSuccessContent/BabyNamePaymentSuccessContent'

export default function BabyNamePaymentSuccess() {
  return (
    <>
      <TopNavbar />
      <Menubar NavBgstyle="outer-header bc-outer" NavTextColor="text-dark" SubmenuTop="true" />
      <BabyNamePaymentSuccessBanner/>
      <BabyNamePaymentSuccessContent/>
      <Footer />
    </>
  )
}
