import React from 'react'
import TopNavbar from '../../Main-Components/TopNavbar/TopNavbar'
import Menubar from '../../Main-Components/Menubar/Menubar'
import Footer from '../../Main-Components/Footer/Footer'
import RuthuJathagaPaymentSuccessBanner from '../../Small-Components/RuthuJathagaPaymentSuccessBanner/RuthuJathagaPaymentSuccessBanner'
import RuthuJathagaPaymentSuccessContent from '../../Small-Components/RuthuJathagaPaymentSuccessContent/RuthuJathagaPaymentSuccessContent'

export default function RuthuJathagaPaymentSuccess() {
    return (
        <>
            <TopNavbar />
            <Menubar NavBgstyle="outer-header bc-outer" NavTextColor="text-dark" SubmenuTop="true" />
            <RuthuJathagaPaymentSuccessBanner />
            <RuthuJathagaPaymentSuccessContent />
            <Footer />
        </>
    )
}
