
function RuthuFormReducer(state, action) {
    switch (action.type) {
        case 'ADD_TO_STATE':
            return { ...state, [action.field]: action.payload };
        default:
            return state;
    }
}

const Year = () => {
    const currentYear = new Date().getFullYear();
    const endYear = 1951;
    return Array.from(
        { length: currentYear - endYear + 1 },
        (_, index) => currentYear - index
    );
}



export const RuthuInitialValues = {
    'dayOptions': [...Array(31).keys()].map((day) => String(day + 1).padStart(2, "0")),
    'monthOptions': [...Array(12).keys()].map((month) => String(month + 1).padStart(2, "0")),
    'yearOptions': Year(),
    'hourOptions': [...Array(12).keys()].map((hour) => String(hour + 1).padStart(2, "0")),
    'minuteOptions': [...Array(60).keys()].map((minute) => String(minute).padStart(2, "0")),
    'switch_location': false,
    'birthPlace': '',
    'userName': '',
    'suggestedPlaces': [],
    'selectedPlaces': [],
    'selectedDay': '',
    'selectedMonth': '',
    'selectedYear': '',
    'selectedHour': '',
    'selectedMinute': '',
    'selectedAmpm': '',
    'nameError': '',
    'birthplaceError': '',
    'AmpmError': '',
    'birthdateError': '',
    'birthMonthError': '',
    'birthYearError': '',
    'birthHourError': '',
    'birthMinuteError': '',
    'FatherName': '',
    'MotherName': '',
    'FatherNameError': '',
    'MotherNameError': '',
    'latitude': '',
    'longitude': '',
    'TimeZone': ''

};
export default RuthuFormReducer