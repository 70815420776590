import React, { useState, useEffect } from "react";
import Input from "../components/input";
import { Notify } from 'notiflix/build/notiflix-notify-aio';
import axios from "axios";
import { Loading } from 'notiflix/build/notiflix-loading-aio';

export default function ReportSearch({ SetPackage, setUserData }) {
  const [user_id, setUserId] = useState('');
  const [Duration, setDuration] = useState('');
  const [BiodataID, setBiodataId] = useState('');
  const[Template,setTemplate]=useState('');
  const [showCustomDate, setShowCustomDate] = useState(false);
  const [CusomerDataId, setCusomerDataId] = useState('');
  function HanleChangeDate(event) {
    setDuration(event.target.value);
  }
  useEffect(() => {
    if (Duration === '2') {
      setShowCustomDate(true);
    } else {
      setShowCustomDate(false);
    }
  }, [Duration]);
  // console.log(Duration);

  const HandleSearch = () => {

    if (!CusomerDataId) {
      Notify.failure('Customer Data Id Required');
    } else {
      Loading.pulse('Loading....');
      axios.post(
        'https://ctsvsolutions.com/onepage-Api/public/api/IndidualSearch',
        {
          userInput: CusomerDataId
        },
      )
        .then(response => {
          if (response.status === 200) {
            SetPackage(response.data.message[0]);
            setUserData(response.data.data[0]);
            Loading.remove();

          } else {
            Notify.failure('Data Unavailable');
            Loading.remove();
          }
        })
        .catch(error => {
          console.error(error);
          Notify.failure('No data found,....old Data');
          Loading.remove();
        });
    }
  };
  const HandleBioSearch = () => {
    if ((!BiodataID) ||(!Template) ) {
      Notify.failure('Choose the BioData Template');
    } else {
      Loading.pulse('Loading....');
      axios.post(
        'https://ctsvsolutions.com/onepage-Api/public/api/Admin-BioData-Search',
        {
          userInput: BiodataID,
          Template:Template
        },
      )
        .then(response => {
          if (response.data.status ==true) {
            SetPackage(response.data.message[0]);
            setUserData(response.data.data);
            Loading.remove();

          } else {
            Notify.failure('Data Unavailable');
            Loading.remove();
          }
        })
        .catch(error => {
          console.error(error);
          Notify.failure('No data found,....old Data');
          Loading.remove();
        });
    }
  }


  return (
    <>
      <form action="">
        <div className="row">
          <div className="col-md-6">
            <div className="">
              <label
                htmlFor="exampleFormControlInput1"
                className="form-label fs-6 fw-bold"
              >
                Bio Data Data Id:
              </label>
              <Input
                type="text"
                className="form-control border-success p-2"
                name="BiodateId"
                id="BiodateId"
                UserInput={(e) => setBiodataId(e.target.value)}
                value={BiodataID}
                placeholder="EnterBioData Id"
              />
            </div>
          </div>
          <div className="col-md-6">
            <div className="row">
            <label
                htmlFor="exampleFormControlInput1"
                className="form-label fs-6 fw-bold mt-2"
              >
                Template
              </label>
              <div className="col-12 col-md-6 mt-2">
                      <select name="" id="" value={Template} onChange={(e)=>setTemplate(e.target.value)} className="form-select">
                        <option value="">Choose Template</option>
                        <option value="BiosampleTemplate1">Template1</option>
                        <option value="BiosampleTemplate2">Template2</option>
                        <option value="BiosampleTemplate3">Template3</option>
                        <option value="BiosampleTemplate4">Template4</option>
                        <option value="BiosampleTemplate5">Template5</option>
                        <option value="BiosamleTemplate6">Template6</option>

                      </select>
              </div>
              <div className="col-12 col-md-6 mt-2">
              <button type="button" onClick={HandleBioSearch} className="btn btn-success align-middle  w-100">Search</button>
              </div>
            </div>
           

            {/* <label
                    htmlFor="exampleFormControlInput1"
                    className="form-label fs-6 fw-bold"
                  >
                    Select:
                  </label>
                  <select
                    name="duration"
                    className="form-select bordersuccess p-2 rounded-3"
                    id="custom"
                    aria-label="Default select example"
                    onChange={HanleChangeDate}
                    // onChange={(e)=>setDateFillter(e.target.value)}
                  >
                    <option value="">select</option>
                    <option value="1">Today</option>
                    <option value="-1">Yesterday</option>
                    <option value="7">7 Days</option>
                    <option value="30">30 Days</option>
                    <option value="2">Custom</option>
                  </select> */}
          </div>
        </div>
        <hr />
        
        {showCustomDate && (
          <div className="col-md-12" id="shopType">
            <div className="row">
              <div className="col-md-6">
                <label
                  htmlFor="exampleFormControlInput1"
                  className="form-label fs-6 fw-bold"
                >
                  From Date:
                </label>
                <Input
                  type="date"

                  className="form-control border-1 border-primary"
                  name="f_create_date"
                  value=""
                  id="f_create_date"
                  placeholder="name@example.com"
                />
              </div>
              <div className="col-md-6">
                <label
                  htmlFor="exampleFormControlInput1"
                  className="form-label fs-6 fw-bold"
                >
                  To Date:
                </label>
                <Input
                  type="date"
                  value=""
                  className="form-control border-1 border-primary"
                  name="t_create_date"
                  id="t_create_date"
                  placeholder="name@example.com"
                />
              </div>
            </div>
          </div>
        )}
        <div className="row">
          <div className="col-md-6 col-12">
            <Input
              type="number"
              className="form-control border-success mt-3"
              name="user_id"
              id="customer_data_is"
              value={CusomerDataId}
              UserInput={(e) => setCusomerDataId(e.target.value)}
              placeholder="Enter CustomerData Id"
            />
          </div>
          <div className="col-12 col-md-6">
            <button
              className="btn btn-danger fw-bold my-3 px-5 "
              name="search_profile"
              type="button"
              onClick={HandleSearch}
            >
              Search
            </button>
          </div>
        </div>

      </form>
    </>
  )
}