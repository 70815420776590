import React, { createContext, useState } from 'react';

export const BirthHoroscopeContext = createContext();

function BirthHoroscope_Home_ContextProvider({ children }) {
    
  const [dayOptions] = useState(
    [...Array(31).keys()].map((day) => String(day + 1).padStart(2, '0'))
  );
  const [monthOptions] = useState(
    [...Array(12).keys()].map((month) => String(month + 1).padStart(2, '0'))
  );
  const [yearOptions] = useState(() => {
    const currentYear = new Date().getFullYear();
    const startYear = 1950;
    return [...Array(currentYear - startYear + 1).keys()].map(
      (year) => startYear + year
    );
  });
  const [hourOptions] = useState(
    [...Array(12).keys()].map((hour) => String(hour + 1).padStart(2, '0'))
  );
  const [minuteOptions] = useState(
    [...Array(60).keys()].map((minute) => String(minute).padStart(2, '0'))
  );
  const [birthPlace, setBirthPlace] = useState('');
  const [userName, setUserName] = useState('');
  const [userGender, setUserGender] = useState('');
  const [suggestedPlaces, setSuggestedPlaces] = useState('');
  const [selectedPlaces, setSelectedPlaces] = useState([]);
  const [selectedDay, setSelectedDay] = useState('');
  const [selectedMonth, setSelectedMonth] = useState('');
  const [selectedYear, setSelectedYear] = useState('');
  const [selectedHour, setSelectedHour] = useState('');
  const [selectedMinute, setSelectedMinute] = useState('');
  const [selectedAmpm, setSelectedAmpm] = useState('');
  const [nameError, setNameError] = useState('');
  const [genderError, setGenderError] = useState('');
  const [birthplaceError, setBirthplaceError] = useState('');
  const [birthdateError, setBirthdateError] = useState('');
  const [birthMonthError, setBirthMonthError] = useState('');
  const [birthYearError, setBirthYearError] = useState('');
  const [birthHourError, setBirthHourError] = useState('');
  const [birthMinuteError, setBirthMinuteError] = useState('');
  const [birthAmpmError, setBirthAmpmError] = useState('');
  const [SwitchLocationButton, SetSwitchLocationButton] = useState(false);
  const [Timezone, setTimeZone] = useState('');

  const contextValue = {
    dayOptions,
    monthOptions,
    yearOptions,
    hourOptions,
    minuteOptions,
    birthPlace,
    setBirthPlace,
    userName,
    setUserName,
    userGender,
    setUserGender,
    suggestedPlaces,
    setSuggestedPlaces,
    selectedPlaces,
    setSelectedPlaces,
    selectedDay,
    setSelectedDay,
    selectedMonth,
    setSelectedMonth,
    selectedYear,
    setSelectedYear,
    selectedHour,
    setSelectedHour,
    selectedMinute,
    setSelectedMinute,
    selectedAmpm,
    setSelectedAmpm,
    nameError,
    setNameError,
    genderError,
    setGenderError,
    birthplaceError,
    setBirthplaceError,
    birthdateError,
    setBirthdateError,
    birthMonthError,
    setBirthMonthError,
    birthYearError,
    setBirthYearError,
    birthHourError,
    setBirthHourError,
    birthMinuteError,
    setBirthMinuteError,
    birthAmpmError,
    setBirthAmpmError,
    SwitchLocationButton,
    SetSwitchLocationButton,
    Timezone,
    setTimeZone,
  };

  return (
    <BirthHoroscopeContext.Provider value={contextValue}>
      {children}
    </BirthHoroscopeContext.Provider>
  );
}

export default BirthHoroscope_Home_ContextProvider;
