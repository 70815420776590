import React from "react";

export default function Input(props) {
  return (
    <>
      <input
        type={props.type}
        id="username"
        className={props.className}
        value={props.value || ''} 
        onChange={props.UserInput}
        placeholder={props.placeholder}
        style={props.style}
      />
    </>
  );
}
