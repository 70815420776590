
import Menubar from '../header/Menubar';
import Footer from '../footer/Footer';
import style from "./MarriageMatchingFreeprediction.module.css";
import React, { useState,useEffect } from "react";
import axios from "axios";
import { Confirm } from 'notiflix/build/notiflix-confirm-aio';
import getepayPortal from "../Getepay_pg_react";
import { useParams } from 'react-router-dom';
import { Notify } from 'notiflix/build/notiflix-notify-aio';
import { Loading } from 'notiflix/build/notiflix-loading-aio';
function MarriageFreePrediction() {
  return (
    <div>
      <Menubar />
      <MarriageFreePredictionForm />
      <Footer />
    </div>
  )
}

const MarriageFreePredictionForm = () => {

    const currentYear = new Date().getFullYear();
    const years = Array.from(new Array(currentYear - 1950), (_, index) => currentYear - index);
    const hours = Array.from(new Array(12), (val, index) => index + 1);
    const minutes = Array.from(new Array(60), (val, index) =>
      index < 10 ? `0${index}` : index
    );
    const [dobDay, setDobDay] = useState("");
    const [dobMonth, setDobMonth] = useState("");
    const [gender, setGender] = useState("");
    const [dobYear, setDobYear] = useState("");
    const [birthHour, setBirthHour] = useState("");
    const [birthMinute, setBirthMinute] = useState("");
    const [amPm, setAmPm] = useState("AM");
    const [Name, setName] = useState("");
    const [askToAstrologer, setAskToAstrologer] = useState([
      "உங்களது திருமண வாழ்வு"
    ]);

    const [birthPlace, setBirthPlace] = useState("");
    const [suggestedPlaces, setSuggestedPlaces] = useState([]);
    const [selectedPlaces, setSelectedPlaces] = useState([]);
    const [Email, setEmail] = useState("");
    const [Mobile_number, setMobileNumber] = useState("");
    const [Latitude, setLatitude] = useState("");
    const [Longitude, setLongitude] = useState("");
    const [NameError, setNameError] = useState("");
    const [genderError, setGenderError] = useState("");
    const [DobError, setDobError] = useState("");
    const [BirthTimeError, setBirthTimeError] = useState("");
    // const [QuestionError, setQuestionError] = useState("");
    const [BirthPlaceError, setBirthPlaceError] = useState("");
    const [MobileNumberError, setMobileNumberError] = useState("");
    const [EmailError, setEmailError] = useState("");
    const [paymentButton,setPaymentButton]=useState("");
   const[freeButton,setFreeButton]=useState('');
   const [offerStatus,setOfferStatus]=useState(false);
    const { user_id,mobile_number } = useParams();
    useEffect(() => {
        
       const  user_info={
        user_id:user_id,
        mobile_number:mobile_number
       }
      axios.post('https://ctsvsolutions.com/onepage-Api/public/api/check-Eligible-Marriage-free-prediction',user_info)
         .then(response=>{
            const ApiResponse=response.data.status;
            if(ApiResponse==true){
                setOfferStatus(true)
                setEmail(response.data.Email);
                setPaymentButton(false);
                setMobileNumber(response.data.user_mobile_number);
                setFreeButton(true);
            }else if(ApiResponse==false){
                setPaymentButton(true);
                setFreeButton(false);
                setEmail(response.data.Email);
                setMobileNumber(response.data.user_mobile_number);
            }else{
                window.location="/Jathaga-Palan";
            }
         
      })
    },[])

    useEffect(() => {
        console.log(askToAstrologer.length); 
        const FreeQuestion=askToAstrologer.length;
        if(FreeQuestion>1){
            setFreeButton(false);
            setPaymentButton(true);
            
        }else if(FreeQuestion==1){
            if(offerStatus==true){
                setPaymentButton(false);
                setFreeButton(true);
            }
           
           
        }
    }, [askToAstrologer]);
    
    const handleCheckboxChange = (event) => {
   
      const { value, checked } = event.target;
      if (checked) {
        setAskToAstrologer([...askToAstrologer, value]);
      } else {
        setAskToAstrologer(askToAstrologer.filter((item) => item !== value));
      }
    };
    const handleDobDayChange = (e) => {
      setDobDay(e.target.value);
    };
  
    const handleDobMonthChange = (e) => {
      setDobMonth(e.target.value);
    };
  
    const handleDobYearChange = (e) => {
      setDobYear(e.target.value);
    };
  
    const handleBirthHourChange = (e) => {
      setBirthHour(e.target.value);
    };
  
    const handleBirthMinuteChange = (e) => {
      setBirthMinute(e.target.value);
    };
  
    const handleAmPmChange = (e) => {
      setAmPm(e.target.value);
    };
    const handleLocationChange = (event) => {
      const currentValue = event.target.value;
      setBirthPlace(currentValue);
      fetchSuggestions(currentValue);
    };
  
    const handleSuggestionSelect = (selectedPlace) => {
      const { geometry, properties } = selectedPlace;
      const latitude = geometry.coordinates[1];
      const longitude = geometry.coordinates[0];
      const district = properties.city;
      const state = properties.state;
      const country = properties.country;
      const location = `${district}, ${state}, ${country}`;
  
      const updatedSelectedPlaces = [...selectedPlaces, location];
  
      setSelectedPlaces(updatedSelectedPlaces);
      setBirthPlace(location);
      setSuggestedPlaces([]);
      setLatitude(latitude);
      setLongitude(longitude);
      document.getElementById("atflg").value = longitude;
      document.getElementById("atflt").value = latitude;
    };
  
    const fetchSuggestions = async (currentValue) => {
      if (currentValue.length >= 3) {
        const apiKey = "68ab21fa64da4974b4f47e04943328b8";
        const url = `https://api.geoapify.com/v1/geocode/autocomplete?text=${encodeURIComponent(
          currentValue
        )}&limit=5&apiKey=${apiKey}`;
  
        try {
          const response = await fetch(url);
          if (response.ok) {
            const data = await response.json();
            setSuggestedPlaces(data.features);
          } else {
            console.error("Error fetching suggestions from the API");
            setSuggestedPlaces([]);
          }
        } catch (error) {
          console.error("Error fetching suggestions from the API: ", error);
          setSuggestedPlaces([]);
        }
      }
    };
  
    const Validation = () => {
      let isValid = true;
  
      if (!Name) {
        setNameError("Name is required");
        isValid = false;
      } else {
        setNameError("");
      }
  
      if (!gender) {
        setGenderError("Gender is Required");
        isValid = false;
      } else {
        setGenderError("");
      }
  
      if (!dobYear || !dobMonth || !dobDay) {
        setDobError("Date of Birth is required");
        isValid = false;
      } else {
        setDobError("");
      }
  
      if (!birthHour || !birthMinute || !amPm) {
        setBirthTimeError("Birth Time is required");
        isValid = false;
      } else {
        setBirthTimeError("");
      }
  
      // if (!askToAstrologer) {
      //   setQuestionError("Questions are required");
      //   isValid = false;
      // } else {
      //   setQuestionError("");
      // }
  
      if (!birthPlace || !Longitude || !Latitude) {
        setBirthPlaceError("Birth place is required");
        isValid = false;
      } else {
        setBirthPlaceError("");
      }
  
      if (!Mobile_number || !/^\d{10}$/.test(Mobile_number)) {
        setMobileNumberError("Mobile Number is required");
        isValid = false;
      } else {
        setMobileNumberError("");
      }
      if (!Email) {
        setEmailError("Email is Required");
        isValid = false;
      } else if (!/^\S+@\S+\.\S+$/.test(Email)) {
        setEmailError("Invalid Email Format");
        isValid = false;
      } else {
        setEmailError("");
      }
      return isValid;
    };
    // useEffect(() => {
    //   const storedValue = localStorage.getItem("AudioBookData");
    //   // console.log(storedValue);
  
    //   if (storedValue) {
    //     const parsedValue = JSON.parse(storedValue);
    //     setName(parsedValue.Name);
    //     setGender(parsedValue.gender);
    //     setBirthPlace(parsedValue.birthPlace);
    //     setDobDay(parsedValue.Day);
    //     setDobMonth(parsedValue.Month);
    //     setDobYear(parsedValue.Year);
    //     setBirthHour(parsedValue.Hour);
    //     setBirthMinute(parsedValue.Minute);
    //     setAmPm(parsedValue.amPm);
    //     setEmail(parsedValue.Email);
    //     setAskToAstrologer(parsedValue.Questions);
    //     setLatitude(parsedValue.Latitude);
    //     setLongitude(parsedValue.Longitude);
    //     setMobileNumber(parsedValue.MobileNumber);
    //     document.getElementById("atflg").value = parsedValue.Longitude;
    //     document.getElementById("atflt").value = parsedValue.Latitude;
    //   }
    // }, []);
  
    const AudioFormSubmit = () => {
      if (Validation()) {
        Loading.pulse();
        ApiInsert();
      }
    };
  
    function ApiInsert (){
      const userData = {
        buyerName: Name,
        buyerEmail: Email,
        buyerMobileNo: Mobile_number,
        DateofBirth: dobDay + "-" + dobMonth + "-" + dobYear,
        birthTime: birthHour + ":" + birthMinute + " " + amPm,
        gender: gender,
        birthPlace: birthPlace,
        Amount: 410,
        Package: "Marriage_Audio_Book",
        latitude: Latitude,
        longitude: Longitude,
        name:Name,
        Questions:askToAstrologer.join(',')
      };
     
   
      axios
      .post("https://ctsvsolutions.com/onepage-Api/public/api/Statement-customerData-insert", {
        userData,
      })
      .then((response) => {
          console.log(response.data);
          const userId = response.data.data.userId;
          const customerDataId = response.data.data.customerDataId;
          const newUserData = {
            user_id: userId,
            customerDataId: customerDataId,
          };
          const userInfoValues = JSON.stringify(newUserData);
          localStorage.setItem("MarriageAudioBook", userInfoValues);
          Loading.remove();

         let Pay= proceedToPayment()
      });
    };
    
  
    function proceedToPayment() {
      const userInfoJSON = localStorage.getItem("MarriageAudioBook");
      const userInfo = JSON.parse(userInfoJSON);
      let user_id=userInfo.user_id;
      let customer_id=userInfo.customerDataId;
      const data = {
        mid: "993377",
        amount: "410.00",
        merchantTransactionId: "sd12121",
        transactionDate: "Mon Oct 03 13:54:33 IST 2022",
        terminalId: "getepay.merchant131068@icici",
        udf1: Mobile_number,
        udf2: user_id,
        udf3: Name,
        udf4: customer_id,
        udf5: "",
        udf6: "",
        udf7: "",
        udf8: "",
        udf9: "",
        udf10: "",
        ru: "https://ctsvsolutions.com/onepage-Api/public/Marriage-Audio-Book",
        callbackUrl: "",
        currency: "INR",
        paymentMode: "ALL",
        bankId: "",
        txnType: "single",
        productType: "IPG",
        txnNote: "MG-Audio-Book",
        vpa: "getepay.merchant131068@icici",
      }; 
    const Config = {
      "mid":"993377",
      "Getepay Terminal Id": "getepay.merchant131068@icici",
      "Getepay Key": "rjW99m2Vo75SBbpyc+K5nfB/eebzWUmkAtx90U5Ku3I=",
      "Getepay IV": "Y7fOrFLKVPosfDFSIFSISQ==",
        "Getepay Url":
          "https://portal.getepay.in:8443/getepayPortal/pg/generateInvoice"
      };
      getepayPortal(data, Config);
    };
    
    const freeApiInsert=()=>{
        const userData = {
            buyerName: Name,
            buyerEmail: Email,
            buyerMobileNo: Mobile_number,
            DateofBirth: dobDay + "-" + dobMonth + "-" + dobYear,
            birthTime: birthHour + ":" + birthMinute + " " + amPm,
            gender: gender,
            birthPlace: birthPlace,
            Amount: 0,
            Package: "Free_Audio_Prediction",
            latitude: Latitude,
            longitude: Longitude,
            name:Name,
            Questions:askToAstrologer.join(',')
          };
          axios
          .post("https://ctsvsolutions.com/onepage-Api/public/api/Add-Free-Audio-Marriage-prediction", {
            userData,
          })
          .then((response) => {
            Loading.remove();

              Notify.success('Your Order Added successfully');
              setTimeout(()=>{
                window.location="/Jathaga-Palan"
              },2000)
           

          });
    }
    const FreeAudioOrder=()=>{
        if (Validation()) {
            Loading.pulse();
            freeApiInsert()
        }
    }
    return (
      <div className="container" style={{ marginTop: "130px" }}>
        <div className="">
          
          <div className="row ">
            <div className="col-md-8 col-12 m-auto">
  
              <h1 className="cate-title fw-bold mb-2" style={{fontSize:"28px"}}>Jathaga palan -ஜாதக பலன்</h1>
        <div className="mt-3">
                      {/* <h6 className="fw-bold" style={{color:'#114c83'}}>
          * உங்களின் ஜனன ஜாதகத்தை எங்கள் ஜோதிடர்  ஆராய்ந்து விரைவில் உங்களது பலனை உங்களது whatsapp எண்ணிற்கு ஆடியோவாக அனுப்புவார்.
            </h6> */}
              </div>
              <div className="mt-3 p-2 p-md-4 " style={styles.container}>
              <div style={styles.shape1}></div>
                    {/* <div style={styles.shape2}></div> */}
                    {/* <div style={styles.shape3}></div> */}
                    {/* <div style={styles.shape4}></div> */}
                <form>
                  <h4 className="text-center fw-bold" style={styles.heading}>
                    Personal Information
                  </h4>
                 
                  <div className="row">
                    <div className="col-md-6 col-12">
                      <label
                        htmlFor="fullname"
                        className="fw-bold"
                       style={styles.question_text}
                      >
                        Full Name
                      </label>
  
                      <input
                        type="text"
                        id="fullname"
                        name="fullname"
                        value={Name}
                        className="form-control"
                        onChange={(e) => setName(e.target.value)}
                        placeholder="Enter your full name"
                      />
                      {NameError && (
                        <span className="text-danger fw-bold">{NameError}</span>
                      )}
                    </div>
  
                    <div className="col-md-6 col-12">
                      <label
                        htmlFor="gender"
                        className="fw-bold"
                        style={styles.question_text}
                      >
                        Gender
                      </label>
                      <select
                        value={gender}
                        onChange={(e) => setGender(e.target.value)}
                        className="form-select"
                      >
                        <option value="">Gender</option>
                        <option value="male">Male</option>
                        <option value="female">Female</option>
                      </select>
                      {genderError && (
                        <span className="text-danger fw-bold">{genderError}</span>
                      )}
                    </div>
                  </div>
                  <div className="row mt-3">
                    <label className="fw-bold"  style={styles.question_text}>
                      Date of Birth
                    </label>
                    <div className="col-md-4 mb-2 col-12">
                      <select
                        value={dobDay}
                        onChange={handleDobDayChange}
                        className="form-select "
                      >
                        <option value="">Day</option>
                        {Array.from(new Array(31), (val, index) => index + 1).map(
                          (day) => (
                            <option key={day} value={day}>
                              {day}
                            </option>
                          )
                        )}
                      </select>
                    </div>
                    <div className="col-md-4 mb-2 col-12">
                      <select
                        value={dobMonth}
                        onChange={handleDobMonthChange}
                        className="form-select"
                      >
                        <option value="">Month</option>
                        {Array.from(new Array(12), (val, index) => index + 1).map(
                          (month) => (
                            <option key={month} value={month}>
                              {month}
                            </option>
                          )
                        )}
                      </select>
                    </div>
                    <div className="col-md-4 mb-2 col-12">
                      <select
                        value={dobYear}
                        onChange={handleDobYearChange}
                        className="form-select"
                      >
                        <option value="">Year</option>
                        {years.map((year) => (
                          <option key={year} value={year}>
                            {year}
                          </option>
                        ))}
                      </select>
                    </div>
                    {/* </div> */}
                    {DobError && (
                      <span className="text-danger fw-bold">{DobError}</span>
                    )}
                  </div>
                  <div className="row">
                    <label
                      className="form-label fw-bold"
                      style={styles.question_text}
                    >
                      Birth Time
                    </label>
                    <div className="col-md-4 col-12 mb-2">
                      <select
                        value={birthHour}
                        onChange={handleBirthHourChange}
                        className="form-select"
                      >
                        <option value="">Hour</option>
                        {hours.map((hour) => (
                          <option key={hour} value={hour}>
                            {hour}
                          </option>
                        ))}
                      </select>
                    </div>
                    <div className="col-md-4 col-12 mb-2">
                      <select
                        value={birthMinute}
                        onChange={handleBirthMinuteChange}
                        className="form-select"
                      >
                        <option value="">Minute</option>
                        {minutes.map((minute) => (
                          <option key={minute} value={minute}>
                            {minute}
                          </option>
                        ))}
                      </select>
                    </div>
                    <div className="col-md-4 col-12 mb-2">
                      <select
                        value={amPm}
                        onChange={handleAmPmChange}
                        className="form-select"
                      >
                        <option value="AM">AM</option>
                        <option value="PM">PM</option>
                      </select>
                    </div>
  
                    {BirthTimeError && (
                      <span className="text-danger fw-bold">
                        {BirthTimeError}
                      </span>
                    )}
                  </div>
                  <div className="row">
                    <div className="col-12">
                      <label
                        htmlFor="birthplace"
                        className="fw-bold"
                        style={styles.question_text}
                      >
                        Birth Place
                      </label>
                      <input
                        type="text"
                        id="birthplace"
                        onChange={handleLocationChange}
                        value={birthPlace}
                        name="birthplace"
                        className="form-control"
                        placeholder="Enter your birth place"
                      />
                      {BirthPlaceError && (
                        <span className="text-danger fw-bold">
                          {BirthPlaceError}
                        </span>
                      )}
                      <input
                        id="atflt"
                        className="pfipbx"
                        name="the_lat"
                        type="hidden"
                        placeholder="Latitude"
                      />
                      <input
                        id="atflg"
                        className="pfipbx"
                        name="the_lng"
                        type="hidden"
                        placeholder="Longitude"
                      />
                      <ul>
                        {suggestedPlaces.map((place, index) => {
                          if (place.properties.city) {
                            const listItemStyle = {
                              padding: "5px 10px",
                              cursor: "pointer",
                              fontSize: "16px",
                              borderBottom: "1px dashed #ddd",
                            };
                            return (
                              <p
                                style={listItemStyle}
                                key={index}
                                onClick={() => handleSuggestionSelect(place)}
                              >
                                {place.properties.city}, {place.properties.state},{" "}
                                {place.properties.country}
                              </p>
                            );
                          }
                        })}
                      </ul>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-md-12 col-12 ">
                      <label
                        htmlFor="question"
                        className="fw-bold"
                        style={styles.question_text}
                      >
                        Ask to Astrologer
                      </label>
                      <br></br>
                      <div className="form-check form-check-inline">
                        <input
                          type="checkbox"
                          value="உங்களது திருமண வாழ்வு"
                          onChange={handleCheckboxChange}
                         
                          checked={askToAstrologer.includes("உங்களது திருமண வாழ்வு")}
                        />
                        <span style={styles.question_text_ques}> உங்களது திருமண வாழ்வு </span>
                     
                      </div>
                      <div className="form-check form-check-inline">
                        <input
                          type="checkbox"
                          value="திருமணம் நடக்கும் காலம்"
                          style={{ marginRight: "5px" }}
                          onChange={handleCheckboxChange}
                          checked={askToAstrologer.includes("திருமணம் நடக்கும் காலம்")}
                        />
                             <span style={styles.question_text_ques}>திருமணம் நடக்கும் காலம்</span>
                       
                      </div>
                      <div className="form-check form-check-inline">
                        <input
                          type="checkbox"
                          value="திருமணத்தின் தரம்"
                          style={{ marginRight: "5px" }}
                          onChange={handleCheckboxChange}
                          checked={askToAstrologer.includes("திருமணத்தின் தரம்")}
                        />
                           <span style={styles.question_text_ques}> திருமணத்தின் தரம் </span>
                    
                      </div>
                      <div className="form-check form-check-inline">
                        <input
                          type="checkbox"
                          value="காதல் திருமணமா"
                          style={{ marginRight: "5px" }}
                          onChange={handleCheckboxChange}
                          checked={askToAstrologer.includes("காதல் திருமணமா")}
                        />
                         <span style={styles.question_text_ques}> காதல் திருமணமா?  </span>
                      
                      </div>
                      <div className="form-check form-check-inline">
                        <input
                          type="checkbox"
                          value="சரியான காலத்தில் திருமணம்"
                          style={{ marginRight: "5px" }}
                          onChange={handleCheckboxChange}
                          checked={askToAstrologer.includes("சரியான காலத்தில் திருமணம்")}
                        />
                           <span style={styles.question_text_ques}> சரியான காலத்தில் திருமணம்  </span>
                       
                      </div>
                      <div className="form-check form-check-inline">
                        <input
                          type="checkbox"
                          value="தாமத திருமண விளக்கம்"
                          style={{ marginRight: "5px" }}
                          onChange={handleCheckboxChange}
                          checked={askToAstrologer.includes("தாமத திருமண விளக்கம்")}
                        />
                        <span style={styles.question_text_ques}>  தாமத திருமண விளக்கம்  </span>
                    
                      </div>
                      <div className="form-check form-check-inline">
                        <input
                          type="checkbox"
                          value="வாழ்கை துணையை அறிய"
                          style={{ marginRight: "5px" }}
                          onChange={handleCheckboxChange}
                          checked={askToAstrologer.includes("வாழ்கை துணையை அறிய")}
                        />
                         <span style={styles.question_text_ques}>வாழ்கை துணையை அறிய </span>
                  
                      </div>
                      <div className="form-check form-check-inline">
                        <input
                          type="checkbox"
                          value="வழிபட வேண்டிய தெய்வங்கள்"
                          style={{ marginRight: "5px" }}
                          onChange={handleCheckboxChange}
                          checked={askToAstrologer.includes("வழிபட வேண்டிய தெய்வங்கள்")}
                        />
                         <span style={styles.question_text_ques}> வழிபட வேண்டிய தெய்வங்கள்</span>
                   
                      </div>
                      <div className="form-check form-check-inline">
                        <input
                          type="checkbox"
                          value="உங்களது திருமண காரகர் பலன்"
                          style={{ marginRight: "5px" }}
                          onChange={handleCheckboxChange}
                          checked={askToAstrologer.includes("உங்களது திருமண காரகர் பலன்")}
                        />
                          <span style={styles.question_text_ques}> உங்களது திருமண காரகர் பலன்  </span>
                      </div>
                    </div>
                  </div>
                  <div className="row mt-3 ">
                    <div className="col-md-6 col-12">
                      <label
                        htmlFor="fullname"
                        className="fw-bold"
                       style={styles.question_text}
                      >
                        Whatsapp Number
                      </label>
  
                      <input
                        type="text"
                        id="fullname"
                        name="fullname"
                        value={Mobile_number}
                        className="form-control"
                        disabled
                        placeholder="Your Mobile Number"
                      />
                    
                    </div>
  
                    <div className="col-md-6 col-12">
                      <label
                        htmlFor="gender"
                        className="fw-bold"
                        style={styles.question_text}
                      >
                        Email
                      </label>
                      <input
                      disabled
                        type="text"
                        id="Email"
                        name="Email"
                        value={Email}
                        className="form-control"
                        
                        placeholder="Your Email Address"
                      />
                    
                    </div>
                  </div>
                  <div className="row">
                        <div className="col-md-6 text-end"> 
                        {freeButton===true && (
                                <button
                                type="button"
                                onClick={FreeAudioOrder}
                                className="btn btn-success mt-3"
                                >
                                Order Free Audio
                                </button>
                        )}
                        </div>
                        <div className="col-md-6 ">
                            {paymentButton && (
                                <button
                                type="button"
                                onClick={AudioFormSubmit}
                                className="btn btn-warning mt-3"
                                >
                                Pay Now
                                </button>
                            )}
                           
                        </div>
                        <div className='mt-2'>
                    {offerStatus === false && (
                        <h6 className='text-danger'> *Already you claimed the Offer</h6>
                    )}

                    {offerStatus === true && freeButton === false && (
                       <h6 class='text-danger'>*Offer eligible for selecting only one question.</h6>
                    )}
                </div>
                        </div>
                </form>
              </div>
            </div>
          </div>
          
        </div>
      </div>
    );
  }
  
  const styles = {
    container: {
        position: "relative",
        // height: "600px",
        // width: "360px",
        boxShadow: "0px 0px 24px #5C5696",
        overflow: "hidden",
      },
      question_text_ques:{
        color:"#1F1836",
        fontWeight:"bold"
      },
      question_text:{
        fontSize:"16px",
        fontWeight:"bold",
        color:'#0F52EE'
      },
      gradientBackground: {
        position: "absolute",
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
        zIndex: -1,
        background: "linear-gradient(90deg, #5D54A4, #7C78B8)",
      },
      shape1: {
        height: "520px",
        // oppacity
        width: "520px",
        background: "#FFF",
        top: "-50px",
        right: "120px",
        borderRadius: "0 72px 0 0",
        position: "absolute",
        transform: "rotate(45deg)",
        zIndex: -2,
      },
      shape2: {
        height: "220px",
        width: "220px",
        background: "#6C63AC",
        opacity: '0.5',
        top: "-172px",
        right: 0,
        borderRadius: "32px",
        position: "absolute",
        transform: "rotate(45deg)",
        zIndex: -2,
      },
      shape3: {
        height: "540px",
        width: "190px",
        opacity: '0.5',
        background: "linear-gradient(270deg, #5D54A4, #6A679E)",
        top: "-24px",
        right: 0,
        borderRadius: "32px",
        position: "absolute",
        transform: "rotate(45deg)",
        zIndex: -2,
      },
      shape4: {
        height: "400px",
        width: "200px",
        opacity: '0.5',
        background: "#7E7BB9",
        top: "420px",
        right: "50px",
        borderRadius: "60px",
        position: "absolute",
        transform: "rotate(45deg)",
        zIndex: -2,
      },
  
    heading: {
      color: "#4E0012",
      marginBottom: "20px",
    },
    label: {
      marginBottom: "5px",
      fontWeight: "bold",
      color: "#333",
    },
    input: {
      width: "100%",
      padding: "8px",
      borderRadius: "5px",
      border: "1px solid #ccc",
      marginBottom: "10px",
    },
    dobInputs: {
      display: "flex",
      justifyContent: "space-between",
    },
    dobInput: {
      width: "30%",
      padding: "8px",
      borderRadius: "5px",
      border: "1px solid #ccc",
      marginBottom: "10px",
    },
    button: {
      backgroundColor: "#f0a500",
      color: "#fff",
      padding: "10px 20px",
      border: "none",
      borderRadius: "5px",
      cursor: "pointer",
    },
  };
  

export default MarriageFreePrediction;
