import React from 'react'
import TopNavbar from '../../Main-Components/TopNavbar/TopNavbar'
import Menubar from '../../Main-Components/Menubar/Menubar'
import Footer from '../../Main-Components/Footer/Footer'
import FAQBanner from '../../Small-Components/FAQBanner/FAQBanner'
import FAQContent from '../../Small-Components/FAQContent/FAQContent'
import { Helmet } from 'react-helmet'
import { useLocation } from 'react-router-dom'
export default function FAQPage() {


  const location = useLocation();
  const canonical = () => {
    const base_url = "https://onepagehoroscope.com";
    const current_path = location.pathname;

    const canonicalPaths = {
      "/FAQ": "/FAQ",
    };

    return (
      <link
        rel="canonical"
        href={base_url + (canonicalPaths[current_path] || current_path)}
      />
    );
  };

  return (
    <>
    <Helmet>
        <title>FAQs About Tamil Astrology Online | Onepage Horoscope</title>
        <meta
          name="description"
          content="Discover the mysteries of online horoscopes in Tamil with our comprehensive FAQ guide. Get answers to your burning questions on Onepage Horoscope!"
        />
        <meta
          name="keywords"
          content="one page Horoscope,FAQ,faq,frequent questions"
        />
        <meta name="robots" content="index,follow" />
        {canonical()}
        <script type="application/ld+json">
          {`
             "@context": "https://schema.org",
             "@type": "FAQPage",
             "mainEntity": [{
               "@type": "Question",
               "name": "What services does Free Tamil Onepage Horoscope provide?",
               "acceptedAnswer": {
                 "@type": "Answer",
                 "text": "Free Tamil Onepage Horoscope offers astrology software for numerology, birth charts, marriage matching, and audio predictions."
               }
             },{
               "@type": "Question",
               "name": "How can I contact Free Tamil Onepage Horoscope for customer support?",
               "acceptedAnswer": {
                 "@type": "Answer",
                 "text": "You can reach Free Tamil Onepage Horoscope's customer support team via email at support@onepagehoroscope.com or by phone at 9787420717."
               }
             },{
               "@type": "Question",
               "name": "What information is included in the birth horoscope provided by Free Tamil Onepage Horoscope?",
               "acceptedAnswer": {
                 "@type": "Answer",
                 "text": "The birth horoscope includes comprehensive details such as planetary positions, 25-year predictions, Sarvashtakavarga, zodiac, and navamsa."
               }
             },{
               "@type": "Question",
               "name": "What is marriage matching, and how does Free Tamil Onepage Horoscope assist with it?",
               "acceptedAnswer": {
                 "@type": "Answer",
                 "text": "Marriage matching, also known as Thirumana Porutham, involves assessing compatibility between prospective partners based on their birth charts. Free Tamil Onepage Horoscope helps individuals analyze their birth charts for marriage compatibility."
               }
             },{
               "@type": "Question",
               "name": "Can I get personalized astrology services for my child from Free Tamil Onepage Horoscope?",
               "acceptedAnswer": {
                 "@type": "Answer",
                 "text": "Yes, you can. Free Tamil Onepage Horoscope offers personalized services for children, including generating auspicious names, birth details analysis, and easy-to-understand numerology and pyramid numerology readings."
               }
             },{
               "@type": "Question",
               "name": "What does the Marriage Bio Data service detail?",
               "acceptedAnswer": {
                 "@type": "Answer",
                 "text": "Yes, you can. Free Tamil Onepage Horoscope offers personalized services for children, including generating auspicious names, birth details analysis, and easy-to-understand numerology and pyramid numerology readings."
               }
             },{
               "@type": "Question",
               "name": "Is there a blog section on the Free Tamil Onepage Horoscope website?",
               "acceptedAnswer": {
                 "@type": "Answer",
                 "text": "Yes, Free Tamil Onepage Horoscope has a blog section where users can find informative articles and insights related to astrology, numerology, and related topics."
               }
             },{
               "@type": "Question",
               "name": "How can I subscribe to Free Tamil Onepage Horoscope's services?",
               "acceptedAnswer": {
                 "@type": "Answer",
                 "text": "You can subscribe to Free Tamil Onepage Horoscope's services by providing your email address and opting for their newsletter or updates. Subscribing keeps you informed about the latest developments and offers."
               }
             }]           
          `}</script>
      </Helmet>
     <TopNavbar />
      <Menubar NavBgstyle="outer-header lp" NavTextColor="text-dark" SubmenuTop="true" />
     
      <FAQBanner/>
        <>
          <FAQContent />
        </>

      <Footer />
    </>
  )
}
