import React from 'react'
import TopNavbar from '../../Main-Components/TopNavbar/TopNavbar'
import Menubar from '../../Main-Components/Menubar/Menubar'
import Footer from '../../Main-Components/Footer/Footer'
import FAQ from '../../Main-Components/FAQ/FAQ'
import MarriageBioDataBanner from '../../Small-Components/MaariageBioDataBanner/MarriageBioDataBanner'
import MarriageBioDataDetails from '../../Small-Components/MarriageBioDataDetails/MarriageBioDataDetails'
import BioDataForm from '../../Forms/BioDataForm/BioDataForm';
import BiodataFormContextProvider from '../../context/forms/BiodataForm_context_provider'
import { Helmet } from 'react-helmet'
import { useLocation } from 'react-router-dom'

export default function MarriageBiodata() {

    const location = useLocation();

    const canonical = () => {
        const base_url = "https://onepagehoroscope.com";
        const current_path = location.pathname;

        const canonicalPaths = {
            "/Marriage-Bio-Data": "/Marriage-Bio-Data",
            // Add more canonical paths if needed
        };

        return (
            <link
                rel="canonical"
                href={base_url + (canonicalPaths[current_path] || current_path)}
            />
        );
    };

    return (
        <>
            <Helmet>
                <title>Bio Data for Marriage in Tamil | One Page திருமண சுயவிபரம்</title>
                <meta
                    name="description"
                    content="Create a marriage biodata profile instantly with our bio data for marriage in tamil. Integrate with One Page Horoscope for astrological insights. Get in Touch Now!"
                />
                <meta
                    name="keywords"
                    content="BIo data maker for marriage,Tamil bio data for marriage,Online bio data for marriage,bio data for marriage in tamil,Bio data for marriage online,marriage biodata, marriage biodata format, online biodata for marriage, biodata for marriage in tamil, free biodata maker, free marriage biodata maker, marriage biodata template free download, bio data for marriage free"
                />
                <meta name="robots" content="index,follow" />
                {canonical()}
                <script type="application/ld+json">

                    {JSON.stringify({
                        "@context": "https://schema.org/",
                        "@type": "Product",
                        "name": "Marriage Biodata",
                        "image": "https://onepagehoroscope.com/static/media/marriage-bio-data-one-page-horoscope.ee3fbc82b83022306f6c.webp",
                        "description": "Create a marriage biodata profile instantly with our bio data for marriage in tamil. Integrate with One Page Horoscope for astrological insights. Get in Touch Now!",
                        "brand": {
                            "@type": "Brand",
                            "name": "One Page Horoscope"
                        },
                        "offers": {
                            "@type": "Offer",
                            "url": "https://onepagehoroscope.com/Marriage-Bio-Data",
                            "priceCurrency": "INR",
                            "price": "249",
                            "availability": "https://schema.org/OnlineOnly",
                            "itemCondition": "https://schema.org/NewCondition"
                        }
                    })
                    }
                </script>
            </Helmet>
            <TopNavbar />
            <Menubar NavBgstyle="outer-header mb" NavTextColor="text-dark" SubmenuTop="true" />
            <BiodataFormContextProvider>
                <MarriageBioDataBanner />
                <BioDataForm />
                <MarriageBioDataDetails />
            </BiodataFormContextProvider>
            <FAQ Type={'BioData'} />
            <Footer />
        </>
    )
}
