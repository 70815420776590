  import React from "react";

  import { Navigate } from 'react-router-dom';
  import { Routes, Route } from "react-router-dom"; 

  import 'lazysizes';
  import 'lazysizes/plugins/parent-fit/ls.parent-fit';
  import 'bootstrap/dist/css/bootstrap.css';
  import 'bootstrap/dist/js/bootstrap.bundle'
  import ScrollToTop from "./components/tamil/Scroll/Scroll";
  import Offer from "./components/Offer/offer";
  import MgOffer from "./components/Offer/Mgoffer";
  import BabyOffer from "./components/Offer/BabyOffer";

  import AudioBookPaymentSuccess from "./components/tamil/payment-success/AudioBookSuccess";
  import AdminLogin from "./components/Admin/Login/AdminLogin";
  import AdminReport from "./components/Admin/Report/Report";
import MarriageFreePrediction from "./components/tamil/FreeAudioPredition/MarriageFreePrediction.js";

import Home from "./new-ui/components/Pages/Home/Home.jsx"
import BirthHoroscope from "./new-ui/components/Pages/Birth-horoscope/BirthHoroscope.jsx"
import "../src/new-ui/components/style/style.css";
import "../src/new-ui/components/style/responsive.css";
import "../src/new-ui/components/style/blog-right.css";
import "../src/new-ui/components/style/blog-view.css";
import "../src/new-ui/components/style/blog.css";
import "@fontsource/poppins";
import "@fontsource/poppins/400.css";
import "@fontsource/poppins/500.css";
import "@fontsource/poppins/600.css";
import "@fontsource/poppins/700.css";
import "@fontsource/poppins/800.css";
import "@fontsource/poppins/900.css";
import BirthHoroscopeview from "./new-ui/components/Pages/Birth-horoscope-view/Birth-horoscope-view.jsx";
import MarriageMatching from "./new-ui/components/Pages/Marriage-matching/MarriageMatching.jsx";
import MarriageMatchingView from "./new-ui/components/Pages/Marriage-Matching-view/MarriageMatchingView.jsx";
import MarriageBiodata from "./new-ui/components/Pages/Marriage-Bio-Data/MarriageBioData.jsx";
import BabyNameList from "./new-ui/components/Pages/Baby-Name-List/BabyNameList.jsx";
import BabyNameView from "./new-ui/components/Pages/Baby-Name-view/BabyNameView.jsx";
import RuthuJathagamNew from "./new-ui/components/Pages/Ruthu-Jathagam/RuthuJathagam.jsx";
import BioDataViewNew from "./new-ui/components/Pages/Bio-Data-View/BioDataView.jsx";
import RuthuJathagaView from "./new-ui/components/Pages/Ruthu-Jathagam-Confirm-Details/RuthuJathagaView.jsx";
import JathagaPalan from "./new-ui/components/Pages/Jathaga-Palan/JathagaPalan.jsx";
import TamilJathagaBook from "./new-ui/components/Pages/Tamil-jathagam-book/TamilJathagaBook.jsx";
import TamilJathagaBookView from "./new-ui/components/Pages/Tamil-Jathagam-Confirm-Details/TamilJathagaBookView.jsx";
import ContactUs from './new-ui/components/Pages/ContactUs/ContactUs.jsx'
import PrivacyPolicyView from "./new-ui/components/Pages/PrivacyPolicyView/PrivacyPolicyView.jsx";
import AboutUsView from "./new-ui/components/Pages/AboutUsView/AboutUsView.jsx";
import TermConditionsView from "./new-ui/components/Pages/TermConditionsView/TermConditionsView.jsx";
import AstroTalk from "./new-ui/components/Pages/AstroTalk/AstroTalk.jsx";
import BlogList from "./new-ui/components/Pages/BlogList/BlogList.jsx";
import CancellationPolicy from "./new-ui/components/Pages/CancellationPolicy/CancellationPolicy.jsx";
import FAQPage from "./new-ui/components/Pages/FAQPage/FAQPage.jsx";
import WeeklyRasiPalan from "./new-ui/components/Pages/WeeklyRasiPalan/WeeklyRasiPalan.jsx";
import BlogFilter from "./new-ui/components/Pages/BlogFilter/BlogFilter.jsx";
import Babynameblog from "./new-ui/components/Pages/BlogView/Babynameblog.jsx";
import ComputerJadhagam from "./new-ui/components/Pages/BlogView/ComputerJadhagam.jsx";
import CreateHoroscopeBlog from "./new-ui/components/Pages/BlogView/CreateHoroscopeBlog.jsx";
import HoraigalBlog from "./new-ui/components/Pages/BlogView/HoraigalBlog.jsx";
import HoroscopeBlog from "./new-ui/components/Pages/BlogView/HoroscopeBlog.jsx";
import JathagaporuthamBlog from "./new-ui/components/Pages/BlogView/JathagaporuthamBlog.jsx";
import LifeHoroscopeBlog from "./new-ui/components/Pages/BlogView/LifeHoroscopeBlog.jsx";
import MarriageBioDataBlog from "./new-ui/components/Pages/BlogView/MarriageBioDataBlog.jsx";
import MarriageMatchingBlog from "./new-ui/components/Pages/BlogView/MarriageMatchingBlog.jsx";
import NakshatraMatchingBlog from "./new-ui/components/Pages/BlogView/NakshatraMatchingBlog.jsx";
import NumorologyBlog from "./new-ui/components/Pages/BlogView/NumorologyBlog.jsx";
import PanchangamBlog from "./new-ui/components/Pages/BlogView/PanchangamBlog.jsx";
import TamilHoroscopeMatchingBlog from "./new-ui/components/Pages/BlogView/TamilHoroscopeMatchingBlog.jsx";
import TamilJathagamBlog from "./new-ui/components/Pages/BlogView/TamilJathagamBlog.jsx";
import ThirumanaPoruthamBlog from "./new-ui/components/Pages/BlogView/ThirumanaPoruthamBlog.jsx";
import LoveCalculatorBlog from "./new-ui/components/Pages/BlogView/LoveCalculatorBlog.jsx";
import OnePagePaymentSuccess from "./new-ui/components/Pages/OnePagePaymentSuccess/OnePagePaymentSuccess.jsx";
import MarriageMatchingPaymentSuccess from "./new-ui/components/Pages/MarriageMatchingPaymentSuccess/MarriageMatchingPaymentSuccess.jsx";
import BiodataPaymentSuccess from "./new-ui/components/Pages/BiodataPaymentSuccess/BiodataPaymentSuccess.jsx";
import BabyNamePaymentSuccess from "./new-ui/components/Pages/BabyNamePaymentSuccess/BabyNamePaymentSuccess.jsx";
import RuthuJathagaPaymentSuccess from "./new-ui/components/Pages/RuthuJathagaPaymentSuccess/RuthuJathagaPaymentSuccess.jsx";
import Error404 from "./new-ui/components/Pages/Error/Error.jsx";
import TamilJathagamBookPaymentSuccess from "./new-ui/components/Pages/Tamil-jathagam-book-success/TamilJathagamBookSuccess.jsx"
import AudioPredictionPaymentSuccess from "./new-ui/components/Pages/AudioPredictionPaymentSuccess/AudioPredictionPaymentSuccess.jsx"
  function App() {




    return (
      <div className="App">
        <ScrollToTop />
        <Routes>
          {/* admin */}
          <Route path="/Admin-Login" element={<AdminLogin />} />
          <Route path="/Admin-Report" element={<AdminReport />} />

          {/* website */}
          <Route path="/offer/:user_id/:data_id/" element={<Offer />} />
          <Route path="/Baby-offer/:user_id/:data_id/" element={<BabyOffer />} />
          <Route path="/Mg-offer/:user_id/:data_id/" element={<MgOffer />} />

          <Route path="/Marriage-free-prediction/:user_id/:mobile_number" element={<MarriageFreePrediction />} />
          <Route path="/Tamil-Jathagam-Payment-Success" element={<AudioBookPaymentSuccess />} />


          <Route path="/index.php" element={<Navigate to="/" />} />
          <Route path="/login.php" element={<Navigate to="/Login" />} />
          <Route path="/birthchart.php" element={<Navigate to="/Birth-horoscope" />} />
          <Route path="/marriagematching.php" element={<Navigate to="/Marriage-matching" />} />
          <Route path="/marriagebiodata.php" element={<Navigate to="/Marriage-Bio-Data" />} />
          <Route path="/babyname.php" element={<Navigate to="/Baby-Name-List" />} />
          <Route path="/contact.php" element={<Navigate to="/contact-us" />} />
          <Route path="/privacy-policy.php" element={<Navigate to="/privacy-policy" />} />
          {/* <Route path="/aboutus.php" element={<Navigate to="/About-us" />} /> */}
          <Route path="/cancellation.php" element={<Navigate to="/Cancellation-policy" />} />
          <Route path="/terms-and-condition.php" element={<Navigate to="/Terms-condition" />} />
          <Route path="/faq.php" element={<Navigate to="/FAQ" />} />
          <Route path="/blog.php" element={<Navigate to="/Blog/categories/Tamil/Blog-Lists" />} />
          <Route path="/blog-details.php" element={<Navigate to="/Blog/categories/Tamil/Blog-View" />} />

          {/* new ui */}
          <Route path="/" element={<Home />} />
          <Route path="/Birth-horoscope" element={<BirthHoroscope />} />
          <Route path="/Birth-Table-view" element={<BirthHoroscopeview />} />
          <Route path="/Marriage-matching" element={<MarriageMatching />} />
          <Route path="/Marriage-Matching-view" element={<MarriageMatchingView />} />
          <Route path="/Marriage-Bio-Data" element={<MarriageBiodata />} />
          <Route path="/Bio-Data-View" element={<BioDataViewNew />} />
          <Route path="/Baby-Name-List" element={<BabyNameList />} />
          <Route path="/Baby-Name-view" element={<BabyNameView />} />
          <Route path="/Ruthu-Jathagam" element={<RuthuJathagamNew />} />
          <Route path="/Ruthu-Jathagam-Confirm-Details" element={<RuthuJathagaView />} />
          <Route path="/Jathaga-Palan" element={<JathagaPalan />} />
          <Route path="/Tamil-jathagam-book" element={<TamilJathagaBook />} />
          <Route path="/Tamil-Jathagam-Confirm-Details" element={<TamilJathagaBookView />} />
          <Route path="/contact-us" element={<ContactUs />} />
          <Route path="/privacy-policy" element={<PrivacyPolicyView />} />
          <Route path="/About-us" element={<AboutUsView />} />
          <Route path="/Terms-condition" element={<TermConditionsView />} />
          <Route path="/Talk-To-Astrologer" element={<AstroTalk />} />
          <Route path="/Cancellation-policy" element={<CancellationPolicy />} />
          <Route path="/Blog" element={<BlogList />} />
          <Route path="/Blog/category/:category_name/" element={<BlogFilter />} />
          <Route path="/FAQ" element={<FAQPage />} />
          <Route path="/rasi-palan/Weekly-rasi-palan" element={<WeeklyRasiPalan />} />
          <Route path="/Blog/New-Born-Baby-Jathagam-in-Tamil" element={<Babynameblog />} />
          <Route path="/computer-Jathagam" element={<ComputerJadhagam />} />
          <Route path="/Blog/Jathagam-Online-PDF" element={<CreateHoroscopeBlog />} />
          <Route path="/Horaigal" element={<HoraigalBlog />} />
          <Route path="/Blog/tamil-astrology-12-houses-explained" element={<HoroscopeBlog />} />
          <Route path="/Blog/importance-of-Jathagam-Porutham" element={<JathagaporuthamBlog />} />
          <Route path="/Blog/Life-Horoscope-In-tamil" element={<LifeHoroscopeBlog />} />
          <Route path="/Blog/bio-data-for-marriage-in-tamil" element={<MarriageBioDataBlog />} />
          <Route path="/Blog/marriage-matching-tips" element={<MarriageMatchingBlog />} />
          <Route path="/Blog/nakshatra-matching" element={<NakshatraMatchingBlog />} />
          <Route path="/Blog/Numerology-Calculator" element={<NumorologyBlog />} />
          <Route path="/Today-tamil-Panchangam" element={<PanchangamBlog />} />
          <Route path="/Blog/tamil-horoscope-matching" element={<TamilHoroscopeMatchingBlog />} />
          <Route path="/Blog/important-content-to-verify-in-tamil-jathagam" element={<TamilJathagamBlog />} />
          <Route path="/Blog/Guide-to-Thirumana-porutham" element={<ThirumanaPoruthamBlog />} />
          <Route path="/Love-Calculator" element={<LoveCalculatorBlog />} />
          <Route path="/OnePage-ThankYou" element={<OnePagePaymentSuccess />} />
          <Route path="/Marriage-Matching-ThankYou" element={<MarriageMatchingPaymentSuccess />} />
          <Route path="/BioData-ThankYou" element={<BiodataPaymentSuccess />} />
          <Route path="/BabyName-ThankYou" element={<BabyNamePaymentSuccess />} />
          <Route path="/RuthuJathagam-ThankYou" element={<RuthuJathagaPaymentSuccess />} />
          <Route path="/Horosocope-Book-ThankYou" element={<TamilJathagamBookPaymentSuccess />} />
          <Route path="/Audio-Prediction-ThankYou" element={<AudioPredictionPaymentSuccess />} />
          <Route path="*" element={<Error404 />} />
        </Routes>
      </div>
    );
  }

  export default App;