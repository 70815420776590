import React from 'react'
import TopNavbar from '../../Main-Components/TopNavbar/TopNavbar'
import Menubar from '../../Main-Components/Menubar/Menubar'
import Footer from '../../Main-Components/Footer/Footer'
import Guiding from '../../Main-Components/Guiding/Guiding'
import TamilJathagaBookViewBanner from '../../Small-Components/TamilJathagaBookViewBanner/TamilJathagaBookViewBanner'
import TamilJathagaViewPerDet from '../../Small-Components/TamilJathagaViewPerDet/TamilJathagaViewPerDet'
import TamilJathagaViewHoroDet from '../../Small-Components/TamilJathagaViewHoroDet/TamilJathagaViewHoroDet'

export default function TamilJathagaBookView() {
    const value = JSON.parse(localStorage.getItem("HoroscopeBookVal"));
    return (
        <>
            <TopNavbar />
            <Menubar NavBgstyle="outer-header hb" NavTextColor="text-dark" SubmenuTop="true" />
            <TamilJathagaBookViewBanner/>
            <TamilJathagaViewPerDet value={value}/>
            <TamilJathagaViewHoroDet value={value}/>
            <Guiding />
            <Footer />
        </>
    )
}
