import React, { lazy, Suspense } from 'react';
import { Helmet } from 'react-helmet';
import { useLocation } from 'react-router-dom';

// Lazy load components
const TopNavbar = lazy(() => import('../../Main-Components/TopNavbar/TopNavbar'));
const Menubar = lazy(() => import('../../Main-Components/Menubar/Menubar'));
const Footer = lazy(() => import('../../Main-Components/Footer/Footer'));
const CancellationPolicyBanner = lazy(() => import('../../Small-Components/CancellationPolicyBanner/CancellationPolicyBanner'));
const CancellationPolicyContent = lazy(() => import('../../Small-Components/CancellationPolicyContent/CancellationPolicyContent'));

export default function CancellationPolicy() {

  const location = useLocation();

  const canonicalPaths = {
    '/Cancellation-policy': '/Cancellation-policy',
  };

  const canonicalUrl =
    'https://onepagehoroscope.com' +
    (canonicalPaths[location.pathname] || location.pathname);

  return (
    <>
      <Helmet>
        <title>Onepage Horoscope - Cancellation Policy</title>
        <meta
          name="description"
          content="One Page Horoscope Cancellation policy"
        />
        <meta
          name="keywords"
          content="one page Horoscope, cancellation, Horoscope, Free Horoscope Software"
        />
        <meta name="robots" content="index,follow" />
        <link rel="canonical" href={canonicalUrl} />
        <script type="application/ld+json">
          {`
            {
              "@context": "https://schema.org/",
              "@type": "Product",
              "name": "OnepageHoroscope Cancellation Policy",
              "image": "",
              "description": "One Page Horoscope cancellation policy",
              "brand": {
                "@type": "Brand",
                "name": "One Page Horoscope"
              }
            }
          `}
        </script>
      </Helmet>

      {/* Suspense to handle loading states */}
      <Suspense fallback={<div>Loading...</div>}>
        <TopNavbar />
        <Menubar NavBgstyle="outer-header lp" NavTextColor="text-dark" SubmenuTop="true" />
        <CancellationPolicyBanner />
        <CancellationPolicyContent />
        <Footer />
      </Suspense>
    </>
  );
}
