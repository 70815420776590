import React from 'react'
import TamilJathagaBookForm from '../../Forms/TamilJathagaBookForm/TamilJathagaBookForm'
// import TamilJathagaBookCintentIcon1 from '../../images/hb-icon-1.webp'
// import TamilJathagaBookCintentIcon2 from '../../images/hb-icon-2.webp'
// import TamilJathagaBookCintentIcon3 from '../../images/hb-icon-3.webp'
// import TamilJathagaBookCintentIcon4 from '../../images/hb-icon-4.webp'
// import TamilJathagaBookCintentIcon5 from '../../images/hb-icon-5.webp'
// import TamilJathagaBookCintentIcon6 from '../../images/hb-icon-6.webp'
// import TamilJathagaBookCintentIcon7 from '../../images/hb-icon-7.webp'

export default function TamilJathagaBookContent() {
  return (
    <>
      <div className="f-wrapper">
        <div className="astro-container">
          <div className="tjb-row">
            <TamilJathagaBookForm/>
            <div className="content">
              <ul className="list ps-0">
                <li>
                  Your horoscope book will come to your house to consult an astrologer
                  about the future.
                </li>
                <li>
                  Get accurate predictions from onepagehoroscope.com updated by
                  various astrologers.
                </li>
                <li>
                  Double check the information you fill in once and get the fill book
                </li>
                <li>54 page horoscope book</li>
              </ul>
              <ul className="list-icons ps-0">
                <li>
                  <img src='https://ctsvsolutions.com/onepage-images/new-ui/jathaga-book-icon-1.webp' alt='nirayana spudam'/>
                  Nirayana spuds
                </li>
                <li>
                  <img src='https://ctsvsolutions.com/onepage-images/new-ui/jathaga-book-icon-2.webp' alt='birth horoscope book'/>
                  16 square wheels
                </li>
                <li>
                  <img src='https://ctsvsolutions.com/onepage-images/new-ui/jathaga-book-icon-3.webp' alt='numerology'/>
                  Numerology
                </li>
                <li>
                  <img src='https://ctsvsolutions.com/onepage-images/new-ui/jathaga-book-icon-4.webp' alt='udumaga dasa'/>
                  Udumaga direction -120 years
                </li>
                <li>
                  <img src='https://ctsvsolutions.com/onepage-images/new-ui/jathaga-book-icon-5.webp' alt='shoda varkkam'/>
                  Shodasha Varkadhibars
                </li>
                <li>
                  <img src='https://ctsvsolutions.com/onepage-images/new-ui/jathaga-book-icon-6.webp' alt='asta varkkam'/>9 Ashta class
                </li>
                <li>
                  <img src='https://ctsvsolutions.com/onepage-images/new-ui/jathaga-book-icon-7.webp' alt='30 year anthram'/>
                  Interval -30 years
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}
