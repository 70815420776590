import React, { Suspense } from 'react';
import { Helmet } from "react-helmet";
import BirthHoroscopeHomeContextProvider from '../../context/forms/BirthHoroscope_Home_ContextProvider';
import TopNavbar from '../../Main-Components/TopNavbar/TopNavbar';
const Menubar = React.lazy(() => import("../../Main-Components/Menubar/Menubar"));
const HomeBanner = React.lazy(() => import('../../Small-Components/HomeBanner/HomeBanner'));
const HomeProductIcons = React.lazy(() => import('../../Small-Components/HomeProductIcons/HomeProductIcons'));
const HomeProductCard = React.lazy(() => import('../../Small-Components/HomeProductCard/HomeProductCard'));
const AstrologyFeatures = React.lazy(() => import('../../Small-Components/AstrologyFeatures/AstrologyFeatures'));
const IndianAstrology = React.lazy(() => import('../../Small-Components/IndianAstrology/IndianAstrology'));
// const AstrologyExplaition = React.lazy(() => import('../../Small-Components/AstrologyExplaition/AstrologyExplaition'));
const Guiding = React.lazy(() => import('../../Main-Components/Guiding/Guiding'));
const RecentBlogCard = React.lazy(() => import("../../Main-Components/PageBlogCard/PageBlogCard"));
const FAQ = React.lazy(() => import('../../Main-Components/FAQ/FAQ'));
const Footer = React.lazy(() => import('../../Main-Components/Footer/Footer'));
const Review = React.lazy(() => import('../../Main-Components/Review/Review'));

export default function Home() {
  if (window.location.hostname === 'www.onepagehoroscope.com') {
    window.location.href = 'https://onepagehoroscope.com' + window.location.pathname + window.location.search;
  }

  return (
    <>
      <Helmet>
        <title>Free Online Horoscope in Tamil | Get Accurate Tamil ஜாதகம்</title>

        <meta
          name="description"
          content="Get an accurate online life horoscope in Tamil .Discover your destiny with birth charts, marriage matching, and Baby Name Horoscope Book Ruthu Jathagam." />
        <meta
          name="keywords"
          content="தமிழ் ஜாதகம், tamil horoscope,jathagam tamil, one page jathagam, online tamil jathagam, pirappu jathagam in tamil, tamil jathagam,horoscope in tamil, free horoscope in tamil, free tamil jathagam, ஜாதகம், tamil jathagam online free, tamil jathagam online, jathagam in tamil jathagam online tamil, jathagam, one page horoscope, free jathagam online, jathagam download,Life horoscope in tamil free,Life horoscope in tamil,Tamil horoscope matching,online horoscope in tamil,tamil astrology online"
        />
        <meta name="author" content="one page horoscope software" />
        <link rel="canonical" href="https://onepagehoroscope.com" />
        <meta name="robots" content="index,follow" />
        <meta property="og:title" content="one page horoscope" />
        <meta
          property="og:description"
          content=" onepage horoscope is astrology software company for all online Users. birthchar, online Marriage Matching, Thirumanaporutam, Free Tamil Jathagam - ஜாதகம், onepage astrology also provide software for janana jadhagam."
        />
        <meta
          property="og:image"
          content="https://onepagehoroscope.com//og-image.png"
        />
        <meta property="og:url" content="https://onepagehoroscope.com" />
        <script type="application/ld+json">
          {JSON.stringify({
            "@context": "https://schema.org/",
            "@type": "WebSite",
            "name": "one page horoscope",
            "url": "https://onepagehoroscope.com/",
            "potentialAction": {
              "@type": "SearchAction",
              "target": "{search_term_string}",
              "query-input": "required name=search_term_string"
            }
          })}
        </script>
      </Helmet>
      <BirthHoroscopeHomeContextProvider>
        <Suspense fallback={'Loading...'}>
          <TopNavbar />
          <Menubar />
          <HomeBanner />
          <HomeProductIcons />
          <HomeProductCard />
          <AstrologyFeatures />
          <IndianAstrology />
          <Guiding />
          <Review />
          <RecentBlogCard />
          <FAQ />
          <Footer />
        </Suspense>
      </BirthHoroscopeHomeContextProvider>
    </>
  );
}
