import React from 'react'
import { Link } from "react-router-dom";
import axios from "axios";
import { useEffect } from "react";
import Skeleton from 'react-loading-skeleton';
import { useState } from "react";

export default function BlogCategories() {

    const [loading, setLoading] = useState(true);
    const [HoroscopeCount, setHoroscopeCount] = useState('');
    const [MgCount, setMgCount] = useState('');
    const [BioCount, setBioCount] = useState('');
    useEffect(() => {
        axios.get('https://ctsvsolutions.com/onepage-Api/public/api/Blog-count')
            .then(response => {
                setHoroscopeCount(response.data.data.Horoscope);
                setMgCount(response.data.data.Marriage_Matching)
                setBioCount(response.data.data.Bio_Data);
                setLoading(false);
                console.log('response from api', response.data.data);
            })
            .catch(error => {
                console.error('Error fetching blog list:', error);
                setLoading(false);
            });
    }, []);

    return (
        <>
            <div className="col-12 mb-1">
                <h2 className="blog-heading text-danger fw-bold">
                    <i className="fa-solid fa-filter"></i>
                    Categories
                </h2>
                <hr />
            </div>
            {loading ? (
                <>
                    <div className="col-12 mb-1">
                        <div className="card bg-transparent mb-3 border-0 categories px-2">
                            <Skeleton height={30} width={200} />
                        </div>
                        <hr />
                    </div>
                    <div className="col-12 mb-1">
                        <div className="card bg-transparent mb-3 border-0 categories px-2">
                            <Skeleton height={30} width={200} />
                        </div>
                        <hr />
                    </div>
                </>
            ) : (
                <>
                    <div className="col-12 mb-1">
                        <div className="card bg-transparent mb-3 border-0 categories px-2">
                            <p className="mb-0">
                                <Link to="/Blog/category/Horoscope">Birth Horoscope</Link>
                                <span className="float-end">{HoroscopeCount}</span>
                            </p>
                        </div>
                        <hr />
                    </div>
                    <div className="col-12 mb-1">
                        <div className="card bg-transparent mb-3 border-0 categories px-2">
                            <p className="mb-0">
                                <Link to="/Blog/category/Marriage_matching">Marriage Matching</Link>

                                <span className="float-end">{MgCount}</span>
                            </p>
                        </div>
                        <hr />
                    </div>
                    <div className="col-12 mb-1">
                        <div className="card bg-transparent mb-3 border-0 categories px-2">
                            <p className="mb-0">
                                <Link to="/Blog/category/Bio_data">Bio Data</Link>

                                <span className="float-end">{BioCount}</span>
                            </p>
                        </div>
                        <hr />
                    </div>
                    <div className="col-12 mb-1">
                        <div className="card bg-transparent mb-3 border-0 categories px-2">
                            <p className="mb-0">
                                <Link to="/Blog/category/Numology">Numerology</Link>

                                <span className="float-end">{BioCount}</span>
                            </p>
                        </div>
                        <hr />
                    </div>
                </>
            )}
        </>
    )
}
