import React, {  useContext } from 'react';
import { Link } from 'react-router-dom';
import { MobileMenuContext } from '../../context/menu/MobileMenuContext';


export default function NavMenu({ NavBgstyle="", NavTextColor="", SubmenuTop="" }) {
    const [ShowMobileMenu, setMobileMenu] = useContext(MobileMenuContext);
    const toggleMenu = () => {
        setMobileMenu(!ShowMobileMenu);
    };
    return (
        <>
            <header className={`header ${NavBgstyle ? NavBgstyle : ''}`}>

                <div className="astro-container">
                    <div className="navrow">
                        <div className="logo">
                            <Link to='/'>
                                <img src='https://ctsvsolutions.com/onepage-images/new-ui/logo.webp' alt="onepage Horoscope Logo" width={'100%'} height={'100%'}  title='one page horoscope logo'/>
                            </Link>
                        </div>
                        <div className="navbarmenu ">
                            <ul className='ps-0 mb-0'>
                                <li className="mm ">
                                    <span className={`mega-menu ${NavTextColor}`}>
                                        Products
                                        <img src={NavTextColor === "text-dark" ? 'https://ctsvsolutions.com/onepage-images/new-ui/dropdown-arrow-dark.webp' : 'https://ctsvsolutions.com/onepage-images/new-ui/dropdown-arrow-light.webp'} alt=' dropdown icon' width={12} height={8} loading="lazy"/>
                                    </span>
                                    <ul className="sub-menu" style={{ top: SubmenuTop ? '120px' : '' }}>
                                        <div className="sub-menu-inner">
                                            <div className="menu-item">
                                                <Link to="/Birth-horoscope">
                                                    <div className="bg">
                                                        <div className="icon">
                                                            <img src='https://ctsvsolutions.com/onepage-images/new-ui/sub-menu-icon.webp' alt='birth horoscope' width={100} height={40} loading="lazy"/>
                                                        </div>
                                                        <div className="content">
                                                            <h4>Birth Horoscope</h4>
                                                            <p>Accurate report, for predicting life potential.</p>
                                                        </div>
                                                    </div>
                                                </Link>
                                            </div>
                                            <div className="menu-item">
                                                <Link to='/marriage-matching'>
                                                    <div className="bg">
                                                        <div className="icon">
                                                        <img src='https://ctsvsolutions.com/onepage-images/new-ui/sub-menu-icon.webp' alt='marriage matching' width={100} height={40} loading="lazy"/>
                                                        </div>
                                                        <div className="content">
                                                            <h4>Marriage Matching</h4>
                                                            <p>Marrige are made in heaven, we here give you the key.</p>
                                                        </div>
                                                    </div>
                                                </Link>
                                            </div>
                                            <div className="menu-item">
                                                <Link to="/Marriage-Bio-Data">
                                                    <div className="bg">
                                                        <div className="icon">
                                                        <img src='https://ctsvsolutions.com/onepage-images/new-ui/sub-menu-icon.webp'alt='marriage bio data' width={100} height={40} loading="lazy"/>
                                                        </div>
                                                        <div className="content">
                                                            <h4>Marriage Bio-Data</h4>
                                                            <p>We manifest about you to your life partner.</p>
                                                        </div>
                                                    </div>
                                                </Link>
                                            </div>
                                            <div className="menu-item">
                                                <Link to="/Baby-Name-List">
                                                    <div className="bg">
                                                        <div className="icon">
                                                        <img src='https://ctsvsolutions.com/onepage-images/new-ui/sub-menu-icon.webp' alt='baby name' width={100} height={40} loading="lazy"/>
                                                        </div>
                                                        <div className="content">
                                                            <h4>Baby Name</h4>
                                                            <p>Numerology inbuild names for you choice.</p>
                                                        </div>
                                                    </div>
                                                </Link>
                                            </div>
                                            <div className="menu-item">
                                                <Link to="/Ruthu-Jathagam">
                                                    <div className="bg">
                                                        <div className="icon">
                                                        <img src='https://ctsvsolutions.com/onepage-images/new-ui/sub-menu-icon.webp' alt='ruthu jathagam' width={100} height={40} loading="lazy"/>
                                                        </div>
                                                        <div className="content">
                                                            <h4>Ruthu Jathagam</h4>
                                                            <p>Age attent horoscope report for your girl children.</p>
                                                        </div>
                                                    </div>
                                                </Link>
                                            </div>
                                        </div>
                                    </ul>
                                </li>
                                <li className="mm">
                                    <span className={`mega-menu ${NavTextColor}`}>
                                        Services
                                        <img src={NavTextColor === "text-dark" ? 'https://ctsvsolutions.com/onepage-images/new-ui/dropdown-arrow-dark.webp' : 'https://ctsvsolutions.com/onepage-images/new-ui/dropdown-arrow-light.webp'} alt=' dropdown icon' width={12} height={8} loading="lazy"/>
                                    </span>
                                    <ul className="sub-menu" style={{ top: SubmenuTop ? '120px' : '' }}>
                                        <div className="sub-menu-inner">
                                            <div className="menu-item">
                                                <Link to="/Jathaga-Palan">
                                                    <div className="bg">
                                                        <div className="icon">
                                                        <img src='https://ctsvsolutions.com/onepage-images/new-ui/sub-menu-icon.webp' alt='audio prediction' width={100} height={40} loading="lazy"/>
                                                        </div>
                                                        <div className="content">
                                                            <h4>Audio Prediction</h4>
                                                            <p>Detail prediction about you, in audio format.</p>
                                                        </div>
                                                    </div>
                                                </Link>
                                            </div>
                                            <div className="menu-item">
                                                <Link to="/Tamil-jathagam-book">
                                                    <div className="bg">
                                                        <div className="icon">
                                                        <img src='https://ctsvsolutions.com/onepage-images/new-ui/sub-menu-icon.webp' alt='horoscope book' width={100} height={40} loading="lazy"/>
                                                        </div>
                                                        <div className="content">
                                                            <h4>hand written Horoscope Book</h4>
                                                            <p>Our special edition service for,who like vintage in every moment.</p>
                                                        </div>
                                                    </div>
                                                </Link>
                                            </div>
                                            <div className="menu-item">
                                                <Link to="/Talk-To-Astrologer">
                                                    <div className="bg">
                                                        <div className="icon">
                                                        <img src='https://ctsvsolutions.com/onepage-images/new-ui/sub-menu-icon.webp' alt='talk to astrologer' width={100} height={40} loading="lazy"/>
                                                        </div>
                                                        <div className="content">
                                                            <h4>Talk To Astrologer</h4>
                                                            <p>We suggest you certified astrologer for your query about your future.</p>
                                                        </div>
                                                    </div>
                                                </Link>
                                            </div>
                                        </div>
                                    </ul>
                                </li>
                                <li>
                                    <Link to="/Blog" className={`mega-menu ${NavTextColor}`}>Blogs</Link>
                                </li>
                                <li>
                                    <Link to="/About-us" className={`mega-menu ${NavTextColor}`}>About Us</Link>
                                </li>
                                <li>
                                    <Link to="/contact-us" className={`mega-menu ${NavTextColor}`}>Contact Us</Link>
                                </li>
                            </ul>
                            {/* <Link to="/Login" className="btn-sm">
                                Sign In
                                <img src='https://ctsvsolutions.com/onepage-images/new-ui/btn-arrow.webp' alt='one page sign in'/>
                            </Link> */}
                        </div>
                    </div>
                    <div className="mobile-menu">
                        <img src='https://ctsvsolutions.com/onepage-images/new-ui/mobile-menu.webp' onClick={toggleMenu} alt="onepage-mobile-menu" width={27} height={25} loading="lazy"/>
                    </div>
                </div>
            </header>

        </>
    )
}
