import React from 'react'
import TopNavbar from '../../Main-Components/TopNavbar/TopNavbar'
import Menubar from '../../Main-Components/Menubar/Menubar'
import Footer from '../../Main-Components/Footer/Footer'
import BabyNameViewBanner from '../../Small-Components/BabyNameViewBanner/BabyNameViewBanner'
import BabyNameViewDetail from '../../Small-Components/BabyNameViewDetail/BabyNameViewDetail'
import Guiding from '../../Main-Components/Guiding/Guiding'

export default function BabyNameView() {
    const value = JSON.parse(localStorage.getItem("BabyNameValues"));
    return (
        <>
            <TopNavbar />
            <Menubar NavBgstyle="outer-header bnr" NavTextColor="text-dark" SubmenuTop="true" />
            <BabyNameViewBanner/>
            <BabyNameViewDetail value={value}/>
            <Guiding/>
            <Footer />
        </>
    )
}
