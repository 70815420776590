import axios from "axios";
import { Loading } from "notiflix/build/notiflix-loading-aio";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faLocationDot } from "@fortawesome/free-solid-svg-icons";
import React, { useEffect, useContext } from "react";
import { useNavigate } from "react-router-dom";
import { BirthHoroscopeContext } from "../../context/forms/BirthHoroscope_Home_ContextProvider";

export default function BirthHoroscopeForm() {
  const { dayOptions, monthOptions, yearOptions, hourOptions, minuteOptions, birthPlace, setBirthPlace, userName,
    setUserName, userGender, setUserGender, suggestedPlaces, setSuggestedPlaces, selectedPlaces,
    setSelectedPlaces, selectedDay, setSelectedDay, selectedMonth, setSelectedMonth, selectedYear,
    setSelectedYear, selectedHour, setSelectedHour, selectedMinute, setSelectedMinute, selectedAmpm,
    setSelectedAmpm, nameError, setNameError, genderError, setGenderError, birthplaceError, setBirthplaceError, birthdateError,
    setBirthdateError, birthMonthError, setBirthMonthError, birthYearError, setBirthYearError, birthHourError, setBirthHourError, birthMinuteError, setBirthMinuteError, birthAmpmError,
    setBirthAmpmError, SwitchLocationButton, SetSwitchLocationButton,
    Timezone, setTimeZone, } = useContext(BirthHoroscopeContext);


  const handleLocationChange = (event) => {
    SetSwitchLocationButton(true)
    const currentValue = event.target.value;
    setBirthPlace(currentValue);
    fetchSuggestions(currentValue, 'geoname_cities');
  };

  const handleSuggestionSelect = (selectedPlace) => {
    SetSwitchLocationButton(false);
    const latitude = selectedPlace.latitude;
    const longitude = selectedPlace.longitude;
    const Timezone = selectedPlace.TimeZone;
    const parts = selectedPlace.districts.split(",");
    const displayName = parts.slice(0, 3).join(",");
    const location = displayName;

    const updatedSelectedPlaces = [...selectedPlaces, location];

    setSelectedPlaces(updatedSelectedPlaces);
    setBirthPlace(location);
    setBirthplaceError("");
    setTimeZone(Timezone);
    setSuggestedPlaces('');

    document.getElementById("atflg").value = longitude;
    document.getElementById("atflt").value = latitude;
  };

  const fetchSuggestions = (currentValue, LocationType) => {
    if (currentValue.length >= 1) {
      const Data = {
        location: currentValue,
        Type: LocationType,
      };
      console.log(Data);
      axios
        .post(
          "https://ctsvsolutions.com/onepage-Api/public/api/Location-Api",
          Data
        )
        .then((response) => {
          if (response.data.status == true) {
            // console.log(response.data.location);
            setSuggestedPlaces(response.data.location);
          } else {
            setSuggestedPlaces('');
          }
        });
    } else {
      setSuggestedPlaces('');
    }


  };
  const SearchNewLocation = () => {

    const currentLocation = birthPlace.includes(",")
      ? birthPlace.split(",")[0]
      : birthPlace;
    console.log(currentLocation);
    fetchSuggestions(currentLocation, 'Map_Box');
  };

  const navigate = useNavigate();

  const customRedirect = (route, data) => {
    navigate(route, { state: data });
  };

  const validation = () => {
    let IsValid = true;
    if (!userName) {
      setNameError("Field is required");
      IsValid = false;
    } else {
      setNameError("");
    }

    if (!userGender) {
      setGenderError("Field is required");
      IsValid = false;
    } else {
      setGenderError("");
    }

    if (!selectedDay || !selectedMonth || !selectedYear) {
      setBirthdateError("Field is required");
      IsValid = false;
    } else {
      setBirthdateError("");
    }

    if (!selectedHour) {
      setBirthHourError("Field is required");
      IsValid = false;
    } else {
      setBirthHourError("");
    }

    if (!selectedMinute) {
      setBirthMinuteError("Field is required");
      IsValid = false;
    } else {
      setBirthMinuteError("");
    }

    if (!selectedAmpm) {
      setBirthAmpmError("Field is required");
      IsValid = false;
    } else {
      setBirthAmpmError("");
    }

    if (!birthPlace) {
      setBirthplaceError("Field is required");
      IsValid = false;
    } else {
      setBirthplaceError("");
    }
    let LatitudeError = document.getElementById("atflt").value;

    if (!LatitudeError) {
      setBirthplaceError("Again Select the BirthPlace");
      IsValid = false;
    } else {
      setBirthplaceError("");
    }
    return IsValid;
  };

  const from_submit_button = (e) => {
    e.preventDefault();
    if (validation() === true) {
      const formdata = {
        username: userName,
        gender: userGender,
        userDateofBirth: `${selectedYear}-${selectedMonth}-${selectedDay}`,
        userBirthtime: `${selectedHour}:${selectedMinute} ${selectedAmpm}`,
        userBirthplace: birthPlace,
        latitude: document.getElementById("atflt").value,
        longitude: document.getElementById("atflg").value,
        TimeZone: Timezone
      };

      Loading.dots("Your Horoscope is processing...");

      axios
        .post(
          "https://ctsvsolutions.com/onepage-Api/public/api/Onepage-RandomInsert",
          formdata
        )
        .then((response) => {
          if (response.status === 200) {
            axios
              .post(
                "https://ctsvsolutions.com/onepage-Api/public/api/Onepage-Horoscope",
                formdata
              )
              .then((response) => {
                if (response.status === 200) {
                  Loading.remove();
                  customRedirect("/Birth-Table-view", response.data);
                  const value = JSON.stringify(response.data.data);
                  localStorage.setItem("onepageValues", value);
                  const formdatavalue = JSON.stringify(formdata);
                  localStorage.setItem("onepageformdata", formdatavalue);
                  const userInfo = {
                    user_id: null,
                    customerDataId: null,
                  };
                  const UserInfoValues = JSON.stringify(userInfo);
                  localStorage.setItem("userInfo", UserInfoValues);
                }
              });
          }
        })
        .catch((err) => {
          console.log(err);
        });
    }
  };

  useEffect(() => {
    const storedValue = localStorage.getItem("onepageformdata");
    if (storedValue) {
      const parsedValue = JSON.parse(storedValue);
      const dateofBirth = parsedValue.userDateofBirth;
      const [year, month, day] = dateofBirth.split("-");

      const Birthtime = parsedValue.userBirthtime;
      const timeComponents = Birthtime.split(" ");
      const time = timeComponents[0];
      const ampm = timeComponents[1];
      const [hour, minute] = time.split(":");

      setUserName(parsedValue.username);
      setUserGender(parsedValue.gender);
      setSelectedPlaces(parsedValue.userBirthplace);
      setSelectedDay(day);
      setSelectedMonth(month);
      setSelectedYear(year);
      setSelectedHour(hour);
      setSelectedMinute(minute);
      setSelectedAmpm(ampm);
      setTimeZone(parsedValue.Timezone);
      setBirthPlace(parsedValue.userBirthplace);
      document.getElementById("atflg").value = parsedValue.longitude;
      document.getElementById("atflt").value = parsedValue.latitude;
    }
    setSuggestedPlaces('');
  }, []);
  return (
    <>
      <form className="cmn-form">
        <h2 className="f-heading">Enter your birth details</h2>
        <p className="f-sub">
          Provide accurate details for the most precise horoscope report.
        </p>
        <div className="form-group">
          <label htmlFor="name">Full Name</label>
          <input type="text" placeholder="Enter Your Name" id="name" name="" className={`input ${nameError && "is-invalid "
            }`} value={userName}
            onChange={(e) => setUserName(e.target.value)} />
          {nameError && (
            <div className="invalid-feedback">
              {nameError}
            </div>
          )}
        </div>
        <div className="form-group">
          <label htmlFor="gender">Gender</label>
          <select className={`${genderError && "is-invalid"
            }`} value={userGender}
            onChange={(e) => setUserGender(e.target.value)} id="gender">
            <option value="">Gender</option>
            <option value="Male">Male</option>
            <option value="Female">Female</option>
          </select>
          {genderError && (
            <div className="invalid-feedback">{genderError}</div>
          )}
        </div>
        <div className="form-group">
          <label htmlFor="birthLocation">birth location</label>
          <input type="text" placeholder="Enter Your Birth Place" name="" className={`input ${birthplaceError && "is-invalid"
            }`} onChange={handleLocationChange}
            value={birthPlace} id="birthLocation" />
          {birthplaceError && (
            <div className="invalid-feedback  ">{birthplaceError}</div>
          )}

          <input
            id="atflt"
            className="pfipbx"
            name="the_lat"
            type="hidden"
            placeholder="Latitude"
          />
          <input
            id="atflg"
            className="pfipbx"
            name="the_lng"
            type="hidden"
            placeholder="Longitude"
          />
          {suggestedPlaces && (
            <ul className="p-0 mt-1 mb-0 overflow-hidden">
              {suggestedPlaces.reverse().map((place, index) => {
                if (place.districts) {
                  const listItemStyle = {
                    padding: "5px 12px",
                    cursor: "pointer",
                    marginBottom: "1px",
                    backgroundColor: "#f2f2f2",
                    fontSize: "14px",
                    fontWeight: "540",
                    borderBottom: "1px dashed #ddd",
                  };

                  return (
                    <React.Fragment key={index}>
                      <p
                        style={listItemStyle}
                        className="px-2"
                        onClick={() => handleSuggestionSelect(place)}
                      >
                        <FontAwesomeIcon icon={faLocationDot} />
                        &nbsp;
                        {place.districts}
                      </p>
                    </React.Fragment>
                  );
                }
                return null;
              })}

            </ul>
          )}
          {SwitchLocationButton && (
            <p
              className="w-100 text-start btn py-0 rounded-0 text-white fw-bold mb-0 mt-0"
              style={{ fontSize: "14px", backgroundColor: "#fe1b57" }}
              onClick={SearchNewLocation}
            >
              {" "}
              <FontAwesomeIcon icon={faLocationDot} /> my place is not
              in List
            </p>
          )}
        </div>
        <div className="form-group">
          <label htmlFor="dob">date of birth</label>
          <div className="row">
            <div className="form-group">
              <label htmlFor='date'>date</label>
              <select className={`${birthdateError && "is-invalid"
                }`} value={selectedDay}
                onChange={(e) => setSelectedDay(e.target.value)} id="date">
                <option value="">Date</option>
                {dayOptions.map((day) => (
                  <option key={day} value={day}>
                    {day}
                  </option>
                ))}
              </select>
              {birthdateError && (
                <div className="invalid-feedback ">{birthdateError}</div>
              )}
            </div>
            <div className="form-group">
              <label htmlFor='month'>month</label>
              <select className={`${birthdateError && "is-invalid"
                }`} value={selectedMonth}
                onChange={(e) => setSelectedMonth(e.target.value)} id="month">
                <option value="">Month</option>
                {monthOptions.map((month) => (
                  <option key={month} value={month}>
                    {month}
                  </option>
                ))}
              </select>
            </div>
            <div className="form-group">
              <label htmlFor='year'>year</label>
              <select className={`${birthdateError && "is-invalid"
                }`} value={selectedYear}
                onChange={(e) => setSelectedYear(e.target.value)} id="year">
                <option value="">Year</option>
                {yearOptions.map((year) => (
                  <option key={year} value={year}>
                    {year}
                  </option>
                ))}
              </select>
            </div>
          </div>
        </div>
        <div className="form-group">
          <label htmlFor="birthTime">birth time</label>
          <div className="row">
            <div className="form-group">
              <label htmlFor='hour'>hour</label>
              <select className={`${birthHourError && "is-invalid"
                }`} value={selectedHour}
                onChange={(e) => setSelectedHour(e.target.value)} id="hour">
                <option value="">Hour</option>
                {hourOptions.map((hour) => (
                  <option key={hour} value={hour}>
                    {hour}
                  </option>
                ))}
              </select>
              {birthHourError && (
                <div className="invalid-feedback">
                  {birthHourError}
                </div>
              )}
            </div>
            <div className="form-group">
              <label htmlFor='minute'>minute</label>
              <select className={`${birthMinuteError && "is-invalid"
                }`} value={selectedMinute}
                onChange={(e) => setSelectedMinute(e.target.value)} id="minute">
                <option value="">Minute</option>
                {minuteOptions.map((minute) => (
                  <option key={minute} value={minute}>
                    {minute}
                  </option>
                ))}
              </select>
              {birthMinuteError && (
                <div className="invalid-feedback">
                  {birthMinuteError}
                </div>
              )}
            </div>
            <div className="form-group">
              <label htmlFor="am/pm">am/pm</label>
              <select className={`${birthAmpmError && "is-invalid"
                }`} value={selectedAmpm}
                onChange={(e) => setSelectedAmpm(e.target.value)} id="am/pm">
                <option value="">AM/PM</option>
                <option value="AM">AM</option>
                <option value="PM">PM</option>
              </select>
              {birthAmpmError && (
                <div className="invalid-feedback  ">{birthAmpmError}</div>
              )}
            </div>
          </div>
        </div>
        <div className="form-group-btn">
          <button type="submit" className="form-btn" onClick={from_submit_button}>
            show my birth horoscope
            <img src="https://ctsvsolutions.com/onepage-images/new-ui/btn-arrow-white.webp" alt="birth chart arrow" width={18} height={15} loading="lazy"/>
          </button>
        </div>
        <div className="mt-3">
          <p className="f-sub text-danger text-center mb-0">
            * Your horoscope will be generated in Tamil
          </p>
        </div>
      </form>
    </>
  )
}
