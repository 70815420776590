import React from 'react'

function RuthuViewContextProvider({children}) {
    const value = JSON.parse(localStorage.getItem("RuthuVal"));
    const personal_info=value.personal_info;
    const rasiChat=value.rasi_array;
    const amsamChart=value.amsam_array;
  return (
    <RuthuViewContext.Provider value={{value,personal_info,rasiChat,amsamChart}}>
          {children}
    </RuthuViewContext.Provider>
  )
}
export const RuthuViewContext=React.createContext();

export default RuthuViewContextProvider