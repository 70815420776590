import React from 'react'
// import MarriageMatchingIllus from '../../images/mm-illus.webp'
// import MarriageMatchingIllusMobile from '../../images/marriage-matching-illu-mobile.webp'

export default function MarriageMatchingBanner() {
    return (
        <>
            <section className="page-outer l-pnk">
                <div className="astro-container">
                    <div className="row">
                        <div className="col">
                            <div className="content">
                                <h1 className="sh">
                                Horoscope
                                    <span className="grt"> Matching</span>
                                </h1>
                                <p className="p-outer">
                                    In this area Marriage Porutham plays a vital role in order to
                                    nullify any bad effects after marriage.
                                </p>
                                <div className="mm-illus">
                                    <img src='https://ctsvsolutions.com/onepage-images/new-ui/mm-illus.webp' alt='jathaga porutham'/>
                                </div>
                                <div className="mm-illus-mobile">
                                    <img
                                        className="img-res"
                                        src='https://ctsvsolutions.com/onepage-images/new-ui/marriage-matching-illu-mobile.webp' alt='free jathaga porutham'
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}
