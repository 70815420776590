import React from 'react'
import JathagaPalanForm from '../../Forms/JathagaPalanForm/JathagaPalanForm'
// import JathagaPalanSubLine from '../../images/sub-line.webp'

export default function JathagaPalanBanner() {
    return (
        <>
            <section className="page-outer center">
                <div className="astro-container">
                    <div className="row">
                        <div className="col">
                            <div className="content">
                                <div className="sub">
                                    <h4>Audio prediction</h4>
                                    <img src='https://ctsvsolutions.com/onepage-images/new-ui/sub-line.webp' alt='birth horoscope in audio'/>
                                    <h4>hand written Horoscope Book</h4>
                                    <img src='https://ctsvsolutions.com/onepage-images/new-ui/sub-line.webp' alt='tamil jathagam audio prediction'/>
                                    <h4>Astro talk</h4>
                                    <div className="clear" />
                                </div>
                                <h1 className="sh lh-1">
                                    Jathaga palan - <span className="grt">ஜாதக பலன்</span>
                                </h1>
                                <p className="p-outer">
                                    * Our astrologer will analyze your birth horoscope and send your
                                    result as audio to your whatsapp number soon.
                                </p>
                            </div>
                        </div>
                    </div>
                    <JathagaPalanForm />
                </div>
            </section>
        </>
    )
}
