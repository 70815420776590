import React from 'react'
import TopNavbar from '../../Main-Components/TopNavbar/TopNavbar'
import Menubar from '../../Main-Components/Menubar/Menubar'
import Footer from '../../Main-Components/Footer/Footer'
import BlogListBanner from '../../Small-Components/BlogListBanner/BlogListBanner'
import BlogRecent from "../../Small-Components/BlogRecent/BlogRecent";
import BlogCategories from "../../Small-Components/BlogCategories/BlogCategories ";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Link, useLocation } from 'react-router-dom'
import { faFacebook, faInstagram, faLinkedin, faTwitter } from '@fortawesome/free-brands-svg-icons'
import { Helmet } from 'react-helmet'
import BirthHoroscopeForm from '../../Forms/BirthHoroscopeForm/BirthHoroscopeForm';
import BirthHoroscopeHomeContextProvider from "../../context/forms/BirthHoroscope_Home_ContextProvider"

export default function HoroscopeBlog() {

    const location = useLocation();
    const canonical = () => {
        const base_url = "https://onepagehoroscope.com";
        const current_path = location.pathname;

        const canonicalPaths = {
            "/Blog/tamil-astrology-12-houses-explained": "/Blog/tamil-astrology-12-houses-explained",

        };

        return (
            <link
                rel="canonical"
                href={base_url + (canonicalPaths[current_path] || current_path)}
            />
        );
    };

    return (
        <>
            <Helmet>
                <title> Astrology in tamil - Learn about the 12 house of Astrology</title>
                <meta
                    name="description"
                    content="Get a detailed astrology in Tamil of your birth horoscope in Tamil with this astrology service. Understand the influences of the planets at the time of birth."
                />
                <meta
                    name="keywords"
                    content="Tamil Jathagam"
                />
                <meta name="robots" content="index,follow" />
                {canonical()}
                <script type="application/ld+json">
                    {JSON.stringify({

                        "@context": "https://schema.org",
                        "@type": "BlogPosting",
                        "mainEntityOfPage": {
                            "@type": ".webpage",
                            "@id": "https://onepagehoroscope.com/Blog/tamil-astrology-12-houses-explained"
                        },
                        "headline": "Learn about the 12 house of Astrology in Tamil",
                        "description": "Get a detailed astrology in Tamil of your birth horoscope in Tamil with this astrology service. Understand the influences of the planets at the time of birth.",
                        "image": "https://ctsvsolutions.com/onepage-images/astrology-in-tamil.webp",
                        "author": {
                            "@type": "",
                            "name": ""
                        },
                        "publisher": {
                            "@type": "Organization",
                            "name": "",
                            "logo": {
                                "@type": "ImageObject",
                                "url": ""
                            }
                        },
                        "datePublished": "2024-02-06",
                        "dateModified": "2024-03-21"
                    })}
                </script>
            </Helmet>
            <TopNavbar />
            <Menubar NavBgstyle="outer-header rjr" NavTextColor="text-dark" SubmenuTop="true" />
            <BlogListBanner />
            <HoroscopeBlogContent />
            <Footer />
        </>
    )
}

function HoroscopeBlogContent() {
    return (
        <>
            <section className="article-wrapper">
                <div className="astro-container pt-3">
                    <div className="row">
                        <div className="col-lg-8 my-2">
                            <div className="card bg-transparent border-0 blog-view">
                                <div className="card-header bg-transparent border-0 px-0">
                                    <h1 className="fs-4 blog-view-heading">
                                        Learn about the 12 house of Astrology in Tamil
                                    </h1>
                                </div>
                                <div className="card-body pt-0 px-0">
                                    <div className="my-2">
                                        <img
                                            src="https://ctsvsolutions.com/onepage-images/astrology-in-tamil.webp"
                                            alt="onepage horoscope 12 houses"
                                            className="img-fluid rounded"
                                        />
                                    </div>
                                    <div className="my-4 d-flex">
                                        <a href="https://www.facebook.com/onepagehoroscope/">
                                            <div className="view-blog-icons facebook">
                                                <FontAwesomeIcon icon={faFacebook} className="text-white px-2" />
                                            </div>
                                        </a>
                                        <a href="https://www.instagram.com/one_page_horoscope/">
                                            <div className="view-blog-icons instagram">
                                                <FontAwesomeIcon icon={faInstagram} className="text-white px-2" />
                                            </div>
                                        </a>
                                        <a href="https://twitter.com/onepage321">
                                            <div className="view-blog-icons twitter">
                                                <FontAwesomeIcon icon={faTwitter} className="text-white px-2" />
                                            </div>
                                        </a>
                                        <a href="https://linkedin.com/showcase/97871433/admin/feed/posts/">
                                            <div className="view-blog-icons linkedin">
                                                <FontAwesomeIcon icon={faLinkedin} className="text-white px-2" />
                                            </div>
                                        </a>
                                    </div>
                                    <div className="description mt-3">
                                        <p className="mb-3">
                                            நமது நாட்டில் <Link to="/Birth-Horoscope" className="text-decoration-none"><span className="text-danger">(Astrology in tamil)</span></Link>   12 வீடுகளின்  தன்மை  பிறந்த நேரத்தின் கால குறியீடுகளை அடிப்படையாகக் கொண்டுள்ளன. இவை ஒவ்வொரு மாற்றத்திற்கும் குறிப்புகளையும் பொருந்துகின்றன. இந்த 12 வீடுகள் உங்கள் உடல், மனம், ஆன்மா, காதல் வாழ்க்கை, பொறுப்புகள், தொழில், பொறுப்புகள், யோகம் முதலியவற்றைக் குறிக்கும். ஒவ்வொரு வீட்டின் செயல்பாடு உங்கள் வாழ்க்கையை பிரதிநிதிக்கும் முக்கியமான பகுப்புகளை குறிக்கும்
                                        </p>
                                        <div className="mb-3">
                                        <BirthHoroscopeHomeContextProvider>
                                        <BirthHoroscopeForm />
                                        </BirthHoroscopeHomeContextProvider>
                                        </div>
                                        {/* <div className="my-2">
                                            <BirthHorosopeBlogForm />
                                        </div> */}
                                        <div>
                                            <h2 className="mb-3 fs-5 blog-view-heading">
                                                முதல் வீடு - 1st house :
                                            </h2>
                                            <p className="description-content mb-3">
                                                முதல் வீடு என்பது லக்கினத்தை குறிக்கும். இந்த வீடு ஜாதகரின்
                                                வாழ்க்கையை சொல்கிறது. ஒருவருடைய உடல் , வலிமை வாய்மை நிறம்
                                                ஆகியவற்றை லக்கினம் கூறுகிறது .
                                            </p>
                                        </div>
                                        <div>
                                            <h2 className="mb-3 fs-5 blog-view-heading">
                                                இரண்டாம் வீடு - 2nd house :
                                            </h2>
                                            <p className="description-content mb-3">
                                                இரண்டாம் வீடு என்பது ஜாதகத்தையும் ஜாதகரின் குடும்பம்
                                                உறுப்பினர்களையும் குறிக்கும். ஜாதகத்திற்கு இரண்டாம் வீடு
                                                முக்கியமானது . இரண்டாம் வீடு கல்வி, பொன், பொருள் உள்நாட்டு ,
                                                வெளிநாட்டுப் பிரயாணங்கள் ஆகியவற்றை அறிய உதவுகிறது.
                                            </p>
                                        </div>
                                        <div>
                                            <h2 className="mb-3 fs-5 blog-view-heading">
                                                மூன்றாம் வீடு - 3rd house:
                                            </h2>
                                            <p className="description-content mb-3">
                                                மூன்றாம் வீடு என்பது ஜாதகரின் சகோதர, சகோதரிகளை பற்றி அறிய
                                                உதவுகிறது. சகோதர, சகோதரிகள் நல்ல அந்தஸ்தோடு இருப்பார்கள்.
                                                அவர்களால் ஜாதகருக்கு எல்லாவிதத்திலும் உதவிகள் கிடைக்கும்.
                                                ஜாதகரின் மனவலிமை,செயல் துணிவு ஆயுள் ஆகியவற்றை அறிய உதவும்.
                                            </p>
                                        </div>
                                        <div>
                                            <h2 className="mb-3 fs-5 blog-view-heading">
                                                நான்காம் வீடு - 4th house :
                                            </h2>
                                            <p className="description-content mb-3">
                                                நான்காம் வீடு என்பது சொந்த நலன்களுக்கும் மகிழ்ச்சிக்கும் உரியது.
                                                தாய், தாய்மாமன் ,வாகனம், உறவினர், மூதாதையார் சொத்து, பயிர்,
                                                நிலம், கால்நடை ஆகியவற்றை அறிய பயன்படுகிறது .
                                            </p>
                                        </div>
                                        <div>
                                            <h2 className="mb-3 fs-5 blog-view-heading">
                                                ஐந்தாம் வீடு - 5th house:
                                            </h2>
                                            <p className="description-content mb-3">
                                                இதுவே பூர்வ புண்ணிய ஸ்தானம் , புத்திர ஸ்தானமாகும். . இந்த வீடு
                                                ஜாதகரின் திறமை தகப்பனார் வலிச்சொத்து , அரசாங்க அனுகூலம் ,
                                                மதிப்பு , உடல்நலம் ஆகிய அனைத்து நலன்களும் இந்த பாவத்தின்
                                                தன்மைகேற்பவே அமையும்.
                                            </p>
                                        </div>
                                        <div>
                                            <h2 className="mb-3 fs-5 blog-view-heading">
                                                ஆறாம் வீடு - 6th house :
                                            </h2>
                                            <p className="description-content mb-3">
                                                இதுவே சத்ரு ஸ்தானமாகும் இந்த வீடு வீணான தொல்லைகள் , உடல் நோய் ,
                                                அச்சம் , கடன் தொல்லைகள் ஆகிய அனைத்தையும் அளிக்கக் கூடியது. இந்த
                                                வீட்டில் ஏதாவது ஒரு நல்ல கிரகம் தங்கினாலோ அல்லது நல்ல கிரகத்தின்
                                                பார்வை ஏற்பட்டாலோதான் தொல்லைகள் குறைய வழிபிறக்கும்.
                                            </p>
                                        </div>
                                        <div>
                                            <h2 className="mb-3 fs-5 blog-view-heading">
                                                ஏழாம் வீடு - 7th house :
                                            </h2>
                                            <p className="description-content mb-3">
                                                இதுவே களத்திர ஸ்தானமாகும். இந்த ஏழாவது வீடு கணவன் மனைவி
                                                தாம்பத்திய உறவு , எண்ணங்கள் ஆகியவற்றை குறிப்பதாகும். திருமணம் ,
                                                கண்ணிரைத்த வாழ்க்கை துணை , சௌபாக்கிய வாழ்வு மனைவி மூலமாக சொத்து
                                                , லாட்டரி , பந்தயம் முதலியவற்றில் வெற்றி ஆகியவற்றை இந்த வீடு
                                                நிர்ணயிக்கும்.
                                            </p>
                                        </div>
                                        <div>
                                            <h2 className="mb-3 fs-5 blog-view-heading">
                                                எட்டாம் வீடு - 8th house :
                                            </h2>
                                            <p className="description-content mb-3">
                                                இதுவே ஆயுள் ஸ்தானமாகும். இதுவே ஜாதகரின் ஆயுள் பாவத்தை
                                                நிர்ணயிப்பதாகும். இந்த வீடு நன்கு அமைய பெற்றிருந்தால் , ஜாதகர்
                                                ஆரோக்கியமான நீண்ட ஆயுளை பெறுவார்.ஜாதகரின் வாழ்நாள் நீண்டதாக அமைய
                                                ராகு மேஷம் ரிஷபம் கடகம் சிம்மம் மகரம் ஆகிய ராசிகளில்
                                                தங்கிருந்தாலே போதுமானது.
                                            </p>
                                        </div>
                                        <div>
                                            <h2 className="mb-3 fs-5 blog-view-heading">
                                                ஒன்பதாம் வீடு - 9th house :
                                            </h2>
                                            <p className="description-content mb-3">
                                                இதுவே பிதுர்ஸ்தானம் பாக்கிய ஸ்தானமாகும். இது அதிஷ்டம் , தர்மம் ,
                                                கர்பம் , ஆகியவை பற்றியும் தந்தையின் நலன் பற்றியும் குறிக்கும்
                                                பாவமாகும். சொந்த சுகம் , தந்தை யாரின் உடல்நலம் , அவருடைய நன்மை
                                                தீமைகள் தந்தைக்கும் மகனுக்கும் உள்ள உறவு முறைகள் , பாசம்
                                                ஆகியவற்றையும் இந்த பாவமே நிர்ணயிக்கும்.
                                            </p>
                                        </div>
                                        <div>
                                            <h2 className="mb-3 fs-5 blog-view-heading">
                                                பத்தாம் வீடு - 10th house:
                                            </h2>
                                            <p className="description-content mb-3">
                                                இதுவே கர்ம ஸ்தானம். தொழில் ஸ்தானமாகும். தொழில் , வாழ்க்கை நிலை ,
                                                கர்மம் ஆகியவற்றிக்குப் பத்தாவது வீடே முக்கியமானது. பத்தாவது
                                                பாவமே அவற்றிக்கு உரியதாகும். பத்தாவது வீடு காலியாக இல்லாமல்
                                                ஏதாவது ஒரு கிரகம் அமர்ந்திருந்தால் மிகவும் நலமாக , இருக்கும்.
                                            </p>
                                        </div>
                                        <div>
                                            <h2 className="mb-3 fs-5 blog-view-heading">
                                                பதினொன்றாம் வீடு - 11th house:
                                            </h2>
                                            <p className="description-content mb-3">
                                                இதுவே லாபஸ்தானம் , மூத்த சகோதர ஸ்தானமாகும். இதை லாப பாவம் என்று
                                                அழைப்பார்கள் செய்தொழிலில் நன்மை, நண்பர்கள், சுற்றத்தார் ஆகியோர்
                                                ஆதரவு வாழ்க்கையின் ஆசாபாசங்கள் நிறைவேறுதல் சொத்து சுகம்
                                                முதலியவற்றிற்கு இந்த வீட்டையே கொள்ளலாம்.
                                            </p>
                                        </div>
                                        <div>
                                            <h2 className="mb-3 fs-5 blog-view-heading">
                                                பன்னிரெண்டாம் வீடு - 12th house:
                                            </h2>
                                            <p className="description-content mb-3">
                                                இதுவே விரயஸ்தானம் , சயன ஸ்தானமாகும். இந்த வீடு விரயம் எனப்படும்.
                                                பன்னிரெண்டாவது வீடு பொருள் இழப்பையும் தொல்லைகளையும் அளிக்கும்.
                                                மற்றும் உறவினர் நண்பர்களை பகைவர்களாக செய்யும் உடல்நலம்
                                                பாதிக்கப்படும் வழக்குகளை வீண் கவலைகளை ஏற்படுத்தும்.
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-4 my-2">
                            <div className="row">
                                <BlogRecent />
                                <BlogCategories />
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}