import React from 'react'

export default function Chart({ rasiChat, amsamChart, type, chartName }) {

    console.log(type)
    const AmsamChartAlignment = (data) => {
        var alignment = data.split(',');
        return alignment.map((value, key) => (
            key % 2 !== 0 ? (<span key={key}> {value} </span>) : key === 0 ? (<span key={key}> {value} </span>) : (<><br /><span key={key}>{value}</span></>)
        ));
    };
    const RasiChartAlignment = (data) => {
        var alignment = data.split(' ');
        return alignment.map((value, key) => (
            key % 2 !== 0 ? (<span key={key}> {value} </span>) : key === 0 ? (<span key={key}> {value} </span>) : (<><br /><span key={key}>{value}</span></>)
        ));
    };

    return (
        <>
            <div className="chart-grid">
                <div className="parent">
                    <div className="grid div2 K12">
                        <div>
                            {type === "rasi-chart" ? RasiChartAlignment(rasiChat.r12) : AmsamChartAlignment(amsamChart.a12)}
                        </div>
                    </div>
                    <div className="grid div3 K1">
                        <div>
                            {type === "rasi-chart" ? RasiChartAlignment(rasiChat.r1) : AmsamChartAlignment(amsamChart.a1)}
                        </div>
                    </div>
                    <div className="grid div4 K2">
                        <div>
                            {type === "rasi-chart" ? RasiChartAlignment(rasiChat.r2) : AmsamChartAlignment(amsamChart.a2)}
                        </div>
                    </div>
                    <div className="grid div5 K3">
                        <div>
                            {type === "rasi-chart" ? RasiChartAlignment(rasiChat.r3) : AmsamChartAlignment(amsamChart.a3)}
                        </div>
                    </div>
                    <div className="grid div6 K11">
                        <div>
                            {type === "rasi-chart" ? RasiChartAlignment(rasiChat.r11) : AmsamChartAlignment(amsamChart.a11)}
                        </div>
                    </div>
                    <div className="grid div7 K10">
                        <div>
                            {type === "rasi-chart" ? RasiChartAlignment(rasiChat.r10) : AmsamChartAlignment(amsamChart.a10)}
                        </div>
                    </div>
                    <div className="grid div8 K9">
                        <div>
                            {type === "rasi-chart" ? RasiChartAlignment(rasiChat.r9) : AmsamChartAlignment(amsamChart.a9)}
                        </div>
                    </div>
                    <div className="grid div9 K8">
                        <div>
                            {type === "rasi-chart" ? RasiChartAlignment(rasiChat.r8) : AmsamChartAlignment(amsamChart.a8)}
                        </div>
                    </div>
                    <div className="grid div10 K7">
                        <div>
                            {type === "rasi-chart" ? RasiChartAlignment(rasiChat.r7) : AmsamChartAlignment(amsamChart.a7)}
                        </div>
                    </div>
                    <div className="grid div11 K6">
                        <div>
                            {type === "rasi-chart" ? RasiChartAlignment(rasiChat.r6) : AmsamChartAlignment(amsamChart.a6)}
                        </div>
                    </div>
                    <div className="grid div12 K5">
                        <div>
                            {type === "rasi-chart" ? RasiChartAlignment(rasiChat.r5) : AmsamChartAlignment(amsamChart.a5)}
                        </div>
                    </div>
                    <div className="grid div13 K4">
                        <div>
                            {type === "rasi-chart" ? RasiChartAlignment(rasiChat.r4) : AmsamChartAlignment(amsamChart.a4)}
                        </div>
                    </div>
                    <div className="grid div14">
                        <div className="center-content">
                            <h4 className='my-0'>{chartName}</h4>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}
