import React from 'react'
import TopNavbar from '../../Main-Components/TopNavbar/TopNavbar'
import Menubar from '../../Main-Components/Menubar/Menubar'
import Footer from '../../Main-Components/Footer/Footer'
import MarriageMatchingPaymentSuccessBanner from '../../Small-Components/MarriageMatchingPaymentSuccessBanner/MarriageMatchingPaymentSuccessBanner'
import MarriageMatchingPaymentSuccessContent from '../../Small-Components/MarriageMatchingPaymentSuccessContent/MarriageMatchingPaymentSuccessContent'

export default function MarriageMatchingPaymentSuccess() {
  return (
    <>
      <TopNavbar />
      <Menubar NavBgstyle="outer-header bc-outer" NavTextColor="text-dark" SubmenuTop="true" />
      <MarriageMatchingPaymentSuccessBanner />
      <MarriageMatchingPaymentSuccessContent />
      <Footer />
    </>
  )
}
