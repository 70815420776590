import React from 'react'
import TopNavbar from '../../Main-Components/TopNavbar/TopNavbar'
import Menubar from '../../Main-Components/Menubar/Menubar'
import Footer from '../../Main-Components/Footer/Footer'
import RuthuJathagaViewBanner from '../../Small-Components/RuthuJathagaViewBanner/RuthuJathagaViewBanner'
import RuthuJathagaViewPerDet from '../../Small-Components/RuthuJathagaViewPerDet/RuthuJathagaViewPerDet'
import RuthuJathagaViewHoroDet from '../../Small-Components/RuthuJathagaViewHoroDet/RuthuJathagaViewHoroDet'
import RuthuJathagaViewChart from '../../Small-Components/RuthuJathagaViewChart/RuthuJathagaViewChart'
import RuthuViewContextProvider from '../../context/viewpage/RuthuViewContextProvider'
import Review from '../../Main-Components/Review/Review'

export default function RuthuJathagaView() {
  return (
    <>
      <TopNavbar />
      <Menubar NavBgstyle="outer-header rjr" NavTextColor="text-dark" SubmenuTop="true" />
      <RuthuViewContextProvider>
        <RuthuJathagaViewBanner />
        <RuthuJathagaViewPerDet />
        <RuthuJathagaViewHoroDet />
        <RuthuJathagaViewChart />
        <Review />
      </RuthuViewContextProvider>

      <Footer />
    </>
  )
}
