import React, { createContext } from 'react'

function BioViewContextProvider({children}) {
    const value = JSON.parse(localStorage.getItem("BioValues"));
   const personalInfo=value.personal_info;
   const rasi_array=value.rasi_array;
   const rasivalue=value.rasivalue;
   const pavaga_maruthal=value.pavaga_maruthal;
   const macthing_star=value.macthing_star;
   const amsam_slash=value.amsam_slash;
   const amsam_array=value.amsam_array;
   const BioDataValues={
    value,personalInfo,
    rasi_array,
    rasivalue,pavaga_maruthal,macthing_star,amsam_slash,amsam_array
   }
  return (
    
    <BioViewContext.Provider  value={BioDataValues}>
        {children}
    </BioViewContext.Provider>
  );
}

export const BioViewContext=createContext();
export default BioViewContextProvider