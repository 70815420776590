import React from "react";
import { useState, useEffect } from "react";
import axios from "axios";
import { Loading } from "notiflix/build/notiflix-loading-aio";
import { useNavigate } from "react-router-dom";
import { faLocationDot } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

export default function BabyNameForm() {
  const [selectedDay, setSelectedDay] = useState("");
  const [selectedMonth, setSelectedMonth] = useState("");
  const [selectedYear, setSelectedYear] = useState("");
  const [selectedHour, setSelectedHour] = useState("");
  const [selectedMinute, setSelectedMinute] = useState("");
  const [selectedAmpm, setSelectedAmpm] = useState("");
  const [userGender, setUserGender] = useState("");
  const [fatherName, setFatherName] = useState("");
  const [MotherName, setMotherName] = useState("");
  const [initial, setInitial] = useState("father");
  const [birthPlace, setBirthPlace] = useState("");
  const [suggestedPlaces, setSuggestedPlaces] = useState([]);
  const [selectedPlaces, setSelectedPlaces] = useState([]);
  const [TimeZone, setTimeZone] = useState('');
  const [birthplaceError, setBirthplaceError] = useState("");
  const [genderError, setGenderError] = useState("");
  const [birthdateError, setBirthdateError] = useState("");
  const [birthHourError, setBirthHourError] = useState("");
  const [birthMinuteError, setBirthMinuteError] = useState("");
  const [birthAmpmError, setBirthAmpmError] = useState("");
  const [monthError, setMonthError] = useState("");
  const [yearError, setYearError] = useState("");
  const [FatherNameError, setFatherNameError] = useState("");
  const [motherNameError, setMotherNameError] = useState("");
  const [latitudeValue, setLatitude] = useState('');
  const [LongitudeValue, setLongitude] = useState('');
  const [SwitchLocationButton, SetSwitchLocationButton] = useState(false);

  const [dayOptions] = useState(
    [...Array(31).keys()].map(day => String(day + 1).padStart(2, "0"))
  );
  const [monthOptions] = useState(
    [...Array(12).keys()].map(month => String(month + 1).padStart(2, "0"))
  );
  const [yearOptions] = useState(() => {
    const currentYear = new Date().getFullYear();
    const startYear = 1950;
    return [...Array(currentYear - startYear + 1).keys()].map(
      year => startYear + year
    );
  });
  const [hourOptions] = useState(
    [...Array(12).keys()].map(hour => String(hour + 1).padStart(2, "0"))
  );
  const [minuteOptions] = useState(
    [...Array(60).keys()].map(minute => String(minute).padStart(2, "0"))
  );

  const navigate = useNavigate();
  const customRedirect = (route, data) => {
    navigate(route, { state: data });
  };

  const handleLocationChange = (event) => {
    SetSwitchLocationButton(true)
    const currentValue = event.target.value;
    setBirthPlace(currentValue);
    fetchSuggestions(currentValue, 'geoname_cities');
  };

  const handleSuggestionSelect = (selectedPlace) => {
    SetSwitchLocationButton(false);
    const latitude = selectedPlace.latitude;
    const longitude = selectedPlace.longitude;
    const Timezone = selectedPlace.TimeZone;
    const parts = selectedPlace.districts.split(",");
    const displayName = parts.slice(0, 3).join(",");
    const location = displayName;

    const updatedSelectedPlaces = [...selectedPlaces, location];

    setSelectedPlaces(updatedSelectedPlaces);
    setBirthPlace(location);
    setBirthplaceError("");
    setTimeZone(Timezone);
    setSuggestedPlaces('');
    setLatitude(latitude);
    setLongitude(longitude);

    // document.getElementById("atflg").value = longitude;
    // document.getElementById("atflt").value = latitude;
  };

  const fetchSuggestions = (currentValue, LocationType) => {
    if (currentValue.length >= 1) {
      const Data = {
        location: currentValue,
        Type: LocationType,
      };
      axios
        .post(
          "https://ctsvsolutions.com/onepage-Api/public/api/Location-Api",
          Data
        )
        .then((response) => {
          if (response.data.status == true) {
            setSuggestedPlaces(response.data.location);
          } else {
            setSuggestedPlaces('');
          }
        });
    } else {
      setSuggestedPlaces('');
    }


  };
  const SearchNewLocation = () => {

    const currentLocation = birthPlace.includes(",")
      ? birthPlace.split(",")[0]
      : birthPlace;
    fetchSuggestions(currentLocation, 'Map_Box');
  };



  const Formvalidation = () => {
    let isValid = true;

    if (!birthPlace || !latitudeValue) {
      setBirthplaceError("required");
      isValid = false;
    } else {
      setBirthplaceError("");
    }

    if (!userGender) {
      setGenderError("required");
      isValid = false;
    } else {
      setGenderError("");
    }
    if (!selectedDay) {
      setBirthdateError("required");
      isValid = false;
    } else {
      setBirthdateError("");
    }
    if (!selectedMonth) {
      setMonthError("required");
      isValid = false;
    } else {
      setMonthError("");
    }
    if (!selectedYear) {
      setYearError("required");
      isValid = false;
    } else {
      setYearError("");
    }
    if (!selectedHour) {
      setBirthHourError("required");
      isValid = false;
    } else {
      setBirthHourError("");
    }
    if (!selectedMinute) {
      setBirthMinuteError("required");
      isValid = false;
    } else {
      setBirthMinuteError("");
    }
    if (!selectedAmpm) {
      setBirthAmpmError("required");
      isValid = false;
    } else {
      setBirthAmpmError("");
    }
    if (!fatherName) {
      setFatherNameError("required");
      isValid = false;
    } else {
      setFatherNameError("");
    }
    if (!MotherName) {
      setMotherNameError("required");
      isValid = false;
    } else {
      setMotherNameError("");
    }
    // isValid = false;
    return isValid;
  };

  const BabyNameFormSubmit = e => {
    if (Formvalidation()) {
      const BabyNameList = {
        BirthMonth: selectedMonth,
        BirthYear: selectedYear,
        BirthDay: selectedDay,
        BirthDate: `${selectedYear}-${selectedMonth}-${selectedDay}`,
        BirthTime: `${selectedHour}:${selectedMinute} ${selectedAmpm}`,
        BirthPlace: birthPlace,
        initial: initial,
        father_name: fatherName,
        mother_name: MotherName,
        gender: userGender,
        latitude: latitudeValue,
        longitude: LongitudeValue,
        TimeZone: TimeZone
      };
      Loading.dots("Baby Name is Loading .....");

      axios
        .post(
          "https://ctsvsolutions.com/onepage-Api/public/api/Baby-Name-List-Random",
          BabyNameList
        )
        .then(response1 => {
          if (response1.status === 200) {
            axios
              .post(
                "https://ctsvsolutions.com/onepage-Api/public/api/Baby-Name-List",
                BabyNameList
              )
              .then(response2 => {
                if (response2.status === 200) {
                  Loading.remove();
                  const value = JSON.stringify(response2.data.data);
                  localStorage.setItem("BabyNameValues", value);
                  const formdatavalue = JSON.stringify(BabyNameList);
                  localStorage.setItem("babyNameformdata", formdatavalue);
                  const BabyUserInfo = {
                    user_id: "",
                    customerDataId: "",
                  };
                  const Baby_user_info = JSON.stringify(BabyUserInfo);
                  localStorage.setItem("BabayUserInfo", Baby_user_info);
                  // console.log('val is',value);
                  customRedirect("/Baby-Name-view");
                } else {
                  console.error("Error in Response 2:", response2);
                }
              })
              .catch(error => {
                console.error(
                  "Error in Axios POST for Marriage-Matching:",
                  error
                );
              });
          } else {
            console.error("Error in Response 1:", response1);
          }
        }
      )
        .catch(error => {
          console.error(
            "Error in Axios POST for Marriage-Matching-Random:",
            error
          );
        });
    }
  };

  useEffect(() => {
    const storedValue = localStorage.getItem("babyNameformdata");
    if (storedValue) {
      const parsedValue = JSON.parse(storedValue);
      const dateofBirth = parsedValue.BirthDate;

      const Birthtime = parsedValue.BirthTime;
      const timeComponents = Birthtime.split(" ");
      const time = timeComponents[0];
      const ampm = timeComponents[1];
      const [hour, minute] = time.split(":");

      // setUserName(parsedValue.username);
      setUserGender(parsedValue.gender);
      // setSelectedPlaces(parsedValue.BirthPlace);
      setSelectedDay(parsedValue.BirthDay);
      setSelectedMonth(parsedValue.BirthMonth);
      setSelectedYear(parsedValue.BirthYear);
      setSelectedHour(hour);
      setSelectedMinute(minute);
      setSelectedAmpm(ampm);
      setBirthPlace(parsedValue.BirthPlace);
      setFatherName(parsedValue.father_name);
      setMotherName(parsedValue.mother_name);
      setInitial(parsedValue.initial);
      setLatitude(parsedValue.latitude);
      setLongitude(parsedValue.longitude)
      // document.getElementById("atflg").value = parsedValue.longitude;
    }
  }, []);

  return (
    <>
      <form className="cmn-form">
        <h4 className="f-heading">Enter your personal details</h4>
        <p className="f-sub">
          Provide accurate details for the most precise report.
        </p>
        <div className="form-group">
          <label htmlFor="birth_place">Birth Place</label>
          <input type="text"
            className={`input ${birthplaceError && "is-invalid"
              }`}
            id="birth_place"
            name="birth_place"
            onChange={handleLocationChange}
            value={birthPlace}
            placeholder="Enter the Birth place"
          />
          {birthplaceError && (
            <div className="invalid-feedback fw-bold">
              {birthplaceError}
            </div>
          )}
          <input
            id="atflt"
            className="pfipbx"
            name="the_lat"
            type="hidden"
            placeholder="Latitude"
          />
          <input
            id="atflg"
            className="pfipbx"
            name="the_lng"
            type="hidden"
            placeholder="Longitude"
          />

          {suggestedPlaces && (
            <ul className="p-0 mt-1 mb-0 overflow-hidden">
              {suggestedPlaces.reverse().map((place, index) => {
                if (place.districts) {
                  const listItemStyle = {
                    padding: "5px 12px",
                    cursor: "pointer",
                    marginBottom: "1px",
                    backgroundColor: "#f2f2f2",
                    fontSize: "14px",
                    fontWeight: "540",
                    borderBottom: "1px dashed #ddd",
                  };

                  return (
                    <React.Fragment key={index}>
                      <p
                        style={listItemStyle}
                        className="px-2"
                        onClick={() => handleSuggestionSelect(place)}
                      >
                        <FontAwesomeIcon icon={faLocationDot} />
                        &nbsp;
                        {place.districts}
                      </p>
                    </React.Fragment>
                  );
                }
                return null;
              })}

            </ul>
          )}
          {SwitchLocationButton && (
            <p
              className="w-100 text-start btn py-0 rounded-0 text-white fw-bold mb-0 mt-0"
              style={{ fontSize: "14px", backgroundColor: "#fe1b57" }}
              onClick={SearchNewLocation}
            >
              {" "}
              <FontAwesomeIcon icon={faLocationDot} /> my place is not
              in List
            </p>
          )}
        </div>
        <div className="form-group">
          <label>Gender</label>
          <select
            className={`${genderError && "is-invalid"}`}
            aria-label="Default select example"
            id="b_gender"
            name="gender"
            value={userGender}
            onChange={(e) => {
              setUserGender(e.target.value);
            }}
          >
            <option value="">select</option>
            <option value="Male-ஆண்">Boy</option>
            <option value="Female-பெண்">Girl</option>
          </select>
          {genderError && (
            <div className="invalid-feedback fw-bold">
              {genderError}
            </div>
          )}
        </div>
        <div className="form-group">
          <label>date of birth</label>
          <div className="row">
            <div className="form-group">
              <label>date</label>
              <select
                className={`${birthdateError && "is-invalid"
                  }`}
                aria-label="Default select example"
                name="fin_day"
                id="fin_day"
                value={selectedDay}
                onChange={e => setSelectedDay(e.target.value)}
              >
                <option value="">Date</option>
                {dayOptions.map(day => (
                  <option key={day} value={day}>
                    {day}
                  </option>
                ))}
              </select>
              {birthdateError && (
                <div className="invalid-feedback fw-bold">{birthdateError}</div>
              )}
            </div>
            <div className="form-group">
              <label>month</label>
              <select
                className={`${monthError && "is-invalid"}`}
                aria-label="Default select example"
                id="fin_month"
                value={selectedMonth}
                onChange={e => setSelectedMonth(e.target.value)}
              >
                <option value="">Month</option>
                {monthOptions.map(month => (
                  <option key={month} value={month}>
                    {month}
                  </option>
                ))}
              </select>
              {monthError && (
                <div className="invalid-feedback fw-bold">{monthError}</div>
              )}
            </div>
            <div className="form-group">
              <label>year</label>
              <select
                className={`${yearError && "is-invalid"}`}
                aria-label="Default select example"
                name="fin_year"
                id="fin_year"
                value={selectedYear}
                onChange={e => setSelectedYear(e.target.value)}
              >
                <option value="">Year</option>
                {yearOptions.map(year => (
                  <option key={year} value={year}>
                    {year}
                  </option>
                ))}
              </select>
              {yearError && (
                <div className="invalid-feedback fw-bold">{yearError}</div>
              )}
            </div>
          </div>
        </div>
        <div className="form-group">
          <label>birth time</label>
          <div className="row">
            <div className="form-group">
              <label>hour</label>
              <select
                className={`${birthHourError && "is-invalid"
                  }`}
                aria-label="Default select example"
                id="fin_hour"
                value={selectedHour}
                onChange={e => setSelectedHour(e.target.value)}
              >
                <option value="">Hour</option>
                {hourOptions.map(hour => (
                  <option key={hour} value={hour}>
                    {hour}
                  </option>
                ))}
              </select>
              {birthHourError && (
                <div className="invalid-feedback fw-bold">{birthHourError}</div>
              )}
            </div>
            <div className="form-group">
              <label>minute</label>
              <select
                className={`${birthMinuteError && "is-invalid"
                  }`}
                aria-label="Default select example"
                id="fin_minute"
                value={selectedMinute}
                onChange={e => setSelectedMinute(e.target.value)}
              >
                <option value="">Minute</option>
                {minuteOptions.map(minute => (
                  <option key={minute} value={minute}>
                    {minute}
                  </option>
                ))}
              </select>
              {birthMinuteError && (
                <div className="invalid-feedback fw-bold">
                  {birthMinuteError}
                </div>
              )}
            </div>
            <div className="form-group">
              <label>am/pm</label>
              <select
                className={`${birthAmpmError && "is-invalid"
                  }`}
                aria-label="Default select example"
                id="fin_ampm"
                value={selectedAmpm}
                onChange={e => setSelectedAmpm(e.target.value)}
              >
                <option value="">Select</option>
                <option value="AM">AM</option>
                <option value="PM">PM</option>
              </select>
              {birthAmpmError && (
                <div className="invalid-feedback fw-bold">{birthAmpmError}</div>
              )}
            </div>
          </div>
        </div>
        <div className="form-group">
          <label>child’s initial</label>
          <div className="c-row">
            <div className="checkbox-row">
              <input
                type="checkbox"
                id="f_initial"
                value="father"
                name="initial"
                checked={initial === "father"}
                onChange={() => {
                  setInitial("father");
                }}
              />
              <label htmlFor="f_initial">father's name</label>
            </div>
            <div className="checkbox-row">
              <input
                type="checkbox"
                id="f_m_initial"
                value="both"
                name="initial"
                checked={initial === "both"}
                onChange={() => {
                  setInitial("both");
                }}
              />
              <label htmlFor="f_m_initial">Father + Mother Name</label>
            </div>
          </div>
        </div>
        <div className="form-group">
          <label htmlFor="f_name">father’s name</label>
          <input type="text"
            className={`input ${FatherNameError && "is-invalid"}`}
            id="f_name"
            name="f_name"
            placeholder="Enter Father Name"
            value={fatherName}
            onChange={(e) => {
              setFatherName(e.target.value);
            }}
          />
          {FatherNameError && (
            <div className="invalid-feedback fw-bold">
              {FatherNameError}
            </div>
          )}
        </div>
        <div className="form-group">
          <label htmlFor="m_name">mother’s name</label>
          <input type="text"
            className={`input ${motherNameError && "is-invalid"
              }`}
            id="m_name"
            name="m_name"
            placeholder="Enter Mother Name"

            value={MotherName}
            onChange={(e) => {
              setMotherName(e.target.value);
            }}
          />
          {motherNameError && (
            <div className="invalid-feedback fw-bold">
              {motherNameError}
            </div>
          )}
        </div>
        <div className="form-group-btn">
          <button type="button" className="form-btn" id="nextBtn"
            onClick={BabyNameFormSubmit}>
            submit
            <img src="https://ctsvsolutions.com/onepage-images/new-ui/btn-arrow-white.webp" alt="baby name arrow" />
          </button>
        </div>
        <div className="mt-3">
          <p className="f-sub text-danger text-center mb-0">
            * Your baby name will be generated in Tamil & English
          </p>
        </div>
      </form>
    </>
  );
}
