import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useInView } from 'react-intersection-observer';
import { Link } from 'react-router-dom';

export default function Guiding() {
    const [count, setCount] = useState('0');
    const { ref, inView } = useInView({
        triggerOnce: true,
        threshold: 0.1
    });

    useEffect(() => {
        if (inView) {
            axios.get('https://ctsvsolutions.com/onepage-Api/public/api/user_sales')
                .then(response => {
                    if (response.data.status === true) {
                        setCount(response.data.count);
                    }
                });
        }
    }, [inView]);

    return (
        <>
            <section className="guiding" ref={ref}>
                <div className="astro-container">
                    <div className="guiding-row">
                        <div className="content">
                            <h2 className="sh">
                                Guiding You To A Clearer Understanding Of
                                <span className="grt"> Life's Journey</span>.
                            </h2>
                            <div className="rated">
                                <div className="col">
                                    <div className="item">
                                        <h3>
                                            Rated <img src='https://ctsvsolutions.com/onepage-images/new-ui/rated-star.webp' alt='horoscope 5 star' width={'48%'} height={30} layout="responsive" loading="lazy"/>
                                        </h3>
                                        <p>By Our Horoscope Seekers Worldwide</p>
                                    </div>
                                </div>
                                <div className="col">
                                    <div className="item">
                                        <h3>{count}+</h3>
                                        <p>Users &amp; Counting Worldwide</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="schedule">
                            <div className="item">
                                <h4>#1 Astologer Online</h4>
                                <h3> Speak with Expert Astrologers Anytime</h3>
                                <p>
                                Ready to connect with a trusted astrologer? Schedule your session now!
                                Our platform makes astrology simple and accessible. Whether it's horoscopes, marriage-matching, or personalized advice, Astro Connect brings you expert guidance in a language you understand.
                                </p>
                                <Link to='/Talk-To-Astrologer' className="guiding-btn-lg">
                                    Appointment Now
                                    <img src='https://ctsvsolutions.com/onepage-images/new-ui/btn-arrow-white.webp' alt='talk to astrologer' width={18} height={15} loading="lazy"/>
                                </Link>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    );
}
