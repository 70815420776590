import React, { useEffect, useState } from 'react'
import TopNavbar from '../../Main-Components/TopNavbar/TopNavbar'
import Menubar from '../../Main-Components/Menubar/Menubar'
import Footer from '../../Main-Components/Footer/Footer'
import BlogListBanner from '../../Small-Components/BlogListBanner/BlogListBanner'
import BlogRecent from "../../Small-Components/BlogRecent/BlogRecent";
import BlogCategories from "../../Small-Components/BlogCategories/BlogCategories ";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { useLocation } from 'react-router-dom'
import { faFacebook, faInstagram, faLinkedin, faTwitter } from '@fortawesome/free-brands-svg-icons'
import { Helmet } from 'react-helmet'
import axios from 'axios'
import { faLocationDot, faPencil } from "@fortawesome/free-solid-svg-icons";
export default function HoraigalBlog() {
   const location = useLocation();
   const canonical = () => {
      const base_url = "https://onepagehoroscope.com";
      const current_path = location.pathname;
      const canonicalPaths = {
         "/Horaigal": "/Horaigal",
      };
      return (
         <link
            rel="canonical"
            href={base_url + (canonicalPaths[current_path] || current_path)}
         />
      );
   };
   return (
      <>
         <Helmet>
            <title>சுப ஹோரைகள்</title>
            <meta
               name="description"
               content="Discover the best times for your daily activities with Suba Hora, the ultimate tool for accurate planetary hour calculations. Enhance your productivity and decision-making by aligning your actions with favorable planetary influences"
            />
            <meta
               name="keywords"
               content="Tamil Horaigal,Hora Tamil,Hora Calculation in Tamil,சுப ஹோரைகள்,Suba Hora,planetary hours,best times for activities,productivity astrology,astrological timing"
            />
            <meta name="robots" content="index,follow" />
            {canonical()}
         </Helmet>
         <TopNavbar />
         <Menubar NavBgstyle="outer-header rjr" NavTextColor="text-dark" SubmenuTop="true" />
         <BlogListBanner />
         <HoraigalBlogContent />
         <Footer />
      </>
   )
}
function HoraigalBlogContent() {
   const [currentDate, setCurrentDate] = useState(new Date());
   const [latitude, setLatitude] = useState(11.004556);
   const [Longitude, setLongitude] = useState(76.961632);
   const [MoringHora, setMoringHora] = useState("");
   const [suggestedPlaces, setSuggestedPlaces] = useState("");
   const [selectedPlaces, setSelectedPlaces] = useState([]);
   const [EveHora, setEveHora] = useState([]);
   const [birthPlace, setBirthPlace] = useState("Coimbatore,TamilNadu,India");
   const [SwitchLocationButton, SetSwitchLocationButton] = useState(false);
   const [Timezone, setTimeZone] = useState(5.5);
   const [plantColor, setPlantColor] = useState("");
   const [HourlyPlantHour, setHourlyPlantColor] = useState('');
   const [EditOption, setEditOption] = useState(true);
   // const [birthplaceError, setBirthplaceError] = useState("");
   const [HoraType, setHoraType] = useState(1);
   const handleSuggestionSelect = (selectedPlace) => {
      SetSwitchLocationButton(false);
      const latitude = selectedPlace.latitude;
      const longitude = selectedPlace.longitude;
      const Timezone = selectedPlace.TimeZone;
      const parts = selectedPlace.districts.split(",");
      const displayName = parts.slice(0, 3).join(",");
      const location = displayName;
      const updatedSelectedPlaces = [...selectedPlaces, location];
      setSelectedPlaces(updatedSelectedPlaces);
      setBirthPlace(location);
      // setBirthplaceError("");
      setTimeZone(Timezone);
      setSuggestedPlaces("");
      setLatitude(latitude);
      setLongitude(longitude);
   };
   const handleChange = (e) => {
      setCurrentDate(new Date(e.target.value));
   };
   const formatDateForInput = (date) => {
      return date.toISOString().split("T")[0];
   };
   const formatDateForApi = (date) => {
      const d = new Date(date);
      const day = "" + d.getDate();
      const month = "" + (d.getMonth() + 1);
      const year = d.getFullYear();
      return [day.padStart(2, "0"), month.padStart(2, "0"), year].join("-");
   };
   useEffect(() => {
      const formattedDate = formatDateForApi(currentDate);
      const Data = {
         latitude: latitude,
         longitude: Longitude,
         birth_place: birthPlace,
         date: formattedDate,
         timezone: Timezone,
      };
      axios
         .post(
            "https://shineastrology.in/professional_api/public/api/Hora-Calculation",
            Data
         )
         .then((response) => {
            if (response.data.status === true) {
               setMoringHora(response.data.data.mrngHora);
               setEveHora(response.data.data.EveHora);
               setPlantColor(response.data.data.colorCode);
               setHourlyPlantColor(response.data.data.Hourly_Hora_color_code);
            }
         });
   }, [EditOption, latitude, Longitude, birthPlace, Timezone, currentDate]);

   const handleLocationChange = (event) => {
      SetSwitchLocationButton(true);
      const currentValue = event.target.value;
      setBirthPlace(currentValue);
      fetchSuggestions(currentValue, "geoname_cities");
   };
   const fetchSuggestions = (currentValue, LocationType) => {
      if (currentValue.length >= 1) {
         const Data = {
            location: currentValue,
            Type: LocationType,
         };
         axios
            .post(
               "https://ctsvsolutions.com/onepage-Api/public/api/Location-Api",
               Data
            )
            .then((response) => {
               if (response.data.status === true) {
                  // console.log(response.data.location);
                  setSuggestedPlaces(response.data.location);
               } else {
                  setSuggestedPlaces("");
               }
            });
      } else {
         setSuggestedPlaces("");
      }
   };
   const SearchNewLocation = () => {
      const currentLocation = birthPlace.includes(",")
         ? birthPlace.split(",")[0]
         : birthPlace;
      fetchSuggestions(currentLocation, "Map_Box");
   };
   const FindTextColor = (Hora, HoraType) => {
      return HoraType === 1 ? plantColor[Hora] : HourlyPlantHour[Hora];
   };
   const ChangeDetails = () => {
      setEditOption((pre) => !pre);
   };
   return (
      <>
         <section className="article-wrapper">
            <div className="astro-container pt-3">
               <div className="row">
                  <div className="col-lg-8 my-2">
                     <div className="card bg-transparent border-0 blog-view">
                        <div className="card-header bg-transparent border-0 px-0">
                           <h1 className="fs-4 blog-view-heading">
                              இன்றைய சுப ஹோரைகள் {formatDateForApi(currentDate)}
                           </h1>
                        </div>
                        <div className="card-body pt-0 px-0">
                           <div className="my-2">
                              <div className="img-section p-1">
                                 <img
                                    src="https://ctsvsolutions.com/onepage-images/subha_hora.webp"
                                    alt="suba-horaigal"
                                    className="img-fluid rounded"
                                 />
                              </div>
                           </div>
                           <div className="my-4 d-flex">
                              <a href="https://www.facebook.com/onepagehoroscope/">
                                 <div className="view-blog-icons facebook">
                                    <FontAwesomeIcon
                                       icon={faFacebook}
                                       className="text-white px-2"
                                    />
                                 </div>
                              </a>
                              <a href="https://www.instagram.com/one_page_horoscope/">
                                 <div className="view-blog-icons instagram">
                                    <FontAwesomeIcon
                                       icon={faInstagram}
                                       className="text-white px-2"
                                    />
                                 </div>
                              </a>
                              <a href="https://twitter.com/onepage321">
                                 <div className="view-blog-icons twitter">
                                    <FontAwesomeIcon icon={faTwitter} className="text-white px-2" />
                                 </div>
                              </a>
                              <a href="https://linkedin.com/showcase/97871433/admin/feed/posts/">
                                 <div className="view-blog-icons linkedin">
                                    <FontAwesomeIcon
                                       icon={faLinkedin}
                                       className="text-white px-2"
                                    />
                                 </div>
                              </a>
                           </div>
                           <div className="description mt-3">
                              <p className="mb-3">
                                 ஜோதிடத்தில், ஒவ்வொரு தினமும் பஞ்சாங்கத்தில் ஹோரை நேரங்கள்
                                 குறிப்பிடப்படுகின்றன. ஹோரை நேரம் என்றால், ஒரு நாளில் ஒவ்வொரு
                                 கிரகத்திற்கும் ஒரு குறிப்பிட்ட நேரத்தில் ஆதிக்கம் செலுத்தும் நேரம்
                                 ஆகும். இந்த ஹோரை நேரத்தின் முக்கியத்துவம் அதிகம்; அந்த நேரங்களை
                                 சரியாக பயன்படுத்தினால், நம் வாழ்க்கையில் வெற்றி பெறலாம். ஜோதிட
                                 பஞ்சாங்கத்தில் மிக முக்கியமான அம்சம் கிரகங்கள். ஒரு நாளில் ஒவ்வொரு
                                 கிரகத்திற்கும் ஒரு குறிப்பிட்ட நேரம் ஆதிக்கம் செலுத்தும். மேலும்,
                                 எந்த கிழமையில் எந்த கிரகம் ஆதிக்கம் செலுத்துகிறது என்பது
                                 குறிப்பிடப்பட்டுள்ளது. எந்த ஹோரையில் சுப நிகழ்ச்சிகள் துவங்க
                                 வேண்டும் என்பது அனைத்தும் ஹோரையின் படி தான் நடக்கிறது.
                              </p>
                           </div>
                           <div className="description mt-3">
                              <h5 className="text-primary mb-1"> சுப ஹோரைகள் -</h5>
                              <div className="Horai-Form">
                                 <div className="card" style={{ backgroundColor: "#D6EFD8" }}>
                                    <div className="card-body">
                                       <div className="row">
                                          <div className="col-md-5 col-12 my-2">
                                             <label htmlFor="Date" className="text-danger">
                                                Date{" "}
                                             </label>
                                             <input
                                                type="date"
                                                id="Date"
                                                value={formatDateForInput(currentDate)}
                                                onChange={handleChange}
                                                className="form-control"
                                             // readOnly={EditOption}
                                             />
                                          </div>
                                          <div className="col-md-5 col-12 my-2">
                                             <label htmlFor="Location" className="text-danger">
                                                Location
                                             </label>
                                             {/* 
                                 <FontAwesomeIcon icon={faMapMarkerAlt} className="text-white px-2" />
                                 */}
                                             <input
                                                type="text"
                                                className="form-control"
                                                id="Location"
                                                placeholder="Location"
                                                value={birthPlace}
                                                onChange={handleLocationChange}
                                             // readOnly={EditOption}
                                             ></input>
                                             {suggestedPlaces && (
                                                <ul className="p-0 mt-1 mb-0">
                                                   {suggestedPlaces.reverse().map((place, index) => {
                                                      if (place.districts) {
                                                         const listItemStyle = {
                                                            padding: "5px 12px",
                                                            cursor: "pointer",
                                                            marginBottom: "1px",
                                                            backgroundColor: "#f2f2f2",
                                                            fontSize: "14px",
                                                            fontWeight: "540",
                                                            borderBottom: "1px dashed #ddd",
                                                         };
                                                         return (
                                                            <React.Fragment key={index}>
                                                               <p
                                                                  style={listItemStyle}
                                                                  className="px-2"
                                                                  onClick={() =>
                                                                     handleSuggestionSelect(place)
                                                                  }
                                                               >
                                                                  <FontAwesomeIcon icon={faLocationDot} />
                                                                  &nbsp;
                                                                  {place.districts}
                                                               </p>
                                                            </React.Fragment>
                                                         );
                                                      }
                                                      return null;
                                                   })}
                                                </ul>
                                             )}
                                             {SwitchLocationButton && (
                                                <p
                                                   className="w-100 text-start btn py-0 rounded-0 text-white fw-bold mb-0 mt-0"
                                                   style={{
                                                      fontSize: "14px",
                                                      backgroundColor: "#fe1b57",
                                                   }}
                                                   onClick={SearchNewLocation}
                                                >
                                                   {" "}
                                                   <FontAwesomeIcon icon={faLocationDot} />
                                                   my place is
                                                   not in List
                                                </p>
                                             )}
                                          </div>
                                          <div className="col-md-2 col-12 text-center mt-4 p-2">
                                             <button
                                                className="btn btn-success w-100"
                                                onClick={ChangeDetails}
                                             >
                                                <FontAwesomeIcon icon={faPencil} />
                                                {" "}
                                                {EditOption ? "Edit" : "Save"}
                                             </button>
                                          </div>
                                       </div>
                                    </div>
                                 </div>
                              </div>
                              <div className="row card mt-5">
                                 <div className="row mt-4">
                                    <div className="col-md-6">
                                       <div className="form-check">
                                          <input
                                             className="form-check-input"
                                             type="radio"
                                             name="exampleRadios"
                                             id="exampleRadios1"
                                             value="1"
                                             checked={HoraType === 1}
                                             onChange={() => setHoraType(1)}
                                          />
                                          <label className="form-check-label" htmlFor="exampleRadios1">
                                             Result based on effect of planets
                                          </label>
                                       </div>
                                    </div>
                                    <div className="col-md-6">
                                       <div className="form-check">
                                          <input
                                             className="form-check-input"
                                             type="radio"
                                             name="exampleRadios"
                                             id="exampleRadios2"
                                             value="2"
                                             checked={HoraType === 2}
                                             onChange={() => setHoraType(2)}
                                          />
                                          <label className="form-check-label" htmlFor="exampleRadios2">
                                             Result Based on hourly hora
                                          </label>
                                       </div>
                                    </div>
                                 </div>
                                 <div className="col-12">
                                    <div className="Horai-output mt-4 text-center">
                                       <div className="row">
                                          <div className="col-md-6 col-12">
                                             <div className="card">
                                                <table className="table table-responsive">
                                                   <thead>
                                                      <tr className="w-100">
                                                         <td
                                                            className="text-white text-center fw-bold fs-5 rounded"
                                                            style={{ backgroundColor: "#7469B6" }}
                                                            colSpan={2}
                                                         >
                                                            Day Hora
                                                         </td>
                                                      </tr>
                                                      <tr>
                                                         <td style={{ backgroundColor: "#EBF4F6" }}>HORA</td>
                                                         <td style={{ backgroundColor: "#EBF4F6" }}>TIME</td>
                                                      </tr>
                                                   </thead>
                                                   <tbody>
                                                      {MoringHora &&
                                                         MoringHora.map((hora, index) => (
                                                            <tr key={index}>
                                                               <td
                                                                  style={{
                                                                     backgroundColor: index % 2 === 0 ? "#F5F7F8" : "#EBF4F6",
                                                                     color: FindTextColor(hora.planet, HoraType),
                                                                  }}
                                                               >
                                                                  {hora.planet}
                                                               </td>
                                                               <td
                                                                  style={{
                                                                     backgroundColor: index % 2 === 0 ? "#F5F7F8" : "#EBF4F6",
                                                                  }}
                                                               >
                                                                  {hora.time_intervals}
                                                               </td>
                                                            </tr>
                                                         ))}
                                                   </tbody>
                                                </table>
                                             </div>
                                          </div>

                                          <div className="col-md-6 col-12">
                                             <div className="card">
                                                <table className="table table-responsive">
                                                   <thead>
                                                      <tr className="w-100">
                                                         <td
                                                            className="text-white text-center fw-bold fs-5 rounded"
                                                            style={{ backgroundColor: "#7469B6" }}
                                                            colSpan={2}
                                                         >
                                                            Night Hora
                                                         </td>
                                                      </tr>
                                                      <tr>
                                                         <td style={{ backgroundColor: "#EBF4F6" }}>HORA</td>
                                                         <td style={{ backgroundColor: "#EBF4F6" }}>TIME</td>
                                                      </tr>
                                                   </thead>
                                                   <tbody>
                                                      {EveHora &&
                                                         EveHora.map((hora, index) => (
                                                            <tr key={index}>
                                                               <td
                                                                  style={{
                                                                     backgroundColor: index % 2 === 0 ? "#F5F7F8" : "#EBF4F6",
                                                                     color: FindTextColor(hora.planet, HoraType),
                                                                  }}
                                                               >
                                                                  {hora.planet}
                                                               </td>
                                                               <td
                                                                  style={{
                                                                     backgroundColor: index % 2 === 0 ? "#F5F7F8" : "#EBF4F6",
                                                                  }}
                                                               >
                                                                  {hora.time_intervals}
                                                               </td>
                                                            </tr>
                                                         ))}
                                                   </tbody>
                                                </table>
                                             </div>
                                          </div>
                                       </div>
                                    </div>
                                 </div>

                              </div>
                           </div>
                        </div>
                     </div>
                  </div>
                  <div className="col-lg-4 my-2">
                     <div className="row">
                        <BlogRecent />
                        <BlogCategories />
                     </div>
                  </div>
               </div>
            </div>
         </section>
      </>
   )
}