import React, {  createContext, useState } from "react";


export const MobileMenuContext=createContext();

const MobileMenuContextProvider=({children })=>{
    const [ShowMobileMenu,setMobileMenu]=useState(false);
    const [MobileActive, setMobileActive]=useState({
        active1:false,
        active2:false

    })
    return(
        <MobileMenuContext.Provider value={[ShowMobileMenu, setMobileMenu, MobileActive, setMobileActive]}>
        {children }
    </MobileMenuContext.Provider>
    )
   
}
export default MobileMenuContextProvider;