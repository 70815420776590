import React from 'react'

export default function AboutUsBanner() {
    return (
        <>
            <section className="page-outer center srvc pb-5">
                <div className="astro-container">
                    <div className="row">
                        <div className="col">
                            <div className="content">
                                <h1 className="sh lh-1">
                                About Us<br className='d-lg-none'/> <span className="grt">One Page Horoscope</span>
                                </h1>
                                <p className="p-outer">About Us</p>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}
