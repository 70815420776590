import { createContext } from "react";



 const BirthViewContextProvider = ({ children }) => {

    const value = JSON.parse(localStorage.getItem("onepageValues"));
    const personalInfo = value.personal_info;
    const tableData = value.tableData;
    const rasiChat = value.rasi_array;
    const amsamChart = value.amsam_array;

    return (
        <BirthViewContext.Provider value={{ value, personalInfo, tableData, rasiChat, amsamChart }}>
            {children}
        </BirthViewContext.Provider>
    );
};
export const BirthViewContext = createContext();
export default BirthViewContextProvider;
