import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { useInView } from 'react-intersection-observer';
import { Link } from 'react-router-dom';

export default function FAQ({ Type = "Home", Faqpage = false }) {
    const [openIndex, setOpenIndex] = useState(null);
    const [faqData, setFaqData] = useState([]);

    const toggleQuestion = (index) => {
        setOpenIndex(openIndex === index ? null : index);
    };
    const [ref, inView] = useInView({
        triggerOnce: true,
        threshold: 0.1
    });

    useEffect(() => {
        if (inView) {
            const Data = {
                Type: Type
            }
            axios.post('https://ctsvsolutions.com/onepage-Api/public/api/FAQ-LIST', Data).then(response => {
                // console.log('yes....',response.data.data);
                setFaqData(response.data.data);
            }).catch(error => {
                console.error("There was an error fetching the FAQ data!", error);
            });
        }

    }, [inView,Type]);

    return (
        <>
            <section className={`faq ${Faqpage ?'py-0':''}`} ref={ref}>
                <div className="astro-container">
                    {!Faqpage && (
                        <div className="s-heading">
                            <h2 className="sh">
                                Frequently <span className="grt">Asked Questions</span>
                            </h2>
                        </div>
                    )}

                    <div className="faq-accordion">
                        <ul className="accordion-list ps-0">
                            {Faqpage ? (
                                faqData.map((faq, index) => (
                                    <li key={index} className={`${openIndex === index ? 'active' : ''}`} >
                                        <h3 onClick={() => toggleQuestion(index)}>
                                            {faq.question}
                                        </h3>
                                        {openIndex === index && (
                                            <div className="answer">
                                                <p>{faq.answer}</p>
                                            </div>
                                        )}
                                    </li>
                                ))
                            ) : (

                                faqData.filter((data, key) => key <= 4).map((faq, index) => (
                                    <li key={index} className={`${openIndex === index ? 'active' : ''}`} >
                                        <h3 onClick={() => toggleQuestion(index)}>
                                            {faq.question}
                                        </h3>
                                        {openIndex === index && (
                                            <div className="answer">
                                                <p>{faq.answer}</p>
                                            </div>
                                        )}
                                    </li>
                                ))
                            )
                            }
                        </ul>
                        {!Faqpage && (
                            <div className="btn-center">
                            <Link to='/FAQ' className="faq-btn-lg">
                                Explore All
                                <img src='https://ctsvsolutions.com/onepage-images/new-ui/btn-arrow-white.webp' alt="horoscope explore all frequently questions"  width={18} height={15} loading="lazy"/>
                            </Link>
                        </div>
                        )}
                        
                    </div>
                </div>
            </section>
        </>
    );
}
