import React from 'react'
import TopNavbar from '../../Main-Components/TopNavbar/TopNavbar'
import Menubar from '../../Main-Components/Menubar/Menubar'
import Footer from '../../Main-Components/Footer/Footer'
import AboutUsBanner from '../../Small-Components/AboutUsBanner/AboutUsBanner'
import AboutUsContent from '../../Small-Components/AboutUsContent/AboutUsContent'
import { Helmet } from 'react-helmet'
import { useLocation } from 'react-router-dom'

export default function AboutUsView() {

    const location = useLocation();
    const canonical = () => {
        const base_url = "https://onepagehoroscope.com";
        const current_path = location.pathname;

        const canonicalPaths = {
            "/About-us": "/About-us",

        };

        return (
            <link
                rel="canonical"
                href={base_url + (canonicalPaths[current_path] || current_path)}
            />
        );
    };

    return (
        <>
            <Helmet>
                <title>About Us | Get to Know About Online Horoscope in Tamil</title>
                <meta
                    name="description"
                    content="Discover the essence of Tamil astrology with Onepage Horoscope. From birth chart calculations to marriage matching reports, we offer a range of services."
                />
                <meta
                    name="keywords"
                    content="one page Horoscope,Aboutus,Horoscope,Free Horoscope Software"
                />
                <meta name="robots" content="index,follow" />
                {canonical()}
                <script type="application/ld+json">
                    {`
             "@context": "https://schema.org/", 
             "@type": "Product", 
             "name": "OnepageHoroscope About us",
             "image": "",
             "description": "Discover the essence of Tamil astrology with Onepage Horoscope. From birth chart calculations to marriage matching reports, we offer a range of services.",
             "brand": {
               "@type": "Brand",
               "name": "One Page Horoscope"           
          `}
                </script>
            </Helmet>
            <TopNavbar />
            <Menubar NavBgstyle="outer-header" NavTextColor="text-dark" SubmenuTop="true" />
            <AboutUsBanner />
            <AboutUsContent />
            <Footer />
        </>
    )
}
