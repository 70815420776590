import React from 'react'
import TopNavbar from '../../Main-Components/TopNavbar/TopNavbar'
import Menubar from '../../Main-Components/Menubar/Menubar'
import Footer from '../../Main-Components/Footer/Footer'
import FAQ from '../../Main-Components/FAQ/FAQ'
import Guiding from '../../Main-Components/Guiding/Guiding'
import BabyNameListBanner from '../../Small-Components/BabyNameListBanner/BabyNameListBanner'
import BabyNameListCard from '../../Small-Components/BabyNameListCard/BabyNameListCard'
import BabyNameFormSection from '../../Small-Components/BabyNameFormSection/BabyNameFormSection'
import { Helmet } from 'react-helmet'
import { useLocation } from 'react-router-dom'

export default function BabyNameList() {

  const location = useLocation();

  const canonical = () => {
    const base_url = "https://onepagehoroscope.com";
    const current_path = location.pathname;

    const canonicalPaths = {
      "/Baby-Name-List": "/Baby-Name-List",

    };

    return (
      <link
        rel="canonical"
        href={base_url + (canonicalPaths[current_path] || current_path)}
      />
    );
  };

  return (
    <>
      <Helmet>
        <title>Get Numerology Tamil baby names| நியூமெராலஜி குழந்தை</title>
        <meta
          name="description"
          content="Discover best baby names in Tamil with numerology insights. Find the girl baby names in tamil and Hindu baby name list a to z. Explore Tamil baby names now!"
        />
        <meta
          name="keywords"
          content="baby names, tamil baby names,baby boy names,baby girl names hindu baby names, numerology names, numerology baby names, name numerology calculator, name number calculator, indian numerology name calculator, குழந்தை பெயர் "
        />
        <meta name="robots" content="index,follow" />
        {canonical()}
        <script type="application/ld+json">
          {JSON.stringify({
            "@context": "https://schema.org/",
            "@type": "Product",
            "name": "Baby Name",
            "image": "https://ctsvsolutions.com/onepage-images/baby-name-one-page-horoscope.webp",
            "description": "Discover best baby names in Tamil with numerology insights. Find the girl baby names in tamil and Hindu baby name list a to z. Explore Tamil baby names now!",
            "brand": {
              "@type": "Brand",
              "name": "One Page Horoscope"
            },
            "offers": {
              "@type": "Offer",
              "url": "https://onepagehoroscope.com/Baby-Name-List",
              "priceCurrency": "INR",
              "price": "249",
              "availability": "https://schema.org/OnlineOnly",
              "itemCondition": "https://schema.org/NewCondition"
            }
          })}

        </script>
      </Helmet>
      <TopNavbar />
      <Menubar NavBgstyle="outer-header" NavTextColor="text-dark" SubmenuTop="true" />
      <BabyNameListBanner />
      <BabyNameFormSection />
      <BabyNameListCard />
      <FAQ Type={"Baby_Name"}/>
      <Guiding />
      <Footer />
    </>
  )
}
