import React from 'react'
import TopNavbar from '../../Main-Components/TopNavbar/TopNavbar'
import Menubar from '../../Main-Components/Menubar/Menubar'
import Footer from '../../Main-Components/Footer/Footer'
import OnePagePaymentSuccessBanner from '../../Small-Components/OnePagePaymentSuccessBanner/OnePagePaymentSuccessBanner';
import AudioPredictionPaymentSucessContent from "../../Small-Components/AudioPredictionPaymentSuccess/AudioPredictionPaymentSucessContent"
export default function BiodataPaymentSuccess() {
  return (
    <>
      <TopNavbar />
      <Menubar NavBgstyle="outer-header bc-outer" NavTextColor="text-dark" SubmenuTop="true" />
      <OnePagePaymentSuccessBanner/>
      <AudioPredictionPaymentSucessContent/>
      <Footer />
    </>
  )
}
