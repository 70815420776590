import React from 'react'
// import PersonalDetailImage from '../../images/personal-data.webp'
// import FamilyDetailImage from '../../images/family.webp'
// import EducatioDetailImage from '../../images/education details.webp'
// import ProfileImage from '../../images/profile-details.webp'

export default function MarriageBioDataDetails() {
  return (
    <>
      <PersonalDetails />
      <FamilyDetails />
      <EducationDetail />
      <PhotoDetail />
    </>
  )
}

function PersonalDetails() {
  return (
    <>
      <section className="hwhb mb">
        <div className="astro-container">
          <div className="bio-row">
            <div className="col img">
              <img src='https://ctsvsolutions.com/onepage-images/new-ui/personal-data.webp' alt='tamil marriage biodata online'/>
            </div>
            <div className="col content">
              <h2 className="sh lh-1">
                Personal <span className="grt">Details</span>
              </h2>
              <p className="p-16">
                Marriage Bio-Data Format Marriage Bio-Data Format document will
                include basic details such as your name, gender, date of birth,
                address, the names of your parents and your email address. You'll also
                want to include information about your hobbies, passions, what you're
                good at and anything else you think the employer might need to
                understand you as a person.
              </p>
            </div>
          </div>
        </div>
      </section>
    </>
  )
}

function FamilyDetails() {
  return (
    <>
      <section className="fd">
        <div className="astro-container">
          <div className="bio-fam-row">
            <div className="col content">
              <h2 className="sh lh-1">
                <span className="grt">Family</span> details
              </h2>
              <p className="p-16">
                Marriage Bio-Data Format Family details particulars are also important
                when writing marriage biodata. The complete information needs to be
                given of siblings with their spouse's name and occupation if married.
                You can start with the name of your Father and his occupation,
                Mother's name and her occupation, followed by names of brothers and
                sisters.
              </p>
            </div>
            <div className="col img">
              <img src='https://ctsvsolutions.com/onepage-images/new-ui/family.webp' alt='marriage biodata format'/>
            </div>
          </div>
        </div>
      </section>
    </>
  )
}

function EducationDetail() {
  return (
    <>
      <section className="hwhb mb-2">
        <div className="astro-container">
          <div className="bio-row">
            <div className="col img">
              <img src='https://ctsvsolutions.com/onepage-images/new-ui/education%20details.webp' alt='marriage biodata format in word'/>
            </div>
            <div className="col content">
              <h2 className="sh lh-1">
                education &amp; <span className="grt">Professional Details</span>
              </h2>
              <p className="p-16">
                Marriage Bio-Data Format including your educational background in your
                biodata helps potential partners and their families to understand what
                level of education you have attained. This is important because some
                families may pick partners for their children based on their
                educational level.
              </p>
              <p className="p-16">
                Marriage Bio-Data Format Professional details your profession is
                another important detail to include in your marriage biodata. This
                helps potential partners and their families to understand what you do
                for a living, the nature of your work, your work schedule, career
                growth potential, and earning potential.
              </p>
            </div>
          </div>
        </div>
      </section>
    </>
  )
}

function PhotoDetail() {
  return (
    <>
      <section className="fd fd-2">
        <div className="astro-container">
          <div className="bio-fam-row">
            <div className="col content">
              <h2 className="sh lh-1">
                <span className="grt">Photo</span>
              </h2>
              <p className="p-16">
                Marriage Bio-Data Format The first thing that you should include in
                your biodata is a recent photograph of you. Photographs are important
                because they help potential partners to put a face to the name, and
                can give them an idea of what you look like.
              </p>
            </div>
            <div className="col img">
              <img src='https://ctsvsolutions.com/onepage-images/new-ui/profile-details.webp' alt='marriage biodata maker'/>
            </div>
          </div>
        </div>
      </section>
    </>
  )
}