import React from 'react'

export default function BirthHoroscopeViewBanner() {
    return (
        <>
            <section className="page-outer center bc-outer bcr">
                <div className="astro-container">
                    <div className="row">
                        <div className="col">
                            <div className="content">
                                <h1 className="sh lh-1">
                                <span className="grt">பஞ்சாங்க</span> விவரம்
                                </h1>
                                <p className="p-outer">ஜாதகத்தின் அட்டவணை</p>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

        </>
    )
}
