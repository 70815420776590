import axios from 'axios';
import React, { useEffect, useState } from 'react'
import Skeleton from 'react-loading-skeleton';
import { Link, useParams } from 'react-router-dom';
import BlogRecent from "../../Small-Components/BlogRecent/BlogRecent";
import BlogCategories from "../BlogCategories/BlogCategories ";

export default function BlogFilterView() {
    const { category_name } = useParams();
    const [loading, setLoading] = useState(true);
    const [blogList, setBlogList] = useState([]);

    useEffect(() => {
        axios.post('https://ctsvsolutions.com/onepage-Api/public/api/Search-category', {
            category_name: category_name
        })
            .then(response => {
                console.log(response.data);
                if (response.data.status === true) {
                    setBlogList(response.data.data);
                    setLoading(false);
                } else {
                    setLoading(false);
                }
                // console.log('response from api', response.data.data);
            })
            .catch(error => {
                console.error('Error fetching blog list:', error);
                setLoading(false);
            });
    }, [category_name]);

    return (
        <>
            <section className="article-wrapper">
                <div className="astro-container pt-5">
                    <div className="row">
                        <div className="col-lg-8 my-2">
                            <div className="row">
                                {loading ? (
                                    [1, 2, 3, 4].map((index) => (
                                        <div className="col-md-6 mb-3" key={index}>
                                            <div className="card h-100 d-flex align-items-stretch blog-card shadow">
                                                <Skeleton height={200} />
                                                <div className="card-body py-0">
                                                    <Skeleton height={20} />
                                                </div>
                                                <div className="card-footer bg-white border-0 mb-5">
                                                    <Skeleton width={100} />
                                                </div>
                                            </div>
                                        </div>
                                    ))
                                ) : (
                                    blogList.map((blog, index) => (
                                        <div className="col-md-6 mb-3" key={index}>
                                            <div className="card h-100 d-flex align-items-stretch blog-card shadow">
                                                <Link to={`/Blog/${blog.Blog_url}`} className="nav-link p-0">
                                                    <div className="">
                                                        <img
                                                            src={`https://ctsvsolutions.com/onepage_admin/onepage-images/${blog.image_name}`}
                                                            className="card-img-top blog-img"
                                                            alt={blog.img_alt_tag}
                                                            style={{ height: 200 }}
                                                        />
                                                        <div className="card-body py-0">
                                                            <a href={() => false} className="text-decoration-none">
                                                                <h5 className="card-title blog-title mt-3 fs-6">
                                                                    {blog.Blog_heading}
                                                                </h5>
                                                            </a>
                                                        </div>
                                                        <div className="card-footer bg-white border-0 mb-5">
                                                            <small className="float-start blog-date">{blog.Date_of_publish}</small>
                                                            <a href={() => false} className="text-decoration-none float-end ">
                                                                <span className="card-tag">
                                                                    <Link to={`/Blog/${blog.Blog_url}`} className="nav-link">
                                                                        Read More <i className="fa fa-arrow-right" />
                                                                    </Link>
                                                                </span>
                                                            </a>
                                                        </div>
                                                    </div>
                                                </Link>
                                            </div>
                                        </div>
                                    ))
                                )}
                            </div>
                        </div>
                        <div className="col-lg-4 my-2">
                            <div className="row">
                                <BlogRecent />
                                <BlogCategories />
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}
