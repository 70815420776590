import React from 'react'
import TopNavbar from '../../Main-Components/TopNavbar/TopNavbar'
import Menubar from '../../Main-Components/Menubar/Menubar'
import Footer from '../../Main-Components/Footer/Footer'
import BlogListBanner from '../../Small-Components/BlogListBanner/BlogListBanner'
import BlogRecent from "../../Small-Components/BlogRecent/BlogRecent";
import BlogCategories from "../../Small-Components/BlogCategories/BlogCategories ";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Link, useLocation } from 'react-router-dom'
import { faFacebook, faInstagram, faLinkedin, faTwitter } from '@fortawesome/free-brands-svg-icons'
import { Helmet } from 'react-helmet'
import MarriageMatchingForm from "../../Forms/MarriageMatchingForm/MarriageMatchingForm";
import MarriageMatchingContextProvider from "../../context/forms/MarriageMatching_contexProvider"
export default function TamilHoroscopeMatchingBlog() {

    const location = useLocation();
    const canonical = () => {
        const base_url = "https://onepagehoroscope.com";
        const current_path = location.pathname;

        const canonicalPaths = {
            "/Blog/tamil-horoscope-matching": "/Blog/tamil-horoscope-matching",

        };

        return (
            <link
                rel="canonical"
                href={base_url + (canonicalPaths[current_path] || current_path)}
            />
        );
    };

    return (
        <>
            <Helmet>
                <title>Tamil horoscope matching - Marriage Matching</title>
                <meta
                    name="description"
                    content="
          Tamil horoscope matching: Unlock the secrets of compatibility in Tamil astrology. Get accurate predictions for love and marriage today!"
                />
                <meta
                    name="keywords"
                    content="Tamil horoscope matching"
                />
                <meta name="robots" content="index,follow" />
                <meta property="og:title" content="Tamil horoscope matching - Marriage Matching" />
                <meta property="og:description" content="Tamil horoscope matching: Unlock the secrets of compatibility in Tamil astrology. Get accurate predictions for love and marriage today!" />
                <meta property="og:image" content="https://ctsvsolutions.com/onepage-images/Tamil-horoscope-matching.webp" />
                <meta property="og:url" content="https://onepagehoroscope.com/Blog/tamil-horoscope-matching" />
                <meta name="twitter:card" content="summary_large_image" />
                <meta name="twitter:title" content="Tamil horoscope matching - Marriage Matching" />
                <meta name="twitter:description" content="Unlock the secrets of Nakshatra matching and find cosmic compatibility. Discover your destined partner with expert horoscope insights." />
                <meta name="twitter:url" content="https://onepagehoroscope.com/Blog/tamil-horoscope-matching" />
                {canonical()}
                <script type="application/ld+json">
                    {JSON.stringify({

                        "@context": "https://schema.org",
                        "@type": "Article",
                        "mainEntityOfPage": {
                            "@type": ".webpage",
                            "@id": "https://onepagehoroscope.com/Blog/Jathagam-Online-PDF"
                        },
                        "headline": "Jathagam Online - Easy PDF Jathagam Online",
                        "description": "Unlock your destiny with Jathagam Online - Your trusted source for accurate astrological predictions. Get personalized insights now!",
                        "image": "https://ctsvsolutions.com/onepage-images/Jathagam-Online.webp",
                        "author": {
                            "@type": "",
                            "name": ""
                        },
                        "publisher": {
                            "@type": "Organization",
                            "name": "",
                            "logo": {
                                "@type": "ImageObject",
                                "url": ""
                            }
                        },
                        "datePublished": "2024-03-19",
                        "dateModified": "2024-03-21"
                    })}
                </script>
            </Helmet>
            <TopNavbar />
            <Menubar NavBgstyle="outer-header rjr" NavTextColor="text-dark" SubmenuTop="true" />
            <BlogListBanner />
            <TamilHoroscopeMatchingContent />
            <Footer />
        </>
    )
}

function TamilHoroscopeMatchingContent() {
    return (
        <>
            <section className="article-wrapper">
                <div className="astro-container pt-3">
                    <div className="row">
                        <div className="col-lg-8 my-2">
                            <div className="card bg-transparent border-0 blog-view">
                                <div className="card-header bg-transparent border-0 px-0">
                                    <h1 className="fs-4 blog-view-heading">
                                        Tamil horoscope matching - Marriage Matching
                                    </h1>
                                </div>
                                <div className="card-body pt-0 px-0">
                                    <div className="my-2">
                                        <img
                                            src="https://ctsvsolutions.com/onepage_admin/onepage-images/Tamil-horoscope-matching.webp"
                                            alt="Tamil horoscope matching"
                                            className="img-fluid rounded"
                                        />
                                    </div>
                                    <div className="my-4 d-flex">
                                        <a href="https://www.facebook.com/onepagehoroscope/">
                                            <div className="view-blog-icons facebook">
                                                <FontAwesomeIcon
                                                    icon={faFacebook}
                                                    className="text-white px-2"
                                                />
                                            </div>
                                        </a>
                                        <a href="https://www.instagram.com/one_page_horoscope/">
                                            <div className="view-blog-icons instagram">
                                                <FontAwesomeIcon
                                                    icon={faInstagram}
                                                    className="text-white px-2"
                                                />
                                            </div>
                                        </a>
                                        <a href="https://twitter.com/onepage321">
                                            <div className="view-blog-icons twitter">
                                                <FontAwesomeIcon icon={faTwitter} className="text-white px-2" />
                                            </div>
                                        </a>
                                        <a href="https://linkedin.com/showcase/97871433/admin/feed/posts/">
                                            <div className="view-blog-icons linkedin">
                                                <FontAwesomeIcon
                                                    icon={faLinkedin}
                                                    className="text-white px-2"
                                                />
                                            </div>
                                        </a>
                                    </div>
                                    <div className="description mt-3">
                                        <p className="mb-3">
                                            <Link to="/Marriage-matching" className="text-decoration-none">
                                                <span className="text-danger">Tamil horoscope matching</span>
                                            </Link> , it is usual in tamilnadu to predict
                                            marriage life of male and female before marriage.
                                        </p>
                                        <div>
                                        <MarriageMatchingContextProvider>
                                               <MarriageMatchingForm BlogStatus={true} />
                                        </MarriageMatchingContextProvider >
                                        </div>
                                        <div>
                                            <h2 className="mb-3 fs-5 blog-view-heading mt-3">
                                                Tamil horoscope matching - Important in Indian Marriage.
                                            </h2>
                                            <p className="description-content mb-3">
                                                Tamil horoscope matchings, its a system followed in south India
                                                before every marriage, for predicting the couple marriage life,
                                                wealth and health.
                                            </p>
                                            <p className="description-content mb-3">
                                                This horoscope matching predicts about children’s, sexual life,
                                                how mutual each other, and lifestyle.
                                            </p>
                                        </div>
                                        <div>
                                            <h2 className="mb-3 fs-5 blog-view-heading">
                                                Horoscope Matching
                                            </h2>
                                            <p className="description-content mb-3">
                                                Horoscope matching include several steps and procedure it is
                                                predict by an astrologer who is master in astrology calculation.
                                            </p>
                                            <p className="description-content mb-3">
                                                Every horoscope matching we need male and female horoscope, and
                                                their prediction include birth star matching, sevva dosam
                                                matching, dasa matching, babasamyam, ragu kethu dasa and
                                                analysis of 5th and 7th bava.
                                            </p>
                                            <p className="description-content mb-3">
                                                Star matching we already discussed detail about how it works and
                                                must have matching to get marriage kindly refer here.
                                            </p>
                                        </div>
                                        <div>
                                            <h2 className="mb-3 fs-5 blog-view-heading">Sevvai Dosham</h2>
                                            <p className="description-content mb-3">
                                                Sevvai dosham , If Mars is in the 1st (Lagna) 7th or 8th house
                                                in a male or female horoscope, then the dosha of Mars is high
                                                (severe). If Mars is malefic in female horoscope (in 1, 7, 8
                                                houses) then in male horoscope Mars should be in any of these
                                                three houses, 1, 7 or 8. If there is a dosha in the first house,
                                                it can be added if there is a dosha in the 7th and 8th house in
                                                the male horoscope. If there is a dosha in the 7th house, it can
                                                be added if there is a dosha in the 8th and 1st houses. If there
                                                is a dosha in the 8th house, if there is a malefic in the 1st
                                                and 7th houses, it can be added.
                                            </p>
                                        </div>
                                        <div>
                                            <h2 className="mb-3 fs-5 blog-view-heading">Ragu Kethu Dosham</h2>
                                            <p className="description-content mb-3">
                                                Tamil Horoscope matching, ragu kethu dosham is calculating
                                                nowadays in the marriage matching report, as technical if raghu
                                                or kethu in the first house or ascendant it called rahu kethu
                                                dosham.
                                            </p>
                                            <p className="description-content mb-3">
                                                If rahu kethu present in second house in the birth chart, this
                                                condition also called rahu kethu dosham, generally this dosham
                                                leads to late marriage but some astrologers suggesting pooja to
                                                neutralise this rahu kethu dosham.
                                            </p>
                                        </div>
                                        <div>
                                            <h2 className="mb-3 fs-5 blog-view-heading">Dasa Santhi</h2>
                                            <p className="description-content mb-3">
                                                Dasa santhi must to check in the marriage matching procedure, if
                                                a boy dasa changing time and girl dasa changing time meet
                                                between 18 months this leads to dasa santhi.
                                            </p>
                                            <p className="description-content mb-3">
                                                For example if boy ruling dasa changing this month, with in this
                                                18 months the girl ruling dasa changed this condition called
                                                dasa santhi.
                                            </p>
                                            <p className="description-content mb-3">
                                                This leads to be the ruling dasa is good for the particular
                                                horoscope, the happiness will be more, but if not the it will
                                                affect our happiness.
                                            </p>
                                        </div>
                                        <div>
                                            <h2 className="mb-3 fs-5 blog-view-heading">Baba Samyam</h2>
                                            <p className="description-content mb-3">
                                                Every movement of planet in both horoscope is calculated and
                                                compare with both bride and groom horoscope is called baba
                                                samyam, it will show how the horoscope suits basically.
                                            </p>
                                            <p className="description-content mb-3">
                                                In the Tamil horoscope matching these are the procedure followed
                                                by the south Indian astrologer, mostly Indian astrologer also
                                                doing the same but they have slight deviation in the process of
                                                calculating mars and Jupiter.
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-4 my-2">
                            <div className="row">
                                <BlogRecent />
                                <BlogCategories />
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}