import React from 'react'
import Blogright from '../../../../components/tamil/Blog-right-section'
import '../../style/mystyle.css'

export default function AboutUsContent() {
    return (
        <>
            <section className="result-info bc-outer bcr Bh-result">
                <div className="astro-container privacy-policy-content">
                    <div className='row'>
                        <div className="col-lg-8 my-2">
                            <div>
                                <h2 className='text-danger'>About One Page Horoscope</h2>
                                <p className='paragraph'>Combine astrology calculation solution with technology, along with detailed individual birth chart at low price.</p>
                                <p className='paragraph'>Onepage Horoscope Services.</p>
                                <ul className='pp-view-list'>
                                    <li>Birth chart</li>
                                    <li>Marriage Matching</li>
                                    <li>Marriage Bio-data</li>
                                </ul>
                            </div>
                            <div>
                                <h2 className='text-danger mb-2'>Birth Chart</h2>
                                <img src="https://ctsvsolutions.com/onepage-images/one_page_horoscope-birth-shart-slider.webp" alt="Birth-chart" className='img-fluid mb-2' />
                                <ul className='pp-view-list'>
                                    <li>Birth chart (or) jathagam is one person individual planet position calculation at the time of birth.</li>
                                    <li>This birth chart is calculating depends on birth place, birth date and birth time.</li>
                                    <li>Onepage horoscope gives birth chart based on Tamil thirukanitha panjang calculation, used by monthly in South India.</li>
                                </ul>
                            </div>
                            <div>
                                <h2 className='text-danger mb-2'>Marriage Matching</h2>
                                <img src="https://ctsvsolutions.com/onepage-images/one-page-horoscope-marriage-matching-slider.webp" alt="marriage-matching-slider" className='img-fluid mb-2' />
                                <ul className='pp-view-list'>
                                    <li>Compare and predict bride and groom, life, relationship, health, Profession and future based on their birth chart in one of the common structures before marriage in India.</li>
                                    <li>Onepage horoscope gives marriage matching predictions about bride and groom star match, dasa match, and dosham, and provides a report and result.</li>
                                </ul>
                            </div>
                            <div>
                                <h2 className='text-danger mb-2'>Marriage Bio-data</h2>
                                <img src="https://ctsvsolutions.com/onepage-images/one-page-horoscope-marriage-matching-slider.webp" alt="marriage-matching-slider" className='img-fluid mb-2' />
                                <ul className='pp-view-list'>
                                    <li>Bio-data refers to everything about you, including yourself, family, profession, etc.</li>
                                    <li>Creating marriage bio-data is easy for everyone, but it's difficult to integrate their horoscope within the structure.</li>
                                    <li>We, Onepage Horoscope, provide your bio-data for marriage with your family details, profession and salary, family wealth, along with your personal horoscope and stars.</li>
                                </ul>
                            </div>
                            <div>
                                <h2 className='text-danger mb-2'>Support</h2>
                                <ul className='pp-view-list'>
                                    <li><span className='fw-bold'>Email:</span> ctsvsolutions@gmail.com</li>
                                    <li><span className='fw-bold'>Contact:</span> +91 9787420717</li>
                                    <li><span className='fw-bold'>Address:</span> 7B, 7th Street, opp. to srinivasa electricals, Kamaraj Nagar, Avarampalayam Illango Nagar, Coimbatore, Tamil Nadu-641006</li>
                                </ul>
                            </div>
                        </div>
                        <div className="col-lg-4 my-2">
                            <Blogright />
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}
