import React from 'react'
import { Link } from "react-router-dom";
import axios from "axios";
import { useEffect } from "react";
import Skeleton from 'react-loading-skeleton';
import { useState } from "react";

export default function BlogRecent() {

    const [loading, setLoading] = useState(true);
    const [recentBlog, setRecentBlog] = useState([]);

    useEffect(() => {
        axios.get('https://ctsvsolutions.com/onepage-Api/public/api/Recent-Blogs')
            .then(response => {
                console.log(response);
                setRecentBlog(response.data.data);
                setLoading(false);
            })
            .catch(error => {
                console.error('Error fetching recent blogs:', error);
                setLoading(false);
            });
    }, []);

    return (
        <>
            <div className="col-12 mb-1">
                <h2 className="blog-heading text-danger fw-bold">
                    <i className="fab fa-superpowers" aria-hidden="true"></i> Recent Post
                </h2>
                <hr />
            </div>
            {loading ? (
                [1, 2, 3, 4, 5].map((index) => (
                    <div className="col-12 mb-1" key={index}>
                        <div className="card bg-transparent mb-3 pt-0 border-0">
                            <div className="blog-right-card">
                                <div className="row">
                                    <div className="col-4">
                                        <Skeleton height={100} />
                                    </div>
                                    <div className="col-8">
                                        <div className="card-body py-1 px-0">
                                            <Skeleton height={20} />
                                            <Skeleton height={20} width={200} />
                                            <Skeleton height={10} width={100} />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                ))
            ) : (
                recentBlog.map((blog, index) => (
                    <div className="col-12 mb-1" key={index}>
                        <div className="card bg-transparent mb-3 pt-0 border-0">
                            <div className="blog-right-card">
                                <div className="row">
                                    <div className="col-4">
                                        <Link to={`/Blog/${blog.Blog_url}`} className="">
                                            <img
                                                src={`https://ctsvsolutions.com/onepage_admin/onepage-images/${blog.image_name}`}
                                                className="img-fluid rounded-start"
                                                alt="Jathagam-Online"
                                                height="100px"
                                            />
                                        </Link>
                                    </div>
                                    <div className="col-8">
                                        <div className="card-body py-1 px-0">
                                            <Link to={`/Blog/${blog.Blog_url}`} className="nav-link p-0">
                                                <p className="card-text mb-0 blog-right-content">
                                                    {blog.Blog_heading}
                                                </p>
                                            </Link>
                                            <small className="blog-right-date">{blog.Date_of_publish}</small>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                ))
            )}
        </>
    )
}
