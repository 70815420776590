import React, { useContext, useState } from 'react'
import { RuthuViewContext } from '../../context/viewpage/RuthuViewContextProvider'
import RuthuPaidTemplate from "../../../../components/tamil/RuthuPaidTemplate/RuthuPaidTemplate"
import axios from 'axios';
import { Loading } from "notiflix/build/notiflix-loading-aio";
import { Notify } from "notiflix/build/notiflix-notify-aio";
import { Report } from "notiflix/build/notiflix-report-aio";
import { Confirm } from "notiflix/build/notiflix-confirm-aio";
import html2pdf from "html2pdf.js/dist/html2pdf.min";
import ReactDOMServer from "react-dom/server";
// import getepayPortal from '../../../../components/tamil/Getepay_pg_react';
import { RazerpayPaymentGateway } from '../../payment-gateway/RazerpayGateway';

export default function RuthuJathagaViewHoroDet() {
    const { personal_info, value } = useContext(RuthuViewContext);
    const [showPopup, setShowPopup] = useState(false);
    const [buyerMobilenumber, setMobilenumber] = useState("");
    const [email, setEmail] = useState("");
    const [buyerName, setBuyername] = useState("");
    const [mobilenumberError, setMobieNumberError] = useState("");
    const [emailError, setEmailError] = useState("");
    const [buyerNameError, setBuyerNameError] = useState("");
    // const userInfoJSON = localStorage.getItem("RuthuUserInfo");
    // let initialUserId = null;
    // let initialCustomerDataId = null;
    // if (userInfoJSON) {
    //     const userInfo = JSON.parse(userInfoJSON);
    //     initialUserId = userInfo.user_id;
    //     initialCustomerDataId = userInfo.customerDataId;
    // }


    // const [user_id, setUserId] = useState(initialUserId);
    // const [customerDataId, setcustomerDataId] = useState(initialCustomerDataId);

    // useEffect(() => {
    //     const userInfo = JSON.parse(localStorage.getItem("RuthuUserInfo"));
    //     console.log(userInfo);
    //     if (userInfo) {
    //         // setUserId(userInfo.user_id);
    //         setcustomerDataId(userInfo.customerDataId);
    //     }
    // }, []);


    const validateInput = () => {
        let hasError = false;

        if (!email) {
            setEmailError("Field is required");
            hasError = true;
        } else {
            setEmailError("");
        }

        if (!buyerName) {
            setBuyerNameError("Field is required");
            hasError = true;
        } else {
            setBuyerNameError("");
        }

        if (!buyerMobilenumber || buyerMobilenumber.length !== 10) {
            setMobieNumberError("Field is required and must be 10 digits");
            hasError = true;
        } else {
            setMobieNumberError("");
        }

        return !hasError;
    };

    const handlepay = () => {
        if (validateInput()) {
            const storedUserInfo = localStorage.getItem("RuthuUserInfo");
            const userInfo = JSON.parse(storedUserInfo);

            const user_data_id = userInfo.user_id;
            const customer_data_id = userInfo.customerDataId;
            if (
                user_data_id !== "" &&
                user_data_id !== null &&
                customer_data_id !== "" &&
                customer_data_id !== null
            ) {
                proceedToPayment();
            } else {
                if (user_data_id === null && customer_data_id === null) {
                    const OnepageGeomenery = localStorage.getItem("Ruthuformdata");
                    const GeoLocation = JSON.parse(OnepageGeomenery);
                    const latitude = GeoLocation.latitude;
                    const longitude = GeoLocation.longitude;
                    const userData = {
                        buyerName: buyerName,
                        buyerEmail: email,
                        buyerMobileNo: buyerMobilenumber,
                        name: value.personal_info.name,
                        DateofBirth: value.personal_info.birth_date,
                        birthTime: value.personal_info.birth_time,
                        gender: value.personal_info.gender,
                        birthPlace: value.personal_info.birth_place,
                        Amount: 248,
                        father_name: value.personal_info.father_name,
                        mother_name: value.personal_info.mother_name,
                        Package: "Ruthu_Jathagam",
                        latitude: latitude,
                        longitude: longitude,
                    };
                    axios
                        .post(
                            "https://ctsvsolutions.com/onepage-Api/public/api/Statement-customerData-insert",
                            {
                                userData,
                            }
                        )
                        .then((response) => {
                            const userId = response.data.data.userId;
                            const customerDataId = response.data.data.customerDataId;
                            const userInfo = {
                                user_id: userId,
                                customerDataId: customerDataId,
                            };
                            const UserInfoValues = JSON.stringify(userInfo);
                            localStorage.setItem("RuthuUserInfo", UserInfoValues);
                            // setcustomerDataId(customerDataId);
                            // setUserId(userId);
                            // console.log('its from 2nd');
                            proceedToPayment(userId);
                        });
                } else {
                    // console.log('its from 3rd');
                    proceedToPayment();
                }
            }
        }
    };

    const proceedToPayment = async () => {
        Loading.dots();
        setShowPopup(false);
        const storedUserInfo = localStorage.getItem("RuthuUserInfo");
        const userInfo = JSON.parse(storedUserInfo);
        const user_id = userInfo.user_id;
        const customerDataId = userInfo.customerDataId;
        const payment_Data = {
            amount: '249',
            email: email,
            description: 'Ruthu Horoscope',
            name: buyerName,
            mobileNumber: buyerMobilenumber,
            user_id: user_id,
            theme: '#E2F2FE',
            customerDataId: customerDataId,
            paymentCallBackurl: 'https://ctsvsolutions.com/onepage-Api/public/api/update-razerpay-payment-paid-status'
        };
        await RazerpayPaymentGateway(payment_Data, DownloadRuthuPaidPdf,true);
        Loading.remove();
        // const data = {
        //     mid: "993377",
        //     amount: "248.00",
        //     merchantTransactionId: "sd12121",
        //     transactionDate: "Mon Oct 03 13:54:33 IST 2022",
        //     terminalId: "getepay.merchant131068@icici",
        //     udf1: buyerMobilenumber,
        //     udf2: email,
        //     udf3: buyerName,
        //     udf4: user_id,
        //     udf5: customerDataId,
        //     udf6: "",
        //     udf7: "",
        //     udf8: "",
        //     udf9: "",
        //     udf10: "",
        //     ru: "https://ctsvsolutions.com/onepage-Api/public/Ruthu-Jathagam-Payment",
        //     callbackUrl: "",
        //     currency: "INR",
        //     paymentMode: "ALL",
        //     bankId: "",
        //     txnType: "single",
        //     productType: "IPG",
        //     txnNote: "Ruthu Jathagam",
        //     vpa: "getepay.merchant131068@icici",
        // };

        // const Config = {
        //     mid: "993377",
        //     "Getepay Terminal Id": "getepay.merchant131068@icici",
        //     "Getepay Key": "rjW99m2Vo75SBbpyc+K5nfB/eebzWUmkAtx90U5Ku3I=",
        //     "Getepay IV": "Y7fOrFLKVPosfDFSIFSISQ==",
        //     "Getepay Url":
        //         "https://portal.getepay.in:8443/getepayPortal/pg/generateInvoice",
        // };
        // getepayPortal(data, Config);
    };

    const currentLocation = window.location.href;
    const [baseUrl, queryParamString] = currentLocation.split("?");
    const queryParamsObject = {};

    if (queryParamString) {
        const queryParams = queryParamString.split("&");

        for (const param of queryParams) {
            const [key, value] = param.split("=");
            queryParamsObject[key] = value;
        }
        const payment_status = queryParamsObject["payment_status"];
        if (payment_status === "Filure") {
            Report.warning(
                "Notiflix Warning",
                '"The peoples who want to live comfortably without producing and fatigue; they are doomed to lose their dignity, then liberty, and then independence and destiny." <br/><br/>- Mustafa Kemal Ataturk',
                "Okay"
            );
        }
        if (payment_status === "CrEdiT") {
            Report.success(
                "Payment Succuss",
                "உங்கள் கட்டணம் வெற்றிகரமாக பெறப்பட்டது ! நீங்கள்  கீழே உள்ள Download ஐ  கிளிக் செய்து உங்கள் PDF ஐ பதிவிறக்கம் செய்து கொள்ளலாம் ...",
                // "
                // "Thank you for your payment! Your transaction has been completed. You can now download your PDF document by clicking the button below:",
                "Download Pdf",
                () => {
                    Loading.hourglass("Generating Ruthu Horoscope....", {
                        backgroundColor: "rgba(0,0,0,0.8)",
                    });

                    const storedUserInfo = localStorage.getItem("RuthuUserInfo");
                    const userInfo = JSON.parse(storedUserInfo);

                    const printElement = ReactDOMServer.renderToString(
                        paidpdfConvertion()
                    );
                    html2pdf()
                        .from(printElement)
                        .set({
                            image: { type: "jpeg", quality: 1 },
                            dpi: 300,
                            renderer: "canvas",
                            html2canvas: { scale: 2 },
                        })
                        .save();

                    html2pdf()
                        .from(printElement)
                        .set({
                            image: { type: "jpeg", quality: 1 },
                            dpi: 300,
                            renderer: "canvas",
                            html2canvas: { scale: 2 },
                        })
                        .outputPdf()
                        .then((pdfData) => {
                            const base64Data = btoa(pdfData);
                            savePaidPdf(
                                base64Data,
                                userInfo.user_id,
                                userInfo.customerDataId
                            );
                        });
                }
            );
        }
    }

    const paidpdfConvertion = () => {
        return <RuthuPaidTemplate value={value} />;
    };

    const savePaidPdf = (pdfData, user_id, customerDataId) => {
        const pdfDataVal = {
            pdfData: pdfData,
            userId: user_id,
            customerId: customerDataId,
            FileName: "Ruthu_Jathagam",
        };
        const DataInfo = {
            userId: user_id,
            customerId: customerDataId,
            columnName: "pdf_file",
        };
        axios
            .post(
                "https://ctsvsolutions.com/onepage-Api/public/api/Save-Paid-Pdf",
                pdfDataVal
            )
            .then((response) => {
                axios
                    .post(
                        "https://ctsvsolutions.com/onepage-Api/public/api/send-paid-PdfIn-whastapp",
                        DataInfo
                    )
                    .then((response) => {
                        axios
                            .post(
                                "https://ctsvsolutions.com/onepage-Api/public/api/Send-pdfIn-Email",
                                DataInfo
                            )
                            .then((response) => {
                                Loading.remove();
                                Confirm.prompt(
                                    "Rating ⭐⭐⭐⭐⭐",
                                    "How was your experince?",
                                    "",
                                    "Answer",
                                    "Cancel",
                                    (clientAnswer) => {
                                        const RatingInfo = {
                                            user_id: user_id,
                                            customer_data_id: customerDataId,
                                            feedback: clientAnswer,
                                        };
                                        axios
                                            .post(
                                                "https://ctsvsolutions.com/onepage-Api/public/api/Update-Feed-Back",
                                                RatingInfo
                                            )
                                            .then((response) => {
                                                window.location.replace("/RuthuJathagam-ThankYou");
                                            });
                                    },
                                    (clientAnswer) => {
                                        window.location.replace("/RuthuJathagam-ThankYou");
                                    }
                                );
                                Notify.success("Sample pdf file sended succussfully");
                            })
                            .catch((error) => {
                                console.error("Error sending WhatsApp message:", error);
                            });
                    })
                    .catch((error) => {
                        console.error("Error sending WhatsApp file:", error);
                    });
            })
            .catch((error) => {
                console.error("Error saving PDF:", error);
            });
    };
    const DownloadRuthuPaidPdf = () => {
        const storedUserInfo = localStorage.getItem("RuthuUserInfo");
        const userInfo = JSON.parse(storedUserInfo);
        const printElement = ReactDOMServer.renderToString(
            paidpdfConvertion()
        );
        html2pdf()
            .from(printElement)
            .set({
                image: { type: "jpeg", quality: 1 },
                dpi: 300,
                renderer: "canvas",
                html2canvas: { scale: 2 },
            })
            .save();

        html2pdf()
            .from(printElement)
            .set({
                image: { type: "jpeg", quality: 1 },
                dpi: 300,
                renderer: "canvas",
                html2canvas: { scale: 2 },
            })
            .outputPdf()
            .then((pdfData) => {
                const base64Data = btoa(pdfData);
                savePaidPdf(
                    base64Data,
                    userInfo.user_id,
                    userInfo.customerDataId
                );
            });
    }
    return (
        <>
            <section className="ad-details ad-details-2">
                <div className="astro-container">
                    <div className="ad-details-row">
                        <div className="img">
                            <img className="img-res" src='https://ctsvsolutions.com/onepage-images/new-ui/ruthu-jathaga-detail.webp' alt='tamil ruthu jathagam panchang' />
                        </div>
                        <div className="content">
                            <h3 className="sh">
                                Almanac <span className="grt">Details</span>
                            </h3>
                            <div className="row">
                                <div className="item">
                                    <span>day</span>
                                    <h4>{personal_info.birth_day}</h4>
                                </div>
                                <div className="item">
                                    <span>Didi</span>
                                    <h4>{personal_info.thithi}</h4>
                                </div>
                                <div className="item">
                                    <span>Yoga star</span>
                                    <h4>{personal_info.yoga_natchathiram}</h4>
                                </div>
                                <div className="item">
                                    <span>Rasi</span>
                                    <h4>{personal_info.rasi}</h4>
                                </div>
                                <div className="item">
                                    <span>Star</span>
                                    <h4>{personal_info.natchathiram}</h4>
                                </div>
                                <div className="item">
                                    <span>yogi</span>
                                    <h4>{personal_info.yogi}</h4>
                                </div>
                                <div className="item">
                                    <span>Laknam</span>
                                    <h4>{personal_info.laknam}</h4>
                                </div>
                                <div className="item">
                                    <span>karanam</span>
                                    <h4>{personal_info.karanam}</h4>
                                </div>
                                <div className="item">
                                    <span>Avayoga Nakshatra</span>
                                    <h4>{personal_info.avayogi_natchathiram}</h4>
                                </div>
                                <div className="item">
                                    <span>patcham</span>
                                    <h4>{personal_info.patcham}</h4>
                                </div>
                                <div className="item">
                                    <span>Ayanamsa</span>
                                    <h4>{personal_info.ayanamsam}</h4>
                                </div>
                                <div className="item">
                                    <span>Tamil Day</span>
                                    <h4>{personal_info.tamil_day}</h4>
                                </div>
                            </div>
                            <button type='button' onClick={() => setShowPopup(true)} className="btn-lg btn-pnk border-0">
                                Download PDF
                                <img src='https://ctsvsolutions.com/onepage-images/new-ui/btn-pdf-icon.webp' alt='ruthu jathagam download pdf' />
                            </button>
                        </div>
                    </div>
                </div>
            </section>
            {showPopup && (
                <div className="global-popup dp-popup">
                    <div className="content">
                        <div className="popup-close" onClick={() => setShowPopup(false)}>
                            <img src='https://ctsvsolutions.com/onepage-images/new-ui/popup-cross-icon.webp' alt="Close" />
                        </div>
                        <img className="illus" src='https://ctsvsolutions.com/onepage-images/new-ui/download-popup-icon.webp' alt="ruthu jathagam pdf" />
                        <h3 className="sh">
                            Download<span className="grt"> PDF</span>
                        </h3>
                        <p>
                            Your report will be delivered to your email and WhatsApp number. Before
                            payment, confirm your details.
                        </p>
                        <form className="cmn-form">
                            <div className="form-group">
                                <label htmlFor="email">Email</label>
                                <input className='input' placeholder='Enter Your E-Mail' type="email" name="email" id="email" value={email}
                                    onChange={(e) => {
                                        setEmail(e.target.value);
                                    }} />
                                <span id="name_error1" style={{ color: "red" }}>
                                    {emailError}
                                </span>
                            </div>
                            <div className="form-group">
                                <label htmlFor="name">Name</label>
                                <input className='input' placeholder='Enter Your Name' type="text" name="name" id="name" onChange={(e) => setBuyername(e.target.value)}
                                    value={buyerName} />
                                <span id="name_error1" style={{ color: "red" }}>
                                    {buyerNameError}
                                </span>
                            </div>
                            <div className="form-group">
                                <label htmlFor="whatsapp">WhatsApp Number</label>
                                <input className='input' placeholder='Enter Your WhatsApp Number' type="tel" name="whatsapp" id="whatsapp" value={buyerMobilenumber}
                                    onChange={(e) => setMobilenumber(e.target.value)} />
                                <span id="name_error1" style={{ color: "red" }}>
                                    {mobilenumberError}
                                </span>
                            </div>
                        </form>
                        <div className="buttons">
                            <button type='button' className="btn-lg btn-pnk border-0" onClick={handlepay}>
                                Buy Now
                                <img src="https://ctsvsolutions.com/onepage-images/new-ui/btn-arrow-white.webp" alt="ruthu jathagam arrow" />
                            </button>
                        </div>
                    </div>
                    <div className="overlay" onClick={() => setShowPopup(false)} />
                </div>
            )}
        </>
    )
}
