import React from 'react'
import TopNavbar from '../../Main-Components/TopNavbar/TopNavbar'
import Menubar from '../../Main-Components/Menubar/Menubar'
import Footer from '../../Main-Components/Footer/Footer'
import BlogListBanner from '../../Small-Components/BlogListBanner/BlogListBanner'
import BlogRecent from "../../Small-Components/BlogRecent/BlogRecent";
import BlogCategories from "../../Small-Components/BlogCategories/BlogCategories ";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {  useLocation } from 'react-router-dom'
import { faFacebook, faInstagram, faLinkedin, faTwitter } from '@fortawesome/free-brands-svg-icons'
import { Helmet } from 'react-helmet'

export default function ComputerJadhagam() {
    const location = useLocation();
    const canonical = () => {
        const base_url = "https://onepagehoroscope.com";
        const current_path = location.pathname;

        const canonicalPaths = {
            "/computer-Jathagam":
                "/computer-Jathagam",
        };

        return (
            <link
                rel="canonical"
                href={base_url + (canonicalPaths[current_path] || current_path)}
            />
        );
    };
    return (
        <>
            <Helmet>
                <title>
                    compurer Jathagam ||கம்ப்யூட்டர் ஜாதகம்
                </title>
                <meta
                    name="description"
                    content="Unlock your birth horoscope with our detailed Computer Jathagam in Tamil. Download your personalized horoscope PDF online and gain insights into your life's journey, including your birth star, planetary positions, and future predictions. Stay informed with our accurate astrological guidance and plan your life with confidence and clarity. Explore your unique birth horoscope today and discover what the stars have in store for you."
                />
                <meta name="keywords" content="compurer Jathagam, compurer Jathagam tamil,free computer jathagam pdf,computer astrology,computer jathagam download free" />
                <meta name="robots" content="index,follow" />
                {canonical()}
            </Helmet>
            <TopNavbar />
            <Menubar NavBgstyle="outer-header rjr" NavTextColor="text-dark" SubmenuTop="true" />
            <BlogListBanner />
            <ComputerJadhagamBlogContent />
            <Footer />
        </>
    )
}

function ComputerJadhagamBlogContent() {
    return (
        <>
            <section className="article-wrapper">
                <div className="astro-container pt-3">
                    <div className="row">
                        <div className="col-lg-8 my-2">
                            <div className="card bg-transparent border-0 blog-view">
                                <div className="card-header bg-transparent border-0 px-0">
                                    <h1 className="fs-4 blog-view-heading">Computer Jathagam</h1>
                                </div>
                                <div className="card-body pt-0 px-0">
                                    <div className="my-2">
                                        <img
                                            src="https://ctsvsolutions.com/onepage-images/computer_jathagam.webp"
                                            alt="Tamil panchangam"
                                            className="img-fluid rounded"
                                        />
                                    </div>
                                    <div className="my-4 d-flex">
                                        <a href="https://www.facebook.com/onepagehoroscope/">
                                            <div className="view-blog-icons facebook">
                                                <FontAwesomeIcon
                                                    icon={faFacebook}
                                                    className="text-white px-2"
                                                />
                                            </div>
                                        </a>
                                        <a href="https://www.instagram.com/one_page_horoscope/">
                                            <div className="view-blog-icons instagram">
                                                <FontAwesomeIcon
                                                    icon={faInstagram}
                                                    className="text-white px-2"
                                                />
                                            </div>
                                        </a>
                                        <a href="https://twitter.com/onepage321">
                                            <div className="view-blog-icons twitter">
                                                <FontAwesomeIcon icon={faTwitter} className="text-white px-2" />
                                            </div>
                                        </a>
                                        <a href="https://linkedin.com/showcase/97871433/admin/feed/posts/">
                                            <div className="view-blog-icons linkedin">
                                                <FontAwesomeIcon
                                                    icon={faLinkedin}
                                                    className="text-white px-2"
                                                />
                                            </div>
                                        </a>
                                    </div>
                                    <div className="description mt-3">
                                        <p className="mb-3">
                                            கம்ப்யூட்டர் ஜாதகம் என்பது உங்கள் பிறந்த நேரம் மற்றும் பிறந்த இடத்தை அடிப்படையாகக் கொண்டு கணிக்கப்படும் நவீன பிறந்த ஜாதகம் ஆகும். பாரம்பரிய ஜாதக கணிப்புகளுக்கு மாற்றாக, இது துல்லியமான கணிப்புகளை வழங்குவதில் மிகவும் திறமையானது.
                                        </p>
                                    </div>
                                    <div className="panchangam-form">
                                        <h4 className="mt-3 text-danger text-center fw-bold mb-0"> Computer Jathagam in 2 minutes  </h4>
                                        {/* <BirthHorosopeBlogForm></BirthHorosopeBlogForm> */}
                                    </div>
                                    <div className="description mt-3">
                                        <h5 className="text-danger">சிறப்பு அம்சங்கள் </h5>
                                        <p>பஞ்சாங்கம் என்றால் "ஐந்து அங்கங்கள்" என்று பொருள்படும். இந்த ஐந்து அங்கங்கள் என்னென்னவெனப் பார்ப்போம்:</p>
                                        <ul>
                                            <li className="text-danger">திதி :</li>
                                            <p> சந்திரன் மற்றும் சூரியனின் நிலையை அடிப்படையாகக் கொண்டது. ஒவ்வொரு திதியும் ஒரு நாளைக் குறிக்கிறது</p>
                                        </ul>
                                        <ul>
                                            <li className="text-danger">வாரம்  :</li>
                                            <p> வாரத்தின் ஏழு நாட்களில் ஒன்று</p>
                                        </ul>
                                        <ul>
                                            <li className="text-danger">நட்சத்திரம்:</li>
                                            <p> சந்திரனின் நிலையை அடிப்படையாகக் கொண்ட நக்ஷத்ரம்.</p>
                                        </ul>
                                        <ul>
                                            <li className="text-danger">யோகம் :</li>
                                            <p> இரு கிரகங்களின் நிலையை அடிப்படையாகக் கொண்ட யோகம்.</p>
                                        </ul>
                                        <ul>
                                            <li className="text-danger">கரணம்  :</li>
                                            <p>  ஒரு நாளில் இரண்டு கரணங்கள் இருப்பதைக் குறிக்கிறது.</p>
                                        </ul>

                                    </div>

                                    <div className="description">
                                        <h5 className="text-danger">பஞ்சாங்கத்தின் முக்கியத்துவம்</h5>
                                        <p>பஞ்சாங்கம் பல்வேறு நிகழ்வுகளின் நேரத்தைத் தீர்மானிக்க பயன்படுகிறது. உதாரணமாக:</p>
                                        <ul>
                                            <li>நிச்சயதார்த்தம், திருமணம் போன்ற முக்கிய நிகழ்வுகளுக்கான நல்ல நேரம்.
                                            </li>
                                            <li>வீடு கட்டுவதற்கு நல்ல நேரம்.
                                            </li>
                                            <li>புதிய வியாபாரம் தொடங்க நல்ல நேரம்.
                                            </li>
                                            <li>விரதங்கள், பண்டிகைகள் போன்றவற்றின் நாள்கள்.
                                            </li>
                                        </ul>
                                        <h5 className="text-danger">பஞ்சாங்கம் எப்படி பயன்படுத்துவது?</h5>
                                        <ul>
                                            <li>நல்ல நேரம் தேர்ந்தெடுப்பது:</li>
                                            <p> பஞ்சாங்கத்தில் நன்மை தரும் திதி, வாரம், நட்சத்திரம், யோகம், கரணம் ஆகியவற்றைத் தெரிந்து கொண்டால், நல்ல நேரத்தைத் தேர்ந்தெடுத்து செயல் திட்டமிடலாம்</p>
                                        </ul>
                                        <ul>
                                            <li>நல்ல நட்சத்திரம்</li>
                                            <p>ஒவ்வொரு நாளும் ஒரு நக்ஷத்ரத்தைச் சார்ந்து இருக்கும். இந்த நக்ஷத்ரத்தை அடிப்படையாகக் கொண்டு முக்கியமான செயல்களை முடிவு செய்யலாம்.</p>
                                        </ul>
                                        <ul>
                                            <li>பண்டிகை நாட்கள்:</li>
                                            <p>  வருடத்தின் முக்கிய பண்டிகை நாட்கள் மற்றும் விரத தினங்கள் பஞ்சாங்கத்தில் குறிப்பிடப்படும்</p>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-4 my-2">
                            <div className="row">
                                <BlogRecent />
                                <BlogCategories />
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}