import React, { useState } from "react";

import { useNavigate } from "react-router-dom";
// import getepayPortal from "../../../../components/tamil/Getepay_pg_react";
import html2pdf from "html2pdf.js/dist/html2pdf.min";
import { Loading } from "notiflix/build/notiflix-loading-aio";
import { Notify } from "notiflix/build/notiflix-notify-aio";
import { Report } from "notiflix/build/notiflix-report-aio";
import ReactDOMServer from "react-dom/server";
import { RazerpayPaymentGateway } from "../../payment-gateway/RazerpayGateway";
import { Offcanvas } from 'bootstrap';


import { Confirm } from "notiflix/build/notiflix-confirm-aio";

import axios from "axios";
import BabyNameTemplate from "../../../../components/tamil/BabyNameTemplate/BabyNameTemplate";

export default function BabyNameViewDetail({ value }) {
  return (
    <>
      <section className="cd-details">
        <div className="astro-container">
          <div className="row">
            <div className="col">
              <div className="img">
                <img className="img-res" src='https://ctsvsolutions.com/onepage-images/new-ui/cd-left-img.jpg' alt="Popular Tamil Baby Names"/>
              </div>
            </div>
            <div className="col">
              <ChildDetails value={value} />
              <OtherDetails value={value} />
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

function ChildDetails({ value }) {
  const personlInfo = value.personal_info;
  const navigate = useNavigate();

  const customRedirect = (route, data) => {
    navigate(route, { state: data });
  };

  const editForm = () => {
    const outputvalue = JSON.parse(localStorage.getItem("babyNameformdata"));
    customRedirect("/Baby-Name-List", outputvalue);
  };
  return (
    <>
      <div className="result-info">
        <div className="result">
          <div className="bhres-row-1">
            <div className="name">
              <h2 className="sh">
                child <span className="grt">details</span>
              </h2>
            </div>
            <div className="edit">
              <button
                className="btn-lg btn-prpl border-0"
                type="button"
                onClick={() => {
                  editForm();
                }}
              >
                Edit
                <img src='https://ctsvsolutions.com/onepage-images/new-ui/btn-edit-icon.webp' alt="baby name edit"/>
              </button>
            </div>
          </div>
          <div className="row">
            <div className="rd px-0">
              <div className="icon">
                <img src='https://ctsvsolutions.com/onepage-images/new-ui/date-icon.webp' alt="date of birth"/>
              </div>
              <div className="detail">
                <span>date of birth</span>
                <h4>{personlInfo.birth_date} </h4>
              </div>
            </div>
            <div className="rd px-0">
              <div className="icon">
                <img src='https://ctsvsolutions.com/onepage-images/new-ui/gender-icon.webp' alt="gender"/>
              </div>
              <div className="detail">
                <span>Gender</span>
                <h4>{personlInfo.gender}</h4>
              </div>
            </div>
            <div className="rd px-0">
              <div className="icon">
                <img src='https://ctsvsolutions.com/onepage-images/new-ui/father-icon.webp' alt="father name"/>
              </div>
              <div className="detail">
                <span>father name</span>
                <h4>{personlInfo.father_name}</h4>
              </div>
            </div>
            <div className="rd px-0">
              <div className="icon">
                <img src='https://ctsvsolutions.com/onepage-images/new-ui/birth-time-icon.webp' alt="birth time"/>
              </div>
              <div className="detail">
                <span>birth time</span>
                <h4>{personlInfo.birth_time}</h4>
              </div>
            </div>
            <div className="rd px-0">
              <div className="icon">
                <img src='https://ctsvsolutions.com/onepage-images/new-ui/location-icon.webp' alt="birth place"/>
              </div>
              <div className="detail">
                <span>birth place</span>
                <h4>{personlInfo.birth_place.split(",")[0]}</h4>
              </div>
            </div>
            <div className="rd px-0">
              <div className="icon">
                <img src='https://ctsvsolutions.com/onepage-images/new-ui/mother-icon.webp' alt="mother name"/>
              </div>
              <div className="detail">
                <span>mother name</span>
                <h4>{personlInfo.mother_name} </h4>
              </div>
            </div>
          </div>
          <button
            className="btn-lg btn-prpl edit-btn-mobile border-0"
            type="button"
            onClick={() => {
              editForm();
            }}
          >
            Edit
            <img src='https://ctsvsolutions.com/onepage-images/new-ui/btn-edit-icon.webp' alt="stylish baby name adit"/>
          </button>
        </div>
      </div>
    </>
  );
}

function OtherDetails({ value }) {
  const personlInfo = value.personal_info;

  const [buyerMobilenumber, setMobilenumber] = useState("");
  const [email, setEmail] = useState("");
  const [buyerName, setBuyername] = useState("");
  const [mobilenumberError, setMobieNumberError] = useState("");
  const [emailError, setEmailError] = useState("");
  const [buyerNameError, setBuyerNameError] = useState("");



  const validateInput = () => {
    let hasError = false;

    if (!email) {
      setEmailError("Field is required");
      hasError = true;
    } else {
      setEmailError("");
    }

    if (!buyerName) {
      setBuyerNameError("Field is required");
      hasError = true;
    } else {
      setBuyerNameError("");
    }

    if (!buyerMobilenumber || buyerMobilenumber.length !== 10) {
      setMobieNumberError("Field is required and must be 10 digits");
      hasError = true;
    } else {
      setMobieNumberError("");
    }
    return !hasError;
  };

  const handlePay = () => {
    if (validateInput()) {
      const babyGeometry = localStorage.getItem("babyNameformdata");
      const geoLocation = JSON.parse(babyGeometry);
      const latitude = geoLocation.latitude;
      const longitude = geoLocation.longitude;

      const userData = {
        buyerName: buyerName,
        buyerEmail: email,
        buyerMobileNo: buyerMobilenumber,
        DateofBirth: value.personal_info.birth_date,
        birthTime: value.personal_info.birth_time,
        gender: value.personal_info.gender,
        father_name: value.personal_info.father_name,
        mother_name: value.personal_info.mother_name,
        birthPlace: value.personal_info.birth_place,
        Amount: 249,
        initial:babyGeometry.initial,
        Package: "baby_name",
        latitude: latitude,
        longitude: longitude,
      };

     const storedUserInfo = localStorage.getItem("BabayUserInfo");
      const userInfo = JSON.parse(storedUserInfo);

      const user_data_id = userInfo.user_id;
      const customer_data_id = userInfo.customerDataId;

      if ((user_data_id !== "" && user_data_id !== null) && (customer_data_id !== "" && customer_data_id !== null)) {
        proceedToPayment();
      } else {
        if ((user_data_id === null || user_data_id === "") && (customer_data_id === null || customer_data_id === "")) { // Fix the condition here
          axios
          .post("https://ctsvsolutions.com/onepage-Api/public/api/Statement-customerData-insert", {
            userData,
          })
          .then((response) => {
            const userId = response.data.data.userId;
            const customerDataId = response.data.data.customerDataId;
            const newUserData = {
              user_id: userId,
              customerDataId: customerDataId,
            };
            const userInfoValues = JSON.stringify(newUserData);
            localStorage.setItem("BabayUserInfo", userInfoValues);
            proceedToPayment();
          });
        } else {
          proceedToPayment();
        }
      }

    }
  };
  const proceedToPayment =async () => {

    const userInfoJSON = localStorage.getItem("BabayUserInfo");
    const userInfo = JSON.parse(userInfoJSON);
    let user_id = userInfo.user_id;
    let customer_id = userInfo.customerDataId;
    const payment_Data = {
      amount: '249', 
      email: email,
      description:'Baby Name',
      name: buyerName,
      mobileNumber: buyerMobilenumber,
      user_id: user_id,
      theme: '#E2F2FE',
      customerDataId: customer_id,
      paymentCallBackurl: 'https://ctsvsolutions.com/onepage-Api/public/api/update-razerpay-payment-paid-status'
    };
    Loading.dots();
    const modalElement = document.getElementById('offcanvasBottom');
    const bootstrapModal = Offcanvas.getInstance(modalElement); 
    bootstrapModal.hide();
  
      await RazerpayPaymentGateway(payment_Data, DownloadBabyPaidPdf,true);
    Loading.remove();
    // Loading.remove();
    // const data = {
    //   mid: "993377",
    //   amount: "249.00",
    //   merchantTransactionId: "sd12121",
    //   transactionDate: "Mon Oct 03 13:54:33 IST 2022",
    //   terminalId: "getepay.merchant131068@icici",
    //   udf1: buyerMobilenumber,
    //   udf2: user_id,
    //   udf3: buyerName,
    //   udf4: customer_id,
    //   udf5: "",
    //   udf6: "",
    //   udf7: "",
    //   udf8: "",
    //   udf9: "",
    //   udf10: "",
    //   ru: "https://ctsvsolutions.com/onepage-Api/public/Baby-Payment",
    //   callbackUrl: "",
    //   currency: "INR",
    //   paymentMode: "ALL",
    //   bankId: "",
    //   txnType: "single",
    //   productType: "IPG",
    //   txnNote: "One-Page Baby Name",
    //   vpa: "getepay.merchant131068@icici",
    // };
    // // console.log(data);
    // const Config = {
    //   "mid": "993377",
    //   "Getepay Terminal Id": "getepay.merchant131068@icici",
    //   "Getepay Key": "rjW99m2Vo75SBbpyc+K5nfB/eebzWUmkAtx90U5Ku3I=",
    //   "Getepay IV": "Y7fOrFLKVPosfDFSIFSISQ==",
    //   "Getepay Url":
    //     "https://portal.getepay.in:8443/getepayPortal/pg/generateInvoice"
    // };
    // getepayPortal(data, Config);
    // console.log(data);
  };

  const currentLocation = window.location.href;
  const [baseUrl, queryParamString] = currentLocation.split("?");
  const queryParamsObject = {};

  if (queryParamString) {
    const queryParams = queryParamString.split("&");

    for (const param of queryParams) {
      const [key, value] = param.split("=");
      queryParamsObject[key] = value;
    }
    const payment_status = queryParamsObject["payment_status"];
  
    if (payment_status === "CrEdiT") {
      Report.success(
        "Payment Succuss",
        "உங்கள் கட்டணம் வெற்றிகரமாக பெறப்பட்டது ! நீங்கள்  கீழே உள்ள Download ஐ  கிளிக் செய்து உங்கள் PDF ஐ பதிவிறக்கம் செய்து கொள்ளலாம் ...",
        "Download Pdf",
        () => {
          Loading.hourglass("Baby Name is Genarating...", {
            backgroundColor: "rgba(0,0,0,0.8)",
          });
          const storedUserInfo = localStorage.getItem("BabayUserInfo");
          const userInfo = JSON.parse(storedUserInfo);
          const printElement = ReactDOMServer.renderToString(
            paidpdfConvertion()
          );
          html2pdf()
            .from(printElement)
            .set({
              image: { type: "jpeg", quality: 1 },
              dpi: 300,
              renderer: "canvas",
              html2canvas: { scale: 2 },
            })
            .save();

          html2pdf()
            .from(printElement)
            .set({
              image: { type: "jpeg", quality: 1 },
              dpi: 300,
              renderer: "canvas",
              html2canvas: { scale: 2 },
            })
            .outputPdf()
            .then((pdfData) => {
              const base64Data = btoa(pdfData);
              savePaidPdf(
                base64Data,
                userInfo.user_id,
                userInfo.customerDataId
              );
            });
        });
    }
  }

  const savePaidPdf = (pdfData, user_id, customerDataId) => {
    const pdfDataVal = {
      pdfData: pdfData,
      userId: user_id,
      customerId: customerDataId,
      FileName: "baby_name",
    };
    // console.log(pdfDataVal);
    const DataInfo = {
      userId: user_id,
      customerId: customerDataId,
      columnName: "pdf_file",
    };
    console.log(DataInfo);
    axios
      .post("https://ctsvsolutions.com/onepage-Api/public/api/Save-Paid-Pdf", pdfDataVal)
      .then((response) => {
        axios
          .post("https://ctsvsolutions.com/onepage-Api/public/api/Baby-Name-pdf-Whastapp", DataInfo)
          .then((response) => {
            axios
              .post("https://ctsvsolutions.com/onepage-Api/public/api/Baby-Name-Email", DataInfo)
              .then((response) => {
                Loading.remove();
                Confirm.prompt(
                  "Rating ⭐⭐⭐⭐⭐",
                  "How was your experince?",
                  "",
                  "Answer",
                  "Cancel",
                  (clientAnswer) => {
                    const RatingInfo = {
                      user_id: user_id,
                      customer_data_id: customerDataId,
                      feedback: clientAnswer,
                    };
                    axios
                      .post(
                        "https://ctsvsolutions.com/onepage-Api/public/api/Update-Feed-Back",
                        RatingInfo
                      )
                      .then((response) => {
                        window.location.replace("/BabyName-ThankYou");
                        // window.location = "/BabyName-ThankYou";
                      });
                  },
                  (clientAnswer) => {
                    window.location.replace("/BabyName-ThankYou");
                  }
                );
                Notify.success("BabyName pdf file sended succussfully");
              })
              .catch((error) => {
                console.error("Error sending WhatsApp message:", error);
              });
          })
          .catch((error) => {
            console.error("Error sending WhatsApp file:", error);
          });
      })
      .catch((error) => {
        console.error("Error saving PDF:", error);
      });
  };

  const paidpdfConvertion = () => {
    return <BabyNameTemplate value={value} />;
  };
  const DownloadBabyPaidPdf=()=>{
    const storedUserInfo = localStorage.getItem("BabayUserInfo");
          const userInfo = JSON.parse(storedUserInfo);
          const printElement = ReactDOMServer.renderToString(
            paidpdfConvertion()
          );
          html2pdf()
            .from(printElement)
            .set({
              image: { type: "jpeg", quality: 1 },
              dpi: 300,
              renderer: "canvas",
              html2canvas: { scale: 2 },
            })
            .save();

          html2pdf()
            .from(printElement)
            .set({
              image: { type: "jpeg", quality: 1 },
              dpi: 300,
              renderer: "canvas",
              html2canvas: { scale: 2 },
            })
            .outputPdf()
            .then((pdfData) => {
              const base64Data = btoa(pdfData);
              savePaidPdf(
                base64Data,
                userInfo.user_id,
                userInfo.customerDataId
              );
            });
  }

  return (
    <>
      <div className="ad-details">
        <div className="ad-details-row">
          <div className="content">
            <h3 className="sh">
              other <span className="grt">details</span>
            </h3>
            <div className="row">
              <div className="item">
                <span>Tamil date</span>
                <h4>{personlInfo.TamilDate} </h4>
              </div>
              <div className="item">
                <span>Rasi</span>
                <h4> {personlInfo.rasi}</h4>
              </div>
              <div className="item">
                <span>Laknam</span>
                <h4>{personlInfo.laknam}</h4>
              </div>
              <div className="item">
                <span>Star</span>
                <h4> {personlInfo.natchathiram}</h4>
              </div>
              <div className="item">
                <span>Yoga star</span>
                <h4>{personlInfo.YogiStar} </h4>
              </div>
              <div className="item">
                <span>stars letters</span>
                <h4>
                  {personlInfo.natchathira_letter_english}{" "}
                  {personlInfo.natchathira_letter_tamil}{" "}
                </h4>
              </div>
            </div>
            <button
              className="btn-lg btn-pnk border-0"
              type="button"
              data-bs-toggle="offcanvas"
              data-bs-target="#offcanvasBottom"
              aria-controls="offcanvasBottom"
            >
              Download PDF
              <img src='https://ctsvsolutions.com/onepage-images/new-ui/download-icon.webp' alt="baby name download pdf"/>
            </button>
          </div>
        </div>
      </div>

      <div
        className="offcanvas offcanvas-bottom payment-popup h-50 h-custom"
        tabIndex={-1}
        id="offcanvasBottom"
        aria-labelledby="offcanvasBottomLabel"
      >
        <div className="offcanvas-header" style={{ backgroundColor: "#FFFBF6" }}>
          <h5 className="offcanvas-title" id="offcanvasBottomLabel">Payment</h5>
          <button
            type="button"
            className="btn-close"
            data-bs-dismiss="offcanvas"
            aria-label="Close"
          />
        </div>
        <div className="offcanvas-body small content">
          <div className="pp-heading">
            <div className="heading">
              <h2 className="sh lh-1">
                <span className="grt">Payment</span>
              </h2>
              <p>
                <strong>Note :</strong> Your report will be delivered to
                your email and WhatsApp number. Before payment,
                confirm your details.
              </p>
            </div>
            <div className="btn border-0">
              <button
                className="btn"
                name="pay"
                id="pay"
                onClick={handlePay}>
                Buy now
                 <img src='https://ctsvsolutions.com/onepage-images/new-ui/btn-arrow-white.webp' alt="baby name arrow" className="ms-2"/>
              </button>
            </div>
          </div>
          <div className="pp-form">
            <div className="form-group">
              <label htmlFor="email">Email</label>
              <input type="email" placeholder="Enter Your Mail"
              className="input"
                name="email"
                id="email"
                aria-describedby="emailHelp"
                value={email}
                onChange={(e) => {
                  setEmail(e.target.value);
                }}
                required />
              <span id="name_error1" style={{ color: "red" }}>
                {emailError}
              </span>
            </div>
            <div className="form-group">
              <label htmlFor="name">Name</label>
              <input type="text" id="name"
                name="name"
                placeholder="Enter Your Name"
                 className="input"
                onChange={(e) => setBuyername(e.target.value)}
                value={buyerName} />
              <span id="name_error1" style={{ color: "red" }}>
                {buyerNameError}
              </span>
            </div>
            <div className="form-group">
              <label htmlFor="whatsapp">WhatsApp Number</label>
              <input type="text" name="mobile_number"
               className="input"
                pattern="[6789][0-9]{9}"
                placeholder="Enter Your Whatsapp Number"
                id="mobile_number"
                value={buyerMobilenumber}
                onChange={(e) => setMobilenumber(e.target.value)} />
              <span id="name_error1" style={{ color: "red" }}>
                {mobilenumberError}
              </span>
            </div>
          </div>
          <div className="pp-heading">
            <div className="btn btn-mobile border-0">
              <button name="pay"
              className="border-0"
                id="pay"
                onClick={handlePay}>
                Buy now
                <img src='https://ctsvsolutions.com/onepage-images/new-ui/btn-arrow-white.webp' className="ms-2" alt="baby name download arrow" />
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
