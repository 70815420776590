import React from 'react';

export default function TopNavbar() {
    return (
        <div className="top-bar">
            <div className="astro-container">
                <div className="top-bar-row">
                    <div className="left-bar">
                        <div className="phone" title='one page horoscope support'>
                            <a href="tel:+91 9787420717">
                                <img src='https://ctsvsolutions.com/onepage-images/new-ui/phone.webp' alt="Phone Icon" width={'18px'} height={'18px'} loading="lazy" />
                                +91 9787420717
                            </a>
                        </div>
                        <div className="tagline">
                            <p className='mb-0'>Most trusted astrology platform worldwide</p>
                        </div>
                    </div>
                    <div className="right-bar">
                        <div className="lang">
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}
