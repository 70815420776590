import React, { useContext } from 'react'
import {MarriageMatchingViewContext} from '../../context/viewpage/MarriageMatchingViewContextprovider'


export default function MarriageMatchingViewData() {
    const { personalInfo, maleJathagaInfo, femalejathagaInfo } = useContext(MarriageMatchingViewContext);

    return (
        <section className="ad-details gb-result gb-result-mobile">
            <div className="astro-container">
                <div className="ad-details-row">
                    {/* Groom's Details */}
                    <div className="content">
                        <h3 className="sh">
                             <span className="grt">மணமகன்</span>
                        </h3>
                        <div className="ad-details-row">
                            <div className="item">
                                <span>பெயர்</span>
                                <h4>{personalInfo.male_name}</h4>
                            </div>
                            <div className="item">
                                <span>நட்சத்திரம்/பாதம்</span>
                                <h4>{personalInfo.male_natchathiram} - {personalInfo.male_patham}</h4>
                            </div>
                            <div className="item">
                                <span>கனம்</span>
                                <h4>{maleJathagaInfo.mlale_ganam_tamil}</h4>
                            </div>
                            <div className="item">
                                <span>பிறந்த தேதி</span>
                                <h4>{personalInfo.maleDateofBirth}</h4>
                            </div>
                            <div className="item">
                                <span>ராசி</span>
                                <h4>{personalInfo.male_rasi}</h4>
                            </div>
                            <div className="item">
                                <span>ரஜ்ஜு</span>
                                <h4>{maleJathagaInfo.male_rajju_type_tamil}</h4>
                            </div>
                            <div className="item">
                                <span>பிறந்த நேரம்</span>
                                <h4>{personalInfo.male_birth_time}</h4>
                            </div>
                            <div className="item">
                                <span>லக்னம்</span>
                                <h4>{personalInfo.male_laknam}</h4>
                            </div>
                            <div className="item">
                                {/* <span />
                                <h4 /> */}
                            </div>
                            <div className="item">
                                <span>பிறந்த இடம்</span>
                                <h4>{personalInfo.male_birthplace.split(',')[0]}</h4>
                            </div>
                            <div className="item">
                                <span>மிருகம்</span>
                                <h4>{maleJathagaInfo.male_mirugam}</h4>
                            </div>
                            <div className="item">
                                {/* <span />
                                <h4 /> */}
                            </div>
                        </div>
                    </div>
                    {/* Bride's Details */}
                    <div className="content">
                        <h3 className="sh">
                             <span className="grt">மணமகள்</span>
                        </h3>
                        <div className="ad-details-row">
                            <div className="item">
                                <span>பெயர்</span>
                                <h4>{personalInfo.female_name}</h4>
                            </div>
                            <div className="item">
                                <span>நட்சத்திரம்/பாதம்</span>
                                <h4>{personalInfo.female_natchathiram} - {personalInfo.female_patham}</h4>
                            </div>
                            <div className="item">
                                <span>கனம்</span>
                                <h4>{femalejathagaInfo.femlale_ganam_tamil}</h4>
                            </div>
                            <div className="item">
                                <span>பிறந்த தேதி</span>
                                <h4>{personalInfo.female_dob}</h4>
                            </div>
                            <div className="item">
                                <span>ராசி</span>
                                <h4>{personalInfo.female_rasi}</h4>
                            </div>
                            <div className="item">
                                <span>ரஜ்ஜு</span>
                                <h4>{femalejathagaInfo.female_rajju_type_tamil}</h4>
                            </div>
                            <div className="item">
                                <span>பிறந்த நேரம்</span>
                                <h4>{personalInfo.female_birth_time}</h4>
                            </div>
                            <div className="item">
                                <span>லக்னம்</span>
                                <h4>{personalInfo.female_laknam}</h4>
                            </div>
                            <div className="item">
                                {/* <span />
                                <h4 /> */}
                            </div>
                            <div className="item">
                                <span>பிறந்த இடம்</span>
                                <h4>{personalInfo.female_birthplace.split(',')[0]}</h4>
                            </div>
                            <div className="item">
                                <span>மிருகம்</span>
                                <h4>{femalejathagaInfo.female_mirugam}</h4>
                            </div>
                            <div className="item">
                                {/* <span />
                                <h4 /> */}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}
