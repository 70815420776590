import React from 'react'
import { useNavigate } from 'react-router-dom';

export default function HorsocopeBookPaymentSuccessContent() {
    const Navigate = useNavigate();
    const Redirect = () => {
        Navigate('/Tamil-jathagam-book')
    }

    return (
        <>
            <section className="result-info bc-outer bcr Bh-result">
                <div className="astro-container privacy-policy-content">
                    <div className='row'>
                        <div className="col-lg-6 col-md-8 m-auto my-5">
                            <div className="card px-2 shadow success-payment-card">
                                <div className='text-center'>
                                    <img src='https://ctsvsolutions.com/onepage-images/new-ui/payment-success.webp' alt="marriage matching payment success" className='img-fluid' width={125} height={125} />
                                </div>
                                <div>
                                    <p className='paragraph' style={{ textIndent: '20px' }}>Thank you for using One Page Horoscope service 🙏.</p>
                                    <p className='paragraph' style={{ textIndent: '20px' }}>Your Horoscope Book will be delivered within 7 days to the address you provided.</p>
                                </div>
                                <div className='mb-4 text-center'>
                                    <button className='btn btn-success' onClick={Redirect}>Okay, Thank You</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}