import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import { Loading } from "notiflix";
// import getepayPortal from "../../../../components/tamil/Getepay_pg_react";
import { RazerpayPaymentGateway } from "../../payment-gateway/RazerpayGateway"
;

export default function TamilJathagaViewHoroDet({ value }) {
  const Navigate = useNavigate();
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [mobile_number, setMobileNumber] = useState("");
  const [streetName, setStreetName] = useState("");
  const [Address, setAdress] = useState("");
  const [District, setDistrict] = useState("");
  const [pinCode, setPinCode] = useState("");
  const [NameError, setNameError] = useState("");
  const [EmailError, setEmailError] = useState("");
  const [MobileNumberError, setMobileNumberError] = useState("");
  const [StreetError, setStreetError] = useState("");
  const [AddressEror, setAddressError] = useState("");
  const [DistrictError, setDistrictError] = useState("");
  const [PinCodeError, setPincodeError] = useState("");

  const ApproveValidation = () => {
    let isValid = true;

    if (!name) {
      setNameError("Name is Required");
      isValid = false;
    } else {
      setNameError("");
    }

    // Email
    if (!/^\S+@\S+\.\S+$/.test(email)) {
      setEmailError("Invalid Email Address");
      isValid = false;
    } else {
      setEmailError("");
    }

    // Mobile Number
    if (!/^\d{10}$/.test(mobile_number)) {
      setMobileNumberError("Invalid Mobile Number");
      isValid = false;
    } else {
      setMobileNumberError("");
    }

    // Street
    if (!streetName) {
      setStreetError("Street Name is Required");
      isValid = false;
    } else {
      setStreetError("");
    }

    // Address
    if (!Address) {
      setAddressError("Address is Required");
      isValid = false;
    } else {
      setAddressError("");
    }

    // District
    if (!District) {
      setDistrictError("District is Required");
      isValid = false;
    } else {
      setDistrictError("");
    }

    if (!/^\d{6}$/.test(pinCode)) {
      setPincodeError("Invalid Pin Code");
      isValid = false;
    } else {
      setPincodeError("");
    }

    return isValid;
  };

  const PlaceOrderButton = () => {
    const Order = JSON.parse(localStorage.getItem("Bookformdata"));
    // console.log(Order);
    if (ApproveValidation() === true) {
      const userData = {
        buyerName: Order.username,
        buyerEmail: email,
        buyerMobileNo: mobile_number,
        DateofBirth: value.personal_info.birth_date,
        birthTime: value.personal_info.birth_time,
        gender: value.personal_info.gender,
        birthPlace: value.personal_info.birth_place,
        Street_name: streetName,
        Address: Address,
        District: District,
        pincode: pinCode,
        father_name: Order.FatherName,
        mother_name: Order.MotherName,
        Amount: 410,

      };
      console.log('res', userData);
      const FindUser = JSON.parse(localStorage.getItem("BookuserInfo"));
      const user_data_id = FindUser.user_id;
      const customer_data_id = FindUser.customerDataId;
      if ((user_data_id !== "" && user_data_id !== null) && (customer_data_id !== "" && customer_data_id !== null)) {
        proceedToPayment();
      } else {
        axios.post(
          "https://ctsvsolutions.com/onepage-Api/public/api/Horoscope-Book-Order",
          userData
        ).then((response) => {
          const BookuserInfo = {
            user_id: response.data.data.userId,
            customerDataId: response.data.data.customerDataId
          };
          localStorage.setItem('BookuserInfo', JSON.stringify(BookuserInfo));
          proceedToPayment();
        }).catch((error) => {
          console.error("Error placing order:", error);
        });

      }

    }
  };
  const proceedToPayment = async() => {
    const storedUserInfo = localStorage.getItem("BookuserInfo");
    const userInfo = JSON.parse(storedUserInfo);
    const user_id = userInfo.user_id;
    const customerDataId = userInfo.customerDataId;
    Loading.dots();
    setOpenPopup(false);

    const payment_Data = {
        amount: '410',
        email: email,
        description: 'Horoscope Book',
        name: name,
        mobileNumber: mobile_number,
        user_id: user_id,
        theme: '#E2F2FE',
        customerDataId: customerDataId,
        paymentCallBackurl: 'https://ctsvsolutions.com/onepage-Api/public/api/update-razerpay-Horoscope-Book-payment-paid-status'
    };
     await RazerpayPaymentGateway(payment_Data,RedirectTosuccessPage,false);
    Loading.remove();
    
    // const data = {
    //   mid: "993377",
    //   amount: "410.00",
    //   merchantTransactionId: "sd12121",
    //   transactionDate: "Mon Oct 03 13:54:33 IST 2022",
    //   terminalId: "getepay.merchant131068@icici",
    //   udf1: mobile_number,
    //   udf2: email,
    //   udf3: name,
    //   udf4: user_id,
    //   udf5: customerDataId,
    //   udf6: "",
    //   udf7: "",
    //   udf8: "",
    //   udf9: "",
    //   udf10: "",
    //   ru: "https://ctsvsolutions.com/onepage-Api/public/Horoscope-payment",
    //   callbackUrl: "",
    //   currency: "INR",
    //   paymentMode: "ALL",
    //   bankId: "",
    //   txnType: "single",
    //   productType: "IPG",
    //   txnNote: "Horoscope Book-Order",
    //   vpa: "getepay.merchant131068@icici",
    // };

    // const Config = {
    //   mid: "993377",
    //   "Getepay Terminal Id": "getepay.merchant131068@icici",
    //   "Getepay Key": "rjW99m2Vo75SBbpyc+K5nfB/eebzWUmkAtx90U5Ku3I=",
    //   "Getepay IV": "Y7fOrFLKVPosfDFSIFSISQ==",
    //   "Getepay Url":
    //     "https://portal.getepay.in:8443/getepayPortal/pg/generateInvoice",
    // };
    // // console.log(data);
    // getepayPortal(data, Config);
  };
  const RedirectTosuccessPage=()=>{
    // window.location.href = "/success";
    Navigate('/Horosocope-Book-ThankYou');
  }

  const [openPopup, setOpenPopup] = useState(false);

  return (
    <>
      <section className="ad-details ad-p70">
        <div className="astro-container">
          <div className="ad-details-row">
            <div className="img">
              <img className="img-res" src='https://ctsvsolutions.com/onepage-images/new-ui/jathaga-book-view.webp' alt="jathagam-book" />
            </div>
            <div className="content">
              <h3 className="sh">
                Almanac <span className="grt">Details</span>
              </h3>
              <div className="row">
                <div className="item">
                  <span>Laknam</span>
                  <h4>{value.personal_info.laknam}</h4>
                </div>
                <div className="item">
                  <span>Tamil date</span>
                  <h4>{value.personal_info.birth_day}</h4>
                </div>
                <div className="item">
                  <span>Rasi</span>
                  <h4>{value.personal_info.rasi}</h4>
                </div>
                <div className="item">
                  <span>Star</span>
                  <h4>{value.personal_info.natchathiram}</h4>
                </div>
                <div className="item">
                  <span>Thithi</span>
                  <h4>{value.personal_info.thithi}</h4>
                </div>
                <div className="item">
                  <span>Yogam</span>
                  <h4>{value.personal_info.yogam}</h4>
                </div>
                <div className="item">
                  <span>Karanam</span>
                  <h4>{value.personal_info.karanam}</h4>
                </div>
                <div className="item">
                  <span>Yoga star</span>
                  <h4>{value.personal_info.yoga_natchathiram}</h4>
                </div>
                <div className="item">
                  <span>Yogi</span>
                  <h4>{value.personal_info.yogi}</h4>
                </div>
                <div className="item">
                  <span>Avayogi Natchathiram</span>
                  <h4>{value.personal_info.avayogi_natchathiram}</h4>
                </div>
                <div className="item">
                  <span>Avayogi</span>
                  <h4>{value.personal_info.avayogi}</h4>
                </div>
                <div className="item">
                  <span>Ayanamsa</span>
                  <h4>லஹரி</h4>
                </div>
              </div>
              <button className="btn-lg btn-pnk border-0"
                type="button"
                onClick={() => setOpenPopup(true)}
              >
                Order Now
                <img src='https://ctsvsolutions.com/onepage-images/new-ui/btn-arrow-white.webp' alt="arrow" />
              </button>
            </div>
          </div>
        </div>
      </section>
      {openPopup && (
        <div className="global-popup order">
          <div className="content center-unset">
            <div className="popup-close" onClick={() => setOpenPopup(false)}>
              <img src='https://ctsvsolutions.com/onepage-images/new-ui/popup-cross-icon.webp' alt="Close popup" />
            </div>
            <div className="mb-3" style={{ height: "300px" }}>
              <iframe width="100%" height="300" style={{borderRadius:'20px'}} src="https://www.youtube.com/embed/wRJCL4OtGZU?si=cym5LBW_KHqrf7Jn" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>

            </div>
            <div className="book-row">
              <h3 className="sh">
                Address <span className="grt">Confirmation</span>
              </h3>
              <div className="price">
                <span>Price</span>
                <h4>₹ 410.00</h4>
              </div>
            </div>
            <form className="cmn-form">
              <div className="form-group">
                <label>Full Name</label>
                <input type="text" placeholder="Enter Your Name" className="input" value={name}
                  onChange={(e) => {
                    setName(e.target.value);
                  }}
                ></input>
                {NameError && (
                  <span className="text-danger">{NameError}</span>
                )}
              </div>
              <div className="form-group">
                <label>Email Address</label>
                <input type="email" placeholder="Enter Your E-Mail" className="input" value={email}
                  onChange={(e) => {
                    setEmail(e.target.value);
                  }}
                ></input>
                {EmailError && (
                  <span className="text-danger">{EmailError}</span>
                )}
              </div>
              <div className="form-group">
                <label>Mobile Number</label>
                <input type="tel" placeholder="Enter Your Mobile Number" className="input" value={mobile_number}
                  onChange={(e) => {
                    setMobileNumber(e.target.value);
                  }}
                ></input>
                {MobileNumberError && (
                  <span className="text-danger">
                    {MobileNumberError}</span>
                )}
              </div>
              <div className="form-group">
                <label>Street Name</label>
                <input type="tel" placeholder="Enter Your Street Name" className="input" value={streetName}
                  onChange={(e) => {
                    setStreetName(e.target.value);
                  }}
                ></input>
                {StreetError && (
                  <span className="text-danger">
                    {StreetError}</span>
                )}
              </div>

              <div className="form-group mb-0">
                <label>Address</label>
                <div className="row">
                  <div className="form-group">
                    <span>House No./Street</span>
                    <textarea type="text" placeholder="Enter Your Address" className="input" value={Address}
                      onChange={(e) => {
                        setAdress(e.target.value);
                      }}
                    ></textarea>
                    {AddressEror && (
                      <span className="text-danger">{AddressEror}</span>
                    )}
                  </div>
                </div>
                <div className="row">
                  <div className="form-group mb-0">
                    <span>District</span>
                    <input type="text" placeholder="Enter Your District" className="input" value={District}
                      onChange={(e) => {
                        setDistrict(e.target.value);
                      }}
                    ></input>
                    {DistrictError && (
                      <span className="text-danger">
                        {DistrictError}
                      </span>
                    )}
                  </div>
                  <div className="form-group mb-0">
                    <span>Pin Code</span>
                    <input type="text" placeholder="Enter Your Pin Code" className="input" value={pinCode}
                      onChange={(e) => {
                        setPinCode(e.target.value);
                      }}
                    ></input>
                    {PinCodeError && (
                      <span className="text-danger">
                        {PinCodeError}
                      </span>
                    )}
                  </div>
                </div>
              </div>
              <div className="row order-row">
                <div className="note">
                  <p>
                    <strong>Note:</strong> You will receive the book within 7 working days.
                  </p>
                </div>
                <div className="form-group-btn">
                  <button type="button" className="form-btn form-btn-pnk" onClick={PlaceOrderButton}>
                    Place Order
                    <img src='https://ctsvsolutions.com/onepage-images/new-ui/btn-arrow-white.webp' alt="Arrow" />
                  </button>
                </div>
              </div>
            </form>
          </div>
          <div className="overlay" />
        </div>
      )}
    </>
  )
}
