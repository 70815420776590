import React from 'react'
// import SuccessImage from '../../images/tick1.webp'
import { useNavigate } from 'react-router-dom';

export default function BabyNamePaymentSuccessContent() {

      const Navigate = useNavigate();
    const Redirect = () => {
        Navigate('/Baby-Name-List')
    }
      
    return (
        <>
            <section className="result-info bc-outer bcr Bh-result">
                <div className="astro-container privacy-policy-content">
                    <div className='row'>
                        <div className="col-lg-6 col-md-8 m-auto my-5">
                            <div className="card px-2 shadow success-payment-card">
                                <div className='text-center'>
                                    <img src='https://ctsvsolutions.com/onepage-images/new-ui/payment-success.webp' alt="baby name payment success" className='img-fluid' width={125} height={125} />
                                </div>
                                <div>
                                    <p className='paragraph' style={{ textIndent: '20px' }}>One Page Horoscope சேவையை உபயோகப்படுத்தியதற்கு நன்றி 🙏.
                                        உங்கள் செல்ல குழந்தையின் எதிர்காலம் சிறப்பாக அமைய Onepage
                                        Horoscope சார்பாக வாழ்த்துக்கள் .</p>
                                    <p className='paragraph' style={{ textIndent: '20px' }}>உங்களது குழந்தையின் பெயர் பட்டியல் நீங்கள் கொடுக்கப்பட்ட
                                        WhatsApp மற்றும் Email க்கு வெற்றிகரமாக அனுப்பப்பட்டது.
                                    </p>
                                </div>
                                <div className='mb-4 text-center'>
                                    <button className='btn btn-success' onClick={Redirect}>Okay, Thank You</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}
