import React, { useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import { BioViewContext } from '../../../components/context/viewpage/BioViewContextProvider';
export default function MarriageBioDataViewProfile() {
    const  { personalInfo }= useContext(BioViewContext);
    const Navigate=useNavigate();

    const EditBtn=()=>{
        Navigate('/Marriage-Bio-Data');
    }
    return (
        <>
            <section className="result-info mbr">
                <div className="astro-container">
                    <div className="result">
                        <div className="profile-dp">
                            <img src={`https://ctsvsolutions.com/onepage-Api/public/assets/images/prof_img/${personalInfo.image}`} alt='bio data profile'/>
                        </div>
                        <div className="content">
                            <div className="bhres-row-1">
                                <div className="name">
                                    <h2 className="sh">{personalInfo.name}</h2>
                                </div>
                                <div className="edit">
                                    <button type='button' onClick={EditBtn} className="btn-lg btn-prpl border-0">
                                        Edit
                                        <img src='https://ctsvsolutions.com/onepage-images/new-ui/btn-edit-icon.webp' alt='bio data edit'/>
                                    </button>
                                </div>
                            </div>
                            <div className="bhres-row-2">
                                <div className="rd">
                                    <div className="icon">
                                        <img src='https://ctsvsolutions.com/onepage-images/new-ui/date-icon.webp' alt='bio data date of birth'/>
                                    </div>
                                    <div className="detail">
                                        <span>date of birth</span>
                                        <h4>{personalInfo.birth_date}</h4>
                                    </div>
                                </div>
                                <div className="rd">
                                    <div className="icon">
                                        <img src='https://ctsvsolutions.com/onepage-images/new-ui/gender-icon.webp' alt='bio data gender'/>
                                    </div>
                                    <div className="detail">
                                        <span>Gender</span>
                                        <h4>{personalInfo.gender}</h4>
                                    </div>
                                </div>
                                <div className="rd">
                                    <div className="icon">
                                        <img src='https://ctsvsolutions.com/onepage-images/new-ui/father-icon.webp' alt='bio data father name'/>
                                    </div>
                                    <div className="detail">
                                        <span>father name</span>
                                        <h4> {personalInfo.father_name}</h4>
                                    </div>
                                </div>
                                <div className="rd">
                                    <div className="icon">
                                        <img src='https://ctsvsolutions.com/onepage-images/new-ui/birth-time-icon.webp' alt='bio data birth time'/>
                                    </div>
                                    <div className="detail">
                                        <span>birth time</span>
                                        <h4>{personalInfo.birth_time}</h4>
                                    </div>
                                </div>
                                <div className="rd">
                                    <div className="icon">
                                        <img src='https://ctsvsolutions.com/onepage-images/new-ui/location-icon.webp' alt='bio data birth place'/>
                                    </div>
                                    <div className="detail">
                                        <span>birth place</span>
                                        <h4>{personalInfo.birth_place.split(',')[0]}</h4>
                                    </div>
                                </div>
                                <div className="rd">
                                    <div className="icon">
                                        <img src='https://ctsvsolutions.com/onepage-images/new-ui/mother-icon.webp' alt='bio data mother name'/>
                                    </div>
                                    <div className="detail">
                                        <span>mother name</span>
                                        <h4>{personalInfo.mother_name}</h4>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <button type='button' className="btn-lg btn-prpl edit-btn-mobile border-0" onClick={EditBtn}>
                            Edit
                            <img src='https://ctsvsolutions.com/onepage-images/new-ui/btn-edit-icon.webp' alt='marriage bio data edit'/>
                        </button>
                    </div>
                </div>
            </section>
        </>
    )
}
