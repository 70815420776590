import React from 'react'
import TopNavbar from '../../Main-Components/TopNavbar/TopNavbar'
import Menubar from '../../Main-Components/Menubar/Menubar'
import Footer from '../../Main-Components/Footer/Footer'
import RuthuJathagamBanner from '../../Small-Components/RuthuJathagamBanner/RuthuJathagamBanner'
import RuthuJathagamForm from '../../Forms/RuthuJathagamForm/RuthuJathagamForm'
import { Helmet } from 'react-helmet'
import { useLocation } from 'react-router-dom'

export default function RuthuJathagam() {

  const location = useLocation();

  const canonical = () => {
    const base_url = "https://onepagehoroscope.com";
    const current_path = location.pathname;

    const canonicalPaths = {
      "/Ruthu-Jathagam": "/Ruthu-Jathagam",
    };

    return (
      <link
        rel="canonical"
        href={base_url + (canonicalPaths[current_path] || current_path)}
      />
    );
  };

  return (
    <>
      <Helmet>
        <title>ருது ஜாதகம் | Explore Ruthu Jathagam Tamil  Horoscope</title>

        <meta
          name="description"
          content="Discover your destiny with Ruthu Jathagam in Tamil! Get personalized horoscope readings and insightful predictions on our website today.Know more."
        />
        <meta
          name="keywords"
          content="ruthu jathagam, ருது ஜாதகம், ruthu jathagam for marriage matching, ruthu jathagam horoscope in tamil, ruthu jathagam in tamil, ruthu jathagam online, ruthu jathagam in tamil free"
        />
        <meta name="robots" content="index,follow" />
        {canonical()}
        <script type="application/ld+json">
          {`
            "@context": "https://schema.org/", 
            "@type": "Product", 
            "name": "Ruthu jathagam",
            "image": "",
            "description": "Discover your destiny with Ruthu Jathagam in Tamil! Get personalized horoscope readings and insightful predictions on our website today.Know more.",
            "brand": {
              "@type": "Brand",
              "name": "One Page Horoscope"        
          `}
        </script>
      </Helmet>
      <TopNavbar />
      <Menubar NavBgstyle="outer-header rjr" NavTextColor="text-dark" SubmenuTop="true" />
      <RuthuJathagamBanner />
      <RuthuJathagamForm />
      <Footer />
    </>
  )
}
