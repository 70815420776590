import React, { useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import { Link } from "react-router-dom";
import {
  faMobile,
  faUser,
} from "@fortawesome/free-solid-svg-icons";
import { faMicrophoneLines } from "@fortawesome/free-solid-svg-icons";


export default function Menubar() {
  return (
    <>
      <Navbar1 />
      <Navbar2 />
    </>
  );
}

function Navbar1() {
  return (
    <div className="fixed-top bg-tertiary">
      <div className="container">
        <div className="row mt-3">
          <div className="col-8">
            <div className="suppor_mail">
              <p className="fw-bold" style={{ color: "#b2012f" }}>
                support@onepagehoroscope.com
                <br />
                <FontAwesomeIcon
                  icon={faMobile}
                  style={{ color: "#b2012f" }}
                />{" "}
                <span> Customer support: 9787420717</span>
              </p>
            </div>
          </div>
          <div className="col-4 suppor_mail">
             <Link className="float-end text-white button-7 p-2" to="/Jathaga-Palan">
              <FontAwesomeIcon icon={faMicrophoneLines} className="text-white  fs-6" />
              Audio prediction
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
}

function Navbar2() {
  const [isNavOpen, setIsNavOpen] = useState(false);

  const toggleNav = () => {
    setIsNavOpen(!isNavOpen);
  };

  const getImageSrc = (imagePath) => {
    // Determine the appropriate image size based on the device's screen width
    const screenWidth = window.innerWidth;
    let imageSize;

    if (screenWidth >= 1024) {
      imageSize = 'desktop';
    } else if (screenWidth >= 768) {
      imageSize = 'tablet';
    } else {
      imageSize = 'mobile';
    }

    return `${imagePath}?size=${imageSize}`;
  };

  return (
    <div
      className={`navigation bg-tertiary nav-fixed-top ${isNavOpen ? "show" : ""}`}
      style={{ top: "58px" }}
    >
      <div className="navbar navbar-expand-xl navbar-light text-center">
        <div className="container">
          <Link to="/" className="navbar-brand">
            <img
              src={getImageSrc("https://ctsvsolutions.com/onepage-images/one_page_logo.webp")}
              alt="logo"
              className="img-fluid onepage-logo"
              width="200"
              height="200"
              loading="lazy"
            />
          </Link>
          <button
            className="navbar-toggler"
            type="button"
            onClick={toggleNav}
            aria-label="Toggle navigation"
          >
            <span className="navbar-toggler-icon"></span>
          </button>

          <div
            className={`collapse navbar-collapse ${isNavOpen ? "show" : ""}`}
            id="navbarSupportedContent"
          >
            <ul className="navbar-nav ms-auto mb-2 mb-lg-0">
              <li className="nav-item">
                <Link to="/" className="nav-link">
                  Home
                </Link>
              </li>
              <li className="nav-item">
                <Link to="/Birth-horoscope" className="nav-link">
                  Birth Horoscope
                </Link>
              </li>
              <li className="nav-item">
                <Link to="/Marriage-matching" className="nav-link">
                  Marriage Matching
                </Link>
              </li>
              <li className="nav-item">
                <Link to="/Marriage-Bio-Data" className="nav-link">
                  Marriage Bio-Data
                </Link>
              </li>
              <li className="nav-item">
                <Link to="/Baby-Name-List" className="nav-link">
                  Baby Name
                </Link>
              </li>
              <li className="nav-item">
                <Link to="/Tamil-jathagam-book" className="nav-link">
                  Horoscope Book
                </Link>
              </li>
              <li className="nav-item">
                <Link to="/Ruthu-Jathagam" className="nav-link">
                  Ruthu Jathagam
                </Link>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  );
}

