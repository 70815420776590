import React from 'react';
import TopNavbar from '../../Main-Components/TopNavbar/TopNavbar';
import Menubar from '../../Main-Components/Menubar/Menubar';
import Footer from '../../Main-Components/Footer/Footer';
import MarriageMatchingViewBanner from '../../Small-Components/MarriageMatchingViewBanner/MarriageMatchingViewBanner';
import MarriageMatchingViewData from '../../Small-Components/MarriageMatchingViewData/MarriageMatchingViewData';
import MarriageMatchingViewContent from '../../Small-Components/MarriageMatchingViewContent/MarriageMatchingViewContent';
import MarriageMatchingViewContextProvider from '../../context/viewpage/MarriageMatchingViewContextprovider';
import MarriageMathingViewChart from '../../Small-Components/MarriageMatchingViewChart/MarriageMathingViewChart';

export default function MarriageMatchingView() {
    return (
    <>
        <MarriageMatchingViewContextProvider>
            <TopNavbar />
            <Menubar NavBgstyle="outer-header mm-result" NavTextColor="text-dark" SubmenuTop="true" />
            <MarriageMatchingViewBanner />
            <MarriageMatchingViewData />
            <MarriageMatchingViewContent />
            <MarriageMathingViewChart />
            <Footer />
        </MarriageMatchingViewContextProvider>
        </>
    );
}
