import React, { useContext, useEffect, useState } from 'react'
// import MaleImage from '../../images/groom.webp'
// import MaleMobileImage from '../../images/groom-mobile.webp'
// import FemaleImage from '../../images/bride.webp'
// import FemaleMobileImage from '../../images/bride-mobile.webp'
// import FormHeadImage from '../../images/mm-illus-2.webp'
import { useNavigate } from 'react-router-dom';
import { Loading } from 'notiflix/build/notiflix-loading-aio';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faLocationDot } from "@fortawesome/free-solid-svg-icons";
import axios from 'axios';
import { MarriageMatchingContext } from "../../context/forms/MarriageMatching_contexProvider";

export default function MarriageMatchingForm({ BlogStatus = false }) {
    const {
        dayOptions,
        monthOptions,
        yearOptions,
        hourOptions,
        minuteOptions,
        maleName, setMalename,
        maleHour, setMaleHour,
        male_minute, setMaleMinute,
        maleAmpm, setMaleAmpm,
        maleBirthday, setMaleBithDay,
        maleBirthMonth, setMaleBirthMonth,
        maleBirthYear, setMaleBirthYear,
        maleBirthplace, setMaleBirthPlace,
        femaleName, setFemaleName,
        femaleHour, setFemaleHour,
        femaleMinute, setfemaleMinute,
        femaleAmpm, setFemaleAmpm,
        femaleBirthDay, setFemaleBirthDay,
        femaleBirthMonth, setFemaleBirthMonth,
        femaleBirthYear, setFemaleBirthYear,
        femaleBirthplace, setFemaleBirthPlace,
        malesuggestedPlaces, setMaleSuggestedPlaces,
        femalesuggestedPlaces, setfeMaleSuggestedPlaces,
        maleselectedPlaces, setmaleSelectedPlaces,
        femaleselectedPlaces, setfemaleSelectedPlaces,
        maleNameError, setMaleNameError,
        femaleNameError, setFemaleNameError,
        maleDayError, setMaleDayError,
        feMaleDayError, setFemaleDayError,
        maleMonthError, setMaleMonthError,
        femaleMonthError, setFemaleMonthError,
        maleYearError, setMaleYearError,
        femaleYearError, setFemaleYearError,
        maleHourError, setMaleHourError,
        femaleHourError, setFemaleHourError,
        maleMinuteError, setMaleMinuteError,
        feMaleMinuteError, setFemaleMinuteError,
        maleAmPmError, setAmpmMinuteError,
        femaleAmPmError, setfeAmpmMinuteError,
        malebirthplaceError, setMaleBirthplaceError,
        femalebirthplaceError, setfeMaleBirthplaceError,
        marriageMatchingFromData, setmarriageMatchingFromData,
        MaleTimeZone, setMaleTimeZone,
        FemaleTimeZone, setFemaleTimeZone,
        FemaleLocationSwitch, setFemaleLocationSwitch,
        MaleLocationSwitch, setMaleLocationSwitch } = useContext(MarriageMatchingContext);


    useEffect(() => {
        const storedValue = localStorage.getItem("mgformdata");
        if (storedValue) {
            const parsedValue = JSON.parse(storedValue);
            const MaleDOB = parsedValue.MaleDateofBirth;
            const [maleyear, malemonth, maleday] = MaleDOB.split("-");
            const male_name = parsedValue.maleName;
            const male_laitude = parsedValue.Malelatitude;
            const male_longitide = parsedValue.Malelongitude;
            const male_birthplace = parsedValue.MaleBirthplace;
            const Male_Birth_time = parsedValue.MaleBirthtime;
            const male_timeComponents = Male_Birth_time.split(" ");
            const maletime = male_timeComponents[0];
            const male_ampm = male_timeComponents[1];
            const [malehour, maleminute] = maletime.split(":");

            const feMaleDOB = parsedValue.feMaleDateofBirth;
            const [femaleyear, femalemonth, femaleday] = feMaleDOB.split("-");
            const female_name = parsedValue.FemaleName;
            const female_laitude = parsedValue.femalelatitude;
            const female_longitide = parsedValue.femalelongitude;
            const female_birthplace = parsedValue.femaleBirthplace;
            const feMale_Birth_time = parsedValue.FemaleBirthTime;
            const female_timeComponents = feMale_Birth_time.split(" ");
            const femaletime = female_timeComponents[0];
            const female_ampm = female_timeComponents[1];
            const [femalehour, femaleminute] = femaletime.split(":");
            setFemaleTimeZone(parsedValue.MaleTimeZone);
            setMaleTimeZone(parsedValue.FemaleTimeZone)
            setMalename(male_name);
            setMaleHour(malehour);
            setMaleMinute(maleminute);
            setMaleAmpm(male_ampm);
            setMaleBithDay(maleday);
            setMaleBirthMonth(malemonth);
            setMaleBirthYear(maleyear);
            setMaleBirthPlace(male_birthplace);
            setFemaleName(female_name);
            setFemaleHour(femalehour);
            setfemaleMinute(femaleminute);
            setFemaleAmpm(female_ampm);
            setFemaleBirthDay(femaleday);
            setFemaleBirthMonth(femalemonth);
            setFemaleBirthYear(femaleyear);
            setFemaleBirthPlace(female_birthplace);
            document.getElementById("atflg").value = parsedValue.Malelongitude;
            document.getElementById("atflt").value = parsedValue.Malelatitude;

            document.getElementById("female_longitude").value =
                parsedValue.femalelongitude;
            document.getElementById("female_latitude").value =
                parsedValue.femalelatitude;
        }
    }, []);


    const handleMaleLocationChange = (event) => {
        setMaleLocationSwitch(true);
        const currentValue = event.target.value;
        setMaleBirthPlace(currentValue);
        fetchMaleSuggestions(currentValue, 'geoname_cities');
    };

    const handlefeMaleLocationChange = (event) => {
        setFemaleLocationSwitch(true);
        const currentValue = event.target.value;
        setFemaleBirthPlace(currentValue);
        fetchfeMaleSuggestions(currentValue, "geoname_cities");
    };

    const fetchfeMaleSuggestions = (currentValue, LocationType) => {
        if (currentValue.length >= 1) {
            const Data = {
                location: currentValue,
                Type: LocationType,
            };
            axios
                .post(
                    "https://ctsvsolutions.com/onepage-Api/public/api/Location-Api",
                    Data
                )
                .then((response) => {
                    if (response.data.status == true) {
                        // console.log(response.data.location);
                        setfeMaleSuggestedPlaces(response.data.location);
                    } else {
                        setfeMaleSuggestedPlaces("");
                    }
                });
        } else {
            setfeMaleSuggestedPlaces("");
        }

    };
    const SearchNewFemaleLocation = () => {
        const currentLocation = femaleBirthplace.includes(",")
            ? femaleBirthplace.split(",")[0]
            : femaleBirthplace;
        console.log(currentLocation);
        fetchfeMaleSuggestions(currentLocation, 'Map_Box');
    };

    const SearchNewMaleLocation = () => {
        const currentLocation = maleBirthplace.includes(",")
            ? maleBirthplace.split(",")[0]
            : maleBirthplace;

        fetchMaleSuggestions(currentLocation, 'Map_Box');
    };

    const handlefeMaleSuggestionSelect = (selectedPlace) => {
        setFemaleLocationSwitch(false);
        const femalelatitude = selectedPlace.latitude;
        const femalelongitude = selectedPlace.longitude;
        const femaleparts = selectedPlace.districts.split(",");
        const FemaledisplayName = femaleparts.slice(0, 3).join(",");
        const Female_location = FemaledisplayName;
        const female_location = Female_location;
        const updatedSelectedPlaces = [...malesuggestedPlaces, female_location];
        setfemaleSelectedPlaces(updatedSelectedPlaces);
        setFemaleBirthPlace(female_location);
        setfeMaleSuggestedPlaces('');
        setFemaleTimeZone(selectedPlace.TimeZone);
        document.getElementById("female_longitude").value = femalelongitude;
        document.getElementById("female_latitude").value = femalelatitude;
    };

    const handleMaleSuggestionSelect = (selectedPlace) => {
        setMaleLocationSwitch(false);
        const latitude = selectedPlace.latitude;
        const longitude = selectedPlace.longitude;

        const maleParts = selectedPlace.districts.split(",");
        const maleplayName = maleParts.slice(0, 3).join(",");
        const male_location = maleplayName;

        // const fmale_location = male_location;

        const location = male_location;

        const updatedSelectedPlaces = [...maleselectedPlaces, location];

        setmaleSelectedPlaces(updatedSelectedPlaces);
        setMaleBirthPlace(location);
        setMaleBirthplaceError("");
        setMaleTimeZone(selectedPlace.TimeZone);
        setMaleSuggestedPlaces([]);
        document.getElementById("atflg").value = longitude;
        document.getElementById("atflt").value = latitude;
    };

    const fetchMaleSuggestions = (currentValue, LocationType) => {
        if (currentValue.length >= 1) {
            const Data = {
                location: currentValue,
                Type: LocationType,
            };
            console.log(Data);
            axios
                .post(
                    "https://ctsvsolutions.com/onepage-Api/public/api/Location-Api",
                    Data
                )
                .then((response) => {
                    if (response.data.status == true) {
                        // console.log(response.data.location);
                        setMaleSuggestedPlaces(response.data.location);
                    } else {
                        setMaleSuggestedPlaces("");
                    }
                });
        } else {
            setMaleSuggestedPlaces("");
        }
    };

    const validateForm = () => {
        let isValid = true;

        // Male fields
        if (!maleName) {
            setMaleNameError("required");
            isValid = false;
        } else {
            setMaleNameError("");
        }

        if (!maleBirthday) {
            setMaleDayError("required");
            isValid = false;
        } else {
            setMaleDayError("");
        }

        if (!maleBirthMonth) {
            setMaleMonthError("required");
            isValid = false;
        } else {
            setMaleMonthError("");
        }

        if (!maleBirthYear) {
            setMaleYearError("required");
            isValid = false;
        } else {
            setMaleYearError("");
        }

        if (!maleHour) {
            setMaleHourError("required");
            isValid = false;
        } else {
            setMaleHourError("");
        }

        if (!male_minute) {
            setMaleMinuteError("required");
            isValid = false;
        } else {
            setMaleMinuteError("");
        }

        if (!maleAmpm) {
            setAmpmMinuteError("required");
            isValid = false;
        } else {
            setAmpmMinuteError("");
        }

        if (!maleBirthplace) {
            setMaleBirthplaceError("required");
            isValid = false;
        } else {
            setMaleBirthplaceError("");
        }

        // Female fields
        if (!femaleName) {
            setFemaleNameError("required");
            isValid = false;
        } else {
            setFemaleNameError("");
        }

        if (!femaleBirthDay) {
            setFemaleDayError("required");
            isValid = false;
        } else {
            setFemaleDayError("");
        }

        if (!femaleBirthMonth) {
            setFemaleMonthError("required");
            isValid = false;
        } else {
            setFemaleMonthError("");
        }

        if (!femaleBirthYear) {
            setFemaleYearError("required");
            isValid = false;
        } else {
            setFemaleYearError("");
        }

        if (!femaleHour) {
            setFemaleHourError("required");
            isValid = false;
        } else {
            setFemaleHourError("");
        }

        if (!femaleMinute) {
            setFemaleMinuteError("required");
            isValid = false;
        } else {
            setFemaleMinuteError("");
        }

        if (!femaleAmpm) {
            setfeAmpmMinuteError("required");
            isValid = false;
        } else {
            setfeAmpmMinuteError("");
        }

        if (!femaleBirthplace) {
            setfeMaleBirthplaceError("required");
            isValid = false;
        } else {
            setfeMaleBirthplaceError("");
        }

        // Additional checks for latitude
        let MaleLatitideError = document.getElementById('atflt').value;
        if (!MaleLatitideError) {
            setMaleBirthplaceError('Please select the Birthplace again');
            isValid = false;
        } else {
            setMaleBirthplaceError('');
        }

        let FemaleLatitudeError = document.getElementById('female_latitude').value;
        if (!FemaleLatitudeError) {
            setfeMaleBirthplaceError('Please select the Birthplace again');
            isValid = false;
        } else {
            setfeMaleBirthplaceError('');
        }

        return isValid;
    };

    const navigate = useNavigate();

    const customRedirect = (route, data) => {
        navigate(route, { state: data });
    };

    const marriageMatchingSubmit = () => {
        if (validateForm()) {
            const marriageMatchingFormData = {
                maleName: maleName,
                MaleDateofBirth: `${maleBirthYear}-${maleBirthMonth}-${maleBirthday}`,
                MaleBirthtime: `${maleHour}:${male_minute} ${maleAmpm}`,
                MaleBirthplace: maleBirthplace,
                Malelatitude: document.getElementById("atflt").value,
                Malelongitude: document.getElementById("atflg").value,
                FemaleName: femaleName,
                femaleBirthplace: femaleBirthplace,
                feMaleDateofBirth: `${femaleBirthYear}-${femaleBirthMonth}-${femaleBirthDay}`,
                FemaleBirthTime: `${femaleHour}:${femaleMinute} ${femaleAmpm}`,
                femalelatitude: document.getElementById("female_latitude").value,
                femalelongitude: document.getElementById("female_longitude").value,
                MaleTimeZone: MaleTimeZone,
                FemaleTimeZone: FemaleTimeZone
            };
            Loading.dots('Marriage matching loading......');

            // console.log(marriageMatchingFromData);
            axios.post("https://ctsvsolutions.com/onepage-Api/public/api/Marriage-Matching-Random", marriageMatchingFormData)
                .then((response1) => {
                    if (response1.status === 200) {
                        axios.post("https://ctsvsolutions.com/onepage-Api/public/api/Marriage-Matching", marriageMatchingFormData)
                            .then((response2) => {
                                if (response2.status === 200) {
                                    Loading.remove();
                                    // console.log("Response 2:", response2);
                                    const value = JSON.stringify(response2.data.data);
                                    localStorage.setItem('mgValues', value);
                                    const formdatavalue = JSON.stringify(marriageMatchingFormData);
                                    localStorage.setItem('mgformdata', formdatavalue);
                                    const MgUserInfo = {
                                        'user_id': "",
                                        'customerDataId': ""
                                    }
                                    const mg_user_info = JSON.stringify(MgUserInfo);
                                    localStorage.setItem('MgUserInfo', mg_user_info);
                                    customRedirect('/Marriage-Matching-view', response2.data);
                                } else {
                                    console.error("Error in Response 2:", response2);
                                }
                            })
                            .catch((error) => {
                                console.error("Error in Axios POST for Marriage-Matching:", error);
                            });
                    } else {
                        console.error("Error in Response 1:", response1);
                    }
                })
                .catch((error) => {
                    console.error("Error in Axios POST for Marriage-Matching-Random:", error);
                });

        }
    };
    useEffect(() => {
        setmarriageMatchingFromData({
            maleName: maleName,
            MaleDateofBirth: `${maleBirthYear}-${maleBirthMonth}-${maleBirthday}`,
            MaleBirthtime: `${maleHour}:${male_minute} ${maleAmpm}`,
            MaleBirthplace: maleBirthplace,
            Malelatitude: document.getElementById("atflt").value,
            Malelongitude: document.getElementById("atflg").value,
            FemaleName: femaleName,
            femaleBirthplace: femaleBirthplace,
            feMaleDateofBirth: `${femaleBirthYear}-${femaleBirthMonth}-${femaleBirthDay}`,
            FemaleBirthTine: `${femaleHour}:${femaleMinute} ${femaleAmpm}`,
            femalelatitude: document.getElementById("female_latitude").value,
            femalelongitude: document.getElementById("female_longitude").value,
        });
    }, [
        maleName,
        maleBirthYear,
        maleBirthMonth,
        maleBirthday,
        maleHour,
        male_minute,
        maleAmpm,
        maleBirthplace,
        femaleName,
        femaleBirthplace,
        femaleBirthYear,
        femaleBirthMonth,
        femaleBirthDay,
        femaleHour,
        femaleMinute,
        femaleAmpm,
    ]);

    return (
        <>
            <section className={`lr-form-s ${BlogStatus?'py-0 ':''}`} >
                <div className="astro-container">

                    <div className="heading">
                        {!BlogStatus && (
                            <>
                                <div className="mm-illus-2">
                                    <img src='https://ctsvsolutions.com/onepage-images/new-ui/mm-illus-2.webp' alt='jadhaga porutham online' />
                                </div>
                                <h3>
                                    This is what we generate in our detailed Marriage Horoscope Matching
                                    evaluation report
                                </h3>
                            </>

                        )}

                    </div>





                    <form className="row">
                        <div className="col">
                            <div className="l-form">
                                {!BlogStatus && (
                                    <img className="fwi-desktop" src='https://ctsvsolutions.com/onepage-images/new-ui/groom.webp' width={'44%'} alt='jathaga palangal' />
                                )}
                                <img className="fwi-mobile" src='https://ctsvsolutions.com/onepage-images/new-ui/groom-mobile.webp' alt='jathaga palangal in tamil' />
                                <div className="form-wrapper cmn-form">
                                    <h4 className="f-heading">Groom</h4>
                                    <p className="f-sub">Enter gromm's details here</p>
                                    <div className="form-group">
                                        <label>Full Name</label>
                                        <input type="text" placeholder='Enter Groom Name' name="" className={`input ${maleNameError && "is-invalid"
                                            }`} value={maleName}
                                            onChange={(e) => {
                                                setMalename(e.target.value);
                                            }} />
                                        {maleNameError && (
                                            <div className="invalid-feedback fw-bold">
                                                {maleNameError}
                                            </div>
                                        )}
                                    </div>
                                    <div className="form-group">
                                        <label>date of birth</label>
                                        <div className="row">
                                            <div className="form-group">
                                                <label>date</label>
                                                <select className={`${maleDayError && "is-invalid"
                                                    }`} value={maleBirthday}
                                                    onChange={(e) => {
                                                        setMaleBithDay(e.target.value);
                                                    }}>
                                                    <option value="">Date</option>
                                                    {dayOptions.map((day) => (
                                                        <option key={day} value={day}>
                                                            {day}
                                                        </option>
                                                    ))}
                                                </select>
                                                {maleDayError && (
                                                    <div className="invalid-feedback fw-bold">
                                                        {maleDayError}
                                                    </div>
                                                )}
                                            </div>
                                            <div className="form-group">
                                                <label>month</label>
                                                <select className={`${maleMonthError && "is-invalid"
                                                    }`} value={maleBirthMonth}
                                                    onChange={(e) => {
                                                        setMaleBirthMonth(e.target.value);
                                                    }}>
                                                    <option value="">Month</option>
                                                    {monthOptions.map((month) => (
                                                        <option key={month} value={month}>
                                                            {month}
                                                        </option>
                                                    ))}
                                                </select>
                                                {maleMonthError && (
                                                    <div className="invalid-feedback fw-bold">
                                                        {maleMonthError}
                                                    </div>
                                                )}
                                            </div>
                                            <div className="form-group">
                                                <label>year</label>
                                                <select className={`${maleYearError && "is-invalid"
                                                    }`} value={maleBirthYear}
                                                    onChange={(e) => setMaleBirthYear(e.target.value)}>
                                                    <option value="">Year</option>
                                                    {yearOptions.map((year) => (
                                                        <option key={year} value={year}>
                                                            {year}
                                                        </option>
                                                    ))}
                                                </select>
                                                {maleYearError && (
                                                    <div className="invalid-feedback fw-bold">
                                                        {maleYearError}
                                                    </div>
                                                )}
                                            </div>
                                        </div>
                                    </div>
                                    <div className="form-group">
                                        <label>birth time</label>
                                        <div className="row">
                                            <div className="form-group">
                                                <label>hour</label>
                                                <select className={`${maleHourError && "is-invalid"
                                                    }`} value={maleHour}
                                                    onChange={(e) => {
                                                        setMaleHour(e.target.value);
                                                    }}>
                                                    <option value="">Hour</option>
                                                    {hourOptions.map((hour) => (
                                                        <option key={hour} value={hour}>
                                                            {hour}
                                                        </option>
                                                    ))}
                                                </select>
                                                {maleHourError && (
                                                    <div className="invalid-feedback fw-bold">
                                                        {maleHourError}
                                                    </div>
                                                )}
                                            </div>
                                            <div className="form-group">
                                                <label>minute</label>
                                                <select className={`${maleMinuteError && "is-invalid"
                                                    }`} value={male_minute}
                                                    onChange={(e) => {
                                                        setMaleMinute(e.target.value);
                                                    }}>
                                                    <option value="">Minute</option>
                                                    {minuteOptions.map((minute) => (
                                                        <option key={minute} value={minute}>
                                                            {minute}
                                                        </option>
                                                    ))}
                                                </select>
                                                {maleMinuteError && (
                                                    <div className="invalid-feedback fw-bold">
                                                        {maleMinuteError}
                                                    </div>
                                                )}
                                            </div>
                                            <div className="form-group">
                                                <label>am/pm</label>
                                                <select className={`${maleAmPmError && "is-invalid"
                                                    }`} value={maleAmpm}
                                                    onChange={(e) => {
                                                        setMaleAmpm(e.target.value);
                                                    }}>
                                                    <option value="">AM/PM</option>
                                                    <option value="AM">AM</option>
                                                    <option value="PM">PM</option>
                                                </select>
                                                {maleAmPmError && (
                                                    <div className="invalid-feedback fw-bold">
                                                        {maleAmPmError}
                                                    </div>
                                                )}
                                            </div>
                                        </div>
                                    </div>
                                    <div className="form-group">
                                        <label>birth location</label>
                                        <input type="text" placeholder='Enter Groom Birth Place' name="" className={`input ${malebirthplaceError && "is-invalid"
                                            }`} onChange={handleMaleLocationChange}
                                            value={maleBirthplace} />
                                        {malebirthplaceError && (
                                            <div className="invalid-feedback  fw-bold">
                                                {malebirthplaceError}
                                            </div>
                                        )}

                                        <input
                                            id="atflt"
                                            className="pfipbx"
                                            name="the_lat"
                                            type="hidden"
                                            placeholder="Latitude"
                                        />
                                        <input
                                            id="atflg"
                                            className="pfipbx"
                                            name="the_lng"
                                            type="hidden"
                                            placeholder="Longitude"
                                        />
                                        {malesuggestedPlaces && (
                                            <ul className="ps-0 mb-0">

                                                {malesuggestedPlaces.reverse().map((place, index) => {
                                                    if (place.districts) {
                                                        const listItemStyle = {
                                                            padding: "5px 12px",
                                                            cursor: "pointer",
                                                            marginBottom: "1px",
                                                            backgroundColor: "#fff",
                                                            fontSize: "14px",
                                                            fontWeight: "540",
                                                            borderBottom: "1px dashed #ddd",
                                                        };
                                                        return (
                                                            <React.Fragment key={index}>
                                                                <p
                                                                    style={listItemStyle}
                                                                    className="px-2"
                                                                    onClick={() =>
                                                                        handleMaleSuggestionSelect(place)
                                                                    }
                                                                >
                                                                    <FontAwesomeIcon icon={faLocationDot} />
                                                                    &nbsp;
                                                                    {place.districts}
                                                                </p>
                                                            </React.Fragment>
                                                        );
                                                    }
                                                })}
                                            </ul>
                                        )}
                                        {MaleLocationSwitch && (
                                            <p
                                                className="w-100 text-start btn py-0 rounded-0 text-white fw-bold mb-0 mt-0"
                                                style={{ fontSize: "14px", backgroundColor: "#fe1b57" }}
                                                onClick={SearchNewMaleLocation}
                                            >
                                                {" "}
                                                <FontAwesomeIcon icon={faLocationDot} /> my place is not
                                                in List
                                            </p>
                                        )}
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col">
                            <div className="l-form right">
                                <img className="fwi-mobile" src='https://ctsvsolutions.com/onepage-images/new-ui/bride-mobile.webp' alt='jathaga palangal in tamil online' />
                                <div className="form-wrapper cmn-form">
                                    <h4 className="f-heading">The Bride</h4>
                                    <p className="f-sub">Enter bride's details here</p>
                                    <div className="form-group">
                                        <label>Full Name</label>
                                        <input type="text" placeholder='Enter Bride Name' name="" className={`input ${femaleNameError && "is-invalid"
                                            }`} value={femaleName}
                                            onChange={(e) => {
                                                setFemaleName(e.target.value);
                                            }} />
                                        {femaleNameError && (
                                            <div className="invalid-feedback fw-bold">
                                                {femaleNameError}
                                            </div>
                                        )}
                                    </div>
                                    <div className="form-group">
                                        <label>date of birth</label>
                                        <div className="row">
                                            <div className="form-group">
                                                <label>date</label>
                                                <select className={`${feMaleDayError && "is-invalid"
                                                    }`} value={femaleBirthDay}
                                                    onChange={(e) => {
                                                        setFemaleBirthDay(e.target.value);
                                                    }}>
                                                    <option value="">Date</option>
                                                    {dayOptions.map((day) => (
                                                        <option key={day} value={day}>
                                                            {day}
                                                        </option>
                                                    ))}
                                                </select>
                                                {feMaleDayError && (
                                                    <div className="invalid-feedback fw-bold">
                                                        {feMaleDayError}
                                                    </div>
                                                )}
                                            </div>
                                            <div className="form-group">
                                                <label>month</label>
                                                <select className={`${femaleMonthError && "is-invalid"
                                                    }`} value={femaleBirthMonth}
                                                    onChange={(e) => {
                                                        setFemaleBirthMonth(e.target.value);
                                                    }}>
                                                    <option value="">Month</option>
                                                    {monthOptions.map((month) => (
                                                        <option key={month} value={month}>
                                                            {month}
                                                        </option>
                                                    ))}
                                                </select>
                                                {femaleMonthError && (
                                                    <div className="invalid-feedback fw-bold">
                                                        {femaleMonthError}
                                                    </div>
                                                )}
                                            </div>
                                            <div className="form-group">
                                                <label>year</label>
                                                <select className={`${femaleYearError && "is-invalid"
                                                    }`} value={femaleBirthYear}
                                                    onChange={(e) => {
                                                        setFemaleBirthYear(e.target.value);
                                                    }}>
                                                    <option value="">Year</option>
                                                    {yearOptions.map((year) => (
                                                        <option key={year} value={year}>
                                                            {year}
                                                        </option>
                                                    ))}
                                                </select>
                                                {femaleYearError && (
                                                    <div className="invalid-feedback fw-bold">
                                                        {femaleYearError}
                                                    </div>
                                                )}
                                            </div>
                                        </div>
                                    </div>
                                    <div className="form-group">
                                        <label>birth time</label>
                                        <div className="row">
                                            <div className="form-group">
                                                <label>hour</label>
                                                <select className={`${femaleHourError && "is-invalid"
                                                    }`} value={femaleHour}
                                                    onChange={(e) => {
                                                        setFemaleHour(e.target.value);
                                                    }}>
                                                    <option value="">Hour</option>
                                                    {hourOptions.map((hour) => (
                                                        <option key={hour} value={hour}>
                                                            {hour}
                                                        </option>
                                                    ))}
                                                </select>
                                                {femaleHourError && (
                                                    <div className="invalid-feedback fw-bold">
                                                        {femaleHourError}
                                                    </div>
                                                )}
                                            </div>
                                            <div className="form-group">
                                                <label>minute</label>
                                                <select className={`${feMaleMinuteError && "is-invalid"
                                                    }`} value={femaleMinute}
                                                    onChange={(e) => {
                                                        setfemaleMinute(e.target.value);
                                                    }}>
                                                    <option value="">Minute</option>
                                                    {minuteOptions.map((minute) => (
                                                        <option key={minute} value={minute}>
                                                            {minute}
                                                        </option>
                                                    ))}
                                                </select>
                                                {feMaleMinuteError && (
                                                    <div className="invalid-feedback fw-bold">
                                                        {feMaleMinuteError}
                                                    </div>
                                                )}
                                            </div>
                                            <div className="form-group">
                                                <label>am/pm</label>
                                                <select className={`${femaleAmPmError && "is-invalid"
                                                    }`} value={femaleAmpm}
                                                    onChange={(e) => {
                                                        setFemaleAmpm(e.target.value);
                                                    }}>
                                                    <option value="">AM/PM</option>
                                                    <option value="AM">AM</option>
                                                    <option value="PM">PM</option>
                                                </select>
                                                {femaleAmPmError && (
                                                    <div className="invalid-feedback fw-bold">
                                                        {femaleAmPmError}
                                                    </div>
                                                )}
                                            </div>
                                        </div>
                                    </div>
                                    <div className="form-group">
                                        <label>birth location</label>
                                        <input type="text" placeholder='Enter Bride Birth Place' name="" className={`input ${femalebirthplaceError && "is-invalid"
                                            }`} onChange={handlefeMaleLocationChange}
                                            value={femaleBirthplace} />
                                        {femalebirthplaceError && (
                                            <div className="invalid-feedback  fw-bold">
                                                {femalebirthplaceError}
                                            </div>
                                        )}
                                        <input
                                            id="female_latitude"
                                            className="pfipbx"
                                            name="female_the_lat"
                                            type="hidden"
                                            placeholder="Latitude"
                                        />
                                        <input
                                            id="female_longitude"
                                            className="pfipbx"
                                            name="female_the_lng"
                                            type="hidden"
                                            placeholder="Longitude"
                                        />
                                        {femalesuggestedPlaces && (
                                            <ul className="ps-0 mb-0">
                                                {femalesuggestedPlaces.reverse().map((place, index) => {
                                                    if (place.districts) {
                                                        const listItemStyle = {
                                                            padding: "5px 12px",
                                                            cursor: "pointer",
                                                            marginBottom: "1px",
                                                            backgroundColor: "#fff",
                                                            fontSize: "14px",
                                                            fontWeight: "540",
                                                            width: '100%',
                                                            borderBottom: "1px dashed #ddd",
                                                        };

                                                        return (
                                                            <React.Fragment key={index}>
                                                                <p
                                                                    style={listItemStyle}
                                                                    className="px-2"
                                                                    onClick={() =>
                                                                        handlefeMaleSuggestionSelect(place)
                                                                    }
                                                                >
                                                                    <FontAwesomeIcon icon={faLocationDot} />
                                                                    &nbsp;
                                                                    {place.districts}
                                                                </p>
                                                            </React.Fragment>
                                                        );
                                                    }
                                                })}
                                            </ul>
                                        )}
                                        {FemaleLocationSwitch && (
                                            <p
                                                className="w-100 text-start btn py-0 rounded-0 text-white fw-bold mb-0 mt-0"
                                                style={{ fontSize: "14px", backgroundColor: "#fe1b57" }}
                                                onClick={SearchNewFemaleLocation}
                                            >
                                                {" "}
                                                <FontAwesomeIcon icon={faLocationDot} /> my place is not
                                                in List
                                            </p>
                                        )}
                                    </div>
                                </div>
                                {!BlogStatus && (
                                    <img className="fwi-desktop" src='https://ctsvsolutions.com/onepage-images/new-ui/bride-desktop.webp' width={'44%'} alt='jathaga palangal tamil' />

                                )}
                            </div>
                        </div>
                        <div className="button-center">
                            <button type="button" className="form-btn" value="See Fit" onClick={() => marriageMatchingSubmit()}>
                                Let's Match
                                <img src='https://ctsvsolutions.com/onepage-images/new-ui/btn-arrow-white.webp' alt='marriage matching arrow' />
                            </button>
                        </div>
                        <div className="mt-3">
                            <p className="f-sub text-danger text-center mb-0">
                                * This marriage matching report is generating in Tamil.
                            </p>
                        </div>
                    </form>
                </div>
            </section>
        </>
    )
}
