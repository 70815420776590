import React from 'react'
import TopNavbar from '../../Main-Components/TopNavbar/TopNavbar'
import Menubar from '../../Main-Components/Menubar/Menubar'
import Footer from '../../Main-Components/Footer/Footer'
import JathagaPalanBanner from '../../Small-Components/JathagaPalanBanner/JathagaPalanBanner'
import Review from '../../Main-Components/Review/Review'
import { useLocation } from 'react-router-dom'
import { Helmet } from 'react-helmet'

export default function JathagaPalan() {

  const location = useLocation();

  const canonical = () => {
    const base_url = "https://onepagehoroscope.com";
    const current_path = location.pathname;

    const canonicalPaths = {
      "/Jathaga-Palan": "/Jathaga-Palan",
    };

    return (
      <link
        rel="canonical"
        href={base_url + (canonicalPaths[current_path] || current_path)}
      />
    );
  };

  return (
    <>
    <Helmet>
          <title>Jathaga Palan | தமிழ் ஆடியோ ஜாதக பலன்  | Audio Jathaga Palan</title>

          <meta
            name="description"
            content="Unlock your destiny with accurate jathagam palan predictions. Get personalized insights for a brighter future. Consult now!"
          />
          <meta
            name="keywords"
            content="Jathaga-palan,Tamil jathaga-palan"
          />
          <meta name="robots" content="index,follow" />
          {canonical()}
          <script type="application/ld+json">
            {`
            "@context": "https://schema.org/", 
            "@type": "Product", 
            "name": "Jathaga-palan",
            "image": "",
            "description": "Jathaga-palan",
            "brand": {
              "@type": "Brand",
              "name": "One Page Horoscope"        
          `}
          </script>
        </Helmet>
      <TopNavbar />
       <Menubar NavBgstyle="outer-header lp" NavTextColor="text-dark" SubmenuTop="true" />
       <JathagaPalanBanner />
       <Review />
       <Footer/>
    </>
  )
}
