import React, { useState,useEffect } from "react";
import Input from "../components/input";
import { useNavigate } from "react-router-dom";
import axios from "axios";
export default function AdminLogin() {
  const [username, setUsername] = useState("");
  const [Error, setError] = useState("");
  const [password, setPassword] = useState("");
  const navigate = useNavigate();

  const checkUser = async () => {
    try {
      const response = await axios.post(
        "https://www.ctsvsolutions.com/onepage-Api/public/api/AdminLogin",
        {
          user_name: username,
          password: password,
        }
      );
      if (response.data.status === true) {
        return true;
      } else {
        return false;
      }
    } catch (error) {
      console.error("Error during login:", error);
      return false;
    }
  };

  const handleLogin = async (e) => {
    e.preventDefault();

    if (await checkUser()) {
      localStorage.setItem("IsLogin",true);
      navigate('/Admin-Report');
    } else {
      setError('Invalid User Name and password');
    }
  };

  useEffect(() => {
    let IsAuth = localStorage.getItem('IsLogin');
    if (IsAuth) {
        navigate('/Admin-Report');
        // navigate('/Admin-Report');
    }
}, []);
  //   const change();

  return (
    <>
    <div className="p-0" style={{ marginTop:200 }}>
    {/* <Menubar /> */}

    </div>
        <div className="container mt-5 p-5">
      <div className="row justify-content-center">
        <div className="col-md-6">
          <div
            className="card"
            style={{ boxShadow: "0 4px 8px rgba(0,0,0,0.1)" }}
          >
            <div className="card-body">
              <h2 className="text-center mb-4 text-success  fw-bold">
                Admin Login
              </h2>
              <div className="text-center">
              <span className="text-danger fw-bold">{Error}</span>
              </div>

                            <form onSubmit={handleLogin}>
                <div className="mb-3">
                  <label
                    htmlFor="username"
                    className="form-label text-primary fw-bold"
                  >
                    User Name:
                  </label>
                  <Input
                    value={username}
                    className="form-control"
                    style={{ borderRadius: "5px" }}
                    placeholder="enter the user Name"
                    UserInput={(e) => setUsername(e.target.value)}
                  />
                </div>

                <div className="mb-3">
                  <label
                    htmlFor="password"
                    className="form-label text-primary fw-bold"
                  >
                    Password:
                  </label>
                  <Input
                    value={password}
                    className="form-control"
                    style={{ borderRadius: "5px" }}
                    placeholder="enter the Password"
                    UserInput={(e) => setPassword(e.target.value)}
                  />
                </div>

                <button
                  type="submit"
                  className="btn btn-info btn-block p-2 text-white fw-bold"
                  style={{ borderRadius: "5px" }}
                >
                  Login
                </button>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
    </>
  );
}
