import React from 'react'
import TopNavbar from '../../Main-Components/TopNavbar/TopNavbar'
import Menubar from '../../Main-Components/Menubar/Menubar'
import Footer from '../../Main-Components/Footer/Footer'
import BlogListBanner from '../../Small-Components/BlogListBanner/BlogListBanner'
import BlogRecent from "../../Small-Components/BlogRecent/BlogRecent";
import BlogCategories from "../../Small-Components/BlogCategories/BlogCategories ";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Link, useLocation } from 'react-router-dom'
import { faFacebook, faInstagram, faLinkedin, faTwitter } from '@fortawesome/free-brands-svg-icons'
import { Helmet } from 'react-helmet'
import BirthHoroscopeForm from '../../Forms/BirthHoroscopeForm/BirthHoroscopeForm';
import BirthHoroscopeHomeContextProvider from "../../context/forms/BirthHoroscope_Home_ContextProvider"

export default function TamilJathagamBlog() {

    const location = useLocation();
    const canonical = () => {
        const base_url = "https://onepagehoroscope.com";
        const current_path = location.pathname;

        const canonicalPaths = {
            "/Blog/important-content-to-verify-in-tamil-jathagam":
                "/Blog/important-content-to-verify-in-tamil-jathagam",
        };

        return (
            <link
                rel="canonical"
                href={base_url + (canonicalPaths[current_path] || current_path)}
            />
        );
    };

    return (
        <>
            <Helmet>
                <title>
                    {" "}
                    Tamil Jathagam - Important content to verify in tamil jathagam
                </title>
                <meta
                    name="description"
                    content="Get detailed Tamil Jathagam analysis by expert astrologers. Discover insights into your personality and future based on your birth chart."
                />
                <meta name="keywords" content="Tamil Jathagam" />
                <meta name="robots" content="index,follow" />
                {canonical()}
                <script type="application/ld+json">
                    {JSON.stringify({

                        "@context": "https://schema.org",
                        "@type": "Article",
                        "mainEntityOfPage": {
                            "@type": ".webpage",
                            "@id": "https://onepagehoroscope.com/Blog/important-content-to-verify-in-tamil-jathagam"
                        },
                        "headline": "Tamil Jathagam - Important content to verify in tamil jathagam",
                        "description": "Get detailed Tamil Jathagam analysis by expert astrologers. Discover insights into your personality and future based on your birth chart.",
                        "image": "https://ctsvsolutions.com/onepage-images/tamil-jathagm-important.webp",
                        "author": {
                            "@type": "",
                            "name": ""
                        },
                        "publisher": {
                            "@type": "Organization",
                            "name": "",
                            "logo": {
                                "@type": "ImageObject",
                                "url": ""
                            }
                        },
                        "datePublished": "2024-03-08",
                        "dateModified": "2024-03-21"
                    })}
                </script>
            </Helmet>
            <TopNavbar />
            <Menubar NavBgstyle="outer-header rjr" NavTextColor="text-dark" SubmenuTop="true" />
            <BlogListBanner />
            <TamilJathagamBlogContent />
            <Footer />
        </>
    )
}

function TamilJathagamBlogContent() {
    return (
        <>
            <section className="article-wrapper">
                <div className="astro-container pt-3">
                    <div className="row">
                        <div className="col-lg-8 my-2">
                            <div className="card bg-transparent border-0 blog-view">
                                <div className="card-header bg-transparent border-0 px-0">
                                    <h1 className="fs-4 blog-view-heading">
                                        Tamil Jathagam - Important content to verify in tamil jathagam
                                    </h1>
                                </div>
                                <div className="card-body pt-0 px-0">
                                    <div className="my-2">
                                        <img
                                            src="https://ctsvsolutions.com/onepage-images/tamil-jathagm-important.webp"
                                            alt=" tamil jathagam"
                                            className="img-fluid rounded"
                                        />
                                    </div>
                                    <div className="my-4 d-flex">
                                        <a href="https://www.facebook.com/onepagehoroscope/">
                                            <div className="view-blog-icons facebook">
                                                <FontAwesomeIcon
                                                    icon={faFacebook}
                                                    className="text-white px-2"
                                                />
                                            </div>
                                        </a>
                                        <a href="https://www.instagram.com/one_page_horoscope/">
                                            <div className="view-blog-icons instagram">
                                                <FontAwesomeIcon
                                                    icon={faInstagram}
                                                    className="text-white px-2"
                                                />
                                            </div>
                                        </a>
                                        <a href="https://twitter.com/onepage321">
                                            <div className="view-blog-icons twitter">
                                                <FontAwesomeIcon icon={faTwitter} className="text-white px-2" />
                                            </div>
                                        </a>
                                        <a href="https://linkedin.com/showcase/97871433/admin/feed/posts/">
                                            <div className="view-blog-icons linkedin">
                                                <FontAwesomeIcon
                                                    icon={faLinkedin}
                                                    className="text-white px-2"
                                                />
                                            </div>
                                        </a>
                                    </div>
                                    <div className="description mt-3">
                                        <p className="mb-3">
                                            Tamil jathagam is also called birth chart, vedic astrology is
                                            following by indian astrologer to predict individual future by
                                            position of planet in birth chart{" "}
                                            <Link to="/Birth-horoscope" className="text-decoration-none">
                                                <span className="text-danger">(tamil jathagam)</span>

                                            </Link>
                                            . Simply giving birth chart to the astrologer is not important for
                                            prediction, we should verify content inside in jathagam.
                                        </p>
                                        <div className="mb-3">
                                        <BirthHoroscopeHomeContextProvider>
                                    ``    <BirthHoroscopeForm />
                                        </BirthHoroscopeHomeContextProvider>
                                        </div>
                                        <h2 className="mb-3 fs-5 blog-view-heading">
                                            Jathagam - Basic Content:
                                        </h2>
                                        <p className="description-content mb-3">
                                            In every jathagam we should focus on basic things that will help
                                            our astrologer for deep analysis of our future, important topics
                                            mention below,
                                        </p>
                                        <ul className="blog-view-list">
                                            <li>
                                                <strong>Panjangam Details</strong>
                                            </li>
                                            <li>
                                                <strong>Sayana Spudam</strong>
                                            </li>
                                            <li>
                                                <strong>Nirayana Spudam</strong>
                                            </li>
                                            <li>
                                                <strong>Rasi Chakram</strong>
                                            </li>
                                            <li>
                                                <strong>Navamsa Chakram</strong>
                                            </li>
                                            <li>
                                                <strong>Dasa Irupu</strong>
                                            </li>
                                            <li>
                                                <strong>Nadapu Dasa</strong>
                                            </li>
                                            <li>
                                                <strong>Bavaga Maruthal</strong>
                                            </li>
                                            <li>
                                                <strong>Numerology.</strong>
                                            </li>
                                        </ul>
                                        <p className="description-content mb-3">
                                            these basic deails will help for better prediction.
                                        </p>
                                        <h2 className="mb-3 fs-5 blog-view-heading">Panjangam Details</h2>
                                        <p className="description-content mb-3">
                                            Panjangam is derived by the indian astrologer, which helps to see
                                            the star position every day and planet standing star in birth
                                            chart.
                                        </p>
                                        <p className="description-content mb-3">
                                            This panjangam have details about star rising time, yogam, tithi,
                                            karnam etc. Every details in this panjangam calculated based on
                                            indian calendar.
                                        </p>
                                        <h2 className="mb-3 fs-5 blog-view-heading">Sayana Spudam</h2>
                                        <p className="description-content mb-3">
                                            Sayana spudam is also called mean node of a planet derived by
                                            astonets using telescopes and scientific equipment and
                                            calculations.
                                        </p>
                                        <h2 className="mb-3 fs-5 blog-view-heading">Nirayana Spudam</h2>
                                        <p className="description-content mb-3">
                                            Astrologer calculating the position of planet from mean node using
                                            ayanamsam is called nirayana spudam, this analysis data will be
                                            the effiecient for prediction.
                                        </p>
                                        <h2 className="mb-3 fs-5 blog-view-heading">Rasi Chakram</h2>
                                        <p className="description-content mb-3">
                                            Indian birth chart is splitted into 12 houses and placing the
                                            planets inside the houses called rasi chakram. the 12 houses are
                                            mesham, rishabam, mithunam, kadagam, simmam, kanni, thulam,
                                            viruchagam, dhanush, magaram, kumbam, and meenam.
                                        </p>
                                        <h2 className="mb-3 fs-5 blog-view-heading">Navamsa Chakaram</h2>
                                        <p className="description-content mb-3">
                                            Navamsa chart is also splitted into 12 houses like rasi chart but
                                            the placement of planet is different. Astrologer have particular
                                            formula for placing the planet into the chart placing planet
                                            according shows navamsa chart.
                                        </p>
                                        <h2 className="mb-3 fs-5 blog-view-heading">Dasa Irupu</h2>
                                        <p className="description-content mb-3">
                                            Every Planet is rulling particular period in the horoscope
                                            calculation, predicting the ruling planet sub planet at the time
                                            of birth and calculating the time traveld and balance traveling
                                            time is called dasa irupu.
                                        </p>
                                        <h2 className="mb-3 fs-5 blog-view-heading">Nadapu Dasa</h2>
                                        <p className="description-content mb-3">
                                            Dasa irupu is fixed, nadapu dasa very some particular period
                                            depends on the ruling period. Taking birth chart the ruling planet
                                            may vary at the time of printing the ruling planet period is
                                            called nadapu dasa.
                                        </p>
                                        <h2 className="mb-3 fs-5 blog-view-heading">Bavaga Maruthal</h2>
                                        <p className="description-content mb-3">
                                            Planetry position in the birth chart is fixed at the time of birth
                                            but astrologer defining janana lagnam(Ascendent) for each birth
                                            chart, some time astrologer calculate the position of planet based
                                            on the janana lagnam. For example the position of sun in the the
                                            birth chart is in 10th house in rasi chart, the position may very
                                            9th or 11th in the bavaga maruthal but it depends.
                                        </p>
                                        <h2 className="mb-3 fs-5 blog-view-heading">Numerology</h2>
                                        <p className="description-content mb-3">
                                            Numerology is a number play this number, it have two values one is
                                            uyir en and another one is udal en. Based on the uyir en and udal
                                            en our lucky number is calculated. Its a big topics we will
                                            details this after.
                                        </p>
                                        <h2 className="mb-3 fs-5 blog-view-heading">Tamil Jathagam</h2>
                                        <p className="description-content mb-3">
                                            Jathagam details in tamil help astrology believers to be in right
                                            path in future with the help of best astrologer.
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-4 my-2">
                            <div className="row">
                                <BlogRecent />
                                <BlogCategories />
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}