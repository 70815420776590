import React, { Suspense } from 'react'
import BirthHoroscopeHomeContextProvider from '../../context/forms/BirthHoroscope_Home_ContextProvider';
import { useLocation } from 'react-router-dom';
import { Helmet } from 'react-helmet';
const TopNavbar = React.lazy(() => import('../../Main-Components/TopNavbar/TopNavbar'));
const Menubar = React.lazy(() => import("../../Main-Components/Menubar/Menubar"));
const FAQ = React.lazy(() => import('../../Main-Components/FAQ/FAQ'));
const Footer = React.lazy(() => import('../../Main-Components/Footer/Footer'));
const BirthHoroscopeBanner = React.lazy(() => import('../../Small-Components/BirthHoroscopeBanner/BirthHoroscopeBanner'));
const BirthHoroscopeForm = React.lazy(() => import('../../Forms/BirthHoroscopeForm/BirthHoroscopeForm'));
const BirthHoroscopeContentCard = React.lazy(() => import('../../Small-Components/BirthHoroscopeContentCard/BirthHoroscopeContentCard'));

export default function BirthHoroscope() {

    const location = useLocation();

    const canonical = () => {
        const base_url = "https://onepagehoroscope.com";
        const current_path = location.pathname;

        const canonicalPaths = {
            "/Birth-horoscope": "/Birth-horoscope",
        };

        return (
            <link
                rel="canonical"
                href={base_url + (canonicalPaths[current_path] || current_path)}
            />
        );
    };

    return (
        <>
            <Helmet>
                <title>Online Horoscope Tamil | இலவச தமிழ் பிறப்பு ஜாதகம்</title>
                <meta
                    name="description"
                    content="Tamil Jathagam online, you can download your personal jathagam through your WhatsApp number, perfect PDF for all kindly of your astrology uses."
                />
                <meta
                    name="keywords"
                    content="tamil jathagam online, tamil birth chart, இலவச பிறப்பு ஜாதகம் ,pirappu jathagam, பிறப்பு ஜாதகம், ஒரு பக்க ஜாதகம், birth chart, birth chart calculator, free birth chart, tamil jathagam, tamil jathagam online, tamil jathagam online free,jathaga kattam,Find rasi and natchathiram by date of birth,date of birth astrology in tamil,Career horoscope by date of birth,horoscope by date of birth in tamil,future horoscope by date of birth"
                />
                <meta name="robots" content="index,follow" />
                {canonical()}
                <script type="application/ld+json">
                    {JSON.stringify({

                        "@context": "https://schema.org/",
                        "@type": "Product",
                        "name": "Birth Horoscope",
                        "image": "https://ctsvsolutions.com/onepage-images/icon.webp",
                        "description": "Discover your destiny with our free Tamil date of birth astrology service. Generate a detailed birth chart showing planetary positions, rasi, navamsam, panjang.",
                        "brand": {
                            "@type": "Brand",
                            "name": "One Page Horoscope"
                        },
                        "offers": {
                            "@type": "Offer",
                            "url": "https://onepagehoroscope.com/Birth-horoscope",
                            "priceCurrency": "INR",
                            "price": "99",
                            "availability": "https://schema.org/OnlineOnly",
                            "itemCondition": "https://schema.org/NewCondition",
                            "hasMerchantReturnPolicy": {
                                "@type": "MerchantReturnPolicy",
                                "refundType": "FullRefund",
                                "returnPolicyCategory": "https://schema.org/RefundAndExchangePolicy"
                            },
                            "shippingDetails": {
                                "@type": "OfferShippingDetails",
                                "shippingDestination": {
                                    "@type": "DefinedRegion",
                                    "name": "IN"
                                },
                                "shippingRate": {
                                    "@type": "MonetaryAmount",
                                    "currency": "INR",
                                    "value": "0"
                                }
                            }
                        }
                    })}
                </script>
            </Helmet>
            <BirthHoroscopeHomeContextProvider>
                <Suspense fallback={<div>Loading...</div>}>
                    <TopNavbar />
                    <Menubar NavBgstyle="outer-header bc-outer" NavTextColor="text-dark" SubmenuTop="true" />
                    <BirthHoroscopeBanner />
                    <div className="form bc-form">
                        <BirthHoroscopeForm />
                    </div>
                    <BirthHoroscopeContentCard />
                    <FAQ />
                    <Footer />
                </Suspense>
            </BirthHoroscopeHomeContextProvider>
        </>
    )
}
