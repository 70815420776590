import React from 'react'
import TopNavbar from '../../Main-Components/TopNavbar/TopNavbar'
import Menubar from '../../Main-Components/Menubar/Menubar'
import Footer from '../../Main-Components/Footer/Footer'
import BlogListBanner from '../../Small-Components/BlogListBanner/BlogListBanner'
import BlogRecent from "../../Small-Components/BlogRecent/BlogRecent";
import BlogCategories from "../../Small-Components/BlogCategories/BlogCategories ";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Link, useLocation } from 'react-router-dom'
import { faFacebook, faInstagram, faLinkedin, faTwitter } from '@fortawesome/free-brands-svg-icons'
import { Helmet } from 'react-helmet'
import MarriageMatchingForm from "../../Forms/MarriageMatchingForm/MarriageMatchingForm";
import MarriageMatchingContextProvider from "../../context/forms/MarriageMatching_contexProvider"
export default function ThirumanaPoruthamBlog() {

    const location = useLocation();
    const canonical = () => {
        const base_url = "https://onepagehoroscope.com";
        const current_path = location.pathname;

        const canonicalPaths = {
            "/Blog/Guide-to-Thirumana-porutham": "/Blog/Guide-to-Thirumana-porutham",

        };

        return (
            <link
                rel="canonical"
                href={base_url + (canonicalPaths[current_path] || current_path)}
            />
        );
    };

    return (
        <>
            <Helmet>
                <title>Finding Your Perfect Match: A Guide to Thirumana Porutham</title>
                <meta
                    name="description"
                    content="Thirumana Porutham is a traditional matchmaking practice in Tamil culture that assesses compatibility of potential couples using astrological charts."
                />
                <meta
                    name="keywords"
                    content="Thirumanaporutham"
                />
                <meta name="robots" content="index,follow" />
                <meta property="og:title" content="Finding Your Perfect Match: A Guide to Thirumana Porutham" />
                <meta property="og:description" content="Thirumana Porutham is a traditional matchmaking practice in Tamil culture that assesses compatibility of potential couples using astrological charts." />
                <meta property="og:image" content="https://ctsvsolutions.com/onepage_admin/onepage-images/Thirumana-Porutham.webp" />
                <meta property="og:url" content="https://onepagehoroscope.com/Blog/Guide-to-Thirumana-porutham" />
                <meta name="twitter:card" content="summary_large_image" />
                <meta name="twitter:title" content="Finding Your Perfect Match: A Guide to Thirumana Porutham" />
                <meta name="twitter:description" content="Thirumana Porutham is a traditional matchmaking practice in Tamil culture that assesses compatibility of potential couples using astrological charts." />
                <meta name="twitter:image" content="https://ctsvsolutions.com/onepage_admin/onepage-images/Thirumana-Porutham.webp" />
                <meta name="twitter:url" content="https://onepagehoroscope.com/Blog/Guide-to-Thirumana-porutham" />
                {canonical()}
                <script type="application/ld+json">


                    {JSON.stringify({
                        "@context": "https://schema.org",
                        "@type": "BlogPosting",
                        "mainEntityOfPage": {
                            "@type": ".webpage",
                            "@id": "https://onepagehoroscope.com/Blog/Guide-to-Thirumana-porutham"
                        },
                        "headline": "Finding Your Perfect Match: A Guide to Thirumana Porutham",
                        "description": "Thirumana Porutham is a traditional matchmaking practice in Tamil culture that assesses compatibility of potential couples using astrological charts.",
                        "image": "https://ctsvsolutions.com/onepage_admin/onepage-images/Thirumana-Porutham.webp",
                        "author": {
                            "@type": "",
                            "name": ""
                        },
                        "publisher": {
                            "@type": "Organization",
                            "name": "",
                            "logo": {
                                "@type": "ImageObject",
                                "url": ""
                            }
                        },
                        "datePublished": "2024-05-03",
                        "dateModified": "2024-05-03"
                    })}
                </script>
            </Helmet>
            <TopNavbar />
            <Menubar NavBgstyle="outer-header rjr" NavTextColor="text-dark" SubmenuTop="true" />
            <BlogListBanner />
            <ThirumanaPoruthamBlogContent />
            <Footer />
        </>
    )
}

function ThirumanaPoruthamBlogContent() {
    return (
        <>
            <section className="article-wrapper">
                <div className="astro-container pt-3">
                    <div className="row">
                        <div className="col-lg-8 my-2">
                            <div className="card bg-transparent border-0 blog-view">
                                <div className="card-header bg-transparent border-0 px-0">
                                    <h1 className="fs-4 blog-view-heading">
                                        Finding Your Perfect Match: A Guide to Thirumana Porutham
                                    </h1>
                                </div>
                                <div className="card-body pt-0 px-0">
                                    <div className="my-2">
                                        <img
                                            src="https://ctsvsolutions.com/onepage_admin/onepage-images/Thirumana-Porutham.webp"
                                            alt="Life Horoscope In tamil"
                                            className="img-fluid rounded"
                                        />
                                    </div>
                                    <div className="my-4 d-flex">
                                        <a href="https://www.facebook.com/onepagehoroscope/">
                                            <div className="view-blog-icons facebook">
                                                <FontAwesomeIcon
                                                    icon={faFacebook}
                                                    className="text-white px-2"
                                                />
                                            </div>
                                        </a>
                                        <a href="https://www.instagram.com/one_page_horoscope/">
                                            <div className="view-blog-icons instagram">
                                                <FontAwesomeIcon
                                                    icon={faInstagram}
                                                    className="text-white px-2"
                                                />
                                            </div>
                                        </a>
                                        <a href="https://twitter.com/onepage321">
                                            <div className="view-blog-icons twitter">
                                                <FontAwesomeIcon icon={faTwitter} className="text-white px-2" />
                                            </div>
                                        </a>
                                        <a href="https://linkedin.com/showcase/97871433/admin/feed/posts/">
                                            <div className="view-blog-icons linkedin">
                                                <FontAwesomeIcon
                                                    icon={faLinkedin}
                                                    className="text-white px-2"
                                                />
                                            </div>
                                        </a>
                                    </div>
                                    <div className="description mt-3">
                                        <p className="mb-3">

                                            இந்திய கலாச்சாரத்தில் வாழ்க்கைத் துணையை தேடும் போது, வெற்றிகரமான மற்றும் இணக்கமான திருமணத்தை உறுதி செய்வதில் பல மரபுகள் மற்றும் சடங்குகள் முக்கிய பங்கு வகிக்கின்றன. தமிழ் கலாச்சாரத்தில்<Link to="/Marriage-matching" className="text-decoration-none">
                                                <span className="text-danger">(Thirumana porutham)</span>
                                            </Link> திருமணப் பொருத்தம் என்பது அத்தகைய ஒரு நடைமுறையாகும். திருமணப் பொருத்தம் எவ்வாறு இயங்குகிறது அல்லது உங்கள் சரியான பொருத்தத்தைக் கண்டறிய அது உங்களுக்கு எவ்வாறு வழிகாட்டும் என்பதைப் பற்றி நீங்கள் ஆர்வமாக இருந்தால், மேலும் அறிய படிக்கவும்.
                                        </p>
                                        <div>
                                        <MarriageMatchingContextProvider>
                                               <MarriageMatchingForm BlogStatus={true} />
                                        </MarriageMatchingContextProvider >
                                        </div>
                                        <div>
                                            <h2 className="mb-3 fs-5 blog-view-heading mt-3">
                                                திருமணப் பொருத்தம் என்றால் என்ன?
                                            </h2>
                                            <p className="description-content mb-3">
                                                திருமணப் பொருத்தம் என்பது திருமணத்திற்கு முன் வருங்கால தம்பதிகளின் பொருந்தக்கூடிய தன்மையை மதிப்பிடுவதற்குப் பயன்படுத்தப்படும் ஒரு பாரம்பரிய முறையாகும். இது அவர்களின் பிறந்த தேதி, நேரம் மற்றும் இடங்களிலிருந்து பெறப்பட்ட அந்தந்த ஜாதகங்களை அடிப்படையாகக் கொண்டது.
                                            </p>

                                        </div>
                                        <div>
                                            <h2 className="mb-3 fs-5 blog-view-heading">
                                                திருமணப் பொருத்தத்தின் முக்கியத்துவம்
                                            </h2>

                                            <ul className="blog-view-list">
                                                <li>
                                                    <strong>இணக்கமான உறவு:</strong>
                                                    <p>வருங்கால கூட்டாளிகளின் ஜோதிட விளக்கப்படங்களை ஒப்பிடுவதன் மூலம், திருமணப் பொருத்தம் உணர்ச்சிபூர்வமான தொடர்பு, உடல் ஈர்ப்பு மற்றும் மன இணக்கம் போன்ற பல்வேறு பகுதிகளில் பொருந்தக்கூடிய தன்மையை உறுதி செய்வதை நோக்கமாகக் கொண்டுள்ளது.</p>
                                                </li>
                                                <li>
                                                    <strong>குடும்ப மகிழ்ச்சி: </strong>
                                                    <p>முழு குடும்பத்தின் நல்லிணக்கத்தையும் உள்ளடக்கும் வகையில் பொருந்தக்கூடிய தன்மை தம்பதியருக்கு அப்பாற்பட்டது. வலுவான ஜோதிடப் பொருத்தம் மாமியார் மற்றும் பெரிய குடும்பத்துடன் சிறந்த உறவை ஏற்படுத்தும்.</p>
                                                </li>
                                                <li>
                                                    <strong>நீண்ட கால உறவு: </strong>
                                                    <p>தம்பதியரின் விளக்கப்படங்களின் பல்வேறு அம்சங்களைச் சீரமைப்பதன் மூலம் திருமணப் பொருத்தம், திருமணத்தின் நீண்ட ஆயுளைக் கணிக்க முடியும் மற்றும் அவர்கள் ஒன்றாக எதிர்கொள்ளும் சாத்தியமான சவால்களை கணிக்க முடியும்.</p>
                                                </li>

                                            </ul>
                                        </div>
                                        <div>
                                            <h2 className="mb-3 fs-5 blog-view-heading">
                                                திருமணப் பொருத்தம் எவ்வாறு செயல்படுகிறது
                                            </h2>
                                            <ul className="blog-view-list">
                                                <li>
                                                    <strong>தொழில் மற்றும் கல்வி:</strong>
                                                    <p>ஒரு ஜோதிடர் இந்தத் தகவலைப் பயன்படுத்தி தம்பதிகளின் விளக்கப்படங்களை பகுப்பாய்வு செய்து, நட்சத்திரம், ராசி மற்றும் பிற பொருத்தம் கூறுகள் போன்ற பல்வேறு அம்சங்களை ஒப்பிடுகிறார்.</p>
                                                </li>
                                                <li>
                                                    <strong>பொருந்தக்கூடிய அறிக்கை:</strong>
                                                    <p>ஜோதிடர் ஒரு விரிவான பொருந்தக்கூடிய அறிக்கையை வழங்குகிறார், சாத்தியமான பொருத்தத்தின் பலம் மற்றும் பலவீனங்களைக் கோடிட்டுக் காட்டுகிறார்.</p>
                                                </li>
                                                <li>
                                                    <strong>வழிகாட்டுதல் மற்றும் பரிந்துரைகள்:</strong>
                                                    <p>பகுப்பாய்வின் அடிப்படையில், ஜோதிடர் தம்பதியருக்கு வழிகாட்டுதல் மற்றும் பரிந்துரைகளை வழங்கலாம், அதாவது சில பரிகாரங்கள் அல்லது இணக்கத்தை மேம்படுத்துவதற்கான மாற்றங்களை பரிந்துரைப்பது போன்றவை.</p>
                                                </li>

                                            </ul>
                                            <h2 className="mb-3 fs-5 blog-view-heading">
                                                திருமணப் பொருத்தம் பற்றிய நவீன பார்வைகள்
                                            </h2>
                                            <p>திருமணப் பொருத்தம் தமிழ் கலாச்சாரத்தில் ஒரு மரியாதைக்குரிய பாரம்பரியமாக இருக்கும் அதே வேளையில், திருமணம் பற்றிய நவீன கண்ணோட்டங்கள் மற்ற காரணிகளையும் கணக்கில் எடுத்துக்கொள்கின்றன. தனிப்பட்ட இணக்கத்தன்மை, பரஸ்பர மரியாதை, தொடர்பு மற்றும் பகிரப்பட்ட மதிப்புகள் ஆகியவை இதில் அடங்கும். இன்று பல தம்பதிகள் திருமணப் பொருத்தத்தை வெற்றிகரமான மற்றும் மகிழ்ச்சியான திருமணத்தை உறுதிப்படுத்தும் பல கருவிகளில் ஒன்றாகப் பயன்படுத்துகின்றனர்.</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-4 my-2">
                            <div className="row">
                                <BlogRecent />
                                <BlogCategories />
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}