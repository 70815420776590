import React from 'react'
// import BioDataBanner from '../../images/mb-header-img.webp'

export default function MarriageBioDataBanner() {
  return (
    <>
      <section className="page-outer mb">
        <div className="astro-container">
          <div className="row">
            <div className="col">
              <div className="content">
                <h1 className="sh">Marriage<span className="grt">  Bio-Data</span>
                </h1>
                <p className="p-outer">
                  Marriage bio-data Format usually contains information such as name,
                  age, date of birth, religion, gotra, education, job details,
                  profession of parents, siblings details, and hobbies. Most people
                  also attach horoscope with their biodata, income, and partner
                  expectations.
                </p>
                <div className="mb-right-illus">
                  <img src='https://ctsvsolutions.com/onepage-images/new-ui/mb-header-img.webp' alt='bio data in tamil'/>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  )
}
