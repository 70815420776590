import React from 'react'
import TopNavbar from '../../Main-Components/TopNavbar/TopNavbar'
import Menubar from '../../Main-Components/Menubar/Menubar'
import Footer from '../../Main-Components/Footer/Footer'
import BlogListBanner from '../../Small-Components/BlogListBanner/BlogListBanner'
import BlogRecent from "../../Small-Components/BlogRecent/BlogRecent";
import BlogCategories from "../../Small-Components/BlogCategories/BlogCategories ";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Link, useLocation } from 'react-router-dom'
import { faFacebook, faInstagram, faLinkedin, faTwitter } from '@fortawesome/free-brands-svg-icons'
import { Helmet } from 'react-helmet'

export default function Babynameblog() {

    const location = useLocation();
    const canonical = () => {
        const base_url = "https://onepagehoroscope.com";
        const current_path = location.pathname;

        const canonicalPaths = {
            "/Blog/New-Born-Baby-Jathagam-in-Tamil": "/Blog/New-Born-Baby-Jathagam-in-Tamil",

        };

        return (
            <link
                rel="canonical"
                href={base_url + (canonicalPaths[current_path] || current_path)}
            />
        );
    };

    return (
        <>
            <Helmet>
                <title> New Born Baby Jathagam in Tamil | குழந்தை ஜாதகம்</title>
                <meta
                    name="description"
                    content="New Born Baby Jathagam in Tamil , we provide baby birth chart pdf in perfect pdf format in tamil language."
                />
                <meta
                    name="keywords"
                    content="New Born Baby Jathagam in Tamil"
                />
                <meta name="robots" content="index,follow" />
                {canonical()}

            </Helmet>
            <TopNavbar />
            <Menubar NavBgstyle="outer-header rjr" NavTextColor="text-dark" SubmenuTop="true" />
            <BlogListBanner />
            <BabyNameBlogContent />
            <Footer />
        </>
    )
}

function BabyNameBlogContent() {
    return (
        <>
            <section className="article-wrapper">
                <div className="astro-container pt-3">
                    <div className="row">
                        <div className="col-lg-8 my-2">
                            <div className="card bg-transparent border-0 blog-view">
                                <div className="card-header bg-transparent border-0 px-0">
                                    <h1 className="fs-4 blog-view-heading">
                                        New Born Baby Jathagam in Tamil - குழந்தை ஜாதகம்
                                    </h1>
                                </div>
                                <div className="card-body pt-0 px-0">
                                    <div className="my-2">
                                        <img
                                            src="https://ctsvsolutions.com/onepage_admin/onepage-images/New Born Baby Jathagam in Tamil .webp"
                                            alt="New Born Baby Jathagam in Tamil"
                                            className="img-fluid rounded"
                                        />
                                    </div>
                                    <div className="my-4 d-flex">
                                        <a href="https://www.facebook.com/onepagehoroscope/">
                                            <div className="view-blog-icons facebook">
                                                <FontAwesomeIcon icon={faFacebook} className="text-white px-2" />
                                            </div>
                                        </a>
                                        <a href="https://www.instagram.com/one_page_horoscope/">
                                            <div className="view-blog-icons instagram">
                                                <FontAwesomeIcon icon={faInstagram} className="text-white px-2" />
                                            </div>
                                        </a>
                                        <a href="https://twitter.com/onepage321">
                                            <div className="view-blog-icons twitter">
                                                <FontAwesomeIcon icon={faTwitter} className="text-white px-2" />
                                            </div>
                                        </a>
                                        <a href="https://linkedin.com/showcase/97871433/admin/feed/posts/">
                                            <div className="view-blog-icons linkedin">
                                                <FontAwesomeIcon icon={faLinkedin} className="text-white px-2" />
                                            </div>
                                        </a>
                                    </div>
                                    <div className="description mt-3">
                                        <p className="mb-3">
                                            <Link to="/Baby-Name-List" className="text-decoration-none">
                                                <span className="text-danger">New Born Baby Jathagam in Tamil</span>
                                            </Link>, one page horoscope provide baby birth chart in perfect pdf format in tamil language. Content inside new born baby jathagem include tamil panjangam details, planetry position, start position, birth start, birth sign and star letter for choosing name.
                                        </p>
                                        {/* <BirthHoroscopeBlogForm /> */}
                                        <h2 className="mb-3 fs-5 blog-view-heading">Panjangam Details</h2>
                                        <p className="description-content mb-3">
                                            Panjangam used in india for making desision through out the year for good occation in their family gathering, but in astrology its must to get the panjangam details for individual horoscope.
                                        </p>
                                        <p className="description-content mb-3">
                                            This details include birth star, yogam, karanam, thithi, thithi soonyam, pasam, etc. Some time astrologer predict important details about behaviour and basic prediction with the use of panjangam.
                                        </p>
                                        <h2 className="mb-3 fs-5 blog-view-heading">
                                            Nirayana Spudam ( Plante Positions)
                                        </h2>
                                        <p className="description-content mb-3">
                                            Planet position calculated at the baby birth time, astrologer design our rasi chart based on this planet position at birth time.
                                        </p>
                                        <p className="description-content mb-3">
                                            Position of planet mention in degress, astrologer placing the planet in 12 hoses of rasi chart, with the help of planet position they place the planet and tells the predcition depend of the placement.
                                        </p>
                                        <p className="description-content mb-3">For Example</p>
                                        <ul className="blog-view-list">
                                            <li>
                                                <strong>planet degree between 0 - 30 they will place in mesha rasi.</strong>
                                            </li>
                                            <li>
                                                <strong>planet degree between 30.01 - 60 they will place in rishaba rasi.</strong>
                                            </li>
                                        </ul>
                                        <h2 className="mb-3 fs-5 blog-view-heading">Rasi Chart</h2>
                                        <p className="description-content mb-3">
                                            Rasi chart is also called as birth chart, with the help of nirayana spudam we place all the planets in their housed the output called rasi chart.
                                        </p>
                                        <p className="description-content mb-3">
                                            Easch and every house of rasi chart will be used for prediction using athibathi of the rasi chart, prediction inculde ourseld, studies, job, business, welth, health, money, marriage, love, our life partner, our family welth, siblings, parents.
                                        </p>
                                        <p className="description-content mb-3">
                                            you can get all twlelve house prediciton here.
                                        </p>
                                        <h2 className="mb-3 fs-5 blog-view-heading">Numerology Report</h2>
                                        <p className="description-content mb-3">
                                            One of the best astrological logic belief, astrologer saying numerology tell our ruling planet based on the rulling planet will guide lead our future along with all other planets.
                                        </p>
                                        <p className="description-content mb-3">
                                            We provide numerology as per baby born date, iclude lucky baby lucky number, lucky color, lucky planet and name number for with analysis.
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-4 my-2">
                            <div className="row">
                                <BlogRecent />
                                <BlogCategories />
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}