import React from 'react'
// import SuccessImage from '../../images/tick1.webp'
import { useNavigate } from 'react-router-dom';

export default function OnePagePaymentSuccessContent() {

    const Navigate = useNavigate();
    const Redirect = () => {
        Navigate('/Birth-horoscope')
    }

    return (
        <>
            <section className="result-info bc-outer bcr Bh-result">
                <div className="astro-container privacy-policy-content">
                    <div className='row'>
                        <div className="col-lg-6 col-md-8 m-auto my-5">
                            <div className="card px-2 shadow success-payment-card">
                                <div className='text-center'>
                                    <img src='https://ctsvsolutions.com/onepage-images/new-ui/payment-success.webp' alt="one page horoscope payment success" className='img-fluid' width={125} height={125} />
                                </div>
                                <div>
                                    <p className='paragraph' style={{ textIndent: '20px' }}>Thank you for using One Page Horoscope service 🙏. May the Jataka be blessed with good health, long life, rich wealth, high fame, true wisdom and prosperity.🙏.</p>
                                    <p className='paragraph' style={{ textIndent: '20px' }}>Your Horoscope has been successfully sent to your given WhatsApp and Email.</p>
                                </div>
                                <div className='mb-4 text-center'>
                                    <button className='btn btn-success' onClick={Redirect}>Okay, Thank You</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}
