import axios from "axios";
import { Loading } from "notiflix/build/notiflix-loading-aio";

function loadScript(src) {
  return new Promise((resolve) => {
    const script = document.createElement("script");
    script.src = src;
    script.onload = () => resolve(true);
    script.onerror = () => resolve(false);
    document.body.appendChild(script);
  });
}

export const RazerpayPaymentGateway = async (
  payment_Data,
  DownloadPaidPdf,
  Loader_status
) => {
  const res = await loadScript("https://checkout.razorpay.com/v1/checkout.js");

  if (!res) {
    alert("Razorpay SDK failed to load. Please check your internet connection.");
    return;
  }
  const data = { amount: Math.round(payment_Data.amount * 102) };

  try {
    // API call to create Razorpay order
    const response = await axios.post(
      "https://ctsvsolutions.com/onepage-Api/public/api/RazerPay",
      data
    );
    const orderData = response.data;

    const options = {
      description: payment_Data.description,
      image: "https://ctsvsolutions.com/onepage-images/one_page_logo.webp",
      currency: "INR",
      key: "rzp_live_Q8cg9V1R9YPHWc",
      amount: orderData.amount,
      name: "Onepage Horoscope",
      order_id: orderData.id,
      prefill: {
        email: payment_Data.email,
        contact: payment_Data.mobileNumber,
        name: payment_Data.name,
      },
      theme: { color: payment_Data.theme },
      handler: function (response) {
        if (Loader_status) {
          Loading.hourglass("processing....", {
            backgroundColor: "rgba(0,0,0,0.8)",
          });
        }
        const data = {
          paymentId: response.razorpay_payment_id,
        };

        // API call to verify payment status
        axios
          .post(
            "https://ctsvsolutions.com/onepage-Api/public/api/RazerPay_status",
            data
          )
          .then((response) => {
            const paymentUpdateData = {
              paymentId: response.data.data.payment_id,
              method: response.data.data.method,
              userId: payment_Data.user_id,
              customerDataId: payment_Data.customerDataId,
              amount: response.data.data.amount / 100, // Convert back to rupees
            };

            if (response.data.status === true) {
              axios
                .post(payment_Data.paymentCallBackurl, paymentUpdateData)
                .then(() => {
                  DownloadPaidPdf();
                  return true;
                });
            }
          });
      },
    };

    const paymentObject = new window.Razorpay(options);
    paymentObject.open();

    paymentObject.on("payment.failed", function () {
      alert("Payment failed. Please try again.");
      return false;
    });
  } catch (error) {
    console.error("Error generating Razorpay order:", error);
    alert("Failed to initiate payment. Please try again.");
    return false;
  }
};
