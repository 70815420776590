import React, { Suspense } from 'react'
import TopNavbar from '../../Main-Components/TopNavbar/TopNavbar'
import Menubar from '../../Main-Components/Menubar/Menubar'
import Footer from '../../Main-Components/Footer/Footer'
import MarriageMatchingBanner from '../../Small-Components/MarriageMatchingBanner/MarriageMatchingBanner'
import MarriageMatchingForm from '../../Forms/MarriageMatchingForm/MarriageMatchingForm'
import MarriageMatchingContent from '../../Small-Components/MarriageMatchingContent/MarriageMatchingContent'
import FAQ from '../../Main-Components/FAQ/FAQ'
import MarriageMatchingContextProvider from '../../context/forms/MarriageMatching_contexProvider'
import { Helmet } from 'react-helmet'
import { useLocation } from 'react-router-dom'

export default function MarriageMatching() {

    const location = useLocation();

    const canonical = () => {
        const base_url = "https://onepagehoroscope.com";
        const current_path = location.pathname;

        const canonicalPaths = {
            "/Marriage-matching": "/Marriage-matching"
        };

        return (
            <link
                rel="canonical"
                href={base_url + (canonicalPaths[current_path] || current_path)}
            />
        );
    };

    return (
        <>
            <Helmet>
                <title>
                    {" "}
                    Marriage Matching Tamil | Star Matching  & திருமண பொருத்தம்
                </title>

                <meta
                    name="description"
                    content="Find your ideal partner through traditional Marriage matching tamil techniques. Our experts evaluate star matching astrology, dhosam matching, dasa matching."
                />
                <meta
                    name="keywords"
                    content="jathaga porutham, jathagam porutham, jathaka porutham in tamil, marriage matching by date of birth, porutham, rasi porutham, rasi porutham for marriage, rasi porutham in tamil, star matching for marriage, thirumana porutham, thirumana porutham in tamil, ஜாதக பொருத்தம், திருமண பொருத்தம், திருமண பொருத்தம் பார்த்த பார்த்தல்,Marriage matching tamil,Marriage matching astrology in tamil,Nakshatra matching for marriage,Marriage prediction in tamil by date of birth,Marriage matching by date of birth in tamil,marriage horoscope matching tamil"
                />
                <meta name="robots" content="index,follow" />
                {canonical()}
                <script type="application/ld+json">
                    {JSON.stringify({
                        "@context": "https://schema.org/",
                        "@type": "Product",
                        name: "Marriage Matching",
                        image:
                            "https://onepagehoroscope.com/static/media/marriage%20matching.e1bd2e37b09970b16dc3.webp",
                        description:
                            "Find your ideal partner through traditional Marriage matching tamil techniques. Our experts evaluate star matching astrology, dhosam matching, dasa matching.",
                        brand: {
                            "@type": "Brand",
                            name: "One Page Horoscope",
                        },
                        offers: {
                            "@type": "Offer",
                            url: "https://onepagehoroscope.com/Marriage-matching",
                            priceCurrency: "INR",
                            price: "149",
                            availability: "https://schema.org/OnlineOnly",
                            itemCondition: "https://schema.org/NewCondition",
                        },
                    })}
                </script>
            </Helmet>
            <MarriageMatchingContextProvider>
                <Suspense fallback={<div>Loading...</div>}>
                    <TopNavbar />
                    <Menubar NavBgstyle="outer-header l-pnk" NavTextColor="text-dark" SubmenuTop="true" />
                    <MarriageMatchingBanner />
                    <MarriageMatchingForm />
                    <MarriageMatchingContent />
                    <FAQ Type={'Marriage_matching'}/>
                    <Footer />
                </Suspense>
            </MarriageMatchingContextProvider>
        </>
    )
}
