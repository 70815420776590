import React, { lazy, Suspense } from 'react';
import { Helmet } from 'react-helmet';
import { useLocation } from 'react-router-dom';

// Lazy load the components
const TopNavbar = lazy(() => import('../../Main-Components/TopNavbar/TopNavbar'));
const Menubar = lazy(() => import('../../Main-Components/Menubar/Menubar'));
const Footer = lazy(() => import('../../Main-Components/Footer/Footer'));
const PrivacyPolicyBanner = lazy(() => import('../../Small-Components/PrivacyPolicyBanner/PrivacyPolicyBanner'));
const PrivacyPolicyContent = lazy(() => import('../../Small-Components/PrivacyPolicyContent/PrivacyPolicyContent'));

export default function PrivacyPolicyView() {

    const location = useLocation();
    const canonical = () => {
        const base_url = "https://onepagehoroscope.com";
        const current_path = location.pathname;

        const canonicalPaths = {
            "/privacy-policy": "/privacy-policy",
        };

        return (
            <link
                rel="canonical"
                href={base_url + (canonicalPaths[current_path] || current_path)}
            />
        );
    };

    return (
        <>
            <Helmet>
                <title>Onepage Horoscope - Privacy Policy</title>
                <meta
                    name="description"
                    content="One page horoscope privacy policy"
                />
                <meta
                    name="keywords"
                    content="one page Horoscope, About us, Horoscope, Free Horoscope Software"
                />
                <meta name="robots" content="index,follow" />
                {canonical()}
                <script type="application/ld+json">
                    {`
                    {
                      "@context": "https://schema.org/",
                      "@type": "Product",
                      "name": "OnepageHoroscope About us",
                      "image": "",
                      "description": "One page horoscope privacy policy.",
                      "brand": {
                        "@type": "Brand",
                        "name": "One Page Horoscope"
                      }
                    }
                    `}
                </script>
            </Helmet>

            {/* Suspense provides a fallback while components load */}
            <Suspense fallback={<div>Loading...</div>}>
                <TopNavbar />
                <Menubar NavBgstyle="outer-header bc-outer" NavTextColor="text-dark" SubmenuTop="true" />
                <PrivacyPolicyBanner />
                <PrivacyPolicyContent />
                <Footer />
            </Suspense>
        </>
    );
}
