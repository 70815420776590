import React, { lazy, Suspense } from 'react';
import { Helmet } from 'react-helmet';
import { useLocation } from 'react-router-dom';

// Lazy load components
const TopNavbar = lazy(() => import('../../Main-Components/TopNavbar/TopNavbar'));
const Menubar = lazy(() => import('../../Main-Components/Menubar/Menubar'));
const Footer = lazy(() => import('../../Main-Components/Footer/Footer'));
const BlogListBanner = lazy(() => import('../../Small-Components/BlogListBanner/BlogListBanner'));
const BlogListCard = lazy(() => import('../../Small-Components/BlogListCard/BlogListCard'));

export default function BlogList() {

  const location = useLocation();

  const canonicalPaths = {
    "/Blog": "/Blog",
  };

  const canonicalUrl =
    'https://onepagehoroscope.com' +
    (canonicalPaths[location.pathname] || location.pathname);

  return (
    <>
      <Helmet>
        <title>Explore Blogs for Astrology In Tamil | One Page Horoscope</title>
        <meta
          name="description"
          content="Blogs for astrology in Tamil! Explore Birth Horoscope, Marriage Matching, Baby Name suggestions, and more. Stay updated with our recent posts!"
        />
        <meta
          name="keywords"
          content="Blog,Horoscope Blog,TamilBlog,AstrologyBlog,OnepageBlog"
        />
        <meta name="robots" content="index,follow" />
        <link rel="canonical" href={canonicalUrl} />
        <script type="application/ld+json">
          {`
            {
              "@context": "https://schema.org/",
              "@type": "Product",
              "name": "OnepageHoroscope Blog",
              "image": "",
              "description": "Blogs for astrology in Tamil! Explore Birth Horoscope, Marriage Matching, Baby Name suggestions, and more. Stay updated with our recent posts!",
              "brand": {
                "@type": "Brand",
                "name": "One Page Horoscope"
              }
            }
          `}
        </script>
      </Helmet>

      {/* Use Suspense to handle lazy-loaded components */}
      <Suspense fallback={<div>Loading...</div>}>
        <TopNavbar />
        <Menubar NavBgstyle="outer-header rjr" NavTextColor="text-dark" SubmenuTop="true" />
        <BlogListBanner />
        <BlogListCard />
        <Footer />
      </Suspense>
    </>
  );
}
