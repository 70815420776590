import React from 'react'
// import TamilJathagaBookCircle from '../../images/circle-illus-hb.webp'
// import TamilJathagaBookIllus from '../../images/book-illus-hb.webp'

export default function TamilJathagaBookBanner() {
    return (
        <>
            <section className="page-outer center hb">
                <div className="astro-container">
                    <div className="bhrow">
                        <div className="bhcol">
                            <div className="content">
                                <h1 className="sh lh-1 mb-0">
                                Tamil Jathagam Book<br className='d-lg-none'/> <span className="grt">தமிழ் ஜாதகம் புத்தகம்</span>
                                </h1>
                                <p className="p-outer mb-0">Tamil Jathagam Book</p>
                                <div className="button-circle-illus">
                                    <img src='https://ctsvsolutions.com/onepage-images/new-ui/jathaga-book-bg.webp' alt='jathaga book'/>
                                </div>
                                <div className="button-book-illus">
                                    <img src='https://ctsvsolutions.com/onepage-images/new-ui/book-illus-horoscope-book.webp' alt='horoscope book'/>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}
