import React, { lazy, Suspense } from 'react';
import { Helmet } from 'react-helmet';
import { useLocation } from 'react-router-dom';

// Lazy load the components
const TopNavbar = lazy(() => import('../../Main-Components/TopNavbar/TopNavbar'));
const Menubar = lazy(() => import('../../Main-Components/Menubar/Menubar'));
const Footer = lazy(() => import('../../Main-Components/Footer/Footer'));
const TermConditionBanner = lazy(() => import('../../Small-Components/TermConditionBanner/TermConditionBanner'));
const TermConditionContent = lazy(() => import('../../Small-Components/TermConditionContent/TermConditionContent'));

export default function TermConditionsView() {

    const location = useLocation();
    const canonical = () => {
        const base_url = "https://onepagehoroscope.com";
        const current_path = location.pathname;

        const canonicalPaths = {
            "/Terms-condition": "/Terms-condition",
        };

        return (
            <link
                rel="canonical"
                href={base_url + (canonicalPaths[current_path] || current_path)}
            />
        );
    };

    return (
        <>
            <Helmet>
                <title>Onepage Horoscope - Terms and Conditions</title>
                <meta
                    name="description"
                    content="One page horoscope values user security and strictly follows the terms and conditions."
                />
                <meta
                    name="keywords"
                    content="one page Horoscope, Terms and conditions"
                />
                <meta name="robots" content="index,follow" />
                {canonical()}
                <script type="application/ld+json">
                    {`
                    {
                      "@context": "https://schema.org/",
                      "@type": "Product",
                      "name": "OnepageHoroscope Terms and conditions",
                      "image": "",
                      "description": "One page horoscope values user security and strictly follows the terms and conditions.",
                      "brand": {
                        "@type": "Brand",
                        "name": "One Page Horoscope"
                      }
                    }
                    `}
                </script>
            </Helmet>

            {/* Suspense provides a fallback while components load */}
            <Suspense fallback={<div>Loading...</div>}>
                <TopNavbar />
                <Menubar NavBgstyle="outer-header bc-outer" NavTextColor="text-dark" SubmenuTop="true" />
                <TermConditionBanner />
                <TermConditionContent />
                <Footer />
            </Suspense>
        </>
    );
}
