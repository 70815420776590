import React, { useState } from 'react'
import TopNavbar from '../../Main-Components/TopNavbar/TopNavbar'
import Menubar from '../../Main-Components/Menubar/Menubar'
import Footer from '../../Main-Components/Footer/Footer'
import BlogListBanner from '../../Small-Components/BlogListBanner/BlogListBanner'
import BlogRecent from "../../Small-Components/BlogRecent/BlogRecent";
import BlogCategories from "../../Small-Components/BlogCategories/BlogCategories ";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {  useLocation } from 'react-router-dom'
import { faFacebook, faInstagram, faLinkedin, faTwitter } from '@fortawesome/free-brands-svg-icons'
import { Helmet } from 'react-helmet'
import axios from 'axios'
// import Gif from '../../images/love_percentage3.gif'

export default function LoveCalculatorBlog() {

    const location = useLocation();

    const canonical = () => {
        const base_url = "https://onepagehoroscope.com";
        const current_path = location.pathname;

        const canonicalPaths = {
            "/Love-Calculator": "/Love-Calculator",

        };

        return (
            <link
                rel="canonical"
                href={base_url + (canonicalPaths[current_path] || current_path)}
            />
        );
    };

    return (
        <>
            <Helmet>
                <title>Love Calculator</title>
                <meta
                    name="description"
                    content="Rasi Palan gives weekly horoscope insights for your zodiac sign, guiding you in career, finance, and health to navigate life's challenges."
                />


                {canonical()}

            </Helmet>
            <TopNavbar />
            <Menubar NavBgstyle="outer-header rjr" NavTextColor="text-dark" SubmenuTop="true" />
            <BlogListBanner />
            <LoveCalculatorBlogContent />
            <Footer />
        </>
    )
}

function LoveCalculatorBlogContent() {

    const [percentage, setpercentage] = useState(0);
    const [ShowOutput, setShowOutput] = useState(false);

    return (
        <>
            <section className="article-wrapper">
                <div className="astro-container pt-3">
                    <div className="row">
                        <div className="col-lg-8 my-2">
                            <div className="card bg-transparent border-0 blog-view">
                                <div className="card-header bg-transparent border-0 px-0">
                                    <h1 className="fs-4 blog-view-heading">
                                        Love Calculator | Find a match

                                    </h1>
                                </div>
                                <div className="card-body pt-0 px-0">
                                    <div className="my-2 d-flex">
                                        <a href="https://www.facebook.com/onepagehoroscope/">
                                            <div className="view-blog-icons facebook">
                                                <FontAwesomeIcon
                                                    icon={faFacebook}
                                                    className="text-white px-2"
                                                />
                                            </div>
                                        </a>
                                        <a href="https://www.instagram.com/one_page_horoscope/">
                                            <div className="view-blog-icons instagram">
                                                <FontAwesomeIcon
                                                    icon={faInstagram}
                                                    className="text-white px-2"
                                                />
                                            </div>
                                        </a>
                                        <a href="https://twitter.com/onepage321">
                                            <div className="view-blog-icons twitter">
                                                <FontAwesomeIcon icon={faTwitter} className="text-white px-2" />
                                            </div>
                                        </a>
                                        <a href="https://linkedin.com/showcase/97871433/admin/feed/posts/">
                                            <div className="view-blog-icons linkedin">
                                                <FontAwesomeIcon
                                                    icon={faLinkedin}
                                                    className="text-white px-2"
                                                />
                                            </div>
                                        </a>
                                    </div>
                                    <div className="my-2">
                                        <img
                                            src="https://ctsvsolutions.com/onepage-images/marriage-matching.webp"
                                            alt="Weekly-rasi-palan"
                                            style={{ height: "400px", width: '100%' }}
                                            className="img-fluid rounded"
                                        />
                                    </div>
                                    <div className="description mt-3">
                                        <p className="mb-3">

                                            உங்களது காதல் பொருத்தம் எப்படி உள்ளது என்பதை நீங்களே பார்த்து கொள்ளுங்கள்....   </p>
                                        <p>
                                            நமது நாட்டின் பண்பாடுகளில் (Marriage matching) மிகவும் அற்புதமானது ஆண் - பெண் ஜாதகப் பொருத்தம் பார்ப்பது. இந்த ஜாதக பொருத்தம் பார்ப்பது காலம் காலமாக தொண்று தொண்டு இருந்து வரும் நடைமுறையாகும்.
                                        </p>
                                        <div>
                                            <div className="my-5">
                                                <LoveCaculatorForm setpercentage={setpercentage} setShowOutput={setShowOutput}>

                                                </LoveCaculatorForm>
                                            </div>
                                            {ShowOutput && (
                                                <div className="my-5">
                                                    <LoveCalculatorOutput percentage={percentage} >

                                                    </LoveCalculatorOutput>
                                                </div>
                                            )}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-4 my-2">
                            <div className="row">
                                <BlogRecent />
                                <BlogCategories />
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}

function LoveCaculatorForm({ setpercentage, setShowOutput }) {
    const [maleName, setMaleName] = useState('');
    const [femaleName, setFemaleName] = useState('');
    const [maleRasi, setMaleRasi] = useState('');
    const [FemaleRasi, setFemaleRasi] = useState('');
    const [malestar, setMalestar] = useState('');
    const [Femalestar, setFemalestar] = useState('');
    const [MaleNameError, setMaleNameError] = useState('');
    const [FemaleNameError, setFeNameError] = useState('');
    const [MaleRasiError, setMaleRasiError] = useState('');
    const [feMaleRasiError, setfeMaleRasiError] = useState('');
    const [MalestarError, setMalestarError] = useState('');
    const [feMalestarError, setfeMalestarError] = useState('');

    const ValidationError = () => {
        setShowOutput(false);
        let IsValid = true;
        if (maleName === "") {
            setMaleNameError('Name is Required');
            IsValid = false;
        } else {
            setMaleNameError('');
        }
        if (femaleName === "") {
            setFeNameError('Name is Required');
            IsValid = false;
        } else {
            setFeNameError('');
        }
        if (maleRasi === "") {
            setMaleRasiError('Rasi is Required');
            IsValid = false;
        } else {
            setMaleRasiError('');
        }
        if (FemaleRasi === "") {
            setfeMaleRasiError('Rasi is Required');
            IsValid = false;
        } else {
            setfeMaleRasiError('');
        }
        if (malestar === "") {
            setMalestarError('Star is Required');
            IsValid = false;
        } else {
            setMalestarError('');
        }
        if (Femalestar === "") {
            setfeMalestarError('Star is Required');
            IsValid = false;
        } else {
            setfeMalestarError('');
        }
        return IsValid;
    }
    const CalculateLove = () => {

        if (ValidationError() === true) {
            const Data = {
                'maleName': maleName,
                'femaleName': femaleName,
                'MaleRasi': maleRasi,
                'FemaleRasi': FemaleRasi,
                'MaleStar': malestar,
                'FemaleStar': Femalestar
            };
            axios.post('https://ctsvsolutions.com/onepage-Api/public/api/Love-calculator', Data)
                .then(response => {
                    const percentage = response.data.percentage;
                    setpercentage(percentage);
                    setShowOutput(true);
                })
                .catch(error => {
                    console.error('Error:', error);
                    // Handle errors here
                });
        }
    };
    return (
        <>
            <div className="card border-0">
                <div className="row">
                    <div className="col-md-6 col-12 mt-2">
                        <div className="card" >
                            <div className="card-header text-center text-white fs-5" style={{ backgroundColor: "#121481" }}>
                                <p>ஆண் </p>
                            </div>
                            <div className="card-body">
                                <div className="row">
                                    <div className="col-12">
                                        <div className="form-group">
                                            <label htmlFor=" ">பெயர் </label>
                                            <input type="text" placeholder="பெயர்" className="form-control" onChange={(e) => setMaleName(e.target.value)} />
                                            {MaleNameError &&
                                                <span className="text-danger">{MaleNameError}</span>
                                            }
                                        </div>
                                    </div>
                                </div>
                                <div className="row mt-3">
                                    <div className="col-6">
                                        <div className="form-group">
                                            <label htmlFor="">ராசி</label>
                                            <select className="form-select" aria-label="Default select example" onChange={(e) => { setMaleRasi(e.target.value) }}>
                                                <option value="" >ராசி</option>
                                                <option value="மேஷம்">மேஷம் </option>
                                                <option value="ரிஷபம்">ரிஷபம் </option>
                                                <option value="மிதுனம்">மிதுனம் </option>
                                                <option value="கடகம்">கடகம் </option>
                                                <option value="சிம்மம்">சிம்மம் </option>
                                                <option value="கன்னி">கன்னி </option>
                                                <option value="துலாம்">துலாம் </option>
                                                <option value="விருச்சகம்">விருச்சகம் </option>
                                                <option value="தனுசு">தனுசு </option>
                                                <option value="மகரம்">மகரம் </option>
                                                <option value="கும்பம்">கும்பம் </option>
                                                <option value="மீனம்">மீனம் </option>
                                            </select>
                                            {MaleRasiError &&
                                                <span className="text-danger">{MaleRasiError}</span>
                                            }
                                        </div>

                                    </div>
                                    <div className="col-6">
                                        <div className="form-group">
                                            <label htmlFor="">நட்சத்திரம் </label>
                                            <select className="form-select" aria-label="Default select example" onChange={(e) => { setMalestar(e.target.value) }}>
                                                <option value="" >நட்சத்திரம்</option>
                                                <option value="அஸ்வினி">அஸ்வினி </option>
                                                <option value="பரணி">பரணி </option>
                                                <option value="கார்த்திகை">கார்த்திகை </option>
                                                <option value="ரோகினி">ரோகினி </option>
                                                <option value="மிருகசீரிஷம்">மிருகசீரிஷம் </option>
                                                <option value="திருவாதிரை">திருவாதிரை </option>
                                                <option value="புனர்பூசம்">புனர்பூசம் </option>
                                                <option value="பூசம்">பூசம் </option>
                                                <option value="ஆயில்யம்">ஆயில்யம் </option>
                                                <option value="மகம்">மகம் </option>
                                                <option value="பூரம்">பூரம் </option>
                                                <option value="உத்திரம்">உத்திரம் </option>
                                                <option value="அஸ்தம்">அஸ்தம் </option>
                                                <option value="சித்திரை">சித்திரை </option>
                                                <option value="சுவாதி">சுவாதி </option>
                                                <option value="விசாகம்">விசாகம் </option>
                                                <option value="அனுஷம்">அனுஷம் </option>
                                                <option value="கேட்டை">கேட்டை </option>
                                                <option value="மூலம்">மூலம் </option>
                                                <option value="பூராடம்">பூராடம் </option>
                                                <option value="திருவோணம்">திருவோணம் </option>
                                                <option value="அவிட்டம்">அவிட்டம்</option>
                                                <option value="சதயம்">சதயம்</option>
                                                <option value="பூரட்டாதி">பூரட்டாதி</option>
                                                <option value="உத்திரட்டாதி">உத்திரட்டாதி</option>
                                                <option value="ரேவதி">ரேவதி</option>
                                            </select>
                                            {MalestarError &&
                                                <span className="text-danger">{MalestarError}</span>
                                            }
                                        </div>
                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>
                    <div className="col-md-6 col-12 mt-2">
                        <div className="card">
                            <div className="card-header text-center text-white fw-bold fs-5" style={{ backgroundColor: '#F27BBD' }}>
                                <p>பெண்</p>
                            </div>
                            <div className="card-body">
                                <div className="row">
                                    <div className="col-12">
                                        <div className="form-group">
                                            <label htmlFor="">பெயர் </label>
                                            <input type="text" placeholder="பெயர்" className="form-control" onChange={(e) => { setFemaleName(e.target.value) }} />
                                            {FemaleNameError &&
                                                <span className="text-danger">{FemaleNameError}</span>
                                            }
                                        </div>
                                    </div>

                                </div>
                                <div className="row mt-3">
                                    <div className="col-6">
                                        <div className="form-group">
                                            <label htmlFor="">ராசி</label>
                                            <select className="form-select" aria-label="Default select example" onChange={(e) => { setFemaleRasi(e.target.value) }}>
                                                <option value="" selected>ராசி</option>
                                                <option value="மேஷம்">மேஷம் </option>
                                                <option value="ரிஷபம்">ரிஷபம் </option>
                                                <option value="மிதுனம்">மிதுனம் </option>
                                                <option value="கடகம்">கடகம் </option>
                                                <option value="சிம்மம்">சிம்மம் </option>
                                                <option value="கன்னி">கன்னி </option>
                                                <option value="துலாம்">துலாம் </option>
                                                <option value="விருச்சகம்">விருச்சகம் </option>
                                                <option value="தனுசு">தனுசு </option>
                                                <option value="மகரம்">மகரம் </option>
                                                <option value="கும்பம்">கும்பம் </option>
                                                <option value="மீனம்">மீனம் </option>
                                            </select>
                                            {feMaleRasiError &&
                                                <span className="text-danger">{feMaleRasiError}</span>
                                            }
                                        </div>

                                    </div>
                                    <div className="col-6">
                                        <div className="form-group">
                                            <label htmlFor="">நட்சத்திரம் </label>
                                            <select className="form-select" aria-label="Default select example" onChange={(e) => { setFemalestar(e.target.value) }}>
                                                <option value="" >நட்சத்திரம்</option>
                                                <option value="அஸ்வினி">அஸ்வினி </option>
                                                <option value="பரணி">பரணி </option>
                                                <option value="கார்த்திகை">கார்த்திகை </option>
                                                <option value="ரோகினி">ரோகினி </option>
                                                <option value="மிருகசீரிஷம்">மிருகசீரிஷம் </option>
                                                <option value="திருவாதிரை">திருவாதிரை </option>
                                                <option value="புனர்பூசம்">புனர்பூசம் </option>
                                                <option value="பூசம்">பூசம் </option>
                                                <option value="ஆயில்யம்">ஆயில்யம் </option>
                                                <option value="மகம்">மகம் </option>
                                                <option value="பூரம்">பூரம் </option>
                                                <option value="உத்திரம்">உத்திரம் </option>
                                                <option value="அஸ்தம்">அஸ்தம் </option>
                                                <option value="சித்திரை">சித்திரை </option>
                                                <option value="சுவாதி">சுவாதி </option>
                                                <option value="விசாகம்">விசாகம் </option>
                                                <option value="அனுஷம்">அனுஷம் </option>
                                                <option value="கேட்டை">கேட்டை </option>
                                                <option value="மூலம்">மூலம் </option>
                                                <option value="பூராடம்">பூராடம் </option>
                                                <option value="திருவோணம்">திருவோணம் </option>
                                                <option value="அவிட்டம்">அவிட்டம்</option>
                                                <option value="சதயம்">சதயம்</option>
                                                <option value="பூரட்டாதி">பூரட்டாதி</option>
                                                <option value="உத்திரட்டாதி">உத்திரட்டாதி</option>
                                                <option value="ரேவதி">ரேவதி</option>
                                            </select>
                                            {feMalestarError &&
                                                <span className="text-danger">{feMalestarError}</span>
                                            }
                                        </div>
                                    </div>
                                </div>

                            </div>

                        </div>
                    </div>
                    <div className="row mt-2">
                        <div className="col-12 d-flex justify-content-center" >
                            <div className="row">
                                <button className="btn btn-primary px-5 py-3 border-0" style={{ backgroundColor: '#D20062' }} onClick={() => CalculateLove()}>
                                    calculate
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}
function LoveCalculatorOutput({ percentage }) {


    return (
        <div className="card mt-4 border-0" >
            <div className="card-header text-center" style={{ backgroundColor: '#8B93FF' }}>
                <h3 className="text-white fw-bold"> Your Love percentage</h3>
            </div>
            <div className="card-body border-0 ">
                <div className="text-center position-relative">
                    <img src='https://ctsvsolutions.com/onepage-images/new-ui/love_percentage.gif' alt="" className="img-fluid " style={{ height: "280px" }} />
                    <p className="position-absolute top-50 start-50 translate-middle fs-1 text-white fw-bold" >{percentage}%</p>
                </div>
                <hr style={{ border: '3px solid #D6589F' }} />
            </div>

        </div>

    );
}