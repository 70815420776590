import React from 'react'
import TopNavbar from '../../Main-Components/TopNavbar/TopNavbar'
import Menubar from '../../Main-Components/Menubar/Menubar'
import Footer from '../../Main-Components/Footer/Footer'
import BlogFilterBanner from '../../Small-Components/BlogFilterBanner/BlogFilterBanner'
import '../../style/blog.css'
import BlogFilterView from '../../Small-Components/BlogFilterView/BlogFilterView'

export default function BlogFilter() {
  return (
    <>
      <TopNavbar />
       <Menubar NavBgstyle="outer-header rjr" NavTextColor="text-dark" SubmenuTop="true" />
        <BlogFilterBanner/>
        <BlogFilterView/>
       <Footer/>
    </>
  )
}
