import axios from 'axios';


export const OnePageApiResponse = ( UserData) => {
  const Printdata = UserData;
  const dateComponents = Printdata.birth_date.split("-");
  const day = dateComponents[0];
  const month = dateComponents[1];
  const year = dateComponents[2];

  const formattedDate = `${year}-${month}-${day}`;
  const formdata = {
    username: Printdata.name,
    gender: Printdata.gender,
    userDateofBirth: formattedDate,
    userBirthtime: Printdata.birth_time,
    userBirthplace: Printdata.birth_place,
    latitude: Printdata.latitude,
    longitude: Printdata.longitude,
  };
  axios
  .post(
    "https://ctsvsolutions.com/onepage-Api/public/api/Onepage-Horoscope",
    formdata
  )
  .then((response) => {
    if (response.data.status === true) {
      const ApiResponse = response.data.data;
      const ApiResponseString = JSON.stringify(ApiResponse);
      localStorage.setItem('OfferOnepage', ApiResponseString);
    }
  })
  .catch((error) => {
    console.error("Error in Axios request:", error);
  });

};

export const MarriageMattchingApiResponse=(data)=>{
  const BirthDate=data.birth_date.split(',');
  const maleBirthDate =BirthDate[0].split("-");
  const FemaleBirthDate = BirthDate[1].split("-");
  const State = data.birth_place.split(",");
  const MaleState = State[0];
  const FemaleState = State[1];
  const Latitude = data.latitude.split(",");
  const Longitude = data.longitude.split(",");
  const BirthTime = data.birth_time.split(",");
  const MaleBirthTime = BirthTime[0];
  const FemaleBirthTime = BirthTime[1];
  const MaleLatitude = Latitude[0];
  const FemaleLatitude = Latitude[1];
  const femaleLongitude = Longitude[0];
  const MaleLogitude = Longitude[1];
  const Name = data.name.split(",");
  const MaleName = Name[0];
  const FemaleName = Name[1];
  const BirthPlace = data.birth_place.split(",");
  const MaleBirthPlace = BirthPlace[0] +','+MaleState;
  const femaleBirthplace = BirthPlace[1] +','+FemaleState;
  const Maleday = maleBirthDate[2];
  const Malemonth = maleBirthDate[1];
  const Maleyear = maleBirthDate[0];
  const Femaleday = FemaleBirthDate[2];
  const FemaleMonth = FemaleBirthDate[1];
  const FemaleYear = FemaleBirthDate[0];
  const femaleDatateofBirth = `${FemaleYear}-${FemaleMonth}-${Femaleday}`;
  const MaleDateofBirth = `${Maleyear}-${Malemonth}-${Maleday}`;

  const marriageMatchingFormData = {
    maleName: MaleName,
    MaleDateofBirth: MaleDateofBirth,
    MaleBirthtime: MaleBirthTime,
    MaleBirthplace: MaleBirthPlace,
    Malelatitude: MaleLatitude,
    Malelongitude: MaleLogitude,
    FemaleName: FemaleName,
    femaleBirthplace: femaleBirthplace,
    feMaleDateofBirth: femaleDatateofBirth,
    FemaleBirthTime: FemaleBirthTime,
    femalelatitude: FemaleLatitude,
    femalelongitude: femaleLongitude,
  };
  // console.log("valur",marriageMatchingFormData);
  axios
    .post(
      "https://ctsvsolutions.com/onepage-Api/public/api/Marriage-Matching",
      marriageMatchingFormData
    )
    .then((response) => {
      const ApiResponse = response.data.data;
      const ApiResponseString = JSON.stringify(ApiResponse);
      localStorage.setItem('OfferMg', ApiResponseString);
    })

}

export const BabyNameResponse = async (data) => {
  const dateComponents = data.birth_date.split("-");
  const selectedDay = dateComponents[0];
  const selectedMonth = dateComponents[1];
  const selectedYear = dateComponents[2];
  const formattedDate = `${selectedYear}-${selectedMonth}-${selectedDay}`;

  const BabyNameList = {
    BirthMonth: selectedMonth,
    BirthYear: selectedYear,
    BirthDay: selectedDay,
    BirthDate: formattedDate,
    BirthTime: data.birth_time,
    BirthPlace: data.birth_place + "," + data.state,
    initial: data.initial,
    father_name: data.father_name,
    mother_name: data.mother_name,
    gender: data.gender,
    latitude: data.latitude,
    longitude: data.longitude,
  };

  try {
     axios.post(
      "https://ctsvsolutions.com/onepage-Api/public/api/Baby-Name-List",
      BabyNameList
    ).then((response) => {
      const ApiResponse = response.data.data;
      const ApiResponseString = JSON.stringify(ApiResponse);
      localStorage.setItem('offer_baby', ApiResponseString);
    })
        
  } catch (error) {
    console.error("Error while making the request:", error);
  }
};