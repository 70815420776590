import React from 'react'
import TopNavbar from '../../Main-Components/TopNavbar/TopNavbar'
import Menubar from '../../Main-Components/Menubar/Menubar'
import Footer from '../../Main-Components/Footer/Footer'
import BlogListBanner from '../../Small-Components/BlogListBanner/BlogListBanner'
import BlogRecent from "../../Small-Components/BlogRecent/BlogRecent";
import BlogCategories from "../../Small-Components/BlogCategories/BlogCategories ";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Link, useLocation } from 'react-router-dom'
import { faFacebook, faInstagram, faLinkedin, faTwitter } from '@fortawesome/free-brands-svg-icons'
import { Helmet } from 'react-helmet'
import MarriageMatchingForm from "../../Forms/MarriageMatchingForm/MarriageMatchingForm";
import MarriageMatchingContextProvider from "../../context/forms/MarriageMatching_contexProvider"
export default function JathagaporuthamBlog() {

    const location = useLocation();
    const canonical = () => {
        const base_url = "https://onepagehoroscope.com";
        const current_path = location.pathname;

        const canonicalPaths = {
            "/Blog/Importance-of-Jathagam-Porutham": "/Blog/Importance-of-Jathagam-Porutham",

        };

        return (
            <link
                rel="canonical"
                href={base_url + (canonicalPaths[current_path] || current_path)}
            />
        );
    };

    return (
        <>
            <Helmet>
                <title> </title>
                <meta
                    name="description"
                    content="Explore jathagam porutham insights! Discover compatibility predictions and matches tailored for you. Find your perfect connection with our accurate astrology analysis."
                />
                <meta
                    name="keywords"
                    content="Jathagam Porutham"
                />
                <meta name="robots" content="index,follow" />
                <meta property="og:title" content="Importance of jathagam porutham in Marriage Life " />
                <meta property="og:description" content="Explore jathagam porutham insights! Discover compatibility predictions and matches tailored for you. Find your perfect connection with our accurate astrology analysis." />
                <meta property="og:image" content="https://ctsvsolutions.com/onepage_admin/onepage-images/Importance-of-Jathagam-Porutham.webp" />
                <meta property="og:url" content="https://onepagehoroscope.com/Blog//Blog/Importance-of-Jathagam-Porutham" />
                <meta name="twitter:card" content="summary_large_image" />
                <meta name="twitter:title" content="Importance of jathagam porutham in Marriage Life" />
                <meta name="twitter:description" content="Explore jathagam porutham insights! Discover compatibility predictions and matches tailored for you. Find your perfect connection with our accurate astrology analysis." />
                <meta name="twitter:image" content="https://ctsvsolutions.com/onepage_admin/onepage-images/Importance-of-Jathagam-Porutham.webp" />
                <meta name="twitter:url" content="https://onepagehoroscope.com/Blog//Blog/Importance-of-Jathagam-Porutham" />
                {canonical()}
                <script type="application/ld+json">
                    {JSON.stringify({

                        "@context": "https://schema.org",
                        "@type": "BlogPosting",
                        "mainEntityOfPage": {
                            "@type": ".webpage",
                            "@id": "https://onepagehoroscope.com/Blog/Importance-of-Jathagam-Porutham"
                        },
                        "headline": "Importance of jathagam porutham in Marriage Life",
                        "description": "Explore jathagam porutham insights! Discover compatibility predictions and matches tailored for you. Find your perfect connection with our accurate astrology analysis.",
                        "image": "https://ctsvsolutions.com/onepage_admin/onepage-images/Importance-of-Jathagam-Porutham.webp",
                        "author": {
                            "@type": "",
                            "name": ""
                        },
                        "publisher": {
                            "@type": "Organization",
                            "name": "",
                            "logo": {
                                "@type": "ImageObject",
                                "url": ""
                            }
                        },
                        "datePublished": "2024-04-29"
                    })}
                </script>
            </Helmet>
            <TopNavbar />
            <Menubar NavBgstyle="outer-header rjr" NavTextColor="text-dark" SubmenuTop="true" />
            <BlogListBanner />
            <JathagaporuthamBlogContent />
            <Footer />
        </>
    )
}

function JathagaporuthamBlogContent() {
    return (
        <>
            <section className="article-wrapper">
                <div className="astro-container pt-3">
                    <div className="row">
                        <div className="col-lg-8 my-2">
                            <div className="card bg-transparent border-0 blog-view">
                                <div className="card-header bg-transparent border-0 px-0">
                                    <h1 className="fs-4 blog-view-heading">
                                        Importance of jathagam porutham in Marriage Life
                                    </h1>
                                </div>
                                <div className="card-body pt-0 px-0">
                                    <div className="my-2">
                                        <img
                                            src="https://ctsvsolutions.com/onepage_admin/onepage-images/Importance-of-Jathagam-Porutham.png"
                                            alt="Life Horoscope In tamil"
                                            className="img-fluid rounded"
                                        />
                                    </div>
                                    <div className="my-4 d-flex">
                                        <a href="https://www.facebook.com/onepagehoroscope/">
                                            <div className="view-blog-icons facebook">
                                                <FontAwesomeIcon
                                                    icon={faFacebook}
                                                    className="text-white px-2"
                                                />
                                            </div>
                                        </a>
                                        <a href="https://www.instagram.com/one_page_horoscope/">
                                            <div className="view-blog-icons instagram">
                                                <FontAwesomeIcon
                                                    icon={faInstagram}
                                                    className="text-white px-2"
                                                />
                                            </div>
                                        </a>
                                        <a href="https://twitter.com/onepage321">
                                            <div className="view-blog-icons twitter">
                                                <FontAwesomeIcon icon={faTwitter} className="text-white px-2" />
                                            </div>
                                        </a>
                                        <a href="https://linkedin.com/showcase/97871433/admin/feed/posts/">
                                            <div className="view-blog-icons linkedin">
                                                <FontAwesomeIcon
                                                    icon={faLinkedin}
                                                    className="text-white px-2"
                                                />
                                            </div>
                                        </a>
                                    </div>
                                    <div className="description mt-3">
                                        <p className="mb-3">

                                            ஜாதக பொருத்தம் மூலம் திருமண வாழ்க்கையில்  <Link to="/Marriage-matching" className="text-decoration-none">
                                                <span className="text-danger">(Jathagam Porutham)</span>
                                            </Link> பொருத்தமான இணைவு மற்றும் நிம்மதியான உறவுகளை ஏற்படுத்த முடியும். இந்த பதிவில் ஜாதக பொருத்தத்தின் முக்கியத்துவம் மற்றும் பயன்களை பார்க்கலாம்.
                                        </p>
                                        <div>
                                        <MarriageMatchingContextProvider>
                                               <MarriageMatchingForm BlogStatus={true} />
                                        </MarriageMatchingContextProvider >
                                        </div>
                                        {/* <MarriageMatching_form></MarriageMatching_form> */}
                                        <div>
                                            <h2 className="mb-3 fs-5 blog-view-heading mt-3">
                                                ஜாதக பொருத்தம் பார்க்கும் முறை: எளிய வழிமுறைகள்:
                                            </h2>
                                            <p className="description-content mb-3">
                                                ஜாதக பொருத்தம் பார்க்கும் முறை எளிய மற்றும் சுலபமானதாக இருக்கும். இதன் மூலம் உங்கள் திருமணத்தின் மேற்றியலைக் கண்டறிந்து, உங்கள் துணையோடு பொருத்தமான இணைவை உருவாக்க முடியும். இதற்கான எளிய வழிமுறைகள்:
                                            </p>
                                            <ul className="blog-view-list">
                                                <li>
                                                    <strong>ஜாதகக் கட்டம்: </strong>
                                                    <p>முதலில், உங்கள் பிறந்த நாளான ஜாதகக் கட்டத்தை பெற்றுக்கொள்ள வேண்டும். இது உங்கள் பிறந்த நேரம், இடம் மற்றும் தேதியை அடிப்படையாகக் கொண்டு தயாரிக்கப்படுகிறது.</p>
                                                </li>
                                                <li>
                                                    <strong>திருமண பொருத்தம்:</strong>
                                                    <p>ஜாதகக் கட்டங்களை ஒன்றாக ஒப்பிடவும். இதன் மூலம், நக்ஷத்திர, ராசி, யோகங்கள் போன்றவற்றைப் பார்த்து, உங்களது துணையோடு பொருந்தும் நிலைகளை மதிப்பீடு செய்யலாம்.</p>
                                                </li>
                                                <li>
                                                    <strong>நவாஷ்ச மற்றும் ராசியங்களை பார்வையிடுக:</strong>
                                                    <p>இரு ஜாதகக் கட்டங்களில் உள்ள நவாஷ்ச மற்றும் ராசியங்களை ஆய்வு செய்ய வேண்டும். இதன் மூலம், உங்கள் உறவுகள் மற்றும் பொதுவான ஆயுள் வழிமுறைகளை உணர முடியும்.</p>
                                                </li>
                                                <li>
                                                    <strong>கிரகங்ககளின்  ஆய்வு:</strong>
                                                    <p>இரு ஜாதகக் கட்டங்களில் உள்ள  கிரகங்களைப் பார்க்கவும். இது உங்கள் திருமண வாழ்க்கையில் ஏற்படும் சவால்களைக் கண்டு, அதற்கான தீர்வுகளை அறிய உதவும்.</p>
                                                </li>
                                                <li>
                                                    <strong>சரியான உத்தரவாதங்கள்:</strong>
                                                    <p>ஜாதக பொருத்தம் பார்க்கும்போது, அதனை ஒருவரது தனிப்பட்ட நிலைகள் மற்றும் மனநிலைகளை பார்வையிடவும். இதன் மூலம், உங்கள் உறவுகளில் அமைதி மற்றும் நிம்மதி அடைய முடியும்.</p>
                                                </li>
                                                <li>
                                                    <strong>ஆலோசகர்களின் உதவியைப் பெறுங்கள்</strong>
                                                    <p>ஜாதகப் பொருத்தம் பார்வையிடும் போது, ஒரு பிரமாணமான ஜோதிட ஆலோசகரின் உதவியைப் பெறலாம். இதன் மூலம், உங்கள் திருமண வாழ்க்கையின் பல்வேறு முகவுகளை சரியாக புரிந்து கொள்வீர்கள்.</p>
                                                </li>
                                            </ul>
                                        </div>
                                        <div>
                                            <h2 className="mb-3 fs-5 blog-view-heading">
                                                ஜாதக பொருத்தம் எவ்வளவு முக்கியம்?
                                            </h2>
                                            <p className="description-content mb-3">
                                                ஜாதக பொருத்தம் திருமணத்தின் பின்னணியில் முக்கியமான அம்சமாகும். இது இரு நபர்களின் ஜாதகக் கட்டங்களை ஒப்பிட்டு, அவர்களின் குணாதிசயங்கள், யோகங்கள் மற்றும் கிரகங்களின் செல்வாக்குகளை மதிப்பீடு செய்ய உதவுகிறது.
                                            </p>
                                            <ul className="blog-view-list">
                                                <li>
                                                    <strong>உங்கள் வாழ்க்கை துணியை புரிதல்</strong>
                                                    <p>ஜாதகப் பொருத்தம் மூலம், இருவருக்கும் அமைதியான மற்றும் ஆரோக்கியமான உறவுகளை உருவாக்க முடியும்.</p>
                                                </li>
                                                <li>
                                                    <strong>நிம்மதியான வாழ்க்கை:</strong>
                                                    <p>சரியான பொருத்தம் மூலம், நீங்கள் தன்னம்பிக்கையுடன் இணைவதை அனுபவிக்கலாம்.</p>
                                                </li>
                                                <li>
                                                    <strong>துணையின் தன்மைகள்: </strong>
                                                    <p>இரு ஜாதகக் கட்டங்களில் உள்ள நவாஷ்ச மற்றும் ராசியங்களை ஆய்வு செய்ய வேண்டும். இதன் மூலம், உங்கள் உறவுகள் மற்றும் பொதுவான ஆயுள் வழிமுறைகளை உணர முடியும்.</p>
                                                </li>
                                                <li>
                                                    <strong>கிரகங்ககளின்  ஆய்வு:</strong>
                                                    <p>உங்கள் துணையின் குணாதிசயங்களை அறிவதால், அதிக நெருக்கமான உறவை உருவாக்கலாம்.</p>
                                                </li>
                                            </ul>
                                        </div>
                                        <div>
                                            <h2 className="mb-3 fs-5 blog-view-heading">
                                                ஜாதக பொருத்தம் தவிர வேறு என்ன பார்க்க வேண்டும்?
                                            </h2>
                                            <p className="description-content mb-3">
                                                ஜாதக பொருத்தம் தவிர, திருமணத்திற்கு முன்னர் பார்க்க வேண்டிய பல முக்கியமான அம்சங்கள் உள்ளன.
                                            </p>
                                            <ul className="blog-view-list">
                                                <li>
                                                    <strong>தொழில் மற்றும் கல்வி: </strong>
                                                    <p>இருவரின் தொழில்நுட்பத்தில் அல்லது கல்வியில் பொருந்துதல் முக்கியமாகும். இதனால் வாழ்க்கை நோக்கங்கள், உயர்ந்த இலக்குகள் போன்றவற்றில் ஒரே தூரத்தை அடைய முடியும்.</p>
                                                </li>
                                                <li>
                                                    <strong>குணாதிசயங்கள் மற்றும் படிப்பினை</strong>
                                                    <p>ஒருவருக்கொருவர் மகிழ்ச்சியுள்ளதாக உணர, ஒரே கண்காணிப்புகள் மற்றும் கவனிப்புகள் இருப்பது முக்கியம்.</p>
                                                </li>
                                                <li>
                                                    <strong>உறவுகள்:</strong>
                                                    <p>இருவரின் குடும்ப பின்புலங்கள், தோழமைகள், மற்றும் சமூகப்பின்னல்களைப் பார்க்க வேண்டும். இதனால், இருவருக்கும் பொதுவான நண்பர்கள் மற்றும் குடும்ப உறவுகள் இருந்தால், திருமண வாழ்க்கை மேலும் பலப்படுத்தப்படும்.</p>
                                                </li>
                                                <li>
                                                    <strong>ஆர்வங்கள் மற்றும் விருப்பங்கள்</strong>
                                                    <p>இருவரும் ஒரே ஆர்வங்களைப் பகிர்ந்து கொள்வதன் மூலம், திருமண வாழ்க்கையை மேலும் மகிழ்ச்சியுடன் நடத்த முடியும்.</p>
                                                </li>
                                            </ul>
                                            <p>இந்த அம்சங்களை கவனத்தில் கொண்டு, நீங்கள் உங்கள் திருமண வாழ்க்கையை மேம்படுத்த முடியும்.</p>
                                        </div>

                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-4 my-2">
                            <div className="row">
                                <BlogRecent />
                                <BlogCategories />
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}