import React from 'react'

export default function BiodataPaymentSuccessBanner() {
    return (
        <>
            <section className="page-outer center bc-outer bcr pb-5">
                <div className="astro-container">
                    <div className="row">
                        <div className="col">
                            <div className="content">
                                <h1 className="sh lh-1">
                                    Horoscope <span className="grt">Marriage Biodata Payment Success</span>
                                </h1>
                                <p className="p-outer">Payment Success</p>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}
