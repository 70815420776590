import React, { useEffect, useState, useContext } from 'react';
import Slider from 'react-slick';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import '../../style/mystyle.css';
import axios from "axios";
import { Loading } from "notiflix/build/notiflix-loading-aio";
import { Notify } from "notiflix/build/notiflix-notify-aio";
import { Report } from "notiflix/build/notiflix-report-aio";
import BioTemplate4 from "../../../../components/tamil/BioData-Templates/BioTemplate4";
import BioTemplate5 from "../../../../components/tamil/BioData-Templates/BioTemplate5";
import BioTemplate6 from "../../../../components/tamil/BioData-Templates/BioTemplate6";
import BioTemplate2 from "../../../../components/tamil/BioData-Templates/BioTemplate2";
import BioTemplate3 from "../../../../components/tamil/BioData-Templates/BioTemplate3";
import { Confirm } from "notiflix/build/notiflix-confirm-aio";
// import getepayPortal from "../../../../components/tamil/Getepay_pg_react";
import html2canvas from "html2canvas";
import BioTemplate1 from "../../../../components/tamil/BioData-Templates/BioTemplate1";
import html2pdf from "html2pdf.js/dist/html2pdf.min";
import ReactDOM from "react-dom";
import { BioViewContext } from '../../context/viewpage/BioViewContextProvider';
import { RazerpayPaymentGateway } from '../../payment-gateway/RazerpayGateway';
// import CloseImage from '../../images/popup-cross-icon.webp';

function BioViewSlider() {
  const { personalInfo, value } = useContext(BioViewContext);
  const [showPopup, setShowPopup] = useState(false);
  const [email, setEmail] = useState(personalInfo.email);
  const [buyerMobilenumber, setMobilenumber] = useState(
    personalInfo.whatsapp_number
  );

  const [mobilenumberError, setmobilenumberError] = useState("");
  const [emailError, setEmailError] = useState("");
  const [buyerName, setBuyername] = useState('');
  const [buyerNameError, setBuyerNameError] = useState("");
  const [currentTemplate, setTemplate] = useState("");

  const Template = localStorage.getItem("paidTemplate");
  useEffect(() => {
    if (Template) {
      setpaidTemplate(Template);
    }
  }, [Template]);

  const [paidTemplate, setpaidTemplate] = useState(Template ? Template : "");

  const handlepay = () => {
    if (validatePopupFileld()) {
      insertPaidData();
    }
  };

  const validatePopupFileld = () => {
    let isValid = true;
    if (!email) {
      setEmailError("Email is required");
      isValid = false;
    } else {
      setEmailError("");
    }
    if (!buyerName) {
      setBuyerNameError("Name is required");
      isValid = false;
    } else {
      setBuyerNameError("");
    }
    if (buyerMobilenumber.length < 10) {
      setmobilenumberError("Mobile number must be 10 digits");
      isValid = false;
    } else {
      setmobilenumberError("");
    }
    return isValid;
  };
  const checkTemplate = () => {
    if (paidTemplate === "Template1") {
      return "BiosampleTemplate1";
    } else if (paidTemplate === "Template2") {
      return "BiosampleTemplate2";
    } else if (paidTemplate === "Template3") {
      return "BiosampleTemplate3";
    } else if (paidTemplate === "Template4") {
      return "BiosampleTemplate4";
    } else if (paidTemplate === "Template5") {
      return "BiosampleTemplate5";
    } else {
      return "BiosamleTemplate6";
    }
  };

  const paymentProcess = async(user_id, customerDataId) => {

    Loading.dots();
    setShowPopup(false);
    const payment_Data = {
        amount: '249',
        email: email,
        description: 'Bio Data',
        name: buyerName,
        mobileNumber: buyerMobilenumber,
        user_id: user_id,
        theme: '#FBFAE1',
        customerDataId: customerDataId,
         paymentCallBackurl: 'https://ctsvsolutions.com/onepage-Api/public/api/update-razerpay-payment-paid-status'
    };
      await RazerpayPaymentGateway(payment_Data,DownloadPaidPdf,true);
    Loading.remove();
    // const data = {
    //   mid: "993377",
    //   amount: amount(),
    //   merchantTransactionId: "sd12121",
    //   transactionDate: "Mon Oct 03 13:54:33 IST 2022",
    //   terminalId: "getepay.merchant131068@icici",
    //   udf1: buyerMobilenumber,
    //   udf2: checkTemplate(),
    //   udf3: buyerName,
    //   udf4: user_id,
    //   udf5: customerDataId,
    //   udf6: "",
    //   udf7: "",
    //   udf8: "",
    //   udf9: "",
    //   udf10: "",
    //   ru: "https://ctsvsolutions.com/onepage-Api/public/Bio-Payment",
    //   callbackUrl: "",
    //   currency: "INR",
    //   paymentMode: "ALL",
    //   bankId: "",
    //   txnType: "single",
    //   productType: "IPG",
    //   txnNote: "onepage-BioData",
    //   vpa: "getepay.merchant131068@icici",
    // };

    // const Config = {
    //   mid: "993377",
    //   "Getepay Terminal Id": "getepay.merchant131068@icici",
    //   "Getepay Key": "rjW99m2Vo75SBbpyc+K5nfB/eebzWUmkAtx90U5Ku3I=",
    //   "Getepay IV": "Y7fOrFLKVPosfDFSIFSISQ==",
    //   "Getepay Url":
    //     "https://portal.getepay.in:8443/getepayPortal/pg/generateInvoice",
    // };
    // getepayPortal(data, Config);
  };

  // const amount = () => {
  //   if (
  //     paidTemplate === "Template1" ||
  //     paidTemplate === "Template4" ||
  //     paidTemplate === "Template5" ||
  //     paidTemplate === "Template6"
  //   ) {
  //     return 249;
  //   } else {
  //     return 249;
  //   }
  // };
  const insertPaidData = () => {
    const storedUserInfo = localStorage.getItem("BioUserInfo");
    const user = JSON.parse(storedUserInfo);
    const user_id = user.user_id;
    const customer_data_id = user.customerDataId;
    const ChoosedTemplate = JSON.stringify(currentTemplate);
    localStorage.setItem("paidTemplate", ChoosedTemplate);

    const userData = {
      buyerName: buyerName,
      buyerEmail: email,
      buyerMobileNo: buyerMobilenumber,
      Package: "marriage_bio_data",
      Amount: 249,
      Userid: user_id,
      CustomerId: customer_data_id,
      Template: checkTemplate(),
    };

    axios
      .post("https://ctsvsolutions.com/onepage-Api/public/api/Check-Template", {
        userData,
      })
      .then((response) => {
        let status = response.data.status;
        if (status === true) {
          axios
            .post(
              "https://ctsvsolutions.com/onepage-Api/public/api/Bio-statement-insert",
              { userData }
            )
            .then((response) => {
              paymentProcess(user_id, customer_data_id);
            });
        } else {
          paymentProcess(user_id, customer_data_id);
        }
      })
      .catch((error) => {
        console.error("Error:", error);
      });
  };

  const currentLocation = window.location.href;
  const [baseUrl, queryParamString] = currentLocation.split("?");
  const queryParamsObject = {};

  if (queryParamString) {
    const queryParams = queryParamString.split("&");

    for (const param of queryParams) {
      const [key, value] = param.split("=");
      queryParamsObject[key] = value;
    }
    const payment_status = queryParamsObject["payment_status"];

    if (payment_status === "Filure") {
      Report.warning(
        "Notiflix Warning",
        '"The peoples who want to live comfortably without producing and fatigue; they are doomed to lose their dignity, then liberty, and then independence and destiny." <br/><br/>- Mustafa Kemal Ataturk',
        "Okay"
      );
    }
    if (payment_status === "CrEdiT") {
      Report.success(
        "Payment Succuss",
        "உங்கள் கட்டணம் வெற்றிகரமாக பெறப்பட்டது ! நீங்கள்  கீழே உள்ள Download ஐ  கிளிக் செய்து உங்கள் PDF ஐ பதிவிறக்கம் செய்து கொள்ளலாம் ...",
        "Download Pdf",
        () => {
          Loading.hourglass("Bio data is processing...", {
            backgroundColor: "rgba(0,0,0,0.8)",
          });
          paidPdfProcess();
        }
      );
    }
  }
  const paidpdfConvertion = () => {
    let trimmedTemplate = paidTemplate.trim().replace(/^"|"$/g, "");
    switch (trimmedTemplate) {
      case "Template1":
        return <BioTemplate1 value={value} />;
      case "Template2":

        return <BioTemplate2 value={value} />;
      case "Template3":

        return <BioTemplate3 value={value} />;
      case "Template4":

        return <BioTemplate4 value={value} />;
      case "Template5":

        return <BioTemplate5 value={value} />;
      case "Template6":
        return <BioTemplate6 value={value} />;
      default:

        return <BioTemplate1 value={value} />;
    }
  };

  const paidPdfProcess = async () => {
    const printElement = paidpdfConvertion();
    const container = document.createElement("div");
    document.body.appendChild(container);
    try {
      const canvas = await html2canvas(container, { useCORS: true });
      const imageData = canvas.toDataURL("image/jpeg", 1.0);
      const root = ReactDOM.createRoot(container);
      root.render(printElement);
      await new Promise((resolve) => setTimeout(resolve, 500));
      const storedUserInfo = localStorage.getItem("BioUserInfo");
      const userInfo = JSON.parse(storedUserInfo);
      // console.log(userInfo);
      html2pdf()
        .from(container)
        .set({
          image: { type: "jpeg", quality: 1, data: imageData },
          dpi: 300,
          renderer: "canvas",
          html2canvas: { scale: 2, useCORS: true },
        })
        .save();
      html2pdf()
        .from(container)
        .set({
          image: { type: "jpeg", quality: 1 },
          dpi: 300,
          renderer: "canvas",
          html2canvas: { scale: 2, useCORS: true },
        })
        .outputPdf()
        .then((pdfData) => {
          const base64Data = btoa(pdfData);
          savePaidPdf(base64Data, userInfo.user_id, userInfo.customerDataId);
        });
    } catch (error) {
      console.error("Error during PDF conversion:", error);
    } finally {
      document.body.removeChild(container);
    }
  };

  const savePaidPdf = (pdfData, user_id, customerDataId) => {
    const pdfDataVal = {
      pdfData: pdfData,
      userId: user_id,
      customerId: customerDataId,
      FileName: paidTemplate.trim().replace(/^"|"$/g, ""),
    };
    const DataInfo = {
      userId: user_id,
      customerId: customerDataId,
      columnName: "pdf_file",
    };
    axios
      .post(
        "https://ctsvsolutions.com/onepage-Api/public/api/Save-Paid-Pdf",
        pdfDataVal
      )
      .then((response) => {
        axios
          .post(
            "https://ctsvsolutions.com/onepage-Api/public/api/send-paid-PdfIn-whastapp",
            DataInfo
          )
          .then((response) => {
            axios
              .post(
                "https://ctsvsolutions.com/onepage-Api/public/api/Send-pdfIn-Email",
                DataInfo
              )
              .then((response) => {
                Loading.remove();
                Confirm.prompt(
                  "Rating ⭐⭐⭐⭐⭐",
                  "How was your experience?",
                  "",
                  "Answer",
                  "Cancel",
                  (clientAnswer) => {
                    const RatingInfo = {
                      user_id: user_id,
                      customer_data_id: customerDataId,
                      feedback: clientAnswer,
                    };
                    axios
                      .post(
                        "https://ctsvsolutions.com/onepage-Api/public/api/Update-Feed-Back",
                        RatingInfo
                      )
                      .then((response) => {
                        // Use window.location.replace to replace the current history entry
                        window.location.replace("/BioData-ThankYou");
                      });
                  },
                  (clientAnswer) => {
                    // Use window.location.replace to replace the current history entry
                    window.location.replace("/BioData-ThankYou");
                  }
                );
                Notify.success("Sample pdf file sent successfully");
              })
              .catch((error) => {
                console.error("Error sending WhatsApp message:", error);
              });
          })
          .catch((error) => {
            console.error("Error sending WhatsApp file:", error);
          });
      })
      .catch((error) => {
        console.error("Error saving PDF:", error);
      });
  };


  const settings = {
    infinite: true,
    slidesToShow: 4,
    autoplay: true,
    autoplaySpeed: 2000,
    slidesToScroll: 1,
    centerMode: true,
    arrows: false,
    dots: true,
    responsive: [
      {
        breakpoint: 1279,
        settings: {
          slidesToShow: 4,
          slidesToScroll: 1,
        }
      },
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
        }
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
        }
      },
      {
        breakpoint: 479,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          centerMode: false,
        }
      },
    ]
  };
  const HandletemplateChoose = (template) => {
    setTemplate(template);
    localStorage.setItem('paidTemplate', template);
    setShowPopup(true);
  };

  const ChoosedImage = () => {

    switch (currentTemplate) {
      case 'Template1':
        return 'https://ctsvsolutions.com/onepage-images/Template1.webp';
      case 'Template2':
        return 'https://ctsvsolutions.com/onepage-images/Template2.webp';
      case 'Template3':
        return 'https://ctsvsolutions.com/onepage-images/template3.webp';
      case 'Template4':
        return 'https://ctsvsolutions.com/onepage-images/template4.webp';
      case 'Template5':
        return 'https://ctsvsolutions.com/onepage-images/template5.webp';
      case 'Template6':
        return 'https://ctsvsolutions.com/onepage-images/template6.webp';
      default:
        return 'https://ctsvsolutions.com/onepage-images/template1.webp'
    }
  }

  const DownloadPaidPdf=()=>{
    paidPdfProcess();
  }

  return (
    <div>
      <section className="bd-templates">
        <div className="astro-container">
          <div className="heading">
            <h2 className="sh lh-1">
              Choose from our <span className="grt"><br />biodata template designs</span>
            </h2>
          </div>
          <div className="tabs-menu">
            <ul id="tabs-nav">
              <li><a href="#tab1">Template</a></li>
            </ul>
          </div>
          <div id="tabs-content">
            <div id="tab1" className="tab-content">
              <Slider {...settings}>
                <div className="item">
                  <div className="template card-design">
                    <img src="https://ctsvsolutions.com/onepage-images/Template1.webp" alt="bio data template 1" className='img-fluid' />
                    <button type="button" className="btn-lg border-0" onClick={(e) => {
                      HandletemplateChoose("Template1");
                    }}>Download</button>
                  </div>
                  <h3 className="h3-h">Template 1</h3>
                </div>

                <div className="item">
                  <div className="template card-design">
                    <img src="https://ctsvsolutions.com/onepage-images/Template2.webp" alt="bio data template 2" className='img-fluid' />
                    <button type="button" className="btn-lg border-0" onClick={(e) => {
                      HandletemplateChoose("Template2");
                    }}>Download</button>
                  </div>
                  <h3 className="h3-h">Template 2</h3>
                </div>

                <div className="item">
                  <div className="template card-design">
                    <img src="https://ctsvsolutions.com/onepage-images/template3.webp" alt="bio data template 3" className='img-fluid' />
                    <button type="button" className="btn-lg border-0" onClick={(e) => {
                      HandletemplateChoose("Template3");
                    }}>Download</button>
                  </div>
                  <h3 className="h3-h">Template 3</h3>
                </div>

                <div className="item">
                  <div className="template card-design">
                    <img src="https://ctsvsolutions.com/onepage-images/template4.webp" alt="bio data template 4" className='img-fluid' />
                    <button type="button" className="btn-lg border-0" onClick={(e) => {
                      HandletemplateChoose("Template4");
                    }}>Download</button>
                  </div>
                  <h3 className="h3-h">Template 4</h3>
                </div>
                <div className="item">
                  <div className="template card-design">
                    <img src="https://ctsvsolutions.com/onepage-images/template5.webp" alt="bio data template 5" className='img-fluid' />
                    <button type="button" className="btn-lg border-0" onClick={(e) => {
                      HandletemplateChoose("Template5");
                    }}>Download</button>
                  </div>
                  <h3 className="h3-h">Template 5</h3>
                </div>
                <div className="item">
                  <div className="template card-design">
                    <img src="https://ctsvsolutions.com/onepage-images/template6.webp" alt="bio data template 6" className='img-fluid' />
                    <button type="button" onClick={(e) => {
                      HandletemplateChoose("Template6");
                    }} className="btn-lg border-0">Download</button>
                  </div>
                  <h3 className="h3-h">Template 6</h3>
                </div>
              </Slider>
            </div>
          </div>
        </div>
      </section>
      {showPopup && (
        <div className="global-popup dp-popup">
          <div className="content">
            <div className="popup-close" onClick={() => setShowPopup(false)}>
              <img src='https://ctsvsolutions.com/onepage-images/new-ui/popup-cross-icon.webp' alt="Close" />
            </div>
            <img className="img-fluid" src={ChoosedImage()} width={150} height={100} alt="tamil marriage bio data" />
            <h3 className="sh">
              Download<span className="grt"> PDF</span>
            </h3>
            <p>
              Your report will be delivered to your email and WhatsApp number. Before
              payment, confirm your details.
            </p>
            <form className="cmn-form">
              <div className="form-group">
                <label htmlFor="email">Email</label>
                <input className='input' placeholder='Enter Your Email' type="email" name="email" id="email" value={email}
                  onChange={(e) => {
                    setEmail(e.target.value);
                  }} />
                <span id="name_error1" style={{ color: "red" }}>
                  {emailError}
                </span>
              </div>
              <div className="form-group">
                <label htmlFor="name">Name <span className='text-danger'>*must be in English</span></label>
                <input className='input' placeholder='Enter Your Name' type="text" name="name" id="name" onChange={(e) => setBuyername(e.target.value)}
                  value={buyerName} />
                <span id="name_error1" style={{ color: "red" }}>
                  {buyerNameError}
                </span>
              </div>
              <div className="form-group">
                <label htmlFor="whatsapp">WhatsApp Number</label>
                <input className='input' placeholder='Enter Your WhatsApp Number' type="tel" name="whatsapp" id="whatsapp" value={buyerMobilenumber}
                  onChange={(e) => setMobilenumber(e.target.value)} />
                <span id="name_error1" style={{ color: "red" }}>
                  {mobilenumberError}
                </span>
              </div>
            </form>
            <div className="buttons">
              <button type='button' className="btn-lg btn-pnk border-0" onClick={handlepay}>
                Buy Now
                <img src="https://ctsvsolutions.com/onepage-images/new-ui/btn-arrow-white.webp" alt="bio data download Arrow" />
              </button>
            </div>
          </div>
          <div className="overlay" onClick={() => setShowPopup(false)} />
        </div>
      )}
    </div>

  );
}

export default BioViewSlider;
