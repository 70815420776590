import React, { useState } from 'react'
import { Link } from 'react-router-dom';
import FAQ from '../../Main-Components/FAQ/FAQ'

export default function FAQContent() {
    const [ActiveAstrologyFeaturesTab, setActiveAstrologyFeaturesTab] = useState(1);
    return (

        <>
            <section className="waf ">
                <div className="astro-container bg-purple p-lg-5 py-5 bradius">
                    <div className="s-heading">
                        <h2 className="sh">
                            Frequently Asked<span className="grt"> Questions</span>
                        </h2>
                    </div>
                    <div className="hr-tabs-menu">
                        <ul id="tabs-nav2" className="tabs-navbar">
                            <li className={ActiveAstrologyFeaturesTab === 1 ? 'active' : ''}>
                                <Link onClick={() => setActiveAstrologyFeaturesTab(1)}
                                >Horoscope</Link>
                            </li>
                            <li className={ActiveAstrologyFeaturesTab === 2 ? 'active' : ''}>
                                <Link onClick={() => setActiveAstrologyFeaturesTab(2)}
                                >Matching </Link>
                            </li>
                            <li className={ActiveAstrologyFeaturesTab === 3 ? 'active' : ''}>
                                <Link onClick={() => setActiveAstrologyFeaturesTab(3)}
                                >Bio-Data </Link>
                            </li>
                            <li className={ActiveAstrologyFeaturesTab === 4 ? 'active' : ''}>
                                <Link onClick={() => setActiveAstrologyFeaturesTab(4)}
                                >Baby Name</Link>
                            </li>

                        </ul>
                    </div>
                    <div id="tabs-content">
                        {ActiveAstrologyFeaturesTab === 1 && (
                            <div id="hr-tab1" className="tab-content2 tab-c">
                                <div className="content">
                                    <FAQ Faqpage={true} />

                                </div>

                            </div>
                        )}
                        {ActiveAstrologyFeaturesTab === 2 && (
                            <div id="hr-tab2" className="tab-content2 tab-c">
                                <div className="content">
                                    <FAQ Type={'Marriage_matching'} Faqpage={true} />


                                </div>

                            </div>
                        )}
                        {ActiveAstrologyFeaturesTab === 3 && (
                            <div id="hr-tab3" className="tab-content2 tab-c">
                                <div className="content">
                                    <FAQ Type={'BioData'} Faqpage={true} />

                                </div>

                            </div>
                        )}
                        {ActiveAstrologyFeaturesTab === 4 && (
                            <div id="hr-tab4" className="tab-content2 tab-c">
                                <div className="content">
                                    <FAQ Type={"Baby_Name"} Faqpage={true} />
                                </div>
                            </div>
                        )}

                    </div>
                </div>
            </section>
        </>

    )
}
