import React from 'react'
import { useContext } from 'react';
import { MobileMenuContext } from "../../context/menu/MobileMenuContext";
import { Link } from 'react-router-dom';

export default function MobileMenu() {
    const [ShowMobileMenu, setMobileMenu, MobileActive, setMobileActive] = useContext(MobileMenuContext);

    const toggleMenu = () => {
        setMobileMenu(!ShowMobileMenu);
      };

      const ChangesubActive = (activeStatus) => {
        setMobileActive((prev) => ({
          ...prev,
          [activeStatus]: !prev[activeStatus],
        }));
      };
      

    return (
        <>
            {ShowMobileMenu && (
                <div className="side-mobile-menu">
                    <div className="mm-close">
                        <img src='https://ctsvsolutions.com/onepage-images/new-ui/popup-cross-icon.webp' onClick={toggleMenu} alt='close button' loading="lazy"/>
                    </div>
                    <ul>
                        <li className={`${MobileActive.active1?'active':''}`} onClick={()=>ChangesubActive('active1')}>
                            <Link>
                                Products
                                <img src='https://ctsvsolutions.com/onepage-images/new-ui/dropdown-arrow-dark.webp' alt=' dropdown icon' loading="lazy"/>
                            </Link>
                            <ul className="sub-menu">
                                <div className="sub-menu-inner">
                                    <div className="menu-item">
                                        <Link to="/Birth-horoscope">
                                            <div className="bg">
                                                <div className="icon">
                                                    <img src='https://ctsvsolutions.com/onepage-images/new-ui/sub-menu-icon.webp' alt='birth horoscope' width={100} height={40} loading="lazy" style={{objectFit:'cover'}}/>
                                                </div>
                                                <div className="content">
                                                    <h6>Birth Horoscope</h6>
                                                    <p>Accurate report, for predicting life potential.</p>
                                                </div>
                                            </div>
                                        </Link>
                                    </div>
                                    <div className="menu-item">
                                        <Link to="/marriage-matching">
                                            <div className="bg">
                                                <div className="icon">
                                                    <img src='https://ctsvsolutions.com/onepage-images/new-ui/sub-menu-icon.webp' alt='marriage matching' width={100} height={40} loading="lazy" style={{objectFit:'cover'}}/>
                                                </div>
                                                <div className="content">
                                                    <h6>Marriage Matching</h6>
                                                    <p>Marrige are made in heaven, we here give you the key.</p>
                                                </div>
                                            </div>
                                        </Link>
                                    </div>
                                    <div className="menu-item">
                                        <Link to="/Marriage-Bio-Data">
                                            <div className="bg">
                                                <div className="icon">
                                                    <img src='https://ctsvsolutions.com/onepage-images/new-ui/sub-menu-icon.webp' alt='marriage bio data' width={100} height={40} loading="lazy" style={{objectFit:'cover'}}/>
                                                </div>
                                                <div className="content">
                                                    <h6>Marriage Bio-Data</h6>
                                                    <p>We manifest about you to your life partner.</p>
                                                </div>
                                            </div>
                                        </Link>
                                    </div>
                                    <div className="menu-item">
                                        <Link to="/Baby-Name-List">
                                            <div className="bg">
                                                <div className="icon">
                                                    <img src='https://ctsvsolutions.com/onepage-images/new-ui/sub-menu-icon.webp' alt='baby name' width={100} height={40} loading="lazy" style={{objectFit:'cover'}}/>
                                                </div>
                                                <div className="content">
                                                    <h6>Baby Name</h6>
                                                    <p>Numerology inbuild names for you choice.</p>
                                                </div>
                                            </div>
                                        </Link>
                                    </div>
                                    <div className="menu-item">
                                        <Link to="/Ruthu-Jathagam">
                                            <div className="bg">
                                                <div className="icon">
                                                    <img src='https://ctsvsolutions.com/onepage-images/new-ui/sub-menu-icon.webp' alt='ruthu jathagam' width={100} height={40} loading="lazy" style={{objectFit:'cover'}}/>
                                                </div>
                                                <div className="content">
                                                    <h6>Ruthu Jathagam</h6>
                                                    <p>Age attent horoscope report for your girl children.</p>
                                                </div>
                                            </div>
                                        </Link>
                                    </div>
                                </div>
                            </ul>
                        </li>
                        <li className={`${MobileActive.active2?'active':''}`} onClick={()=>ChangesubActive('active2')}>
                            <Link>
                                Services
                                <img src='https://ctsvsolutions.com/onepage-images/new-ui/dropdown-arrow-dark.webp' alt=' dropdown icon' loading="lazy"/>
                            </Link>
                            <ul className="sub-menu">
                                <div className="sub-menu-inner">
                                    <div className="menu-item">
                                        <Link to="/Jathaga-Palan">
                                            <div className="bg">
                                                <div className="icon">
                                                      <img src='https://ctsvsolutions.com/onepage-images/new-ui/sub-menu-icon.webp' alt='audio prediction' width={100} height={40} loading="lazy" style={{objectFit:'cover'}}/>
                                                </div>
                                                <div className="content">
                                                    <h6>Audio Prediction</h6>
                                                    <p>Detail prediction about you, in audio format.</p>
                                                </div>
                                            </div>
                                        </Link>
                                    </div>
                                    <div className="menu-item">
                                    <Link to="/Tamil-jathagam-book">
                                            <div className="bg">
                                                <div className="icon">
                                                      <img src='https://ctsvsolutions.com/onepage-images/new-ui/sub-menu-icon.webp' alt='horoscope book' width={100} height={40} loading="lazy" style={{objectFit:'cover'}}/>
                                                </div>
                                                <div className="content">
                                                    <h6>hand written Horoscope Book</h6>
                                                    <p>Our special edition service for,who like vintage in every moment.</p>
                                                </div>
                                            </div>
                                        </Link>
                                    </div>
                                    <div className="menu-item">
                                        <Link to="/Talk-To-Astrologer">
                                            <div className="bg">
                                                <div className="icon">
                                                      <img src='https://ctsvsolutions.com/onepage-images/new-ui/sub-menu-icon.webp' alt='talk to astrologer' width={100} height={40} loading="lazy" style={{objectFit:'cover'}}/>
                                                </div>
                                                <div className="content">
                                                    <h6>Astro talk</h6>
                                                    <p>We suggest you certified astrologer for your query about your future.</p>
                                                </div>
                                            </div>
                                        </Link>
                                    </div>
                                </div>
                            </ul>
                        </li>
                        <li>
                        <Link to="/Blog">Blogs</Link>
                        </li>
                        <li>
                            <Link to="/About-us">About Us</Link>
                        </li>
                        <li>
                            <Link to="/contact-us">Contact Us</Link>
                        </li>
                    </ul>
                    {/* <a href="" className="btn-sm">
                        Sign In
                        <img src='https://ctsvsolutions.com/onepage-images/new-ui/btn-arrow.webp' alt='one page sign in'/>
                    </a> */}
                </div>
            )}
        </>
    )
}
